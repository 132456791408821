<template>
  <SearchLayout>
    <LoadingComponent :loading="spin > 0" />
    <Headings
      :title="$t(docType === 'CID' ? `search.titleCID` : `search.title`)"
      :back="true"
      :subtitle="(fullChapterName ? fullChapterName.toUpperCase() + ' ' : '') + organizationName"
      :use-gold-back-style="true"
    >
      <div id="inputs" class="row justify-content-between">
        <div class="col-1 col-md-1 col-lg-1 col-xl-2" />
        <div class="col-11 col-md-8 col-lg-7 col-xl-6">
          <SelectComponent
            v-model="currentVersionId"
            :tabindex="1"
            :options="versions"
            :name="$t(`search.versionControl`)"
            obj-key="displayValue"
            obj-val="id"
            class="select-big version-select-control"
          />
        </div>
        <div class="col-1 d-md-none" />
        <div class="col-11 col-md-3 col-lg-4 col-xl-4">
          <div class="form-group">
            <div class>
              <label class="select-label">
                {{ $t(`search.originalDocument`) }}
              </label>
            </div>
            <div class="d-flex actions-container">
              <div class="mr-1">
                <button
                  class="btn btn-preview"
                  :class="shortPreview ? 'btn-gold' : 'btn-green'"
                  @click="previewDocument"
                >
                  <img :src="showIcon" />
                </button>
              </div>
              <div class="ml-1">
                <button class="btn btn-gold btn-preview" @click.prevent="downloadDoc">
                  <img :src="downloadIcon" />
                </button>
              </div>
              <div class="ml-1">
                <button class="btn btn-gold btn-preview" @click.prevent="copyLink">
                  <v-icon class="copy-icon">mdi-content-copy</v-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Headings>
    <v-row class="top">
      <v-col class="flex-grow-0 pb-0">
        <button
          class="btn btn-green btn-medium download-button text-no-wrap"
          @click.prevent="downloadChapterWithAnnexes"
        >
          {{ $t(`search.downloadChapter`) }}
        </button>
      </v-col>
      <v-col cols="5" class="download_disclaimer pl-1 pb-0">
        <div class="text-uppercase">{{ $t('disclaimer.title') }}</div>
        <div>{{ $t('disclaimer.body') }}</div>
      </v-col>
      <v-col class="pb-0">
        <v-row justify="end">
          <v-tooltip v-for="link in externalLinks" :key="link.id" top>
            <template #activator="{ on }">
              <v-col class="flex-grow-0 d-flex flex-column justify-end align-center" v-on="on">
                <a :href="link.url" target="_blank" rel="noopener noreferrer">
                  <v-img v-if="link.logoUrl" :src="link.logoUrl" max-width="100" max-height="64" contain />
                </a>
                <div class="text-truncate text-center pt-2" style="max-width: 100px">{{ link.application }}</div>
              </v-col>
            </template>
            {{ link.tooltip }}
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>
    <div class="row mb-60">
      <div class="col-12">
        <div class="table-preview-shadow">
          <div v-if="shortPreview" :class="`pdf-preview ${shortPreview ? 'short-preview' : ''}`">
            <pdf v-for="i in chapterNumPages" :key="'short_' + i" :page="i" :src="chapterSrc" />
          </div>
          <div v-else :class="`pdf-preview ${shortPreview ? 'short-preview' : ''}`">
            <pdf v-for="i in documentNumPages" :key="'long_' + i" :page="i" :src="documentSrc" />
          </div>
        </div>
        <div v-if="currentVersionObject.annexes && currentVersionObject.annexes.length > 0" class="col-12">
          <div class="annexes-label">{{ $t(`common.annexes`) }} ({{ currentVersionObject.annexes.length }})</div>
        </div>
        <div v-if="currentVersionObject.annexes && currentVersionObject.annexes.length > 0" class="col-12">
          <div v-for="annex in annexesLocal" :key="annex.id" class="annex-info mb-3">
            {{
              annex.name + (annex.displayName && annex.name !== annex.displayName ? ' (' + annex.displayName + ')' : '')
            }}
            <div v-if="annex.pdfPreviewUrl" class="open-close" @click="setVisible(annex)">
              <img v-if="!annex.visible" :src="addNew" />
              <div v-else class="close_btn">
                <span class="close">{{ closeBtn }}</span>
              </div>
            </div>

            <div v-if="annex.downloadUrl" class="download-annex">
              <img :src="downloadIcon" @click="downloadAnnexFE(annex)" />
            </div>

            <div class="preview" :class="annex.visible ? 'vis' : 'hid'">
              <div class="pdf-preview-annex">
                <pdf v-for="i in annex.pages" :key="i + '_' + annex.id" :page="i" :src="annex.pdf" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row bottom">
        <div class="col-xs-12 col-md-5">
          <div class="btns-container">
            <button class="btn btn-grey btn-medium mr-3" @click.prevent="goBack">
              {{ $t(`common.back`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </SearchLayout>
</template>

<script>
import addNew from '@/assets/icons/add-row.svg';
import downloadIcon from '@/assets/icons/download.svg';
import showIcon from '@/assets/icons/show.svg';
import { mapGetters, mapActions } from 'vuex';
import pdf from '@/components/pdf/vuePdfNoSss.vue';
import SearchLayout from '@/components/layout/SearchLayout';
import Headings from '@/components/base/Headings';
import LoadingComponent from '@/components/base/LoadingComponent';
import SelectComponent from '@/components/base/SelectComponent';
import { GET_CHAPTER_PDF } from '@/utils/api.endpoints';

export default {
  name: 'SearchDocumentPreview',
  components: {
    SearchLayout,
    Headings,
    pdf,
    LoadingComponent,
    SelectComponent,
  },
  data() {
    return {
      shortPreview: true,
      downloadIcon,
      chapterNumPages: 0,
      chapterSrc: '',
      documentNumPages: 0,
      documentSrc: '',
      addNew,
      showIcon,
      spin: 0,
      annexesLocal: [],
      currentVersionId: null,
      currentVersionObject: {},
    };
  },
  computed: {
    ...mapGetters('search', {
      current: 'getCurrentSearch',
      versions: 'getVersions',
      results: 'getSearchResults',
      externalLinks: 'getExternalLinks',
      fullChapterName: 'getChapterName',
      organizationName: 'getOrgName',
    }),
    closeBtn() {
      return '\xD7';
    },
    docType() {
      return this.$route.params.type;
    },
    getYear() {
      return this.$route.params.year;
    },
    getBook() {
      return this.$route.params.book;
    },
    getCsNumber() {
      return this.$route.params.csNumber;
    },
  },
  watch: {
    shortPreview(val) {
      if (!val) {
        const url = `${this.$serverUrl}${GET_CHAPTER_PDF(this.currentVersionObject.documentToCSId)}`;
        this.documentSrc = pdf.createLoadingTask(url);
        this.documentSrc.promise.then((pdf) => {
          this.documentNumPages = pdf.numPages;
        });
      }
    },
    currentVersionObject(newVal) {
      if (newVal) {
        this.getCurrentPdfs();
      }
    },
    currentVersionId(newVal) {
      if (newVal) {
        this.currentVersionObject = this.versions.find((item) => item.id === newVal) || {};
        this.setCurrentVersionObject(this.currentVersionObject);
      }
    },
  },
  mounted() {
    this.spin++;
    this.setCurrent(Object.assign({}, this.$route.params));
    this.getVersioningPublished()
      .then(() => {
        this.currentVersionObject = this.versions[this.versions.length - 1] || {};
        this.currentVersionId = this.currentVersionObject.id;
        this.setCurrentVersionObject(this.currentVersionObject);
      })
      .finally(() => {
        this.spin -= this.spin > 0 ? 1 : 0;
      });
  },
  created() {
    //this.getAllRFCs({});
  },
  methods: {
    ...mapActions('search', [
      'getDocumentUrl',
      'getAnnexUrl',
      'getChapterUrl',
      'setCurrent',
      'setCurrentVersionObject',
      'downloadChapter',
      'downloadDocument',
      'downloadAnnex',
      'getVersioning',
      'getVersioningPublished',
      'prevDocument',
      'fetchDocumentById',
      'downloadChapterWithAnnexes',
    ]),
    ...mapActions('rfcs', ['getAllRFCs', 'getSingleRFCLogo', 'order']),
    downloadDoc() {
      this.spin++;
      this.downloadDocument({
        cb: () => {
          this.spin -= this.spin > 0 ? 1 : 0;
        },
      });
    },
    downloadAnnexFE(annex) {
      const annexFilename = annex.name;
      this.spin++;
      this.downloadAnnex({
        filename: annexFilename,
        annexDownloadUrl: annex.downloadUrl,
        cb: () => {
          this.spin -= this.spin > 0 ? 1 : 0;
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    setVisible(annex) {
      annex.visible = !annex.visible;
    },
    previewDocument() {
      this.spin++;
      this.prevDocument({
        cb: (link) => {
          this.spin -= this.spin > 0 ? 1 : 0;
          window.open(link, '_blank');
        },
        ecb: () => {
          this.spin -= this.spin > 0 ? 1 : 0;
        },
      });
    },
    getCurrentPdfs() {
      this.spin++;
      const url = `${this.$serverUrl}${GET_CHAPTER_PDF(
        this.currentVersionObject.documentToCSId || this.currentVersionObject.documentToCsLevelId
      )}`;
      this.chapterSrc = pdf.createLoadingTask(url);
      this.chapterSrc.promise.then((pdf) => {
        this.spin -= this.spin > 0 ? 1 : 0;
        this.chapterNumPages = pdf.numPages;
      });
      if ((this.currentVersionObject.annexes || []).length) {
        Promise.all(
          this.currentVersionObject.annexes.map((annex) => {
            return this.getAnnexUrl({ id: annex.id }).then((url) => {
              const annexSrc = pdf.createLoadingTask(url);
              return annexSrc.promise.then((pdf) => {
                return {
                  id: annex.id,
                  name: annex.name || 'N/A',
                  displayName: annex.displayName,
                  visible: false,
                  pages: pdf.numPages,
                  pdf: annexSrc,
                  downloadUrl: annex.downloadUrl,
                  pdfPreviewUrl: annex.pdfPreviewUrl,
                  url: url,
                };
              });
            });
          })
        )
          .then((items) => {
            this.annexesLocal = items.sort((a, b) => (a.name > b.name ? 1 : -1));
            this.$forceUpdate();
            this.isLoading = false;
          })
          .finally(() => {
            this.spin -= this.spin > 0 ? 1 : 0;
          });
      }
    },
    copyLink() {
      const url = this.$serverUrl.replace('api/', '');
      let link = '';
      if (this.docType === 'NS') {
        link = `${url}/chapter-view?type=NS&year=${this.getYear}&organization=${this.organizationName.replace(
          ' ',
          '+'
        )}`;
      } else {
        const shortName = this.organizationName
          .substring(this.organizationName.indexOf('(') + 1)
          .split(')')[0]
          .replace(' ', '+');
        link = `${url}/chapter-view?type=CID&year=${this.getYear}&organization=${shortName}&book=${this.getBook.replace(
          ' ',
          '+'
        )}`;
      }
      if (this.getCsNumber) link += `&chapter=${this.getCsNumber}`;
      const version = this.versions.filter((e) => e.id === this.currentVersionId)[0].version;
      link += `&version=${version}`;
      navigator.clipboard.writeText(link);
    },
  },
};
</script>

<style scoped>
.select-label {
  font-family: Roboto, sans-serif;
  font-size: 1.3em;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 400;
  color: #808080;
  padding-bottom: 2px;
}

.actions-container {
  width: 130px;
}

.annexes-label {
  font-size: 20px;
}

.hid {
  display: none;
}

.vis {
  display: block;
}

.pdf-preview > span {
  box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.56);
  margin-bottom: 8px;
}

.download_disclaimer {
  font-style: italic;
  font-size: 1.2em;
}

.download-button {
  padding: 5px 10px;
}

.copy-icon {
  color: white;
}
</style>
