var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.savedSearches
    ? _c(
        "Modal",
        {
          attrs: {
            "is-modal-open": _vm.isModalOpen,
            "close-dialog": _vm.closeDialog,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", { staticClass: "save-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t(`savedSearches.saveTitle`)) + " "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c("div", { staticClass: "d-flex modal-footer" }, [
                      _c("div", { staticClass: "p-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-green btn-medium mr-2",
                            on: { click: _vm.closeDialog },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]
                        ),
                      ]),
                      _c("div", { staticClass: "ml-auto p-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-green btn-medium",
                            attrs: { disabled: !_vm.selectedItem },
                            on: {
                              click: function ($event) {
                                return _vm.selectSavedSearch(_vm.selectedItem)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s("Select") + " ")]
                        ),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            1051244183
          ),
        },
        [
          _c("div", { staticClass: "subtitle" }, [
            _vm._v(
              " " + _vm._s(_vm.$t(`savedSearches.savedSearchesSubtitle`)) + " "
            ),
          ]),
          _c("div", { staticClass: "document-type" }, [
            _vm._v(" " + _vm._s(_vm.$t(`savedSearches.documentType`)) + " "),
          ]),
          _c("v-select", {
            staticClass: "saved-searches-select",
            attrs: {
              "menu-props": { bottom: true, offsetY: true, offset: 20 },
              items: _vm.types,
              "return-object": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function ({ item }) {
                    return [
                      _c("v-row", [
                        item == "NS"
                          ? _c("div", { staticClass: "document_type_ns" }, [
                              _c("img", { attrs: { src: _vm.nsSearch } }),
                            ])
                          : _c("div", { staticClass: "document_type_cid" }, [
                              _c("img", { attrs: { src: _vm.cidSearch } }),
                            ]),
                        _c("span", { staticClass: "text-card ml-8 mt-5" }, [
                          _vm._v(" " + _vm._s(item) + " "),
                        ]),
                      ]),
                    ]
                  },
                },
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      _c("v-row", [
                        item == "NS"
                          ? _c("div", { staticClass: "document_type_ns" }, [
                              _c("img", { attrs: { src: _vm.nsSearch } }),
                            ])
                          : _c("div", { staticClass: "document_type_cid" }, [
                              _c("img", { attrs: { src: _vm.cidSearch } }),
                            ]),
                        _c("span", { staticClass: "text-card ml-4" }, [
                          _vm._v(" " + _vm._s(item) + " "),
                        ]),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              2890466513
            ),
            model: {
              value: _vm.selectedType,
              callback: function ($$v) {
                _vm.selectedType = $$v
              },
              expression: "selectedType",
            },
          }),
          _c(
            "v-list",
            {
              staticClass: "overflow-y-auto saved-searches-list mt-4",
              attrs: { "max-height": "350" },
            },
            [
              _c(
                "v-list-item-group",
                {
                  staticClass: "saved-searches",
                  attrs: { color: "primary" },
                  model: {
                    value: _vm.selectedItem,
                    callback: function ($$v) {
                      _vm.selectedItem = $$v
                    },
                    expression: "selectedItem",
                  },
                },
                _vm._l(_vm.filteredSearches, function (item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      attrs: { value: item, "active-color": "primary" },
                    },
                    [
                      _c("saved-search-card", {
                        attrs: {
                          item: item,
                          "delete-item-callback": _vm.openDeleteSearchDialog,
                          "open-info": _vm.openInfo,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("DeleteSearchModal", {
            staticStyle: { "z-index": "20001" },
            attrs: {
              "is-modal-open": _vm.isDeleteSearchOpen,
              "close-dialog": _vm.closeDeleteSearchDialog,
              search: _vm.forDelete,
              "is-info": true,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }