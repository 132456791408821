var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "max-width": "100%",
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        scroller: _vm.scroller,
        disabled: _vm.disabled,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g({}, on),
                [
                  _c(
                    "v-text-field",
                    _vm._b(
                      {
                        ref: "txt",
                        class: _vm.classes,
                        attrs: {
                          readonly: "",
                          clearable: !_vm.disabled,
                          rules: _vm.checkDate,
                        },
                        model: {
                          value: _vm.dateFormatted,
                          callback: function ($$v) {
                            _vm.dateFormatted = $$v
                          },
                          expression: "dateFormatted",
                        },
                      },
                      "v-text-field",
                      _vm.$attrs,
                      false
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { slot: "prepend-inner" },
                          slot: "prepend-inner",
                        },
                        [_vm._v("mdi-calendar-month-outline")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showPicker,
        callback: function ($$v) {
          _vm.showPicker = $$v
        },
        expression: "showPicker",
      },
    },
    [
      _c("v-date-picker", {
        staticClass: "date-input-wrapper",
        attrs: {
          "first-day-of-week": "1",
          locale: _vm.$root.$i18n.locale,
          "locale-first-day-of-year": "4",
          "show-week": "",
          "allowed-dates": _vm.allowed,
          min: _vm.minimal,
          max: _vm.maximal,
        },
        on: { change: _vm.sendValue },
        model: {
          value: _vm.dateValue,
          callback: function ($$v) {
            _vm.dateValue = $$v
          },
          expression: "dateValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }