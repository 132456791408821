var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.search && _vm.search.presentation
    ? _c(
        "Modal",
        {
          attrs: {
            "is-modal-open": _vm.isModalOpen,
            "close-dialog": _vm.closeDialogCheck,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", { staticClass: "save-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t(`savedSearches.saveTitle`)) + " "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    !_vm.isInfo
                      ? _c("div", { staticClass: "d-flex" }, [
                          _c("div", { staticClass: "p-2" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-green btn-medium",
                                on: { click: _vm.closeDialog },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("common.cancel")) + " "
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "ml-auto p-2" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-green btn-medium",
                                on: { click: _vm.saveSearchButton },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("savedSearches.save")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            726925234
          ),
        },
        [
          _c("LoadingComponent", {
            attrs: { loading: _vm.isLoading },
            on: {
              "update:loading": function ($event) {
                _vm.isLoading = $event
              },
            },
          }),
          _c("div", { staticClass: "subtitle" }, [
            _vm._v(" " + _vm._s(_vm.$t(`savedSearches.saveSubtitle`)) + " "),
          ]),
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t(`savedSearches.nameLabel`)) + " "),
          ]),
          _c(
            "div",
            { staticClass: "search_name" },
            [
              _vm.search.presentation.type === "NS"
                ? _c("div", { staticClass: "document_type_ns" }, [
                    _c("img", { attrs: { src: _vm.nsSearch } }),
                  ])
                : _vm._e(),
              _vm.search.presentation.type === "CID"
                ? _c("div", { staticClass: "document_type_cid" }, [
                    _c("img", { attrs: { src: _vm.cidSearch } }),
                  ])
                : _vm._e(),
              !_vm.isInfo
                ? _c("InputField", {
                    staticClass: "input-medium input-style",
                    attrs: { tabindex: 1, "use-model": true },
                    model: {
                      value: _vm.saveSearchName,
                      callback: function ($$v) {
                        _vm.saveSearchName = $$v
                      },
                      expression: "saveSearchName",
                    },
                  })
                : _c("div", { staticClass: "rename-box" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.saveSearchName,
                          expression: "saveSearchName",
                        },
                      ],
                      ref: "input",
                      staticClass: "input-medium input-style rename-input",
                      domProps: { value: _vm.saveSearchName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.saveSearchName = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "rename-button",
                        on: { click: _vm.renameSavedSearch },
                      },
                      [_vm._v("Rename")]
                    ),
                  ]),
            ],
            1
          ),
          _c("h2", [_vm._v(_vm._s(_vm.$t(`savedSearches.parameters`)))]),
          _vm.search.presentation.type === "NS"
            ? _c("div", [
                _vm.search.presentation.ims
                  ? _c("div", { staticClass: "parameter_section" }, [
                      _c("div", { staticClass: "section_title" }, [
                        _vm._v(_vm._s(_vm.$t(`common.infrastructureManagers`))),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "list-inline" },
                        _vm._l(
                          _vm.search.presentation.ims,
                          function (im, index) {
                            return _c("li", { key: index }, [
                              _vm._v(_vm._s(im.name)),
                            ])
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.search.presentation.type === "CID"
            ? _c("div", [
                _vm.search.presentation.rfcs
                  ? _c("div", { staticClass: "parameter_section" }, [
                      _c("div", { staticClass: "section_title" }, [
                        _vm._v(_vm._s(_vm.$t(`common.railFreightCorridors`))),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "list-inline" },
                        _vm._l(
                          _vm.search.presentation.rfcs,
                          function (rfc, index) {
                            return _c("li", { key: index }, [
                              _vm._v(_vm._s(rfc.name)),
                            ])
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.search.presentation.yearsSelected) + " "),
          _vm.search.presentation.years
            ? _c("div", { staticClass: "parameter_section" }, [
                _c("div", { staticClass: "section_title" }, [
                  _vm._v(_vm._s(_vm.$t(`savedSearches.ttYear`))),
                ]),
                _c(
                  "ul",
                  { staticClass: "list-inline" },
                  _vm._l(_vm.search.presentation.years, function (year, index) {
                    return _c("li", { key: index }, [_vm._v(_vm._s(year))])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.search.presentation.selectedChapters) + " "),
          _vm.search.presentation.chapters
            ? _c(
                "div",
                { staticClass: "parameter_section" },
                [
                  _c("div", { staticClass: "section_title" }, [
                    _vm._v(_vm._s(_vm.$t(`savedSearches.includedChapters`))),
                  ]),
                  _c(
                    "v-layout",
                    {
                      staticClass: "overflow-y-auto",
                      staticStyle: { "max-height": "100px" },
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "list-inline pl-2" },
                        _vm._l(
                          _vm.search.presentation.chapters,
                          function (chapter, index) {
                            return _c("li", { key: index }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    chapter.name
                                      ? chapter.name
                                      : chapter.csNumber
                                      ? `${chapter.csNumber} ${chapter.nameEn}`
                                      : chapter.nameEn
                                  ) +
                                  " "
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("ConfirmModal", {
            staticStyle: { "z-index": "20001" },
            attrs: {
              "is-modal-open": _vm.isConfirmModalOpen,
              "close-dialog": _vm.closeConfirmModal,
              "confirm-callback": _vm.confirmCallback,
              "lable-text": _vm.confirmLableText,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }