import { axiosInstance, handleRestAPIError } from '../utils/axios.js';
import {
  SEARCH_STRUCTURE_URL,
  DOWNLOAD_FULL_DOCUMENT,
  SEARCH_URL,
  SEARCH_RESULT_DOWNLOAD_URL,
  GET_CHAPTER_PDF,
  GET_DOCUMENT_PDF,
  GET_VERSIONING,
  GET_DOCUMENT_BY_ID,
  GET_DOCUMENT_DOCX_ORIGINAL,
  GET_CHAPTER_WITH_ANNEXES,
  GET_DOCUMENT_CHAPTER,
  GET_USER_SAVED_SEARCHES,
  CREATE_USER_SAVED_SEARCHES,
  RENAME_USER_SAVED_SEARCHES,
  DELETE_USER_SAVED_SEARCHES,
} from '../utils/api.endpoints';

export const searchResults = function (data, page, cb, ecb) {
  const { type, ...reqData } = data;
  axiosInstance
    .post(SEARCH_URL(type, page), reqData)
    .then((response) => {
      cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const prevDocumentRequest = function (documentId, levelId, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': null,
    },
    responseType: 'arraybuffer',
  };

  const url = GET_DOCUMENT_PDF({
    documentId: documentId,
    levelId: levelId,
  });
  axiosInstance.get(url, config).then(
    (response) => {
      const blob = new Blob([response], {
        type: 'application/pdf;base64',
      });
      const link = URL.createObjectURL(blob);
      cb(link);
    },
    () => {
      if (ecb) ecb();
    }
  );
};

export const fetchDocumentByIdRequest = function (documentId, cb = null, ecb = null) {
  axiosInstance
    .get(GET_DOCUMENT_BY_ID(documentId))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
    });
};

export const getVersioningRequest = function (data, cb, ecb) {
  axiosInstance
    .get(GET_VERSIONING(data))
    .then((response) => {
      cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const getVersioningPublishedRequest = function (data, cb, ecb) {
  const { type, orgID, year, book, chapterID } = data;
  return axiosInstance
    .get(GET_DOCUMENT_CHAPTER(type, orgID, year, book, chapterID))
    .then((response) => {
      cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const downloadResults = function (data, cb, ecb) {
  const { type, includeAnnexes, searchResult } = data;
  const config = {
    responseType: 'arraybuffer',
  };
  axiosInstance
    .post(SEARCH_RESULT_DOWNLOAD_URL(includeAnnexes, type), searchResult, config)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const downloadChapter = function (documentToCSId, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  axiosInstance
    .get(GET_CHAPTER_PDF(documentToCSId), config)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const downloadChapterWithAnnexesRequest = function (documentToCSId, annexes, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': 'application/pdf',
    },
    responseType: 'arraybuffer',
  };
  axiosInstance
    .get(GET_CHAPTER_WITH_ANNEXES(annexes, documentToCSId), config)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const downloadDocument = function (data, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': null,
    },
    responseType: 'arraybuffer',
  };
  axiosInstance
    .get(
      GET_DOCUMENT_PDF({
        documentId: data.documentId,
        levelId: data.levelId,
      }),
      config
    )
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const downloadAnnex = function (data, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': null,
    },
    responseType: 'arraybuffer',
  };
  axiosInstance
    .get(data.annexDownloadUrl, config)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const downloadDocumentOriginal = function (data, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': null,
    },
    responseType: 'arraybuffer',
  };
  axiosInstance
    .get(
      GET_DOCUMENT_DOCX_ORIGINAL({
        documentId: data.documentId,
        levelId: data.levelId,
      }),
      config
    )
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const getCommonStructureSearch = function (data, cb, ecb) {
  axiosInstance
    .get(SEARCH_STRUCTURE_URL(data))
    .then((response) => {
      cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const downloadFullDocumentZip = function (data, cb, ecb) {
  const config = {
    responseType: 'arraybuffer',
  };
  axiosInstance
    .get(DOWNLOAD_FULL_DOCUMENT(data), config)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
    });
};

export const fetchUserSearches = function (cb, ecb) {
  axiosInstance
    .get(GET_USER_SAVED_SEARCHES)
    .then((response) => {
      cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const saveUserSearch = function (data, cb, ecb) {
  console.log(data);
  axiosInstance
    .post(CREATE_USER_SAVED_SEARCHES, data)
    .then((response) => {
      cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const renameUserSearch = function (searchId, data, cb, ecb) {
  axiosInstance
    .put(RENAME_USER_SAVED_SEARCHES(searchId), data)
    .then((response) => {
      cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const deleteUserSearch = function (searchId, cb, ecb) {
  axiosInstance
    .delete(DELETE_USER_SAVED_SEARCHES(searchId))
    .then((response) => {
      cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else handleRestAPIError(error);
    });
};

export const convertInfo = function (item) {
  const chapters = [];
  const yearsSelected = [];
  for (const cs of item.searchParameters.commonStructures) {
    yearsSelected.push(cs.csId);
    for (const chapter of cs.selectedChapters) {
      chapters.push(chapter);
    }
  }

  const infoSearch = {
    name: item.name,
    presentation: {
      type: item.searchParameters.type,
      ims: item.searchParameters.imIds.map((e) => {
        return {
          value: e.id,
          name: e.name,
        };
      }),
      rfcs: item.searchParameters.rfcIds.map((e) => {
        return {
          value: e.id,
          name: e.name,
        };
      }),
      years: item.searchParameters.yearsSelected,
      chapters: chapters,
      counries: item.searchParameters.countryIds,
    },
    data: {
      id: item.id,
      type: item.searchParameters.type,
      selectedCountry: item.searchParameters.countryIds[0] ? item.searchParameters.countryIds[0].id : null,
      yearsSelected: yearsSelected,
      selectedChapters: chapters,
      includeAnnexes: item.includeAnnexes,
      text: item.query,
    },
  };

  return infoSearch;
};
