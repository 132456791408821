var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { right: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: tooltip }) {
            return [
              _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": "",
                    "nudge-bottom": "4",
                    origin: "top left",
                    transition: "scale-transition",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: menu }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                { attrs: { fab: "", depressed: "" } },
                                { ...tooltip, ...menu }
                              ),
                              [
                                _vm.currentLocale && _vm.currentLocale.flagUrl
                                  ? _c("v-avatar", [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.currentLocale.flagUrl,
                                        },
                                      }),
                                    ])
                                  : [
                                      _vm._v(
                                        _vm._s(
                                          (
                                            (_vm.currentLocale || {}).locale ||
                                            ""
                                          ).toUpperCase()
                                        )
                                      ),
                                    ],
                              ],
                              2
                            ),
                            _c("LoadingComponent", {
                              attrs: { loading: _vm.loading },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "pa-0" },
                    _vm._l(_vm.sortedLanguages, function (lang) {
                      return _c(
                        "v-list-item",
                        {
                          key: lang.id,
                          on: {
                            click: function ($event) {
                              return _vm.changeLanguage(lang)
                            },
                          },
                        },
                        [
                          _c("v-list-item-avatar", [
                            lang.flagUrl
                              ? _c("img", {
                                  staticStyle: { border: "1px solid #ddd" },
                                  attrs: { src: lang.flagUrl },
                                })
                              : _vm._e(),
                          ]),
                          _c("v-list-item-content", [
                            _vm._v(_vm._s(lang.name)),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" " + _vm._s((_vm.currentLocale || {}).name) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }