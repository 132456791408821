<template>
  <DefaultLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <Headings
      class="d-inline-block"
      :title="$t(`users.usersManagementProfile`)"
      back-location="UsersList"
      :subtitle="username"
    />
    <v-row>
      <v-col cols="12" sm="12" md="5">
        <h2 class="heading3">{{ $t(`users.userProfile`) }}</h2>
        <v-card id="user_data_card" class="mr-0 mb-3 pt-0 pb-0" max-width="400" elevation="0">
          <v-card-text>
            <div class="row">
              <div class="col-12">
                <v-list-item-avatar class="details-avatar">
                  <img src="@/assets/avatar1.png" />
                </v-list-item-avatar>
              </div>
              <div id="user_name_label" class="col-12 pt-0 mb-4">
                <span id="user_name">{{ username }}</span>
              </div>
              <div id="user_country_label" class="col-12 pt-0 pb-0">
                {{ $t(`search.country`) }}
                <span id="user_country">{{ userCountry }}</span>
              </div>
              <div id="user_company_label" class="col-12 pt-0 pb-0">
                {{ $t(`users.company`) }}
                <span id="user_company">
                  <u>{{ userCompany }}</u>
                </span>
              </div>
              <div id="user_entity_label" class="col-12 pt-0 pb-6">
                {{ $t(`common.typeOfEntity`) }}
                <span id="user_entity">{{ userEntity }}</span>
              </div>
              <div id="user_email_label" class="col-12 pt-0">
                {{ $t(`common.username`) }}
                <span id="user_email">
                  <u>{{ userEmail }}</u>
                </span>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-col sm="12">
          <p id="date_of_registration">
            {{ $t(`users.registered`) }}
            {{ userRegistrationDate }}
          </p>
        </v-col>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <h2 class="heading3">{{ $t(`users.accessRights`) }}</h2>
        <v-card id="user_inputs_card" class="mr-0 mb-3 pt-0 pb-0" max-width="500" color="#F8F8F8" elevation="0">
          <v-card-text class="pl-0">
            <div class="role_container">
              <SelectComponent
                v-model="roleType"
                class="selection-bold"
                :tabindex="1"
                :options="roleTypes"
                :placeholder="roleType"
                :name="$t(`users.roleType`)"
                obj-key="name"
                obj-val="value"
              />
            </div>
            <div class="update_email_container">
              <InputField
                class="selection-bold"
                :tabindex="2"
                :name="$t(`users.email`)"
                :value.sync="updatedEmail"
                :use-model="true"
                @update="updatedEmail = $event"
              />
              <p id="update_email_feedback">{{ emailFeedback }}</p>
            </div>
            <template v-if="roleType == 'DOCUMENT_MANAGER'">
              <div class="access_im_container">
                <MultipleSelectComponent
                  v-model="accessIMModel"
                  :tabindex="3"
                  :options="imTypes"
                  :name="$t(`users.accessToIms`)"
                  obj-key="name"
                  obj-val="value"
                  class="multiselect-big"
                />
              </div>
              <div class="access_rfc_container">
                <MultipleSelectComponent
                  v-model="accessRFCModel"
                  :tabindex="4"
                  :options="rfcTypes"
                  :name="$t(`users.accessToRfcs`)"
                  obj-key="name"
                  obj-val="value"
                  class="multiselect-big"
                />
              </div>
              <div class="access_additional_container">
                <label id="access_additional_label">
                  {{ $t(`users.additionalAccess`) }}
                </label>
                <div class="access_additional_switch_container">
                  <div class="switch_container mt-1">
                    <p id="access_additional_txt">
                      {{ $t(`users.giveAccessToMasterCID`) }}
                    </p>
                    <div class="switch_options_container">
                      <p id="yes_option_label" :class="{ 'switch-selected': masterUploadRight }">
                        {{ $t(`common.yes`).toUpperCase() }}
                      </p>
                      <label class="switch">
                        <input v-model="masterUploadRight" type="checkbox" @click="getAdditionalAccessStatus" />
                        <span :class="{ active: masterUploadRight }" class="slider round" />
                      </label>
                      <p id="no_option_label" :class="{ 'switch-selected': !masterUploadRight }">
                        {{ $t(`common.no`).toUpperCase() }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="7">
        <div class="btns-container">
          <button class="back_user_btn" @click.prevent="goBack">
            {{ $t(`common.back`) }}
          </button>
          <button class="save_btn" @click.prevent="updateUser">
            {{ $t(`common.save`) }}
          </button>
          <button class="set_pass_btn" @click="openDialog">
            {{ $t(`users.setUserPassword`) }}
          </button>
          <button class="delete_btn" @click="deleteUser">
            {{ $t(`users.deleteUser`) }}
          </button>
        </div>
      </v-col>
    </v-row>
    <ChangeUserPassword :is-modal-open="isModalOpen" :close-dialog="closeDialog" :user-id="userID" />
    <ConfirmModal
      :is-modal-open="isConfirmModalOpen"
      :close-dialog="closeConfirmModal"
      :confirm-callback="confirmCallback"
      :lable-text="confirmLableText"
      style="z-index: 20001"
    />
  </DefaultLayout>
</template>

<script>
import Headings from '@/components/base/Headings';
import DefaultLayout from '@/components/layout/DefaultLayout';
import SelectComponent from '@/components/base/SelectComponent';
import InputField from '@/components/base/InputField';
import MultipleSelectComponent from '@/components/base/MultipleSelectComponent';
import LoadingComponent from '@/components/base/LoadingComponent';
import ChangeUserPassword from '@/components/users/ChangeUserPassword';
import { isEmailValid } from '@/utils/utils.js';

import { mapActions, mapState, mapMutations } from 'vuex';
import ConfirmModal from '../common/modals/ConfirmModal';

export default {
  name: 'UserDetails',
  components: {
    DefaultLayout,
    Headings,
    SelectComponent,
    MultipleSelectComponent,
    InputField,
    LoadingComponent,
    ChangeUserPassword,
    ConfirmModal,
  },
  data() {
    return {
      userID: null,
      username: null,
      userCompany: null,
      userEntity: null,
      userEmail: null,
      userRole: null,
      userRegistrationDate: null,
      userIMs: [],
      userRFCs: [],
      roleTypes: [
        {
          name: 'Regular user',
          value: 'REGULAR_USER',
        },
        {
          name: 'Translator',
          value: 'TRANSLATOR',
        },
        {
          name: 'Document manager',
          value: 'DOCUMENT_MANAGER',
        },
        {
          name: 'Administrator',
          value: 'ADMIN',
        },
      ],
      accessIMModel: [],
      accessRFCModel: [],
      roleType: null,
      masterUploadRight: false,
      updatedEmail: null,
      emailFeedback: null,
      isLoading: false,
      oldMasterPermissionsValue: false,
      isModalOpen: false,
      isConfirmModalOpen: false,
      confirmLableText: '',
      confirmCallback: null,
    };
  },
  computed: {
    ...mapState({
      editedUser: (state) => state.users.current,
      userCountry: (state) => state.countries.current.name,
      rfcTypes: (state) =>
        state.rfcs.data.map((rfc) => {
          return {
            value: rfc.id,
            name: rfc.name,
          };
        }),
      imTypes: (state) =>
        state.ims.data.map((im) => {
          return {
            value: im.id,
            name: im.name,
          };
        }),
    }),
  },
  created() {
    this.isLoading = true;
    this.userID = this.$route.params.id;
    this.clearUserState();
    this.getUser();
    this.getAllRFCs({});
    this.getAllIMs({});
  },
  methods: {
    ...mapActions('users', [
      'getUserById',
      'updateUserById',
      'clearUserState',
      'grantMasterCIDPermissions',
      'revokeMasterCIDPermissions',
      'deleteUserById',
    ]),
    ...mapActions('rfcs', ['getAllRFCs', 'grantAccessForUser', 'revokeAccessForUser', 'clearUsersList']),
    ...mapActions('ims', ['getAllIMs']),
    ...mapActions('countries', ['getCountry']),
    ...mapMutations('users', ['setUsersList']),
    setUserProperties() {
      const user = this.editedUser;
      this.username = this.formatUsername();
      this.getCountry({ id: user.countryId });

      this.userCompany = user.companyName;
      this.userEntity = user.organizationType.name;
      this.userEmail = user.email;
      this.updatedEmail = this.userEmail;
      this.userRole = user.roles;
      this.roleType = this.userRole;
      this.accessRFCModel = user.railFreightCorridorOverviews
        .filter((rfc) => rfc !== null)
        .map((rfc) => {
          return {
            value: rfc.id,
            name: rfc.name,
          };
        });

      this.userRFCs = this.accessRFCModel.map((model) => model.value);
      this.masterUploadRight = user.masterUploadRight;

      // TODO Fix this
      this.accessIMModel = user.infrastructureManagerOverviews
        .filter((im) => im !== null)
        .map((im) => {
          return {
            value: im.id,
            name: im.name,
          };
        });

      this.userIMs = this.accessIMModel.map((model) => model.value);
      this.userRegistrationDate = new Date(Date.parse(user.termsAndConditionsConsentAt)).toLocaleString();
      this.isLoading = false;
    },
    formatUsername() {
      return this.editedUser ? this.editedUser.firstName + ' ' + this.editedUser.familyName : '';
    },
    getAdditionalAccessStatus() {
      //
    },
    goBack() {
      this.$router.push({ name: 'UsersList' });
    },
    updateUser() {
      if (!isEmailValid(this.updatedEmail)) {
        this.$root.$emit('toast-error', this.$t('validationRules.invalid_email'));
        return;
      }

      this.isLoading = true;
      let userUpdatedData;
      if (this.roleType && this.updatedEmail) {
        userUpdatedData = {
          email: this.updatedEmail,
          roles: this.roleType,
        };
      }
      if (!this.updatedEmail) {
        userUpdatedData = {
          email: this.userEmail,
          roles: this.roleType,
        };
      }

      userUpdatedData.userId = this.userID;
      this.oldMasterPermissionsValue = this.masterUploadRight;
      this.updateUserById({
        data: userUpdatedData,
        cb: (user) => {
          if (this.oldMasterPermissionsValue !== user.masterUploadRight) {
            this.oldMasterPermissionsValue
              ? this.grantMasterCIDPermissions({ userId: user.userId })
              : this.revokeMasterCIDPermissions({ userId: user.userId });
          }
          this.isLoading = false;
          this.giveAccess();
        },
        ecb: () => {
          this.isLoading = false;
          this.$root.$emit('toast-error', this.$t('users.update_failed'));
        },
      });
    },
    openDialog() {
      this.isModalOpen = true;
    },
    closeDialog() {
      this.isModalOpen = false;
    },
    giveAccess() {
      this.isLoading = true;
      if (this.roleType === 'DOCUMENT_MANAGER') {
        const grantIMAccessFor = this.accessIMModel
          .filter((im) => !this.userIMs.includes(im.value))
          .map((validAccess) => validAccess.value);

        const grantRFCAccessFor = this.accessRFCModel
          .filter((rfc) => !this.userRFCs.includes(rfc.value))
          .map((validAccess) => validAccess.value);

        if (grantIMAccessFor.length || grantRFCAccessFor.length) {
          const userAccessData = {
            userId: this.userID,
            imIds: grantIMAccessFor,
            // TODO Fix this
            rfcIds: grantRFCAccessFor,
          };
          this.grantAccessForUser({
            data: userAccessData,
            cb: () => {
              this.getUser();
              this.isLoading = false;
            },
          });
        } else {
          this.isLoading = false;
        }

        const revokeIMAccessFor = this.userIMs.filter((im) => !this.accessIMModel.some((model) => model.value === im));
        const revokeRFCAccessFor = this.userRFCs.filter(
          (rfc) => !this.accessRFCModel.some((model) => model.value === rfc)
        );

        if (revokeIMAccessFor.length || revokeRFCAccessFor.length) {
          this.revokeAccessForUser({
            data: {
              userId: this.userID,
              imIds: revokeIMAccessFor,
              rfcIds: revokeRFCAccessFor,
              cb: () => {
                this.isLoading = false;
              },
            },
          });
        } else {
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
        this.$root.$emit('toast-success', this.$t('users.user_updated'));
      }
    },
    deleteUser() {
      this.openConfirmModal(this.$t(`users.userDeleteConfirmation`), () => {
        this.deleteUserById({
          id: this.userID,
          cb: () => {
            this.setUsersList([]);
            this.$router.push({
              name: 'UsersList',
            });
          },
          ecb: () => {
            this.$root.$emit('toast-error', this.$t('users.delete_failed'));
          },
        });
        this.isConfirmModalOpen = false;
      });
    },
    getUser() {
      this.getUserById({ userId: this.userID }).then(() => {
        this.setUserProperties();
      });
    },
    checkAndUpdateEmail(email) {
      if (!email.includes('@')) {
        this.emailFeedback = this.$t('validationRules.invalid_email');
      } else {
        this.updatedEmail = email;
        this.emailFeedback = null;
      }
    },
    openConfirmModal(text, callback) {
      this.confirmLableText = text;
      this.confirmCallback = callback;
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
  },
};
</script>

<style scoped>
select,
input {
  background-color: #fff;
}

>>> .content-col {
  background-color: #f8f8f8;
}

>>> .selection-bold select,
>>> .selection-bold input {
  font-family: Montserrat, sans-serif;
  font-size: 1.4em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #080808;
  height: 56px;
  background-color: #fff;
}

.heading3 {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #080808;
}

#user_data_card {
  margin: 19px 30px 30px 0;
  padding: 30px;
}

.role_container,
.access_im_container {
  width: 100%;
  display: inline-block;
}

#user_country_label,
#user_company_label,
#user_entity_label,
#user_email_label {
  font-family: Roboto, sans-serif;
  font-size: 1.4em;
  color: #808080;
}

#user_country,
#user_company,
#user_entity,
#user_email {
  font-family: Montserrat, sans-serif;
  font-size: 1.15em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #080808;
  margin-left: 10px;
}

#user_company {
  font-weight: 500;
}

#user_email {
  color: #bf873d;
}

#user_inputs_card {
  padding-top: 0px;
}

.set_pass_btn,
.back_user_btn,
.save_btn {
  width: 20%;
  height: 48px;
  border-radius: 8px;
  background-color: #e3e3e3;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #404040;
  margin: 8px;
}

.save_btn {
  background-color: #2b5f1b;
  color: #fff;
}

.set_pass_btn {
  background-color: #fff;
  border: 0.4px solid grey;
  border-radius: 8px;
}

.delete_btn {
  width: 30%;
  height: 48px;
  border: 0.4px solid grey;
  border-radius: 8px;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #404040;
  margin: 8px;
}

@media screen and (max-width: 750px) {
  #user_data_card {
    padding: 0px;
  }
}

#update_email_feedback {
  font-size: 12px;
  color: red;
}

#date_of_registration {
  font-size: 12px;
  color: #c0c0c0;
  font-style: italic;
}

.details-avatar {
  width: 120px !important;
  height: 120px !important;
}

#user_name {
  font-size: 1.8em;
  font-weight: 500;
  color: #080808;
}

.v-card__text {
  font-size: 10px;
}

.form-group {
  margin-bottom: 16px;
}

.slider {
  background-color: transparent;
}

.slider::before {
  transform: translateX(15px);
}

.slider.active {
  background-color: rgb(198, 241, 184);
}

.slider.active::before {
  transform: translateX(0px);
}
</style>
