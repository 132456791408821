<template>
  <v-tooltip right>
    <template #activator="{ on: tooltip }">
      <v-menu offset-y nudge-bottom="4" origin="top left" transition="scale-transition">
        <template #activator="{ on: menu }">
          <v-btn fab depressed v-on="{ ...tooltip, ...menu }">
            <v-avatar v-if="currentLocale && currentLocale.flagUrl">
              <img :src="currentLocale.flagUrl" />
            </v-avatar>
            <template v-else>{{ ((currentLocale || {}).locale || '').toUpperCase() }}</template>
          </v-btn>
          <LoadingComponent :loading="loading" />
        </template>
        <v-list class="pa-0">
          <v-list-item v-for="lang in sortedLanguages" :key="lang.id" @click="changeLanguage(lang)">
            <v-list-item-avatar>
              <img v-if="lang.flagUrl" :src="lang.flagUrl" style="border: 1px solid #ddd" />
            </v-list-item-avatar>
            <v-list-item-content>{{ lang.name }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    {{ (currentLocale || {}).name }}
  </v-tooltip>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import LoadingComponent from '@/components/base/LoadingComponent.vue';

export default {
  name: 'LanguageSelector',
  components: {
    LoadingComponent,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('languages', ['getLocales']),
    currentLocale() {
      const current = this.$root.$i18n.locale;
      return this.getLocales.find((item) => item.locale === current);
    },
    sortedLanguages() {
      const current = this.$root.$i18n.locale;
      return this.getLocales
        .filter((item) => item.locale !== current)
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? +1 : 0));
    },
  },
  methods: {
    ...mapMutations('languages', ['setLocale']),
    changeLanguage(lang) {
      this.loading = true;
      this.$axios
        .get(`languages/${lang.id}/translations`)
        .then((response) => {
          this.$root.$i18n.setLocaleMessage(lang.locale, response);
          this.$root.$i18n.locale = lang.locale;
          this.setLocale(lang.locale);
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
