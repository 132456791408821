// Check Sessions
export const CHECK_SESSION_URL = 'sessions';

// Users Authentication
export const SESSIONS_URL = 'sessions';
export const LOGIN_AS_GUEST_URL = 'sessions/guest';
export const REGISTER_USER_URL = 'users';

// Common Endpoints
export const COUNTRIES_URL = 'countries';
export const ENTITIES_URL = 'type-of-entities';
export const SEARCH_URL = (type, page = 0) => `search?type=${type}&page=${page}`;
export const SEARCH_RESULT_DOWNLOAD_URL = (includeAnnexes, type) =>
  `search/result?includeAnnexes=${includeAnnexes}&type=${type}`;
export const SEARCH_STRUCTURE_URL = (type) => `search/search-cs?type=${type}`;
export const GET_CHAPTER_PDF = (documentToCsId) => `search/chapter?documentToCSId=${documentToCsId}`;
export const GET_CHAPTER_PDF_WITH_ANNEXES = (annexes, documentToCsId) =>
  `search/chapter?annexesIds=${annexes}&documentToCSId=${documentToCsId}`;
export const GET_ANNEX_ORIGINAL = ({ annexId }) => `upload-document/annexes/${annexId}/content`;
export const GET_ANNEX_PDF = (annexId) => `search/annex/${annexId}`;
export const GET_DOCUMENT_PDF = ({ documentId, levelId }) =>
  `search/document?csLevelId=${levelId}&documentId=${documentId}`;
export const GET_DOCUMENT_DOCX_ORIGINAL = ({ documentId, levelId }) =>
  `search/document/original?csLevelId=${levelId}&documentId=${documentId}`;

export const DOWNLOAD_FULL_DOCUMENT = ({ imId, rfcId, commonStructureId, bookIds }) =>
  `search/full-document/zip?imId=${imId || ''}&rfcId=${rfcId || ''}&commonStructureId=${commonStructureId}&bookIds=${
    bookIds || ''
  }`;
export const GET_VERSIONING = (documentId) => `version-control/${documentId}`;
export const GET_PUBLISHED_DOC_VERSIONING = (csLevelId, documentId) =>
  `version-control/search/published/doc?csLevelId=${csLevelId}&documentId=${documentId}`;
export const GET_DOCUMENT_CHAPTER = (type, orgID, year, book, chapterID) =>
  `documents/chapter?type=${type || ''}&organizationId=${orgID || ''}&year=${year || ''}&book=${book || ''}&chapterId=${
    chapterID || ''
  }`;
export const GET_CHAPTER_WITH_ANNEXES = (annexIds, documentToCSId) =>
  `search/chapter/with-annexes?annexesIds=${annexIds}&documentToCSId=${documentToCSId}`;

// RFCs URLS
export const GET_RFCS_URL = 'rail_freight_corridors';
export const CREATE_RFCS_URL = 'rail_freight_corridors';
export const GET_SINGLE_RFCS_URL = (id) => `rail_freight_corridors/${id}`;
export const UPDATE_SINGLE_RFCS_URL = (id) => `rail_freight_corridors/${id}`;
export const DELETE_SINGLE_RFCS_URL = (id) => `rail_freight_corridors/${id}`;
export const RFCS_LOGO_URL = (id) => `rail_freight_corridors/${id}/logo`;
export const GRANT_ACCESS_FOR_USER = (userId) => `/admin-users/grant-access/${userId}`;
export const REVOKE_ACCESS_FOR_USER = (userId) => `/admin-users/revoke-access/${userId}`;
export const DELETE_USER = `/users/me`;

//IMs URLs
export const GET_IMS_URL = 'infrastructure_managers';
export const CREATE_IMS_URL = 'infrastructure_managers';
export const GET_SINGLE_IMS_URL = (id) => `infrastructure_managers/${id}`;
export const UPDATE_SINGLE_IMS_URL = (id) => `infrastructure_managers/${id}`;
export const DELETE_SINGLE_IMS_URL = (id) => `infrastructure_managers/${id}`;
export const IMS_LOGO_URL = (id) => `infrastructure_managers/${id}/logo`;

//Countries URLs
export const CREATE_COUNTRY_URL = 'countries';
export const GET_SINGLE_COUNTRY_URL = (id) => `countries/${id}`;
export const UPDATE_SINGLE_COUNTRY_URL = (id) => `countries/${id}`;
export const DELETE_SINGLE_COUNTRY_URL = (id) => `countries/${id}`;
export const GET_ALL_COUNTRIES_URL = 'countries';
export const GET_MEMBERS_COUNTRIES_URL = 'countries?membersOnly=true';
export const GET_ALL_TAGS = 'tags';

//Forgotten password URLs
export const RESET_PASSWORD_URL = 'users/password';
export const SET_NEW_PASSWORD_URL = 'users/password/validation';

// Users
export const GET_ADMIN_USERS = 'admin-users';
export const GET_USERS_BY_CRITERIA = (username, organizationType, role, orderBy, order, page = 0, pageSize = 10) =>
  `admin-users?search=${username}&organizationType=${organizationType}&role=${role}&orderBy=${orderBy}&order=${order}&page=${page}&pageSize=${pageSize}`;
export const UPDATE_USER_PROFILE = 'users/me';
export const UPDATE_USER_PASSWORD = 'users/me/password';
export const CHANGE_USER_PASSWORD = (userId) => `admin-users/${userId}/password`;
export const GET_SUBSCRIBED_IMS = 'user-notifications/subscribe/IMs';
export const SUBSCRIBE_IMS = (id) => `user-notifications/subscribe/IMs/${id}`;
export const UNSUBSCRIBE_IMS = (id) => `user-notifications/unsubscribe/IMs/${id}`;
export const GET_SUBSCRIBED_RFCS = 'user-notifications/subscribe/RFCs';
export const SUBSCRIBE_RFC = (id) => `user-notifications/subscribe/RFCs/${id}`;
export const UNSUBSCRIBE_RFC = (id) => `user-notifications/unsubscribe/RFCs/${id}`;

// User Details
export const GET_USER_BY_ID = (userId) => `admin-users/${userId}`;
export const DELETE_USER_BY_ID = (userId) => `admin-users/${userId}`;
export const UPDATE_USER_BY_ID = (userId) => `admin-users/update/${userId}`;
export const GRANT_MASTER_UPLOAD_ACCESS_TO_USER_BY_ID = (userId) => `admin-users/grant-access/master-upload/${userId}`;
export const REVOKE_MASTER_UPLOAD_ACCESS_TO_USER_BY_ID = (userId) =>
  `admin-users/revoke-access/master-upload/${userId}`;

// User Saved Searches
export const GET_USER_SAVED_SEARCHES = 'users/me/saved-searches';
export const CREATE_USER_SAVED_SEARCHES = 'users/me/saved-searches';
export const RENAME_USER_SAVED_SEARCHES = (saveSearchId) => `users/me/saved-searches/${saveSearchId}`;
export const DELETE_USER_SAVED_SEARCHES = (saveSearchId) => `users/me/saved-searches/${saveSearchId}`;

// Common Structure URLs URLs
export const GET_CS_URL = (type) => `common-structures?type=${type}`;
export const CREATE_CS_URL = (csId) => `common-structures${csId ? '?csId=' + csId : ''}`;
export const GET_SINGLE_CS_URL = (id) => `common-structures/${id}`;
export const DELETE_SINGLE_CS_URL = (id) => `common-structures/${id}`;
export const CREATE_CS_LEVEL_URL = (id) => `common-structures/${id}/levels`;

export const UPDATE_CS_LEVEL_URL = (id, levelId) => `common-structures/${id}/levels/${levelId}`;
export const DELETE_CS_LEVEL_URL = (id, levelId) => `common-structures/${id}/levels/${levelId}`;
export const MOVE_CS_LEVEL_URL = (id, levelId, newPosition, parentId) =>
  `common-structures/${id}/levels/${levelId}/move/${newPosition}?parentId=${parentId}`;
export const GET_CS_BY_TYPE_AND_DATE = (type, year, flat = true) =>
  `common-structures/search/by-year?type=${type}&year=${year}&flat=${flat}`;
export const ADD_CS_TAG_URL = (id, levelId, tagId) => `common-structures/${id}/levels/${levelId}/add-tag/${tagId}`;
export const REMOVE_CS_TAG_URL = (id, levelId, tagId) =>
  `common-structures/${id}/levels/${levelId}/remove-tag/${tagId}`;
export const GET_CS_YEARS = `common-structures/years`;

// Document upload
export const GET_ALL_UPLOADED_DOCUMENTS = ({
  type = '',
  year = '',
  ims = '',
  rfcs = '',
  page = 0,
  pageSize = 10,
  orderBy = '',
  orderType = '',
}) =>
  `upload-document?type=${type}&year=${year}&imIds=${ims}&rfcIds=${rfcs}&page=${page}&pageSize=${pageSize}&order=${orderType}&orderBy=${orderBy}`;
export const GET_DOCUMENT_BY_ID = (documentId) => `upload-document/${documentId}`;
export const UPLOAD_DOCUMENT = (type) => `upload-document/${type}`;
export const UPLOAD_ANNEX_DOCUMENT = (type) => `upload-document/${type}/annex`;
export const UPDATE_ANNEX_CHAPTERS = (annexId) => `upload-document/annexes/${annexId}`;
export const DELETE_ANNEX_DOCUMENT = (annexId) => `upload-document/annex/${annexId}`;
export const REUPLOAD_ANNEX_DOCUMENT = (type, documentId) => `upload-document/${type}/annex/${documentId}`;
export const PUBLISH_DOCUMENT_TYPE = (type, append) => `upload-document/${type}/publish${append}`;
export const UNPUBLISH_DOCUMENT = (documentId) => `documents/${documentId}/unpublish`;
export const DELETE_WHOLE_YEAR = (documentId) => `documents/${documentId}/whole-year`;
export const EXISTING_BOOK_CHECK = (csLevelId, documentId) =>
  `upload-document/existing-book-check?csLevelId=${csLevelId}&documentId=${documentId}`;
export const GET_EXISTING_FILES = (rfcId, csLevelName) =>
  `upload-document/previous-documents/years?rfcId=${rfcId}&commonStructureLevelName=${csLevelName}`;
export const COPY_EXISTING_FILE = (rfcId, csLevelId, newDocumentId, year) =>
  `upload-document/previous-documents/duplicate?rfcId=${rfcId}&csLevelId=${csLevelId}&newDocumentId=${newDocumentId}&year=${year}`;
export const UPLOAD_MASTER_CID = `upload-document/master/cid`;
export const UPLOAD_MASTER_ANNEX_CID = `upload-document/master/cid/annex`;
export const REUPLOAD_MASTER_ANNEX_CID = (documentId) => `upload-document/master/cid/annex/${documentId}`;
export const DOWNLOAD_MASTER_CID = (year) => `upload-document/master/cid/download?year=${year}`;
export const CREATE_DOCUMENT = (type, append) => `upload-document/document/${type}${append}`;
export const CREATE_MASTER_DOCUMENT = (append) => `upload-document/master/document/cid/${append}`;
export const GET_DOCUMENT_ANALYSIS = (documentId) => `document-analysis/analyze-document/${documentId}`;
export const CREATE_DOCUMENT_COPY = (sourceDocumentId, newVersion) =>
  `upload-document/create-copy?sourceDocumentId=${sourceDocumentId}&newVersion=${newVersion}`;

// tags
export const GET_TAGS_URL = `tags`;
export const CREATE_TAG_URL = `tags`;
export const GET_TAG_DETAILS = (id) => `tags/${id}`;
export const UPDATE_TAG_URL = (id) => `tags/${id}`;
export const DELETE_TAG_URL = (id) => `tags/${id}`;
