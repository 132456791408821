var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "Modal",
        {
          staticClass: "subscriptions-modal",
          attrs: {
            "is-modal-open": _vm.isModalOpen,
            "close-dialog": _vm.closeDialog,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.$t(`common.password`)) + " ")]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-green btn-change-password",
                            on: { click: _vm.changePassword },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t(`common.setPassword`)) + " "
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            on: { click: _vm.closeDialog },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t(`common.close`)) + " ")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "change-pass-error" }, [
                    _vm._v(_vm._s(_vm.errorRequest)),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-row",
            [
              _c("InputField", {
                staticClass: "font-weight-bold py-0 mb-10 w-100",
                attrs: {
                  name: _vm.$t(`common.password`),
                  tabindex: 2,
                  "use-model": true,
                  error: _vm.error,
                  type: "password",
                  required: "",
                },
                on: {
                  update: function ($event) {
                    return _vm.setUserPassword($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("InputField", {
                staticClass: "font-weight-bold w-100",
                attrs: {
                  name: _vm.$t(`common.confirmPassword`),
                  tabindex: 2,
                  "use-model": true,
                  error: _vm.error,
                  type: "password",
                },
                on: {
                  update: function ($event) {
                    return _vm.setUserConfirmPassword($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Modal", {
        attrs: {
          "is-modal-open": _vm.isModalConfirmOpen,
          "close-dialog": _vm.closeConfirmDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-heading mb-10" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("profile.change_user_password")) + " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-3" }),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-cancel",
                        on: { click: _vm.confirmChangePassword },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.yes`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-confirm",
                        on: { click: _vm.closeConfirmDialog },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.no`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }