import { axiosInstance } from '../utils/axios.js';
import {
  CREATE_COUNTRY_URL,
  GET_SINGLE_COUNTRY_URL,
  UPDATE_SINGLE_COUNTRY_URL,
  DELETE_SINGLE_COUNTRY_URL,
} from '../utils/api.endpoints';

/*export const addCountry = function(data, cb, ecb) {
  axiosInstance
    .post(create_country_url, data)
    .then(response => {
      if (cb) cb(response);
    })
    .catch(error => {
      if (ecb) ecb(error);
      else console.log("Error", error);
    });
};*/
export const getCountryRequest = function (id, cb, ecb = null) {
  axiosInstance
    .get(GET_SINGLE_COUNTRY_URL(id))
    .then((response) => {
      const im = response;
      if (cb) cb(im);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const updateCountryRequest = function (data, cb, ecb = null) {
  const { id, ...dataObj } = data;
  //data={id, name, code}
  //id= data.id
  //dataObj={name, code}
  axiosInstance
    .put(UPDATE_SINGLE_COUNTRY_URL(id), dataObj)
    .then((response) => {
      const c = response;
      if (cb) cb(c);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const deleteCountryRequest = function (id, cb, ecb = null) {
  axiosInstance
    .delete(DELETE_SINGLE_COUNTRY_URL(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const addCountryRequest = function (obj, cb, ecb = null) {
  const data = obj;
  axiosInstance
    .post(CREATE_COUNTRY_URL, data)
    .then((response) => {
      const country = response;
      if (cb) cb(country);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export default {};
