<template>
  <v-card outlined class="px-8 py-4 d-flex flex-column justify-space-between align-center background--text fill-height">
    <div class="font-weight-bold d-flex align-center pb-2">
      <slot name="header">{{ caption }}</slot>
    </div>
    <div class="black--text text-h4 font-weight-bold">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'CardKPI',
  props: {
    caption: {
      type: String,
      default: '',
    },
  },
};
</script>
