var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "table-title" }, [
    _vm._v(" " + _vm._s(_vm.title) + " "),
    _c("span", { staticClass: "total" }, [
      _vm._v(" (" + _vm._s(_vm.count) + ") "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }