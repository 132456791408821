var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LoadingComponent", { attrs: { loading: _vm.loading } }),
      _c(
        "div",
        { staticClass: "pa-4 d-flex flex-column align-center" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "CardKPI",
                    {
                      attrs: {
                        caption: _vm.$t("statistics.global.number_ims"),
                      },
                    },
                    [_vm._v(_vm._s(_vm.stats.ims))]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "CardKPI",
                    {
                      attrs: {
                        caption: _vm.$t("statistics.global.number_rfcs"),
                      },
                    },
                    [_vm._v(_vm._s(_vm.stats.rfcs))]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "CardKPI",
                    {
                      attrs: {
                        caption: _vm.$t("statistics.global.total_orgs"),
                      },
                    },
                    [_vm._v(_vm._s(_vm.stats.organizations))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "CardKPI",
                    {
                      attrs: {
                        caption: _vm.$t("statistics.global.registered_users"),
                      },
                    },
                    [_vm._v(_vm._s(_vm.stats.registeredUsers))]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "CardKPI",
                    {
                      attrs: {
                        caption: _vm.$t("statistics.global.guest_users"),
                      },
                    },
                    [_vm._v(_vm._s(_vm.stats.guestUsers))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-center mt-6" },
            [
              _c("v-simple-table", { staticClass: "stripe tbl_stats" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { nowrap: "" } }, [
                      _vm._v(_vm._s(_vm.$t("statistics.global.user_type"))),
                    ]),
                    _c("th", { attrs: { nowrap: "" } }, [
                      _vm._v(_vm._s(_vm.$t("statistics.global.number_users"))),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.stats.userByType, function (row, idx) {
                    return _c("tr", { key: idx }, [
                      _c("td", [_vm._v(_vm._s(row.type))]),
                      _c("td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(_vm._s(row.users)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }