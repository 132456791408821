<template>
  <DefaultLayout>
    <Headings :title="$t(`common.commonStructures`)" :subtitle="$t(`common.management`)" />
    <div id="inputs" class="row justify-content-between">
      <div class="col-2">
        <SelectComponent
          v-model="managementType"
          class="user-list-input select-big"
          :tabindex="2"
          :options="managementTypes"
          :name="$t(`common.manageType`)"
          obj-key="name"
          obj-val="value"
        />
      </div>
      <div id="search-col" class="offset-8 col-2">
        <button class="btn btn-green btn-search" @click.stop="createStructure">
          {{ $t(`common.createNew`) }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <LabelWithCount v-if="managementType" :title="subtitle" :count="cs.length" />
      </div>
      <div class="col-12">
        <div class="table-rfcs">
          <table>
            <thead class="table-head-shadow">
              <tr>
                <th v-for="(header, index) in headers" :key="index">
                  {{ header.name }}
                  <img
                    :id="header.id"
                    :class="sortDirection(header.id)"
                    :src="sortIcon(header.id)"
                    @click="sortBy(header.id)"
                  />
                </th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in cs" :key="index" class="table-clickable-row" @click="open(item)">
                <td>{{ item.type }}</td>
                <td>{{ item.year }}</td>
                <!--
                <td class="col-actions">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="#DBA153"
                  >
                    <v-icon dark>arrow_forward</v-icon>
                  </v-btn>
                </td>
                -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <DuplicateStructure :is-modal-open="isModalOpen" :close-dialog="closeDialog" />
  </DefaultLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import mixinCommon from './CommonList.data';
import { orderTypesEnum } from '@/utils/enums';
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import LabelWithCount from '@/components/base/LabelWithCount';
import SelectComponent from '@/components/base/SelectComponent';
import DuplicateStructure from './modals/DuplicateStructure';

const notSortedIcon = require('@/assets/icons/grey-icons/arrow.png');
const sortedIcon = require('@/assets/icons/gold-icons/arrow.png');

export default {
  name: 'CommonList',
  components: {
    DefaultLayout,
    LabelWithCount,
    SelectComponent,
    DuplicateStructure,
    Headings,
  },
  mixins: [mixinCommon],
  data() {
    return {
      managementType: 'NS',
      isModalOpen: false,
    };
  },
  computed: {
    ...mapState({
      type: (state) => state.structure.type,
      cs: (state) => state.structure.data,
      sortIcon: (state) => (id) => {
        return state.structure.orderBy === id ? sortedIcon : notSortedIcon;
      },
      sortDirection: (state) => (id) => {
        return state.structure.orderBy === id && state.structure.orderType === orderTypesEnum.ASC ? '' : 'reversed';
      },
    }),
    subtitle() {
      if (this.managementType) {
        const data = this.managementTypes.find((o) => o.name === this.managementType);
        return data.title;
      } else {
        return '';
      }
    },
  },
  watch: {
    managementType: {
      immediate: true,
      handler(newVal) {
        this.setType(newVal);
        this.getAllCSs({ type: this.managementType });
      },
    },
  },
  methods: {
    ...mapActions('structure', ['getAllCSs', 'setType', 'order']),
    open(r) {
      this.$router.push({
        name: 'CommonDetails',
        params: { id: r.id },
      });
    },
    createStructure() {
      this.isModalOpen = true;
    },
    closeDialog() {
      this.isModalOpen = false;
    },
    sortBy(id) {
      this.order(id);
    },
  },
};
</script>
