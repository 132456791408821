var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "bg-f8",
      attrs: {
        id: "nav2",
        permanent: "",
        "mini-variant-width": "80px",
        "mini-variant": true,
        right: true,
      },
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        _vm._l(_vm.userMenuIcons, function (item, index) {
          return _c(
            "v-list-item",
            {
              key: index,
              staticClass: "item_menu",
              attrs: { color: "white" },
              on: {
                click: function ($event) {
                  return _vm.goTo(item.type)
                },
              },
            },
            [
              _c(
                "v-list-item-icon",
                { class: `menu-icon-container ${item.class}` },
                [_c("img", { attrs: { src: item.icon, title: item.title } })]
              ),
              _c(
                "v-list-item-title",
                { staticClass: "items_title", style: item.titleStyle },
                [_vm._v(" " + _vm._s(item.title) + " ")]
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }