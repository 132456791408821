var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AuthLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "v-container",
        { staticClass: "login_container" },
        [
          _c("Logo", [_c("v-spacer"), _c("LanguageSelector")], 1),
          _c("InputField", {
            ref: "user",
            attrs: {
              name: "common.username",
              value: _vm.username,
              "use-model": true,
              error: _vm.errors.error("username"),
            },
            on: {
              "update:value": function ($event) {
                _vm.username = $event
              },
              update: function ($event) {
                _vm.username = $event
              },
            },
          }),
          _vm._v(" " + _vm._s(_vm.errors["username"]) + " "),
          _c("InputField", {
            staticClass: "mb-0",
            attrs: {
              name: "common.password",
              value: _vm.password,
              "use-model": true,
              type: _vm.showPass ? "text" : "password",
              error: _vm.errors.error("password"),
            },
            on: {
              "update:value": function ($event) {
                _vm.password = $event
              },
              update: function ($event) {
                _vm.password = $event
              },
            },
          }),
          _c(
            "p",
            {
              attrs: { id: "forgotten_pass_label" },
              on: { click: _vm.forgottenPassword },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.forgottenPassword")) + " ")]
          ),
          _c("div", { staticClass: "form-group mb-5" }, [
            _c(
              "form",
              {
                staticClass: "captcha",
                attrs: { action: "?", method: "POST" },
              },
              [
                _c("div", {
                  staticClass: "g-recaptcha",
                  attrs: {
                    id: "recaptcha-main",
                    "data-sitekey": "6LcFS98ZAAAAAPp1m2QPlxd07FqDGYni1dSMyzJf",
                  },
                }),
                _vm.errors.error("formData.recaptcha")
                  ? _c("span", { staticClass: "form-error" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.errors.error("formData.recaptcha")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "button",
                {
                  staticClass: "btn-login",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.logInUser.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.login")) + " ")]
              ),
              _c(
                "router-link",
                { staticClass: "btn-register", attrs: { to: "/register" } },
                [_vm._v(_vm._s(_vm.$t("common.register")))]
              ),
              _vm.errors.error("generic")
                ? _c("span", { staticClass: "form-error generic" }, [
                    _vm._v(" " + _vm._s(_vm.errors.error("generic")) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "router-link",
                { staticClass: "btn-back", attrs: { to: "/" } },
                [_vm._v(_vm._s(_vm.$t("common.back")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }