var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "subscriptions-modal",
      attrs: {
        "is-modal-open": _vm.isModalOpen,
        "close-dialog": _vm.closeDialog,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _vm._v(
                " " +
                  _vm._s(_vm.$t(`users.subscriptions`)) +
                  " (" +
                  _vm._s(_vm.totalSubscriptions || 0) +
                  ") "
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-green btn-save-subscriptions",
                        on: { click: _vm.closeDialog },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.save`)) + " ")]
                    ),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "tabs-header",
                  attrs: {
                    "background-color": "white",
                    centered: false,
                    grow: false,
                    vertical: false,
                    right: false,
                  },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tabs-slider"),
                  _c("v-tab", { attrs: { href: "#tab-im" } }, [
                    _vm._v(_vm._s(_vm.$t(`common.ns`))),
                  ]),
                  _c("v-tab", { attrs: { href: "#tab-rfc" } }, [
                    _vm._v(_vm._s(_vm.$t(`common.cid`))),
                  ]),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-im" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", tile: "" } },
                        [
                          _c("v-card-text", [
                            _c("div", { staticClass: "table-ims" }, [
                              _c("table", [
                                _c(
                                  "thead",
                                  { staticClass: "table-head-shadow" },
                                  [
                                    _c(
                                      "tr",
                                      [
                                        _vm._l(
                                          _vm.imHeaders,
                                          function (header, index) {
                                            return _c("th", { key: index }, [
                                              _vm._v(
                                                " " + _vm._s(header.name) + " "
                                              ),
                                            ])
                                          }
                                        ),
                                        _c("th"),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.ims, function (im, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _c("img", {
                                          staticClass: "logo-preview",
                                          attrs: {
                                            src: _vm.getIMLogo(im.id),
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" " + _vm._s(im.name) + " "),
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "subscribe-button-container",
                                          attrs: {
                                            set: (_vm.subscribed =
                                              _vm.isSubscribed("im", im.id)),
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn subscribe-button",
                                              class: _vm.subscribed
                                                ? "btn-white"
                                                : "btn-gold",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.subscribeOrUnsubscribe(
                                                    _vm.subscribed,
                                                    "im",
                                                    im.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.subscribed
                                                        ? `users.unsubscribe`
                                                        : `users.subscribe`
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-rfc" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", tile: "" } },
                        [
                          _c("v-card-text", [
                            _c("div", { staticClass: "table-rfcs" }, [
                              _c("table", [
                                _c(
                                  "thead",
                                  { staticClass: "table-head-shadow" },
                                  [
                                    _c(
                                      "tr",
                                      [
                                        _vm._l(
                                          _vm.rfcHeaders,
                                          function (header, index) {
                                            return _c("th", { key: index }, [
                                              _vm._v(
                                                " " + _vm._s(header.name) + " "
                                              ),
                                            ])
                                          }
                                        ),
                                        _c("th"),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.rfcs, function (rfc, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _c("img", {
                                          staticClass: "logo-preview",
                                          attrs: {
                                            src: _vm.getRFCLogo(rfc.id),
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" " + _vm._s(rfc.name) + " "),
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "subscribe-button-container",
                                          attrs: {
                                            set: (_vm.subscribed =
                                              _vm.isSubscribed("rfc", rfc.id)),
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn subscribe-button",
                                              class: _vm.subscribed
                                                ? "btn-gold"
                                                : "btn-white",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.subscribeOrUnsubscribe(
                                                    _vm.subscribed,
                                                    "rfc",
                                                    rfc.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.subscribed
                                                        ? `users.unsubscribe`
                                                        : `users.subscribe`
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }