<template>
  <DefaultLayout>
    <Headings
      class="d-inline-block"
      :title="$t(`common.documentManagement`)"
      back-location="DocManagement"
      :subtitle="$t(`common.upload`) + ' ' + uploadType + ' - ' + submitStatus"
    />
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <div v-if="submitStatus === 'Success'" class="status-card-container">
            {{
              $t(`documentManagement.uploadSuccess`) +
              docStatus +
              ' a ' +
              (isMaster ? 'Master ' : '') +
              uploadType +
              ' ' +
              $t(`documentManagement.document`)
            }}
            <img src="@/assets/icons/done.svg" class="success-icon" />
          </div>
          <div v-else class="status-card-container status-error">
            {{ $t(`documentManagement.uploadFailed`) + uploadType + $t(`documentManagement.documentFailed`) }}
          </div>
        </v-row>
        <v-row class="cols" align="center" justify="center">
          <div class="mt-7">
            <button class="btn btn-green" @click.prevent="goBack">
              {{ $t(`common.ok`) }}
            </button>
          </div>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <label class="summary-table-title">{{ $t(`documentManagement.uploadSummary`) }}</label>
      </v-col>
      <v-col cols="12">
        <div class="summary-table">
          <table>
            <thead class="table-head-shadow">
              <tr>
                <th v-for="(header, index) in headers" :key="index">
                  {{ header.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in summaryData" :key="index" class="user_row">
                <template v-if="data.result.error">
                  <td colspan="8">
                    {{ $t(`common.errorUploading`) }} {{ data.name }} -
                    {{ data.result.error }}
                  </td>
                </template>
                <template v-if="!data.result.error">
                  <td id="name_user">
                    {{ data.result.userName || data.result.uploadedBy }}
                  </td>
                  <td>{{ data.result.organizationName }}</td>
                  <td>{{ data.result.year }}</td>
                  <td>{{ data.result.version }}</td>
                  <td>{{ data.result.status }}</td>
                  <td>{{ today }}</td>
                  <!-- <td>{{ data.result.chapters }}</td> -->
                  <td>{{ note }}</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  name: 'DocManagementUploadStatus',
  components: {
    DefaultLayout,
    Headings,
  },
  data() {
    return {
      summaryData: [],
      note: '',
      type: '',
      docStatus: '',
      isMaster: false,
    };
  },
  computed: {
    ...mapState({
      submitStatus: (state) => state.documents.submitStatus,
      uploadType: (state) => state.documents.uploadType,
    }),
    today() {
      const today = new Date();
      return today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
    },
    headers() {
      return [
        {
          name: this.$t('documentManagement.upload_status.uploaded_by').toUpperCase(),
          id: 'organizationName',
        },
        {
          name: 'IM/RFC',
          id: 'type',
        },
        {
          name: 'TTY',
          id: 'year',
        },
        {
          name: 'VER.',
          id: 'version',
        },
        {
          name: this.$t('documentManagement.upload_status.status').toUpperCase(),
          id: 'status',
        },
        {
          name: this.$t('documentManagement.upload_status.date_upload').toUpperCase(),
          id: 'lastPublished',
        },
        {
          name: this.$t('documentManagement.upload_status.notes').toUpperCase(),
          id: 'lastUpdatedByUserName',
        },
      ];
    },
  },
  created() {
    this.removeAllBooks();
    this.clearDocumentUploadedData();
    if (!this.submitStatus || !this.uploadType) {
      this.goBack();
    }
    const routeData = this.$route.params.data;
    if (Array.isArray(routeData)) {
      this.summaryData = routeData;
    } else {
      this.summaryData.push({
        result: routeData,
      });
    }
    this.note = this.$route.params.note;
    this.type = this.$route.params.type;
    this.isMaster = this.$route.params.isMaster;
    this.docStatus = this.summaryData.find((data) => data.result.status === 'DRAFT')
      ? this.isMaster
        ? 'uploaded'
        : 'saved'
      : 'published';
  },
  methods: {
    ...mapActions('rfcs', ['getAllRFCs']),
    goBack() {
      this.$router.push({
        name: 'DocManagement',
      });
    },
    ...mapMutations('documents', ['removeAllBooks', 'clearDocumentUploadedData']),
  },
};
</script>

<style scoped>
.status-card-container {
  border-radius: 8px;
  background-color: #f0ffeb;
  width: 50%;
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-size: 1.8em;
  font-weight: 500;
  padding: 30px;
}

.status-error {
  background-color: #ff9494;
  text-align: center;
}

.summary-table-title {
  font-family: Montserrat, sans-serif;
  font-size: 2em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.success-icon {
  float: right;
}
</style>
