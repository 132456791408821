<script>
import componentFactory from './componentFactory.js';

let params = {};
if (process.env.VUE_ENV !== 'server') {
  const pdfjsWrapper = require('./pdfjsWrapper.js').default;
  const PDFJS = require('pdfjs-dist/es5/build/pdf.js');

  if (typeof window !== 'undefined' && 'Worker' in window && navigator.appVersion.indexOf('MSIE 10') === -1) {
    //eslint-disable-next-line
    const PdfjsWorker = require('worker-loader!pdfjs-dist/es5/build/pdf.worker.js');
    PDFJS.GlobalWorkerOptions.workerPort = new PdfjsWorker();
  }

  params = pdfjsWrapper(PDFJS);
}

export default componentFactory(params); //eslint-disable-line vue/require-direct-export
</script>

<style src="./annotationLayer.css"></style>
