var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("router-view", { key: _vm.$route.fullPath }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.msgType,
            timeout: _vm.msgTimeout,
            top: "",
            rounded: "",
            "content-class": "font-weight-bold",
          },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [_vm._v(_vm._s(_vm.msgText))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }