<template>
  <label class="table-title">
    {{ title }}
    <span class="total"> ({{ count }}) </span>
  </label>
</template>

<script>
export default {
  name: 'LabelWithCount',
  props: {
    title: {
      type: String,
      default: '',
    },
    count: {
      type: [String, Number],
      default: 0,
    },
  },
};
</script>
