<template>
  <v-row>
    <LoadingComponent :loading.sync="isLoading" />
    <v-col cols="12" sm="12" md="4">
      <div>
        <div v-if="componentVisible()" class="mb-4">
          <label class="doc-label">{{ $t(`common.status`) }}</label>
          <label class="status-label">{{ currentDocument.status }}</label>
        </div>
        <SelectComponent
          v-if="componentVisible()"
          class="selection-bold input-big cs-select"
          :tabindex="1"
          :options="isCIDDocument ? rfcList : imsList"
          :value="currentDocument.organizationName"
          :name="isCIDDocument ? $t(`common.railFreightCorridors`) : $t(`common.infrastructureManagers`)"
          :is-disabled="isPublished"
          obj-key="name"
          :obj-val="isCIDDocument ? 'nameOnly' : 'name'"
          :error="formErrors.organizationName"
          @change="updateCurrentDocument({ key: 'organizationName', value: $event })"
        />
        <v-row>
          <v-col cols="12" sm="12" :md="componentVisible() ? 8 : 12">
            <SelectComponent
              class="selection-bold input-big"
              :class="{ 'mt-5': !componentVisible() }"
              :tabindex="2"
              :options="years"
              :value="currentDocument.year"
              :name="$t(`common.year`)"
              :is-disabled="isPublished || !!currentDocument.id"
              obj-key="value"
              obj-val="value"
              :error="formErrors.selectedYear"
              @change="fetchCsLevelsForYear($event)"
            />
            <span v-if="formErrors.csLevels" class="form-error">
              {{ formErrors.csLevels }}
            </span>
          </v-col>
          <v-col v-if="componentVisible()" cols="12" sm="12" md="4">
            <InputField
              class="selection-bold"
              :tabindex="3"
              :name="$t(`common.version`)"
              :is-disabled="isPublished || !!currentDocument.id"
              :value.sync="currentDocument.version"
              :use-model="true"
              :error="formErrors.version"
              @update="validateVersionNumber($event)"
            />
          </v-col>
        </v-row>
        <div v-if="componentVisible()" class="form-group">
          <label class="doc-label">
            {{ $t(`documentManagement.leaveNotes`) }}
          </label>
          <textarea
            :disabled="isPublished"
            class="selection-bold notes-textarea"
            :value="currentDocument.note"
            cols="40"
            rows="3"
            @change="updateDocumentProperty('note', $event.target.value)"
          />
        </div>
        <template v-if="currentDocument.type === 'CID' && filteredBooksOnly && !isFetching">
          <div v-for="(book, index) in filteredBooksOnly" :key="index" class="attach-file-container mb-2">
            <template
              v-if="
                selectedBookFiles[index] &&
                selectedBookFiles[index].file &&
                selectedBookFiles[index].status !== 'NOT_FOUND'
              "
            >
              <v-row>
                <v-col cols="11" :sm="isFile(selectedBookFiles[index].file) ? 9 : 11" class="mb-0">
                  <input
                    :ref="generateRef(index)"
                    type="file"
                    accept=".docx"
                    class="file-picker"
                    @change="onBookSelected($event, index)"
                  />
                  <button
                    :title="getTitleMappingForStatus(selectedBookFiles[index].status)"
                    :class="getClassForAttachedFile(selectedBookFiles[index].status)"
                    :disabled="selectedBookFiles[index].status === 'PROCESSING'"
                    @click="clickUpload(index)"
                  >
                    <img
                      v-if="
                        selectedBookFiles[index].status !== 'PROCESSING' &&
                        selectedBookFiles[index].status !== 'EXTRACTION_ERROR'
                      "
                      src="@/assets/icons/done.svg"
                      class="pr-2"
                    />
                    {{ book.nameEn + ' ' + getStatusText(selectedBookFiles[index].status) }}
                  </button>
                </v-col>
                <v-col cols="1" class="align-items-center px-0">
                  <button class="btn btn-green btn-annex my-3 mx-0" @click.prevent="navigateToAnalysisPage">
                    <img :src="showIcon" />
                  </button>
                </v-col>
                <v-col v-if="isFile(selectedBookFiles[index].file)" cols="12" sm="3">
                  <label class="remove-file-label" @click="onRemoveBook(index)">{{ $t(`common.remove`) }}</label>
                </v-col>
              </v-row>
            </template>
            <template
              v-if="
                !selectedBookFiles[index] ||
                !selectedBookFiles[index].file ||
                selectedBookFiles[index].status === 'NOT_FOUND'
              "
            >
              <input
                :ref="generateRef(index)"
                type="file"
                accept=".docx"
                class="file-picker"
                @change="onBookSelected($event, index)"
              />
              <button
                v-if="
                  !selectedBookFiles[index] ||
                  !selectedBookFiles[index].file ||
                  selectedBookFiles[index].status === 'NOT_FOUND'
                "
                class="btn-attach-file"
                @click="clickUpload(index)"
              >
                <img class="document-icon" src="@/assets/icons/gold-icons/documentmanagement-gold.svg" />
                {{ $t('documentManagement.attach') + ' ' + book.nameEn }}
              </button>
              <span v-if="isSubmitted && !selectedBookFiles.length" class="form-error">{{
                $t(`documentManagement.attachFiles`)
              }}</span>
            </template>
          </div>
        </template>
        <template v-if="currentDocument.type === 'NS'">
          <div class="attach-file-container">
            <input ref="file" type="file" accept=".docx" class="file-picker" @change="onFileSelected($event)" />
            <button v-if="!selectedFile" class="btn-attach-file" @click="$refs.file.click()">
              <img class="document-icon" src="@/assets/icons/gold-icons/documentmanagement-gold.svg" />
              {{ $t('documentManagement.attach') + ' ' + currentDocument.type + ' ' + $t('editLevels.file') }}
            </button>
            <v-row v-if="selectedFile && !isFetching">
              <v-col cols="11" :sm="isPublished ? 11 : 8" class="mb-0">
                <button
                  :title="getTitleMappingForStatus(selectedFile.status)"
                  :class="getClassForAttachedFile(selectedFile.status)"
                >
                  <img
                    v-if="selectedFile.status !== 'PROCESSING' && selectedFile.status !== 'EXTRACTION_ERROR'"
                    src="@/assets/icons/done.svg"
                    class="pr-2"
                  />
                  {{ currentDocument.type + ' ' + getStatusText(selectedFile.status) }}
                </button>
              </v-col>
              <v-col cols="1" class="align-items-center px-0">
                <button class="btn btn-green btn-annex my-3 mx-0" @click.prevent="navigateToAnalysisPage">
                  <img :src="showIcon" />
                </button>
              </v-col>
              <v-col v-if="!isPublished" cols="12" sm="3">
                <label class="remove-file-label" @click="removeFile">{{ $t(`common.remove`) }}</label>
              </v-col>
            </v-row>
            <span v-if="isSubmitted && !selectedFile" class="form-error">{{
              $t(`documentManagement.attachFiles`)
            }}</span>
          </div>
        </template>
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="8">
      <v-row>
        <v-col cols="4">
          <span class="annexes-label">{{ $t(`documentManagement.appliedAnnexes`) }}</span>
          <span class="annexes-label-count ml-1">({{ annexesCount }})</span>
        </v-col>
        <v-col cols="1">
          <span class="">{{ $t(`documentManagement.includeOnDownload`) }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(annex, index) in annexes"
          :key="index"
          cols="12"
          class="attach-file-container attach-annex-container"
        >
          <v-row>
            <v-col cols="4">
              <button
                class="btn-attached-file"
                :disabled="isPublished || !annex.annexId"
                @click="handleAnnexDisplayNameChange($event, index, annex)"
              >
                <img class="document-icon" src="@/assets/icons/done.svg" />
                {{ annex.annexFileName || $t('documentManagement.annexFile') + ' ' + (index + 1) }}
                <br />
                {{ annex.annexFileName === annex.displayName ? '' : annex.displayName }}
              </button>
            </v-col>
            <v-col cols="1">
              <input
                type="checkbox"
                :checked="annex.includeOnDownload"
                :disabled="isPublished"
                @change="handleIncludeOnDownload($event, index, annex)"
              />
            </v-col>
            <v-col :cols="4">
              <MultipleSelectComponent
                class="multiselect-medium"
                :tabindex="5"
                :value="annex.chapters"
                :options="levels || []"
                :placeholder="$t(`documentManagement.annexPlaceholder`)"
                obj-key="nameEn"
                obj-val="id"
                :disabled="isPublished"
                @change="addOrRemoveChapterOfAnnex($event, index)"
              />
            </v-col>
            <v-col cols="1">
              <button class="btn btn-gold btn-annex-small" @click.prevent="downloadAnnexFE(index)">
                <img :src="downloadIcon" />
              </button>
            </v-col>
            <v-col v-if="annex.pdfPreviewUrl" cols="1">
              <button class="btn btn-gold btn-annex-small" @click.prevent="previewAnnexFE(index)">
                <img :src="showIcon" />
              </button>
            </v-col>
            <v-col v-if="!isPublished && index === indexOfVisiblePlus" cols="1">
              <div class="add-row-container" @click="addNewAnnex">
                <img src="@/assets/icons/gold-icons/addrow.svg" />
              </div>
            </v-col>
            <v-col v-if="!isPublished" cols="1">
              <div class="add-row-container remove-annex-label" @click="removeAnnex(index)">
                <label class="remove-file-label">{{ $t(`common.remove`) }}</label>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="attach-file-container attach-annex-container">
          <v-row v-if="!isPublished && !newAnnexFile">
            <v-col cols="4">
              <input
                ref="fileAnnex"
                type="file"
                accept=".docx,.doc,.xls,.xlsx,.pptx,.ppt,.txt,.pdf,.png,.jpg,.jpeg"
                class="file-picker"
                @change="onAnnexFileSelected($event)"
              />
              <button
                v-if="!newAnnexFile"
                class="btn-attach-file"
                @drop="dropHandler($event)"
                @dragover.prevent
                @dragenter.prevent
                @click="attachNewAnnexFileClick"
              >
                <img class="document-icon" src="@/assets/icons/gold-icons/documentmanagement-gold.svg" />
                {{ $t(`documentManagement.attachAnnexFile`) }}
              </button>
              <button v-if="newAnnexFile" class="btn-attached-file">
                <img class="document-icon" src="@/assets/icons/done.svg" />
                {{ $t(`documentManagement.annexFile`) + ' ' + (annexes.length + 1) }}
              </button>
            </v-col>
            <v-col cols="6">
              <MultipleSelectComponent
                class="multiselect-medium"
                :tabindex="5"
                :value="newAnnexChapters"
                :options="levels || []"
                :placeholder="$t(`documentManagement.annexPlaceholder`)"
                obj-key="nameEn"
                obj-val="id"
                @change="addOrRemoveChapter($event)"
              />
            </v-col>
            <v-col v-if="!isPublished" cols="1">
              <div class="add-row-container" @click="addNewAnnex">
                <img src="@/assets/icons/gold-icons/addrow.svg" />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import InputField from '@/components/base/InputField';
import downloadIcon from '@/assets/icons/download.svg';
import showIcon from '@/assets/icons/show.svg';
import SelectComponent from '@/components/base/SelectComponent';
import { mapActions, mapMutations, mapState } from 'vuex';
import MultipleSelectComponent from '@/components/base/MultipleSelectComponent';
import LoadingComponent from '@/components/base/LoadingComponent';
import { isVersionValid } from '@/utils/utils';

export default {
  name: 'DocManagementForm',
  components: {
    InputField,
    SelectComponent,
    MultipleSelectComponent,
    LoadingComponent,
  },
  props: {
    currentDocument: {
      type: Object,
      required: true,
    },
    setLoading: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isSubmitted: false,
      formErrors: {
        selectedYear: null,
        organizationName: null,
        version: null,
        csLevels: null,
        selectedFile: null,
        selectedBooks: null,
      },
      newAnnexFile: null,
      newAnnexChapters: [],
      isLoading: false,
      // isMasterUpload: false,
      isFetching: true,
      indexOfVisiblePlus: 0,
      downloadIcon,
      showIcon,
    };
  },
  computed: {
    ...mapState({
      initialAnnexesState: (state) => {
        return state.annexesInitialState;
      },
      years: (state) => {
        return state.documents.years;
      },
      rfcList: (state) => {
        if (state.auth.current.roles === 'ADMIN') {
          return state.rfcs.data.map((rfc) => {
            return {
              value: rfc.id, // no id is provided from the service for the document details call - only name
              name: rfc.name + ' ( ' + rfc.shortName + ' )',
              nameOnly: rfc.name,
            };
          });
        } else {
          const rfcAccess = state.auth.current.railFreightCorridorOverviews.map((rfcAccess) => rfcAccess.id);
          return state.rfcs.data
            .filter((r) => rfcAccess.includes(r.id))
            .map((rfc) => {
              return {
                value: rfc.id, // no id is provided from the service for the document details call - only name
                name: rfc.name + ' ( ' + rfc.shortName + ' )',
                nameOnly: rfc.name,
              };
            });
        }
      },
      isMasterUpload: (state) => state.documents.current.isMasterFile,
      csLevels: (state) => {
        return state.documents.selectedCommonStructure;
      },
      levels: (state) => state.documents.selectedCommonStructure.levels,
      filteredBooksOnly: (state) => {
        if (state.documents.selectedCommonStructure.levels) {
          return state.documents.selectedCommonStructure.levels.filter((level) => level.isBook);
        } else {
          return [];
        }
      },
      selectedBookFiles: (state) => state.documents.selectedBooks,
      imsList: (state) => {
        if (state.auth.current.roles === 'ADMIN') {
          return state.ims.data.map((im) => {
            return {
              value: im.id, // no id is provided from the service for the document details call - only name
              name: im.name,
            };
          });
        } else {
          const imsAccess = state.auth.current.infrastructureManagerOverviews.map((imAccess) => imAccess.id);
          return state.ims.data
            .filter((i) => imsAccess.includes(i.id))
            .map((im) => {
              return {
                value: im.id, // no id is provided from the service for the document details call - only name
                name: im.name,
              };
            });
        }
      },
      selectedFile: (state) => state.documents.selectedFile,
      annexes: (state) => state.documents.annexes,
      annexesCount: (state) => state.documents.annexes.length,
    }),
    isPublished() {
      return this.currentDocument.status === 'PUBLISHED';
    },
    isCIDDocument() {
      return this.currentDocument.type === 'CID';
    },
  },
  watch: {
    filteredBooksOnly(val) {
      if (val && this.currentDocument.id) {
        this.setLoading(true);
        const csLevelIds = val.map((b) => b.id);
        this.fetchUploadedBooks({
          csLevelIds: csLevelIds,
          documentId: this.currentDocument.id,
          cb: () => {
            this.indexOfVisiblePlus = this.annexes.length;
            this.$forceUpdate();
            this.isFetching = false;
            this.setLoading(false);
          },
          ecb: () => {
            this.isFetching = false;
            this.setLoading(false);
          },
        });
      }
    },
  },
  created() {
    this.getYears({});

    this.getAllCSs({
      type: this.$route.query?.type,
    });

    if (this.currentDocument.id) {
      this.indexOfVisiblePlus = this.annexes.length;
    } else {
      this.setIsMasterUpload(this.$route.query?.isMaster);
    }
  },
  methods: {
    ...mapActions('structure', ['getAllCSs']),
    ...mapActions('documents', [
      'fetchCommonStructureForDate',
      'updateDocument',
      'publishFile',
      'unpublishDocument',
      'doDeleteWholeYear',
      'saveFileAndAnnexes',
      'fetchUploadedBooks',
      'saveMasterFileAndAnnexes',
      'getYears',
      'downloadAnnex',
      'previewAnnex',
    ]),
    ...mapMutations('documents', [
      'updateCurrentDocument',
      'addSelectedFile',
      'removeSelectedFile',
      'addSelectedAnnex',
      'removeSelectedAnnex',
      'addBook',
      'removeBook',
      'removeAllBooks',
      'clearDocumentUploadedData',
      'updateExistingAnnexChapters',
      'updateAnnexIncludeOnDownload',
      'updateAnnexDisplayName',
      'setHasUploadedFile',
      'setIsMasterUpload',
    ]),
    getClassForAttachedFile(status) {
      let cl = 'btn-attach-file';
      switch (status) {
        case 'PROCESSED':
        case undefined:
          cl = 'btn-attached-file';
          break;
        case 'PROCESSING':
          cl = 'btn-processing-file';
          break;
        case 'EXTRACTION_ERROR':
          cl = 'btn-error-file';
          break;
      }

      return cl;
    },
    getTitleMappingForStatus(status) {
      let st = 'btn-attach-file';
      switch (status) {
        case 'PROCESSED':
        case undefined:
          st = 'Processed';
          break;
        case 'PROCESSING':
          st = 'Processing';
          break;
        case 'EXTRACTION_ERROR':
          st = 'Extraction Error';
          break;
        default:
          st = 'Not found';
          break;
      }
      return st;
    },
    getStatusText(status) {
      return this.$t(status !== 'EXTRACTION_ERROR' ? 'documentManagement.attached' : 'common.extractionError');
    },
    isFile(file) {
      return file instanceof File;
    },
    filterBooksOnly() {
      this.filteredBooksOnly = this.csLevels.levels.filter((level) => level.isBook);
    },
    componentVisible() {
      return !this.isCIDDocument || (this.isCIDDocument && !this.isMasterUpload);
    },
    generateRef(index) {
      return 'file' + index;
    },
    attachNewAnnexFileClick() {
      if (!this.isPublished) {
        this.$refs.fileAnnex.click();
      }
    },
    clickUpload(index) {
      if (!this.isPublished) {
        const i = 'file' + index;
        this.$refs[i][0].click();
      }
    },
    fetchCsLevelsForYear(newYear, shouldFetchBooks = false) {
      this.setLoading(true);
      const requestData = {
        type: this.currentDocument.type,
        year: newYear,
        flat: true,
        fetchBooks: shouldFetchBooks,
      };
      this.fetchCommonStructureForDate({
        data: requestData,
        cb: () => {
          this.isFetching = false;
          this.$forceUpdate();
          this.setLoading(false);
        },
        ecb: () => {
          this.isFetching = false;
          this.setLoading(false);
        },
      });
      this.updateDocumentProperty('year', newYear);
    },
    addOrRemoveChapter(chapters) {
      this.newAnnexChapters = chapters;
    },
    // eslint-disable-next-line no-unused-vars
    addOrRemoveChapterOfAnnex(chapters, index) {
      const chIds = [...chapters.filter((ch) => ch.id).map((c) => c.id), ...chapters.filter((ch) => !ch.id)];
      this.updateExistingAnnexChapters({
        index: index,
        chapters: chIds,
      });
    },
    handleIncludeOnDownload(selection, index, annex) {
      this.updateAnnexIncludeOnDownload({
        index: index,
        annex: annex,
        includeOnDownload: !annex.includeOnDownload,
      });
    },
    handleAnnexDisplayNameChange(selection, index, annex) {
      const newDisplayName = prompt(this.$t(`Change annex name`), annex.displayName);
      if (newDisplayName !== null && newDisplayName !== undefined) {
        this.updateAnnexDisplayName({
          index: index,
          annex: annex,
          displayName: newDisplayName,
        });
      }
    },
    addNewAnnex() {
      this.newAnnexFile = null;
      this.newAnnexChapters = null;
      this.indexOfVisiblePlus = this.annexes.length;
    },
    updateDocumentProperty(prop, newValue) {
      this.updateCurrentDocument({
        key: prop,
        value: newValue,
      });
    },
    removeAnnex(index) {
      this.removeSelectedAnnex(index);
      // if all attached annexes are removed
      // null the values, so the add annex field will become visible
      if (!this.annexes.length) {
        this.newAnnexFile = null;
        this.newAnnexChapters = [];
        this.indexOfVisiblePlus = 0;
      } else {
        this.indexOfVisiblePlus = this.annexes.length - 1;
      }
    },
    previewAnnexFE(index) {
      this.isLoading = true;
      const annexId = this.annexes[index].annexId;
      this.previewAnnex({
        annexId: annexId,
        cb: (link) => {
          this.isLoading = false;
          window.open(link, '_blank');
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    downloadAnnexFE(index) {
      const annex = this.annexes[index];
      const annexId = annex.annexId;
      const annexFilename = annex.annexFileName;
      this.downloadAnnex({
        annexId: annexId,
        filename: annexFilename,
        cb: () => {
          this.isLoading = false;
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    validateForm() {
      this.formErrors.selectedYear = !this.currentDocument.year ? this.$t(`documentManagement.selectYear`) : null;

      if (this.currentDocument.type === 'NS') {
        this.formErrors.selectedFile = !this.selectedFile ? this.$t(`documentManagement.attachFile`) : null;
      } else {
        this.formErrors.selectedBooks = !this.selectedBookFiles.length
          ? this.$t(`documentManagement.attachFile`)
          : null;
      }
      this.formErrors.organizationName = !this.currentDocument.organizationName
        ? this.$t(`documentManagement.select`) + this.currentDocument.type
        : null;

      this.formErrors.csLevels =
        this.csLevels && this.csLevels.levels && !this.csLevels.levels.length
          ? this.$t(`documentManagement.levelsError`)
          : null;

      return Object.values(this.formErrors).filter((err) => !!err).length === 0;
    },
    onBookSelected(event, index) {
      const file = event.target.files[0];
      this.addBook({
        index: index,
        file: file,
      });
      this.setHasUploadedFile(true);
      this.$forceUpdate();
    },
    onRemoveBook(index) {
      this.removeBook({ index: index });
      this.$forceUpdate();
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      this.setHasUploadedFile(true);
      this.addSelectedFile(file);
    },
    removeFile() {
      this.removeSelectedFile();
      this.$refs.file.value = null;
    },
    validateVersionNumber(event) {
      if (!isVersionValid(event)) {
        this.formErrors.version = this.$t(`documentManagement.versionError`);
      } else {
        this.formErrors.version = null;
      }
      this.updateDocumentProperty('version', event);
    },
    getRfcIdByName(name) {
      return this.rfcList.find((rfc) => rfc.nameOnly === name).value;
    },
    getImIdByName(name) {
      return this.imsList.find((im) => im.name === name).value;
    },
    navigateToStatusPage(responseData) {
      this.$router.push({
        name: 'DocManagementUploadStatus',
        params: {
          data: responseData,
          note: this.currentDocument.note,
          type: this.currentDocument.type,
          isMaster: this.isMasterUpload,
        },
      });
    },
    navigateToAnalysisPage() {
      console.log('passing params: ', {
        documentId: this.currentDocument.id,
        documentType: this.currentDocument.type,
        organizationName: this.currentDocument.organizationName,
        year: this.currentDocument.year,
        version: this.currentDocument.version,
      });
      this.$router.push({
        name: 'DocumentAnalysis',
        query: {
          documentId: this.currentDocument.id,
          documentType: this.currentDocument.type,
          organizationName: this.currentDocument.organizationName,
          year: this.currentDocument.year,
          version: this.currentDocument.version,
        },
      });
    },
    navigateToDocumentsListPage() {
      this.$router.push({
        name: 'DocManagement',
      });
    },
    prepareSaveData() {
      // check annexes
      const emptyAnnexes = this.annexes.filter((annex) => annex.chapters.length === 0);

      if (emptyAnnexes.length) {
        return false;
      }

      const formData = new FormData();
      formData.append('version', this.currentDocument.version);
      const note = this.currentDocument.note ? this.currentDocument.note : '';
      formData.append('documentNote', note);

      const booksData = [];
      let fileUploaded = true;

      if (this.currentDocument.type === 'CID') {
        this.filteredBooksOnly.forEach((book, index) => {
          const b = this.selectedBookFiles[index] ? this.selectedBookFiles[index].file : null;
          if (b && this.isFile(b)) {
            booksData.push({
              csLevel: book.id,
              file: b,
            });
          }
        });
        if (!this.isMasterUpload) {
          formData.append('rfcId', this.getRfcIdByName(this.currentDocument.organizationName));
        }
      } else {
        const csLevelId = this.filteredBooksOnly[0].id;
        formData.append('csLevel', csLevelId);
        if (!this.isFile(this.selectedFile)) {
          fileUploaded = false;
        }
        formData.append('filename', this.selectedFile);
        formData.append('imId', this.getImIdByName(this.currentDocument.organizationName));
      }

      const requestData = {
        type: this.currentDocument.type,
        formData: formData,
        isMaster: false,
        isActualFileUploaded: fileUploaded,
      };

      requestData.booksData = booksData.length ? booksData : undefined;

      const annexFormData = [];
      const updatedAnnexCsLevels = [];
      this.annexes.forEach((annex) => {
        if (this.isFile(annex.file)) {
          const formData = new FormData();
          formData.append('filename', annex.file);
          formData.append('csLevels', annex.chapters);
          formData.append('version', this.currentDocument.version);
          formData.append('documentNote', this.currentDocument.note);

          if (!this.isMasterUpload) {
            if (this.currentDocument.type === 'CID') {
              formData.append('rfcId', this.getRfcIdByName(this.currentDocument.organizationName));
            } else {
              formData.append('imId', this.getImIdByName(this.currentDocument.organizationName));
            }
          }
          annexFormData.push(formData);
        } else {
          // check if annex chapters were added/removed
          if (annex.isUpdated) {
            updatedAnnexCsLevels.push({
              annexId: annex.annexId,
              chapters: annex.chapters,
              displayName: annex.displayName,
              includeOnDownload: annex.includeOnDownload,
            });
          }
        }
      });

      return {
        annexes: annexFormData,
        type: this.currentDocument.type,
        fileData: requestData,
        updatedAnnexes: updatedAnnexCsLevels,
      };
    },
    saveFile(isMaster = false) {
      this.setLoading(true);
      this.isSubmitted = true;
      if (isMaster || (!isMaster && this.validateForm())) {
        const data = this.prepareSaveData();
        if (!data) {
          this.setLoading(false);
          this.$root.$emit('toast-error', this.$t(`documentManagement.annexErrorNoCsSelected`));
          return;
        }

        if (isMaster) {
          this.saveMasterFileAndAnnexes({
            annexes: data.annexes,
            fileData: data.fileData,
            cb: (responseData) => {
              this.removeAllBooks();
              this.setLoading(false);
              this.navigateToStatusPage(responseData);
            },
            ecb: () => {
              this.removeAllBooks();
              this.setLoading(false);
            },
          });
        } else {
          this.saveFileAndAnnexes({
            annexes: data.annexes,
            updatedAnnexes: data.updatedAnnexes,
            type: data.type,
            fileData: data.fileData,
            cback: (responseData) => {
              let actualFilesUploaded = false;
              if (this.currentDocument.type === 'CID') {
                actualFilesUploaded = !!this.selectedBookFiles.find((book) => {
                  return book && this.isFile(book.file);
                });
              } else {
                actualFilesUploaded = this.isFile(this.selectedFile);
              }
              this.setLoading(false);
              const error = responseData.length ? responseData.find((r) => r.result && r.result.error) : null;
              if (error) {
                if (!error.result.status) {
                  this.$root.$emit('toast-error', error.result.error);
                }
                this.setLoading(false);
                // this.navigateToDocumentsListPage();
              }
              if (responseData.noFile) {
                this.setLoading(false);
                this.$root.$emit('toast-error', responseData.noFile);
              } else {
                if (actualFilesUploaded && !error) {
                  this.navigateToStatusPage(responseData);
                } else {
                  this.navigateToDocumentsListPage();
                }
              }
            },
            ecb: () => {
              this.removeAllBooks();
              this.clearDocumentUploadedData();
              this.setLoading(false);
            },
          });
        }
      } else {
        this.setLoading(false);
      }
    },
    publish() {
      this.setLoading(true);
      this.isSubmitted = true;
      if (this.validateForm()) {
        // First, we save the form
        const data = this.prepareSaveData();
        if (!data) {
          this.setLoading(false);
          this.$root.$emit('toast-error', this.$t(`documentManagement.annexErrorNoCsSelected`));
          return;
        }

        this.saveFileAndAnnexes({
          annexes: data.annexes,
          updatedAnnexes: data.updatedAnnexes,
          type: data.type,
          fileData: data.fileData,
          // eslint-disable-next-line no-unused-vars
          cback: (responseData) => {
            this.publishFile({
              type: data.type,
              fileData: data.fileData,
              cb: (responseData) => {
                // this.removeAllBooks();
                this.setLoading(false);
                this.navigateToStatusPage(responseData);
              },
              ecb: () => {
                this.removeAllBooks();
                this.setLoading(false);
              },
            });
          },
          ecb: () => {
            this.removeAllBooks();
            this.setLoading(false);
          },
        });
      } else {
        this.setLoading(false);
      }
    },
    unpublish() {
      this.setLoading(true);
      this.unpublishDocument({
        documentId: this.currentDocument.id,
        cb: () => {
          this.setLoading(false);
          location.reload();
        },
      });
    },
    deleteWholeYear() {
      this.setLoading(true);
      this.doDeleteWholeYear({
        documentId: this.currentDocument.id,
        cb: () => {
          this.setLoading(false);
          this.navigateToDocumentsListPage();
        },
      });
    },
    onAnnexFileSelected(event) {
      this.newAnnexFile = event.target.files[0];
      this.addSelectedAnnex({
        file: this.newAnnexFile,
        annexFileName: this.newAnnexFile.name,
        displayName: this.newAnnexFile.name,
        includeOnDownload: true,
        chapters: this.newAnnexChapters ? this.newAnnexChapters.map((c) => c.id) : [],
      });
    },
    dropHandler(ev) {
      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
      if (this.isPublished) {
        return;
      }

      if (ev.dataTransfer.items) {
        this.newAnnexFile = ev.dataTransfer.items[0].getAsFile();
      } else {
        this.newAnnexFile = ev.dataTransfer.files[0];
      }
      this.addSelectedAnnex({
        file: this.newAnnexFile,
        annexFileName: this.newAnnexFile.name,
        displayName: this.newAnnexFile.name,
        includeOnDownload: true,
        chapters: this.newAnnexChapters ? this.newAnnexChapters.map((c) => c.id) : [],
      });
    },
  },
};
</script>

<style scoped>
>>> .cs-select select {
  padding-right: 36px;
}

textarea {
  resize: none;
  font-size: 1.4em;
  font-weight: 600;
}

.doc-label {
  font-family: Roboto, sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  color: #808080;
}

.status-label {
  font-size: 1.6em;
  font-weight: 500;
  font-style: italic;
  color: #080808;
}

.annex-separator {
  box-shadow: 0px 13px 15.8px 0px rgba(0, 0, 0, 0.26);
}

.modal-button-confirm {
  background-color: #fff;
  color: #404040;
}

.annexes-label {
  font-size: 2em;
  font-weight: normal;
}

.annexes-label-count {
  font-size: 2em;
  font-weight: 500;
  color: #c1893a;
}

.add-row-container {
  position: relative;
  top: 15px;
}

.add-row-container img {
  cursor: pointer;
}

.remove-annex-label {
  top: 0px;
}

.attach-annex-container {
  max-height: 80px;
}

.notes-textarea {
  white-space: pre-wrap;
}

.btn-annex {
  width: 32px !important;
  height: 32px !important;
  margin: 24px 4px 0px;
  padding: 0px !important;
}

.btn-annex img {
  width: 32px;
}

.btn-annex-small {
  width: 26px !important;
  height: 24px !important;
  margin: 16px 4px 0px;
  padding: 0px !important;
}

.btn-annex-small img {
  width: 24px;
}

/* @media screen and (max-width: 1337px) {
  .document-icon {
    width: 24px;
  }
} */
</style>
