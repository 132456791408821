<template>
  <DefaultLayout>
    <Headings :title="$t(`country.refData`)" :subtitle="$t(`common.countries`)">
      <button class="add_country_btn" @click.prevent="openDialog()">
        {{ $t('country.add_country') }}
      </button>
    </Headings>
    <div class="row">
      <div class="col-12">
        <LabelWithCount :title="$t(`country.allCountriesLabel`)" :count="totalCountries" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-users">
          <table class="table-countries">
            <thead>
              <tr>
                <th v-for="(header, index) in headers" :key="index">
                  {{ header.name }}
                </th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(country, index) in foundCountries"
                :key="index"
                class="country_row table-clickable-row"
                @click.stop="openDialog(country.id)"
              >
                <td id="name_country">
                  <b>{{ country.name }}</b>
                </td>
                <td>{{ country.code }}</td>
                <!--
                <td class="col-actions">
                  <v-btn class="mx-2" fab dark small color="#DBA153">
                    <v-icon dark>arrow_forward</v-icon>
                  </v-btn>
                </td>
                -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Modal :key="country.id" :is-modal-open="isModalOpen" :close-dialog="closeDialog">
      <template #header>
        {{ $t(country.id ? 'country.edit_country' : 'country.add_country') }}
      </template>
      <InputField
        :name="$t(`common.countryName`)"
        :value.sync="country.name"
        :update="updateName"
        :error="errors.error('country.name')"
      />
      <InputField
        :name="$t(`common.countryCode`)"
        :value.sync="country.code"
        :update="updateCode"
        :error="errors.error('country.code')"
      />
      <template #footer>
        <div v-if="!country.id" class="row">
          <div class="col-6">
            <button class="save_edited_country_btn" @click="add">
              {{ $t('common.add') }}
            </button>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-6">
            <button class="save_edited_country_btn" @click="update">
              {{ $t('common.save') }}
            </button>
          </div>
          <div class="col-6">
            <button class="delete_country_btn" @click="deleteItem">
              {{ $t('common.delete') }}
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <ConfirmModal
      :is-modal-open="isConfirmModalOpen"
      :close-dialog="closeConfirmModal"
      :confirm-callback="confirmCallback"
      :lable-text="confirmLableText"
      style="z-index: 20001"
    />
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import LabelWithCount from '@/components/base/LabelWithCount';
import Modal from '@/components/base/Modal';
import InputField from '@/components/base/InputField';
import { mapState, mapActions, mapGetters } from 'vuex';
import ConfirmModal from '../common/modals/ConfirmModal';

const imageOne = require('@/assets/icons/grey-icons/arrow.png');
const imageTwo = require('@/assets/icons/gold-icons/arrow.png');

export default {
  name: 'CountriesList',
  components: {
    DefaultLayout,
    LabelWithCount,
    Headings,
    Modal,
    InputField,
    ConfirmModal,
  },
  data() {
    return {
      headers: [
        {
          name: this.$t('common.country').toUpperCase(),
          id: 'username_sort',
          image: imageOne,
        },
        {
          name: this.$t('common.countryCode').toUpperCase(),
          id: 'company_sort',
          image: imageOne,
        },
      ],
      isModalOpen: false,
      errors: [],
      validate: ['country.name', 'country.code'],
      isConfirmModalOpen: false,
      confirmLableText: '',
      confirmCallback: null,
    };
  },
  computed: {
    ...mapState({
      countriesArray: (state) => state.common.countries,
      foundCountries: (state) => state.common.foundCountries,
      country: (state) => state.countries.current,
    }),
    ...mapGetters({
      totalCountries: 'common/countriesCount',
      filterCountries: 'common/filterCountries',
    }),
  },
  mounted() {
    this.validate.valid(this);
  },
  created() {
    if (!this.countriesArray.length) this.getCountries({});
    this.image = imageOne;
  },
  methods: {
    ...mapActions('common', ['getCountries']),
    ...mapActions('countries', [
      'addCountry',
      'updateCountry',
      'updateName',
      'updateCode',
      'currentItem',
      'getCountry',
      'clearCurrent',
      'deleteCountry',
    ]),
    change(id) {
      const imgElement = document.getElementById(id);
      imgElement.src = imgElement.src === imageOne ? imageTwo : imageOne;
      this.order = imgElement.src === imageOne ? 'DESC' : 'ASC';
    },
    add() {
      this.addCountry({
        data: this.country,
        cb: (data) => {
          this.addCountryFeedback = data.message;
          this.toggleModal();
          this.clearCurrent();
        },
        ecb: (err) => {
          this.$root.$emit('toast-error', err);
          this.addCountryFeedback = err.error;
        },
      });
    },
    update() {
      this.updateCountry({
        data: this.country,
        cb: (data) => {
          this.addCountryFeedback = data.message;
          this.toggleModal();
          this.clearCurrent();
        },
        ecb: (err) => {
          console.log(err);
          this.addCountryFeedback = err.error;
        },
      });
    },
    deleteItem() {
      const { id } = this.country;
      if (id) {
        this.openConfirmModal(this.$t(`country.countryError`), () => {
          this.deleteCountry({ id });
          this.isConfirmModalOpen = false;
        });
      }
      this.toggleModal();
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    openDialog(id) {
      if (id) this.getCountry({ id });
      this.toggleModal();
    },
    closeDialog() {
      this.toggleModal();
      this.clearCurrent();
    },
    openConfirmModal(text, callback) {
      this.confirmLableText = text;
      this.confirmCallback = callback;
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
  },
};
</script>

<style scoped>
.table-countries td {
  height: 32px;
}
</style>
