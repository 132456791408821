var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AuthLayout",
    [
      _c(
        "v-container",
        { staticClass: "register_container" },
        [
          _c("Logo", [_c("v-spacer"), _c("LanguageSelector")], 1),
          _vm.requestError
            ? _c("label", { staticClass: "form-error form-error-r" }, [
                _vm._v(_vm._s(_vm.requestError)),
              ])
            : _vm._e(),
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "", novalidate: "true" },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("InputField", {
                      attrs: {
                        tabindex: 1,
                        placeholder: _vm.$t(`common.firstName`),
                        "use-model": true,
                        error: _vm.errors.error("formData.firstName"),
                      },
                      model: {
                        value: _vm.formData.firstName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "firstName", $$v)
                        },
                        expression: "formData.firstName",
                      },
                    }),
                    _c("InputField", {
                      attrs: {
                        placeholder: _vm.$t(`common.username`),
                        tabindex: 3,
                        "use-model": true,
                        error: _vm.errors.error("formData.email"),
                      },
                      model: {
                        value: _vm.formData.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "email", $$v)
                        },
                        expression: "formData.email",
                      },
                    }),
                    _c("SelectComponent", {
                      attrs: {
                        tabindex: 5,
                        options: _vm.countries,
                        placeholder: _vm.$t(`common.country`),
                        "obj-key": "name",
                        "obj-val": "id",
                        error: _vm.errors.error("formData.countryId"),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.setCountry($event)
                        },
                        change: function ($event) {
                          return _vm.setCountry($event)
                        },
                      },
                      model: {
                        value: _vm.formData.countryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "countryId", $$v)
                        },
                        expression: "formData.countryId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6", attrs: { id: "rightCol" } },
                  [
                    _c("InputField", {
                      attrs: {
                        placeholder: _vm.$t(`common.lastName`),
                        tabindex: 2,
                        "use-model": true,
                        error: _vm.errors.error("formData.familyName"),
                      },
                      model: {
                        value: _vm.formData.familyName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "familyName", $$v)
                        },
                        expression: "formData.familyName",
                      },
                    }),
                    _c("InputField", {
                      attrs: {
                        placeholder: _vm.$t(`common.companyName`),
                        tabindex: 4,
                        "use-model": true,
                        error: _vm.errors.error("formData.companyName"),
                      },
                      model: {
                        value: _vm.formData.companyName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "companyName", $$v)
                        },
                        expression: "formData.companyName",
                      },
                    }),
                    _c("SelectComponent", {
                      attrs: {
                        tabindex: 6,
                        options: _vm.entities,
                        placeholder: _vm.$t(`common.typeOfEntity`),
                        "obj-key": "name",
                        "obj-val": "id",
                        error: _vm.errors.error("formData.organizationTypeId"),
                      },
                      on: {
                        change: function ($event) {
                          ;(_vm.formData.organizationTypeId = $event),
                            _vm.clearValidation()
                        },
                      },
                      model: {
                        value: _vm.formData.organizationTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "organizationTypeId", $$v)
                        },
                        expression: "formData.organizationTypeId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6 py-0 mb-10" },
                  [
                    _c("InputField", {
                      attrs: {
                        tabindex: 7,
                        placeholder: _vm.$t(`common.password`),
                        "use-model": true,
                        error: _vm.errors.error("formData.password"),
                        type: "password",
                      },
                      model: {
                        value: _vm.formData.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "password", $$v)
                        },
                        expression: "formData.password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6 py-0 mb-10" },
                  [
                    _c("InputField", {
                      attrs: {
                        tabindex: 8,
                        placeholder: _vm.$t(`common.confirmPassword`),
                        "use-model": true,
                        error: _vm.errors.error("formData.confirm"),
                        type: "password",
                      },
                      model: {
                        value: _vm.formData.confirm,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "confirm", $$v)
                        },
                        expression: "formData.confirm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 py-0" },
                  [
                    _c("InputField", {
                      staticClass: "agreement-checkbox",
                      attrs: {
                        tabindex: 10,
                        name: _vm.$t(`registration.termsAndConditions`),
                        "use-model": true,
                        type: "checkbox",
                        error: _vm.errors.error(
                          "formData.termsAndConditionsConsent"
                        ),
                      },
                      model: {
                        value: _vm.formData.termsAndConditionsConsent,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "termsAndConditionsConsent",
                            $$v
                          )
                        },
                        expression: "formData.termsAndConditionsConsent",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 py-0" },
                  [
                    _c("InputField", {
                      staticClass: "agreement-checkbox",
                      attrs: {
                        tabindex: 12,
                        name: _vm.$t(`registration.personalDataConsent`),
                        "use-model": true,
                        type: "checkbox",
                      },
                      model: {
                        value: _vm.formData.subscribedNewUpdates,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "subscribedNewUpdates", $$v)
                        },
                        expression: "formData.subscribedNewUpdates",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 py-0" },
                  [
                    _c("InputField", {
                      staticClass: "agreement-checkbox",
                      attrs: {
                        tabindex: 13,
                        name: _vm.$t(`users.receiveInformationalEmails`),
                        "use-model": true,
                        type: "checkbox",
                      },
                      model: {
                        value: _vm.formData.subscribedNewsletter,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "subscribedNewsletter", $$v)
                        },
                        expression: "formData.subscribedNewsletter",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "form",
                    {
                      staticClass: "captcha",
                      attrs: { action: "?", method: "POST" },
                    },
                    [
                      _c("div", {
                        staticClass: "g-recaptcha",
                        attrs: {
                          id: "recaptcha-main",
                          "data-sitekey":
                            "6LcFS98ZAAAAAPp1m2QPlxd07FqDGYni1dSMyzJf",
                          "data-tabindex": "12",
                        },
                      }),
                      _vm.errors.error("formData.recaptcha")
                        ? _c("span", { staticClass: "form-error" }, [
                            _vm._v(
                              _vm._s(_vm.errors.error("formData.recaptcha"))
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn-reg",
                      attrs: { tabindex: "13" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.registerNewUser.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(`common.register`)) + " ")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "btn-back mt-0", attrs: { to: "/" } },
                      [_vm._v(_vm._s(_vm.$t(`common.back`)))]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }