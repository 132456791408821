<template>
  <Modal :key="item.id" :is-modal-open="isModalOpen" :close-dialog="closeDialog">
    <LoadingComponent :value="isLoading" />
    <template #header>
      {{
        $t('editLevels.editing', {
          type: $t(!item.isBook && type && type !== 'NS' ? 'editLevels.chapter' : 'editLevels.file'),
          num: item.csNumber,
        })
      }}
    </template>

    <InputField
      v-model="currentName"
      class="selection-bold"
      :tabindex="2"
      :name="$t(!item.isBook ? 'editLevels.chapter_name' : 'editLevels.file_name')"
      :use-model="true"
    />
    <MultipleSelectComponent
      v-if="item.parentId"
      v-model="selectedItems"
      :tabindex="4"
      :options="tags"
      :name="$t('tags.tags') + ':'"
      obj-key="name"
      obj-val="id"
    />
    <v-row>
      <v-col cols="6">
        <InputField
          v-model="isBookLocal"
          class="selection-bold level-checkbox"
          :tabindex="2"
          :name="$t('editLevels.is_file')"
          type="checkbox"
          :use-model="true"
        />
      </v-col>
      <v-col cols="6">
        <InputField
          v-if="item.parentId"
          v-model="isWithoutLevel"
          class="selection-bold level-checkbox"
          :tabindex="3"
          :name="$t('editLevels.is_level')"
          type="checkbox"
          :use-model="true"
        />
      </v-col>
    </v-row>
    <v-data-table
      :items="externalLinks"
      :headers="headers"
      disable-sort
      disable-pagination
      hide-default-footer
      dense
      style="max-width: 860px"
    >
      <template #top>
        <div class="pb-3 d-flex align-center">
          <span class="font-weight-medium" style="font-size: 1.2rem">{{ $t('structure.externalLinks') }}</span>
          <v-btn class="ml-3" fab x-small depressed color="secondary" @click="addLink">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
      <template #item="row">
        <tr>
          <td>{{ row.item.application }}</td>
          <td>{{ row.item.tooltip }}</td>
          <td>
            <div class="text-truncate" :title="row.item.url">{{ row.item.url.replace(/^[^:]+:\/\//, '') }}</div>
          </td>
          <td>
            <v-img :src="row.item.logoUrl" max-width="100" max-height="64" contain />
          </td>
          <td align="center" width="130" nowrap>
            <v-btn icon color="secondary" @click="editLink(row.item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="removeLink(row.item)">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <template #footer>
      <div class="row">
        <div class="col-6">
          <button class="save_edited_country_btn" @click="updateLevel">
            {{ $t('common.save') }}
          </button>
        </div>
        <div class="col-6">
          <button class="delete_country_btn" @click="closeDialog">
            {{ $t('common.close') }}
          </button>
        </div>
      </div>
    </template>
    <!-- New link dialog -->
    <v-dialog v-model="dlgEditLink" persistent width="auto">
      <v-form ref="frm" @submit.prevent="updateLink">
        <LoadingComponent :loading="isLoading" />
        <v-card min-width="300">
          <v-card-title class="pt-2 pr-1">
            {{ $t((currentLink || {}).id ? 'structure.oldLink' : 'structure.newLink') }}
            <v-spacer />
            <v-btn icon @click="dlgEditLink = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-text-field
              v-model.trim="currentLink.application"
              :label="$t('structure.appName')"
              outlined
              dense
              :rules="[ruleRequired]"
            />
            <v-text-field
              v-model.trim="currentLink.tooltip"
              :label="$t('structure.tooltip')"
              outlined
              dense
              :rules="[ruleRequired]"
            />
            <v-text-field
              v-model.trim="currentLink.url"
              type="url"
              :label="$t('structure.linkURL')"
              outlined
              dense
              :rules="[ruleRequired, validURL]"
            />
            <div class="d-flex align-center justify-space-around">
              <v-img
                v-if="currentLink.newLogoUrl || currentLink.logoUrl"
                :src="currentLink.newLogoUrl || currentLink.logoUrl"
                max-width="100"
                max-height="64"
                contain
              />
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    color="error"
                    :style="currentLink.newLogoUrl ? '' : 'visibility: hidden;'"
                    v-on="on"
                    @click="(currentLink.newLogoFile = null), (currentLink.newLogoUrl = '')"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                {{ $t('structure.tooltipLogo') }}
              </v-tooltip>
              <v-btn color="secondary" depressed tag="label" for="link_logo" style="cursor: pointer">
                <v-icon class="mr-2">mdi-camera</v-icon>
                {{ $t('structure.logo') }}
              </v-btn>
              <input
                id="link_logo"
                ref="file_ctrl"
                type="file"
                class="file_ctrl"
                accept=".jpg,.jpeg,.png"
                @change="chooseLogo"
              />
            </div>
            <div class="v-messages error--text mt-2">
              <transition name="message-transition">
                <div v-if="errorLogo" class="v-messages__message">
                  {{ errorLogo }}
                </div>
              </transition>
            </div>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-row>
              <v-col>
                <button class="save_edited_country_btn" type="submit">
                  {{ $t('common.save') }}
                </button>
              </v-col>
              <v-col>
                <button class="delete_country_btn" type="button" @click="dlgEditLink = false">
                  {{ $t('common.cancel') }}
                </button>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </Modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import mixinCommon from '../CommonList.data';
import InputField from '@/components/base/InputField';
import MultipleSelectComponent from '@/components/base/MultipleSelectComponent';
import Modal from '@/components/base/Modal';
import LoadingComponent from '@/components/base/LoadingComponent';
import { ADD_CS_TAG_URL, MOVE_CS_LEVEL_URL, UPDATE_CS_LEVEL_URL } from '@/utils/api.endpoints';
import mixinValidations from '@/utils/mixinValidations';
import { clearFileInput } from '@/utils/utils';

const emptyLink = {
  id: '',
  application: '',
  tooltip: '',
  url: '',
  logoUrl: '',
  newLogoFile: null,
  newLogoUrl: '',
};

export default {
  name: 'EditLevels',
  components: {
    InputField,
    MultipleSelectComponent,
    Modal,
    LoadingComponent,
  },
  mixins: [mixinCommon, mixinValidations],
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function,
      default: null,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    getData: {
      type: Function,
      default: null,
    },
    selectedTags: {
      type: Array,
      default: () => [],
    },
    nameEn: {
      type: String,
      default: '',
    },
    isBook: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    lowestLevel: {
      type: Number,
      default: 0,
    },
    /*
    highestLevel:
      {
        type: Number,
        default: 0
      },
    loading:
      {
        type: Function,
        required: true
      },
      */
    initialWithoutLevel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      currentName: '',
      isBookLocal: false,
      selectedItems: [],
      isWithoutLevel: this.initialWithoutLevel,
      externalLinks: [],
      dlgEditLink: false,
      currentLink: Object.assign({}, emptyLink),
      errorLogo: '',
    };
  },
  computed: {
    ...mapState({
      tags: (state) => state.tags.data,
    }),
    csID() {
      return this.$route.params.id;
    },
    headers() {
      return [
        {
          text: this.$t('structure.appName'),
          value: 'application',
          class: 'font-weight-bold',
        },
        {
          text: this.$t('structure.tooltip'),
          value: 'tooltip',
          class: 'font-weight-bold',
        },
        {
          text: this.$t('structure.linkURL'),
          value: 'url',
          class: 'font-weight-bold',
        },
        {
          text: this.$t('structure.logo'),
          value: 'logoUrl',
          class: 'font-weight-bold',
        },
        {
          text: '',
          value: 'action',
          class: 'font-weight-bold',
          width: 100,
        },
      ];
    },
  },
  watch: {
    nameEn(val) {
      this.currentName = val;
    },
    isBook(val) {
      this.isBookLocal = val;
    },
    selectedTags(val) {
      this.selectedItems = val;
    },
    isModalOpen(val) {
      if (val) {
        this.isWithoutLevel = this.item.position < 0;
        this.fetchExternalLinks();
      }
    },
    dlgEditLink(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.frm.resetValidation();
        });
      }
    },
  },
  methods: {
    ...mapActions('structure', ['updateCSLevel', 'addTagToLevel', 'moveCSLevel']),
    ...mapMutations('structure', ['setSingleCS', 'setSingleCsLevels']),
    updateLevel() {
      this.isLoading = true;
      const item = this.item;
      return new Promise((resolve) => {
        if (
          this.currentName !== this.nameEn ||
          this.isBookLocal !== this.isBook ||
          (this.isWithoutLevel && this.item.position > 0)
        ) {
          this.$axios
            .put(UPDATE_CS_LEVEL_URL(this.csID, item.id), {
              isBook: this.isBookLocal,
              nameEn: this.currentName,
              nameLocal: item.nameLocal,
              complianceWeight: item.complianceWeight,
            })
            .then((response) => {
              if (!(response && 'error' in response)) {
                if (this.isWithoutLevel && item.position > 0) {
                  resolve(this.moveLevel(this.lowestLevel >= 0 ? -1 : this.lowestLevel - 1));
                } else {
                  this.getData();
                  resolve(true);
                }
              } else this.$root.$emit('toast-error', response.error);
            })
            .catch((err) => {
              this.$root.$emit('toast-error', err);
            })
            .finally(() => {
              resolve(true);
            });
        } else if (!this.isWithoutLevel && this.item.position < 0) {
          resolve(this.moveLevel(1));
        } else resolve(true);
      })
        .then(() => {
          return this.selectedTags !== this.selectedItems ? this.addTag() : Promise.resolve(false);
        })
        .then((result) => {
          if (result) this.getData();
          this.closeDialog();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    moveLevel(position) {
      return this.$axios
        .put(MOVE_CS_LEVEL_URL(this.csID, this.item.id, position, this.item.parentId || ''))
        .then((response) => {
          if (!(response && 'error' in response)) {
            this.setSingleCsLevels(response);
          } else this.$root.$emit('toast-error', response.error);
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.isWithoutLevel = false;
        });
    },
    addTag() {
      return Promise.all(
        this.selectedItems.map((tag) => {
          return this.$axios
            .put(ADD_CS_TAG_URL(this.csID, this.item.id, tag.id))
            .then((response) => {
              if (!(response && 'error' in response)) {
                this.setSingleCS(response);
                return true;
              } else this.$root.$emit('toast-error', response.error);
            })
            .catch((error) => {
              this.$root.$emit('toast-error', error);
            });
        })
      );
    },
    fetchExternalLinks() {
      this.externalLinks = [];
      this.isLoading = true;
      this.$axios
        .get(`common-structures/${this.csID}/levels/${this.item.id}/links`)
        .then((response) => {
          this.externalLinks = response.map((item) => {
            if (item.logoUrl) item.logoUrl += '?t=' + Date.now();
            return item;
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateLink() {
      if (this.currentLink.logoUrl || this.currentLink.newLogoUrl) {
        if (this.$refs.frm.validate()) {
          this.isLoading = true;
          const id = this.currentLink.id;
          let url = `common-structures/${this.csID}/levels/${this.item.id}/links`;
          if (id) url += '/' + id;
          this.$axios[id ? 'put' : 'post'](url, this.currentLink)
            .then((response) => {
              if ('error' in response) this.$root.$emit('toast-error', response.error);
              else return response;
            })
            .then((link) => {
              if (link) {
                const id = this.currentLink.id;
                if (id) {
                  const idx = this.externalLinks.findIndex((item) => item.id === id);
                  if (idx !== -1) this.externalLinks.splice(idx, 1, link);
                } else {
                  this.externalLinks.push(link);
                  url += '/' + link.id;
                }
                // set/update logo
                if (this.currentLink.newLogoFile) {
                  const form = new FormData();
                  form.append('logo', this.currentLink.newLogoFile);
                  return this.$axios.put(`${url}/logo`, form).then((logoURL) => {
                    link.logoUrl = logoURL.logoUrl + '?t=' + Date.now();
                  });
                }
              }
            })
            .then(() => {
              this.dlgEditLink = false;
            })
            .catch((err) => {
              this.$root.$emit('toast-error', err);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      } else this.errorLogo = this.$t('structure.logoRequired');
    },
    editLink(link) {
      this.errorLogo = '';
      this.currentLink = Object.assign({}, emptyLink, link);
      this.dlgEditLink = true;
    },
    addLink() {
      this.errorLogo = '';
      this.currentLink = Object.assign({}, emptyLink);
      this.dlgEditLink = true;
    },
    removeLink(link) {
      this.$confirm({
        title: this.$t('structure.confirmation'),
        text: this.$t('structure.confirmDelete'),
        acceptText: this.$t('common.yes'),
        cancelText: this.$t('common.no'),
      })
        .then(() => {
          this.isLoading = true;
          this.$axios
            .delete(`common-structures/${this.csID}/levels/${this.item.id}/links/${link.id}`)
            // eslint-disable-next-line no-unused-vars
            .then((response) => {
              const idx = this.externalLinks.findIndex((item) => item.id === link.id);
              if (idx !== -1) this.externalLinks.splice(idx, 1);
            })
            .catch((err) => {
              this.$root.$emit('toast-error', err);
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch(() => false);
    },
    chooseLogo(evt) {
      const file = (evt.dataTransfer || evt.target).files[0];
      if (file.size > 3e6) this.errorLogo = this.$t('structure.logoTooBig');
      else if (!/\.(png|jpg|jpeg)$/i.test(file.name)) this.errorLogo = this.$t('structure.logoFileType');
      else if (file.size > 100) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentLink.newLogoUrl = e.target.result;
        };
        reader.readAsDataURL(file);
        this.currentLink.newLogoFile = file;
      }
      clearFileInput(this.$refs.file_ctrl);
    },
  },
};
</script>

<style scoped>
>>> .modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  min-width: 550px;
  position: relative;
}

>>> .level-checkbox label {
  padding-top: 7px;
}
</style>
