<template>
  <DefaultLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <Headings :title="$t(`common.ImAndRfcManagement`)" subtitle="IM Management" />
    <div id="inputs" class="row justify-content-between">
      <div class="col-4">
        <SelectComponent
          id="im-rfc-select"
          v-model="managementType"
          class="user-list-input"
          :tabindex="2"
          :options="managementTypes"
          :name="$t(`common.manageType`)"
          obj-key="name"
          obj-val="value"
          @change="changeUrl"
        />
      </div>
      <div v-if="isAdmin" id="search-col" class="offset-6 col-2">
        <button class="btn btn-add-new-im" @click.prevent="open({ id: 'new' })">
          {{ $t(`ims.addNewIm`) }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <LabelWithCount :title="$t(`ims.imsManagement`)" :count="ims.length" />
      </div>
      <div class="col-12">
        <div class="table-ims">
          <table>
            <thead class="table-head-shadow">
              <tr>
                <th />
                <th v-for="(header, index) in headers" :key="index">
                  {{ header.name }}
                  <img
                    :id="header.id"
                    :class="sortDirection(header.id)"
                    :src="sortIcon(header.id)"
                    @click="sortBy(header.id)"
                  />
                </th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(im, index) in ims" :key="index" class="table-clickable-row" @click="open(im)">
                <td>
                  <img class="logo-preview" :src="getLogo(im.id)" alt />
                </td>
                <td>{{ im.name }}</td>
                <td>{{ im.localizedName }}</td>
                <td>{{ mapCountries(im.countryIds) }}</td>
                <!--
                <td class="col-actions">
                  <v-btn class="mx-2" fab dark small color="#DBA153" @click="open(im)">
                    <v-icon dark>arrow_forward</v-icon>
                  </v-btn>
                </td>
                -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { orderTypesEnum } from '@/utils/enums';
import { IMS_LOGO_URL } from '@/utils/api.endpoints';
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import LabelWithCount from '@/components/base/LabelWithCount';
import SelectComponent from '@/components/base/SelectComponent';
import LoadingComponent from '@/components/base/LoadingComponent';

const notSortedIcon = require('@/assets/icons/grey-icons/arrow.png');
const sortedIcon = require('@/assets/icons/gold-icons/arrow.png');

export default {
  name: 'IMsList',
  components: {
    DefaultLayout,
    SelectComponent,
    LabelWithCount,
    Headings,
    LoadingComponent,
  },
  data() {
    return {
      managementType: '/ims',
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      authState: 'auth',
      imsState: 'ims',
      rfcState: 'rfcs',
      commonState: 'common',
    }),
    ims() {
      if (this.isAdmin) {
        return this.imsState.data;
      } else {
        const imsAccess = this.authState.current.infrastructureManagerOverviews.map((imAccess) => imAccess.id);
        return this.imsState.data.filter((i) => imsAccess.includes(i.id));
      }
    },
    managementTypes() {
      if (this.isAdmin) {
        return [
          {
            name: this.$t('rfcs.rfcManagement'),
            value: '/rfcs',
          },
          {
            name: this.$t('ims.imsManagement'),
            value: '/ims',
          },
        ];
      } else {
        const result = [];
        const ims = this.authState.current.infrastructureManagerOverviews.length;
        const rfcs = this.authState.current.railFreightCorridorOverviews.length;
        if (ims) {
          result.push({
            name: this.$t('ims.imsManagement'),
            value: '/ims',
          });
        }
        if (rfcs) {
          result.push({
            name: this.$t('rfcs.rfcManagement'),
            value: '/rfcs',
          });
        }
        return result;
      }
    },
    countries() {
      return this.commonState.countries;
    },
    /*
      totalItems()
      {
        return this.imsState.totalItems;
      },
      orderBy()
      {
        return this.imsState.orderBy;
      },
      orderType()
      {
        return this.imsState.orderType;
      },
       */
    isAdmin() {
      return this.authState.current.roles === 'ADMIN';
    },
    headers() {
      return [
        {
          name: this.$t('ims.headers.name').toUpperCase(),
          id: 'name',
        },
        {
          name: this.$t('ims.headers.code').toUpperCase(),
          id: 'localizedName',
        },
        {
          name: this.$t('ims.headers.countries').toUpperCase(),
          id: 'countryIds',
        },
      ];
    },
  },
  created() {
    this.isLoading = true;
    this.getCountries({});
    this.getAllIMs({
      cb: () => {
        this.isLoading = false;
      },
      ecb: () => {
        this.isLoading = false;
      },
    });
  },
  methods: {
    ...mapActions('ims', ['getAllIMs', 'getSingleIMLogo', 'order']),
    ...mapActions('common', ['getCountries']),
    open(r) {
      this.$router.push({
        name: 'IMsDetails',
        params: { id: r.id },
      });
    },
    mapCountries(countryIds) {
      let result = '';
      countryIds.forEach((countryId) => {
        const country = this.countries.find((c) => c.id === countryId);
        result += country ? country.name + ', ' : '';
      });
      return result.slice(0, -2);
    },
    getLogo(id) {
      return `${this.$serverUrl}${IMS_LOGO_URL(id)}`;
    },
    sortBy(id) {
      this.order(id);
    },
    sortIcon(id) {
      return this.rfcState.orderBy === id ? sortedIcon : notSortedIcon;
    },
    sortDirection(id) {
      return this.rfcState.orderBy === id && this.rfcState.orderType === orderTypesEnum.ASC ? '' : 'reversed';
    },
    changeUrl(val) {
      this.$router.push(val);
    },
  },
};
</script>

<style scoped>
#im-rfc-select select {
  color: #000;
}

.btn-add-new-im {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 7px 5px;
  font-size: 1.4em;
  min-width: 140px;
  height: 42px;
  background-color: #265f14;
}
</style>
