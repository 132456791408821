var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("Headings", {
        attrs: {
          title: _vm.$t(`common.commonStructures`),
          subtitle: _vm.$t(`common.management`),
        },
      }),
      _c(
        "div",
        { staticClass: "row justify-content-between", attrs: { id: "inputs" } },
        [
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c("SelectComponent", {
                staticClass: "user-list-input select-big",
                attrs: {
                  tabindex: 2,
                  options: _vm.managementTypes,
                  name: _vm.$t(`common.manageType`),
                  "obj-key": "name",
                  "obj-val": "value",
                },
                model: {
                  value: _vm.managementType,
                  callback: function ($$v) {
                    _vm.managementType = $$v
                  },
                  expression: "managementType",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "offset-8 col-2", attrs: { id: "search-col" } },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-green btn-search",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.createStructure.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`common.createNew`)) + " ")]
              ),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.managementType
              ? _c("LabelWithCount", {
                  attrs: { title: _vm.subtitle, count: _vm.cs.length },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "table-rfcs" }, [
            _c("table", [
              _c("thead", { staticClass: "table-head-shadow" }, [
                _c(
                  "tr",
                  _vm._l(_vm.headers, function (header, index) {
                    return _c("th", { key: index }, [
                      _vm._v(" " + _vm._s(header.name) + " "),
                      _c("img", {
                        class: _vm.sortDirection(header.id),
                        attrs: { id: header.id, src: _vm.sortIcon(header.id) },
                        on: {
                          click: function ($event) {
                            return _vm.sortBy(header.id)
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.cs, function (item, index) {
                  return _c(
                    "tr",
                    {
                      key: index,
                      staticClass: "table-clickable-row",
                      on: {
                        click: function ($event) {
                          return _vm.open(item)
                        },
                      },
                    },
                    [
                      _c("td", [_vm._v(_vm._s(item.type))]),
                      _c("td", [_vm._v(_vm._s(item.year))]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
      _c("DuplicateStructure", {
        attrs: {
          "is-modal-open": _vm.isModalOpen,
          "close-dialog": _vm.closeDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }