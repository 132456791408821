<template>
  <DefaultLayout>
    <LoadingComponent :loading="isLoading" />
    <Headings :title="$t(`users.usersManagement`)" :subtitle="$t(`users.users`)" />
    <div id="download-col" class="row mb-2">
      <button class="btn btn-green btn-search" @click="downloadUsers">
        {{ $t(`users.downloadUsersNewsletter`) }}
      </button>
    </div>
    <div id="inputs" class="row">
      <div class="col-3">
        <InputField
          class="user-list-input"
          :name="$t(`users.searchByUsername`)"
          :tabindex="1"
          :value.sync="userNameModel"
          :use-model="true"
          :enter-callback="searchByCriteria"
          @update="userNameModel = $event"
        />
      </div>
      <div class="col-3">
        <SelectComponent
          v-model="userRoleModel"
          class="user-list-input"
          :tabindex="2"
          :options="roles"
          :name="$t(`users.userRole`)"
          obj-key="name"
          obj-val="value"
        />
        <!-- <select-component inputLabel="User Role" v-model="userRoleModel" :data="roles" /> -->
      </div>
      <div class="col-3">
        <SelectComponent
          v-model="userTypeModel"
          class="user-list-input"
          :tabindex="3"
          :options="entities"
          :name="$t(`users.userType`)"
          obj-key="name"
          obj-val="id"
        />
        <!-- <select-component inputLabel="User Role" v-model="userRoleModel" :data="roles" /> -->
      </div>
      <div class="col-3">
        <div id="search-col">
          <button class="btn btn-green btn-search" @click="searchByCriteria">
            <img class="search-icon" src="@/assets/icons/grey-icons/search.svg" />
            {{ $t(`common.search`) }}
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <LabelWithCount :title="$t(`users.allUsers`)" :count="calculateTotalUsers" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-users">
          <table>
            <thead class="table-head-shadow">
              <tr>
                <th v-for="(header, index) in headers" :key="index">
                  {{ header.name }}
                  <img
                    :id="header.id"
                    :class="sortDirection(header.name)"
                    :src="sortIcon(header.name)"
                    @click="sort(header.name)"
                  />
                </th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="!isLoading && !usersList.length" colspan="6" class="no-data-col">
                  {{ $t(`users.noResults`) }}
                </td>
              </tr>
              <tr
                v-for="(user, index) in usersList"
                :key="index"
                class="user_row table-clickable-row"
                @click="open(user)"
              >
                <td id="name_user">{{ user.firstName }} {{ user.familyName }}</td>
                <td>{{ user.companyName }}</td>
                <td>{{ user.organizationType.name }}</td>
                <td>{{ getUserRole(user.roles) }}</td>
                <td>{{ user.email }}</td>
              </tr>
            </tbody>
            <tfoot v-if="totalPages > 0 && totalPages > page + 1">
              <tr>
                <td colspan="9">
                  <span class="btn-loadmore" @click="loadMore">{{ $t(`common.loadMore`) }}</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import { orderTypesEnum } from '@/utils/enums';
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import LabelWithCount from '@/components/base/LabelWithCount';
import SelectComponent from '@/components/base/SelectComponent';
import InputField from '@/components/base/InputField';
import { mapState, mapActions, mapMutations } from 'vuex';
import LoadingComponent from '@/components/base/LoadingComponent';

const notSortedIcon = require('@/assets/icons/grey-icons/arrow.png');
const sortedIcon = require('@/assets/icons/gold-icons/arrow.png');

export default {
  name: 'UsersList',
  components: {
    DefaultLayout,
    Headings,
    LabelWithCount,
    SelectComponent,
    InputField,
    LoadingComponent,
  },
  data() {
    return {
      userRoleModel: '',
      userNameModel: '',
      userTypeModel: '',
      isLoading: false,
      orderBy: 'USERNAME', // default
      orderType: orderTypesEnum.ASC, // default,
      pageSize: 10, // Default
    };
  },
  computed: {
    ...mapState({
      usersList: (state) => state.users.data,
      page: (state) => state.users.currentPage,
      totalPages: (state) => state.users.totalPages,
      totalItems: (state) => state.users.totalItems,
      sortIcon: (state) => (id) => {
        return state.users.orderBy === id ? sortedIcon : notSortedIcon;
      },
      entities: (state) => {
        return [
          {
            name: '',
            id: '',
          },
          ...state.users.entities,
        ];
      },
      /*
        sortDirection: (state) => (id) => {
          if (
            state.users.orderBy === id &&
            state.users.orderType === orderTypesEnum.ASC
          )
            return "";
          else return "reversed";
        },
        */
    }),
    calculateTotalUsers() {
      return this.usersList.length;
    },
    roles() {
      return [
        {
          name: ' ',
          value: null,
        },
        {
          name: this.$t('profile.administrator'),
          value: 'ADMIN',
        },
        {
          name: this.$t('profile.document_manager'),
          value: 'DOCUMENT_MANAGER',
        },
        {
          name: this.$t('profile.regular_user'),
          value: 'REGULAR_USER',
        },
        {
          name: this.$t('profile.translator'),
          value: 'TRANSLATOR',
        },
      ];
    },
    headers() {
      return [
        {
          name: this.$t('common.firstName'),
          id: 'firstName',
        },
        {
          name: this.$t('common.companyName'),
          id: 'companyName',
        },
        {
          name: this.$t('common.typeOfEntity'),
          id: 'organizationType',
        },
        {
          name: this.$t('users.role'),
          id: 'roles',
        },
        {
          name: this.$t('common.username'),
          id: 'email',
        },
      ];
    },
  },
  created() {
    this.isLoading = true;
    this.getFilteredUsers({
      username: '',
      role: '',
      orderBy: this.orderBy.toUpperCase(),
      order: this.orderType,
      page: 0,
      pageSize: this.pageSize,
      cb: () => {
        this.getTypesOfEntities({
          cb: () => {
            this.isLoading = false;
          },
          ecb: () => {
            this.isLoading = false;
          },
        });
        // this.isLoading = false;
      },
      ecb: () => {
        this.isLoading = false;
      },
    });
  },
  methods: {
    ...mapActions('users', ['getAllAdminUsers', 'getFilteredUsers', 'order', 'getTypesOfEntities']),
    ...mapMutations('users', ['setUsersList']),
    sort(headerId) {
      this.orderBy = headerId;
      this.orderType = this.orderType === orderTypesEnum.DESC ? orderTypesEnum.ASC : orderTypesEnum.DESC;

      this.getFilteredUsers({
        username: this.userNameModel,
        organizationType: this.userTypeModel,
        role: this.userRoleModel,
        orderBy: this.orderBy.toUpperCase(),
        order: this.orderType,
        page: 0,
        pageSize: this.pageSize,
        cb: () => {
          this.isLoading = false;
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    sortDirection(headerName) {
      return headerName === this.orderBy && this.orderType === orderTypesEnum.ASC ? '' : 'reversed';
    },
    searchByCriteria() {
      this.isLoading = true;
      this.setUsersList([]);
      this.getFilteredUsers({
        username: this.userNameModel,
        organizationType: this.userTypeModel,
        role: this.userRoleModel,
        orderBy: this.orderBy.toUpperCase(),
        order: this.orderType,
        page: 0,
        pageSize: this.pageSize,
        cb: () => {
          this.isLoading = false;
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    getUserRole(userRole) {
      return (this.roles.find((role) => role.value === userRole) || {}).name;
    },
    open(u) {
      this.$router.push({
        name: 'UserDetails',
        params: { id: u.userId },
      });
    },
    loadMore() {
      this.pageSize += 20;
      this.getFilteredUsers({
        username: this.userNameModel,
        organizationType: this.userTypeModel,
        role: this.userRoleModel,
        orderBy: this.orderBy.toUpperCase(),
        order: this.orderType,
        page: 0,
        pageSize: this.pageSize,
        cb: () => {
          this.isLoading = false;
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    downloadUsers() {
      this.isLoading = true;
      this.$axios
        .get('admin-users/subscribed-to-newsletter')
        .then((response) => {
          console.log(response);
          const blob = new Blob([response], { type: 'text/csv' });
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'users.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.search-icon {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  filter: brightness(0) invert(1);
}

#search-col {
  float: right;
  margin-top: 23px;
}

.content-col {
  background-color: #fff;
}

.btn-search {
  font-family: Montserrat, sans-serif;
  padding: 7px 5px;
  font-size: 1.4em;
  font-weight: 500;
  min-width: 140px;
  height: 50px;
}

.user-list-input input,
.user-list-input select {
  color: #000;
  height: 56px;
}

.btn-loadmore {
  margin: 0 auto;
  cursor: pointer;
  width: 200px;
  display: block;
  color: #265f14;
  font-weight: 600;
  text-shadow: 1px 1px rgba(255, 255, 255, 1);
  font-size: 1.1em;
}

tfoot tr:hover {
  background: rgb(255, 255, 255);
}

tfoot tr td {
  text-align: center;
}

.no-data-col {
  padding-top: 30px;
}
</style>
