var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c(
        "v-tabs",
        { staticClass: "flex-grow-0", attrs: { "center-active": "" } },
        [
          _c("v-tab", { attrs: { to: { name: "StatGlobal" } } }, [
            _vm._v(_vm._s(_vm.$t("statistics.tab_names.global_statistics"))),
          ]),
          _c("v-tab", { attrs: { to: { name: "StatGlobalSearch" } } }, [
            _vm._v(
              _vm._s(_vm.$t("statistics.tab_names.global_search_statistics"))
            ),
          ]),
          _c(
            "v-tab",
            {
              attrs: { to: { name: "StatSearchOrg", params: { type: "NS" } } },
            },
            [_vm._v(_vm._s(_vm.$t("statistics.tab_names.ns_statistics")))]
          ),
          _c(
            "v-tab",
            {
              attrs: { to: { name: "StatSearchOrg", params: { type: "CID" } } },
            },
            [_vm._v(_vm._s(_vm.$t("statistics.tab_names.cid_statistics")))]
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        { staticClass: "bordered_tab_items flex-grow-1", attrs: { value: 0 } },
        [_c("v-tab-item", [_c("router-view", { key: _vm.$route.path })], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }