import { getAll, get, update, create, deleteItem } from '@/services/tags.service';

// initial state
const state = () => ({
  data: [],
  current: {},
  totalItems: 0,
});

// getters
const getters = {
  totalItems: (state) => {
    if (!state.current) return 0;
    return state.current.length;
  },
};

// actions
const actions = {
  // eslint-disable-next-line no-unused-vars
  getAllTags({ commit }, { cb = null, ecb = null }) {
    getAll((tags) => {
      const data = [...tags];
      if (cb) cb();
      commit('setTags', data);
    });
  },
  getSingleTag({ commit }, { id, cb = null, ecb = null }) {
    get(
      id,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleTag', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  updateSingleTag({ commit }, { data, cb = null, ecb = null }) {
    update(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleTag', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  createSingleTag({ commit }, { data, cb = null, ecb = null }) {
    create(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleTag', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  deleteSingleTag({ commit }, { id, cb = null, ecb = null }) {
    deleteItem(
      id,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleTag', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  clearCurrent({ commit }) {
    commit('clearCurrent');
  },
};

// mutations
const mutations = {
  setTags(state, data) {
    state.data = data;
    state.totalItems = data.length;
  },
  setSingleTag(state, data) {
    state.current = data;
  },
  clearCurrent(state) {
    state.current = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
