var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("Headings", {
        staticClass: "d-inline-block",
        attrs: {
          title: _vm.$t(`common.documentManagement`),
          "back-location": "DocManagement",
          subtitle:
            _vm.$t(`common.upload`) +
            " " +
            _vm.uploadType +
            " - " +
            _vm.submitStatus,
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-row", { attrs: { align: "center", justify: "center" } }, [
                _vm.submitStatus === "Success"
                  ? _c("div", { staticClass: "status-card-container" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(`documentManagement.uploadSuccess`) +
                              _vm.docStatus +
                              " a " +
                              (_vm.isMaster ? "Master " : "") +
                              _vm.uploadType +
                              " " +
                              _vm.$t(`documentManagement.document`)
                          ) +
                          " "
                      ),
                      _c("img", {
                        staticClass: "success-icon",
                        attrs: { src: require("@/assets/icons/done.svg") },
                      }),
                    ])
                  : _c(
                      "div",
                      { staticClass: "status-card-container status-error" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(`documentManagement.uploadFailed`) +
                                _vm.uploadType +
                                _vm.$t(`documentManagement.documentFailed`)
                            ) +
                            " "
                        ),
                      ]
                    ),
              ]),
              _c(
                "v-row",
                {
                  staticClass: "cols",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c("div", { staticClass: "mt-7" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-green",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.goBack.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.ok`)) + " ")]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("label", { staticClass: "summary-table-title" }, [
              _vm._v(_vm._s(_vm.$t(`documentManagement.uploadSummary`))),
            ]),
          ]),
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("div", { staticClass: "summary-table" }, [
              _c("table", [
                _c("thead", { staticClass: "table-head-shadow" }, [
                  _c(
                    "tr",
                    _vm._l(_vm.headers, function (header, index) {
                      return _c("th", { key: index }, [
                        _vm._v(" " + _vm._s(header.name) + " "),
                      ])
                    }),
                    0
                  ),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.summaryData, function (data, index) {
                    return _c(
                      "tr",
                      { key: index, staticClass: "user_row" },
                      [
                        data.result.error
                          ? [
                              _c("td", { attrs: { colspan: "8" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t(`common.errorUploading`)) +
                                    " " +
                                    _vm._s(data.name) +
                                    " - " +
                                    _vm._s(data.result.error) +
                                    " "
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        !data.result.error
                          ? [
                              _c("td", { attrs: { id: "name_user" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.result.userName ||
                                        data.result.uploadedBy
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(data.result.organizationName)),
                              ]),
                              _c("td", [_vm._v(_vm._s(data.result.year))]),
                              _c("td", [_vm._v(_vm._s(data.result.version))]),
                              _c("td", [_vm._v(_vm._s(data.result.status))]),
                              _c("td", [_vm._v(_vm._s(_vm.today))]),
                              _c("td", [_vm._v(_vm._s(_vm.note))]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }