import { axiosInstance } from '../utils/axios.js';
import {
  GET_CS_BY_TYPE_AND_DATE,
  UPLOAD_DOCUMENT,
  GET_ALL_UPLOADED_DOCUMENTS,
  GET_DOCUMENT_BY_ID,
  PUBLISH_DOCUMENT_TYPE,
  UNPUBLISH_DOCUMENT,
  DELETE_WHOLE_YEAR,
  GET_CS_YEARS,
  DOWNLOAD_MASTER_CID,
  UPLOAD_MASTER_CID,
  UPLOAD_ANNEX_DOCUMENT,
  UPLOAD_MASTER_ANNEX_CID,
  EXISTING_BOOK_CHECK,
  CREATE_DOCUMENT,
  GET_ANNEX_ORIGINAL,
  GET_ANNEX_PDF,
  CREATE_MASTER_DOCUMENT,
  DELETE_ANNEX_DOCUMENT,
  UPDATE_ANNEX_CHAPTERS,
  GET_DOCUMENT_ANALYSIS,
  GET_EXISTING_FILES,
  COPY_EXISTING_FILE,
  CREATE_DOCUMENT_COPY,
} from '../utils/api.endpoints';

export function publishDocumentTypeRequest(type, append, cb = null, ecb = null) {
  const config = {
    headers: {
      'Content-Type': null,
    },
  };
  axiosInstance
    .post(PUBLISH_DOCUMENT_TYPE(type.toLowerCase(), append), {}, config)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
}

export function getCsByTypeAndDateRequest(data, cb = null, ecb = null) {
  const { type, year } = data;
  const flat = data.flat !== undefined ? data.flat : true;
  axiosInstance
    .get(GET_CS_BY_TYPE_AND_DATE(type, year, flat))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
}

export function getCsYears(cb = null, ecb = null) {
  axiosInstance
    .get(GET_CS_YEARS)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
}

export function getAllDocumentsRequest(data = {}, cb = null, ecb = null) {
  axiosInstance
    .get(GET_ALL_UPLOADED_DOCUMENTS(data))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
}

export function uploadMasterCIDRequest(data, cb = null, ecb = null) {
  const config = {
    headers: {
      'Content-Type': null,
    },
  };
  axiosInstance
    .post(UPLOAD_MASTER_CID, data, config)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
}

export function downloadDocumentsRequest(year, cb = null) {
  const config = {
    headers: {
      'Content-Type': null,
    },
    responseType: 'arraybuffer',
  };
  axiosInstance.get(DOWNLOAD_MASTER_CID(year), config).then((response) => {
    if (cb) cb(response);
  });
}

export const downloadAnnexById = function (data, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': null,
    },
    responseType: 'arraybuffer',
  };
  axiosInstance
    .get(GET_ANNEX_ORIGINAL({ annexId: data.annexId }), config)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const previewAnnexById = function (annexId, cb, ecb) {
  const config = {
    headers: {
      'Content-Type': null,
    },
    responseType: 'arraybuffer',
  };

  const url = GET_ANNEX_PDF(annexId);
  axiosInstance.get(url, config).then(
    (response) => {
      const blob = new Blob([response], {
        type: 'application/pdf;base64',
      });
      const link = URL.createObjectURL(blob);
      cb(link);
    },
    () => {
      if (ecb) ecb();
    }
  );
};

export function getDocumentByIdRequest(documentId, cb = null, ecb = null) {
  axiosInstance
    .get(GET_DOCUMENT_BY_ID(documentId))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
}

export function createDocumentIfNewRequest(type, append, documentNote, cb = null, ecb = null) {
  axiosInstance
    .post(CREATE_DOCUMENT(type.toLowerCase(), append), documentNote)
    .then((r) => {
      if (cb) cb(r);
    })
    .catch((err) => {
      if (ecb) ecb(err);
    });
}

export function createMasterDocumentIfNewRequest(append, cb = null, ecb = null) {
  axiosInstance
    .post(CREATE_MASTER_DOCUMENT(append))
    .then((r) => {
      if (cb) cb(r);
    })
    .catch((err) => {
      if (ecb) ecb(err);
    });
}

async function fireRequest(request, type, collection) {
  return request.then((r) =>
    collection.push({
      name: type,
      result: r,
    })
  );
}

export async function saveFileAndAnnexesRequest(annexes, updatedAnnexes, deletedAnnexes, type, fileData, cb = null) {
  const config = {
    headers: {
      'Content-Type': null,
    },
  };

  // const promises = [];
  const docType = type.toLowerCase();
  const fileUrl = UPLOAD_DOCUMENT(docType);
  const results = [];
  if (fileData.booksData) {
    for (const book of fileData.booksData) {
      if (book.file instanceof File) {
        const fd = new FormData();
        // fd.append("version", fileData.formData.get("version"));
        fd.append('rfcId', fileData.formData.get('rfcId'));
        const notes = fileData.formData.get('documentNote') !== undefined ? fileData.formData.get('documentNote') : '';
        fd.append('documentNote', notes);
        fd.append('documentId', fileData.formData.get('documentId'));
        fd.append('filename', book.file);
        fd.append('csLevel', book.csLevel);
        await fireRequest(axiosInstance.post(fileUrl, fd, config), 'document', results);
      }
    }
  } else {
    const formData = fileData.formData;
    const file = formData.get('filename');
    if (file && formData.get('csLevel') && file instanceof File) {
      // NS or CID file
      await fireRequest(axiosInstance.post(fileUrl, fileData.formData, config), 'document', results);
    }
  }
  // Annexes
  if (deletedAnnexes && deletedAnnexes.length) {
    for (const annex of deletedAnnexes) {
      await fireRequest(axiosInstance.delete(DELETE_ANNEX_DOCUMENT(annex)), 'annex', []);
    }
  }
  if (annexes && annexes.length) {
    const annexesUrl = UPLOAD_ANNEX_DOCUMENT(docType);
    for (const annex of annexes) {
      await fireRequest(axiosInstance.post(annexesUrl, annex, config), 'annex', []);
    }
  }

  // remove updated if already deleted
  updatedAnnexes = updatedAnnexes.filter((updated) => !deletedAnnexes.includes(updated.annexId));
  if (updatedAnnexes.length) {
    for (const annex of updatedAnnexes) {
      const url = UPDATE_ANNEX_CHAPTERS(annex.annexId);
      await fireRequest(
        axiosInstance.put(url, {
          includeOnDownload: annex.includeOnDownload,
          displayName: annex.displayName,
          csLevels: annex.chapters,
        }),
        'annex',
        []
      );
    }
  }
  if (cb) cb(results);
}

export const unpublishDocumentRequest = async function (documentId, cb = null, ecb = null) {
  axiosInstance
    .delete(UNPUBLISH_DOCUMENT(documentId))
    .then((r) => {
      if (cb) cb(r);
    })
    .catch((err) => {
      if (ecb) ecb(err);
    });
};

export const deleteWholeYearRequest = async function (documentId, cb = null, ecb = null) {
  axiosInstance
    .delete(DELETE_WHOLE_YEAR(documentId))
    .then((r) => {
      if (cb) cb(r);
    })
    .catch((err) => {
      if (ecb) ecb(err);
    });
};

export function saveMasterFileAndAnnexesRequest(annexes, fileData, cb = null) {
  const config = {
    headers: {
      'Content-Type': null,
    },
  };

  const promises = [];

  if (fileData.booksData) {
    fileData.booksData.forEach((book) => {
      const fd = new FormData();

      fd.append('documentId', fileData.formData.get('documentId'));
      fd.append('filename', book.file);
      fd.append('csLevel', book.csLevel);
      promises.push(
        axiosInstance.post(UPLOAD_MASTER_CID, fd, config).then((r) => ({
          name: 'document',
          result: r,
        }))
      );
    });
  }

  // Annexes
  if (annexes && annexes.length) {
    const annexesUrl = UPLOAD_MASTER_ANNEX_CID;
    annexes.forEach(function (annex) {
      promises.push(
        axiosInstance.post(annexesUrl, annex, config).then((r) => ({
          name: 'annex',
          result: r,
        }))
      );
    });
  }

  Promise.all(promises).then((results) => {
    if (cb) cb(results);
  });
}

export function fetchUploadedBooksRequest(csLevelIds, documentId, cb = null) {
  const promises = [];

  csLevelIds.forEach((id) => {
    promises.push(axiosInstance.get(EXISTING_BOOK_CHECK(id, documentId)));
  });

  Promise.all(promises).then((results) => {
    if (cb) cb(results);
  });
}

export async function fetchExistingFiles(rfcId, csLevelName, cb = null) {
  await axiosInstance.get(GET_EXISTING_FILES(rfcId, csLevelName)).then((res) => cb(res));
}

export function duplicateFileToDocument(rfcId, csLevelId, newDocumentId, year, cb = null) {
  axiosInstance.get(COPY_EXISTING_FILE(rfcId, csLevelId, newDocumentId, year)).then((res) => cb(res));
}

export function saveMasterCIDAndAnnexesRequest(annex, fileData, cb = null) {
  const config = {
    headers: {
      'Content-Type': null,
    },
  };

  const promises = [];

  // NS or CID file
  promises.push(
    axiosInstance.post(UPLOAD_MASTER_CID, fileData.formData, config).then((r) => ({
      name: 'document',
      result: r,
    }))
  );

  // Annexes
  promises.push(
    axiosInstance.post(UPLOAD_MASTER_ANNEX_CID, annex, config).then((r) => ({
      name: 'annex',
      result: r,
    }))
  );

  Promise.all(promises).then((results) => {
    if (cb) cb(results);
  });
}

export function fetchDocumentAnalysisRequest(documentId, cb = null, ecb = null) {
  axiosInstance
    .get(GET_DOCUMENT_ANALYSIS(documentId))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
}

export function createDocumentCopyRequest(sourceDocumentId, newVersion, cb = null, ecb = null) {
  axiosInstance
    .get(CREATE_DOCUMENT_COPY(sourceDocumentId, newVersion))
    .then((r) => {
      if (cb) cb(r);
    })
    .catch((err) => {
      if (ecb) ecb(err);
    });
}
