<template>
  <div v-if="show" class="headings">
    <div class="h-container" :class="{ 'h-container-gold': useGoldBackStyle }">
      <div
        v-if="backLocation || back"
        class="back-arrow-container"
        :class="{ 'gold-button-container': useGoldBackStyle }"
      >
        <span
          :class="{ 'back-icon-gold': useGoldBackStyle }"
          class="material-icons btn-heading-back d-inline-block back-icon"
          @click="goBack"
          >{{ icons.arrowBack }}
        </span>
      </div>
      <div :class="{ 'text-headings-container': useGoldBackStyle }">
        <h3 class="heading1 mb-2 mt-0 btn-heading-back" @click="goBack">
          {{ title }}
        </h3>
        <h2 class="heading2 mt-0">{{ subtitle }}</h2>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Headings',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    backLocation: {
      type: String,
      default: '',
    },
    queryParams: {
      type: Object,
      default: null,
    },
    back: {
      type: Boolean,
      default: false,
    },
    useGoldBackStyle: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true, // eslint-disable-line vue/no-boolean-default
    },
  },
  computed: {
    icons() {
      return {
        arrowBack: 'arrow_back',
      };
    },
  },
  methods: {
    goBack() {
      if (this.back) {
        this.$router.go(-1);
      } else if (this.queryParams) {
        this.$router
          .push({
            name: this.backLocation,
            params: this.queryParams,
          })
          .catch(() => true);
      } else {
        this.$router.push({ name: this.backLocation }).catch(() => true);
      }
    },
  },
};
</script>

<style scoped>
.back-arrow-container {
  position: absolute;
  left: -28px;
  top: -5px;
}

.gold-button-container {
  width: 56px !important;
  height: 56px !important;
  padding: 0 !important;
  background-color: #c1893a !important;
  color: white !important;
  border: unset !important;
  text-align: center;
  border-radius: 8px;
  top: 0px !important;
}

.btn-heading-back {
  cursor: pointer;
}

.back-icon-gold {
  position: absolute;
  top: 12px;
  left: 6px;
}

.back-icon {
  font-size: 3em;
  font-weight: 600;
  opacity: 0.9;
}

.h-container {
  margin-left: 20px;
}

.h-container-gold {
  margin-left: 38px;
}
</style>
