import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';

import rfcs from './modules/rfcs';
import ims from './modules/ims';
import structure from './modules/structure';
import auth from './modules/auth';
import common from './modules/common';
import countries from './modules/countries';
import users from './modules/users';
import search from './modules/search';
import documents from './modules/documents';
import tags from './modules/tags';
import languages from './modules/locales.js';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    common,
    countries,
    rfcs,
    ims,
    auth,
    users,
    search,
    structure,
    tags,
    documents,
    languages,
  },
  strict: debug,
  plugins: [
    persistedState({
      key: 'rnenci',
      paths: ['languages.locale'],
    }),
  ],
});
