var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AuthLayout",
    [
      _c(
        "v-container",
        [
          _c("Logo", [_c("v-spacer"), _c("LanguageSelector")], 1),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 welcome_text_wrapper pb-0" }, [
              _c("div", {
                staticClass: "welcome_text d-inline-block",
                domProps: { innerHTML: _vm._s(_vm.$t("landingPage.welcome")) },
              }),
              _c("div", {
                staticClass:
                  "welcome_rne d-inline-block pl-0 pr-1 pr-sm-2 pl-sm-2",
                domProps: { innerHTML: _vm._s(_vm.$t("landingPage.RNE")) },
              }),
              _c("div", {
                staticClass: "welcome_rne d-inline-block",
                domProps: { innerHTML: _vm._s(_vm.$t("landingPage.NCI")) },
              }),
            ]),
            _c("div", { staticClass: "col-12 pt-3 pb-1" }, [
              _c("p", {
                staticClass: "welcome_subtext",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("landingPage.networkStatement")),
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col col-sm-6" }, [
              _c(
                "a",
                {
                  staticClass: "font-headings-btns network_btn",
                  on: {
                    click: function ($event) {
                      return _vm.goTo("NS")
                    },
                  },
                },
                [
                  _c("span", { attrs: { icon_main: "" } }, [
                    _c("img", {
                      staticClass: "search_icon",
                      attrs: {
                        src: require("@/assets/icons/grey-icons/search.svg"),
                      },
                    }),
                  ]),
                  _c("span", {
                    staticClass: "heading-btn-text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("landingPage.network statements btn")
                      ),
                    },
                  }),
                ]
              ),
            ]),
            _c("div", { staticClass: "col col-sm-6" }, [
              _c(
                "a",
                {
                  staticClass: "font-headings-btns corridor_btn",
                  on: {
                    click: function ($event) {
                      return _vm.goTo("CID")
                    },
                  },
                },
                [
                  _c("span", [
                    _c("img", {
                      staticClass: "search_icon",
                      attrs: {
                        src: require("@/assets/icons/grey-icons/search.svg"),
                      },
                    }),
                  ]),
                  _c("span", {
                    staticClass: "heading-btn-text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("landingPage.corridor information btn")
                      ),
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "row info-text-container" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "login_link d-inline-block mr-2",
                    attrs: { to: "/login" },
                  },
                  [_vm._v(_vm._s(_vm.$t(`common.login`)))]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "register_link d-inline-block",
                    attrs: { to: "/register" },
                  },
                  [_vm._v(_vm._s(_vm.$t(`common.register`)))]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col mt-3", attrs: { id: "bottom_text" } },
              [
                _c("p", {
                  staticClass: "landing-page-sub-text",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t(`landingPage.dearVisitor`)),
                  },
                }),
                _c("br"),
                _c("p", {
                  staticClass: "landing-page-sub-text",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t(`landingPage.needSupport`)),
                  },
                }),
                _c("br"),
                _c("p", {
                  staticClass: "brand_txt mb-2",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t(`landingPage.copyRights`, { year: _vm.year })
                    ),
                  },
                }),
                _c("p", {
                  staticClass: "brand_txt",
                  domProps: {
                    innerHTML: _vm._s("Version: " + _vm.currentVersion),
                  },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }