var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    { staticClass: "anonymous_login__layout" },
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "div",
        {
          staticClass: "modal_log_in_anonymous",
          attrs: { id: "log_in_anonymous_container" },
        },
        [
          _c("div", { staticClass: "log-in-anonymous-container" }, [
            _c("div", { staticClass: "login_text font-weigth-normal" }, [
              _c("span", {
                staticClass: "font-weigth-semibold",
                domProps: {
                  innerHTML: _vm._s(_vm.$t(`anonymousLogIn.welcome`)),
                },
              }),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t(`anonymousLogIn.welcomeText`)),
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "type-container pt-10" },
              [
                _c("SelectComponent", {
                  attrs: {
                    tabindex: 1,
                    options: _vm.entities,
                    name: _vm.$t(`anonymousLogIn.organisationType`),
                    placeholder: _vm.$t(`anonymousLogIn.organisationSelect`),
                    "obj-key": "name",
                    "obj-val": "id",
                    error: _vm.errors.error("organizationTypeId"),
                  },
                  model: {
                    value: _vm.organizationTypeModel,
                    callback: function ($$v) {
                      _vm.organizationTypeModel = $$v
                    },
                    expression: "organizationTypeModel",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "row", attrs: { id: "captcha_row" } }, [
              _c("div", { attrs: { id: "captcha_col_anonym" } }, [
                _c(
                  "form",
                  {
                    staticClass: "captcha text-center",
                    attrs: { action: "?", method: "POST" },
                  },
                  [
                    _c("div", {
                      staticClass: "g-recaptcha d-inline-block",
                      attrs: {
                        id: "recaptcha-main",
                        "data-sitekey":
                          "6LcFS98ZAAAAAPp1m2QPlxd07FqDGYni1dSMyzJf",
                      },
                    }),
                    _vm.errors.error("recaptcha")
                      ? _c("span", { staticClass: "form-error" }, [
                          _vm._v(_vm._s(_vm.errors.error("recaptcha"))),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "dialog_actions" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "button",
                    {
                      attrs: { id: "next_login_btn" },
                      on: { click: _vm.goNext },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(`common.next`)) + " ")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }