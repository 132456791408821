var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        "is-modal-open": _vm.isModalOpen,
        "close-dialog": _vm.closeDialog,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("div", { staticClass: "d-flex modal-footer" }, [
                _c("div", { staticClass: "p-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-green btn-medium mr-2",
                      on: { click: _vm.confirmCallback },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.ok")) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "ml-auto p-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-grey btn-medium",
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(" " + _vm._s(_vm.lableText) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }