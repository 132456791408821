export default {
  namespaced: true,
  state() {
    return {
      languages: [],
      locale: 'en',
    };
  },
  getters: {
    getLocales(state) {
      return state.languages;
    },
    getCurLocale(state) {
      return state.locale;
    },
  },
  mutations: {
    setLanguages(state, langList) {
      state.languages = langList;
    },
    setLocale(state, locale) {
      state.locale = locale;
    },
  },
};
