<template>
  <div>
    <LoadingComponent :loading="loading" />
    <div class="pa-4 d-flex flex-column align-center">
      <v-row>
        <v-col cols="4">
          <CardKPI :caption="$t('statistics.global.number_ims')">{{ stats.ims }}</CardKPI>
        </v-col>
        <v-col cols="4">
          <CardKPI :caption="$t('statistics.global.number_rfcs')">{{ stats.rfcs }}</CardKPI>
        </v-col>
        <v-col cols="4">
          <CardKPI :caption="$t('statistics.global.total_orgs')">{{ stats.organizations }}</CardKPI>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <CardKPI :caption="$t('statistics.global.registered_users')">{{ stats.registeredUsers }}</CardKPI>
        </v-col>
        <v-col cols="6">
          <CardKPI :caption="$t('statistics.global.guest_users')">{{ stats.guestUsers }}</CardKPI>
        </v-col>
      </v-row>
      <div class="d-flex justify-center mt-6">
        <v-simple-table class="stripe tbl_stats">
          <thead>
            <tr>
              <th nowrap>{{ $t('statistics.global.user_type') }}</th>
              <th nowrap>{{ $t('statistics.global.number_users') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, idx) in stats.userByType" :key="idx">
              <td>{{ row.type }}</td>
              <td style="text-align: center">{{ row.users }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import CardKPI from '@/components/analytics/CardKPI.vue';
import LoadingComponent from '@/components/base/LoadingComponent.vue';

export default {
  name: 'StatsGlobal',
  components: {
    CardKPI,
    LoadingComponent,
  },
  data() {
    return {
      stats: {},
      loading: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$axios
        .get('statistics/global')
        .then((response) => {
          this.stats = response;
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
