<template>
  <DefaultLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <Headings :title="$t(`common.commonStructures`)" :subtitle="$t(`common.management`)" back-location="CommonList" />
    <div id="inputs" class="row justify-content-between">
      <div class="col-2">
        <SelectComponent
          v-model="managementType"
          class="user-list-input disabled-field select-big"
          :tabindex="2"
          :is-disabled="true"
          :options="managementTypes"
          :name="$t(`common.type`)"
          obj-key="name"
          obj-val="value"
        />
      </div>
      <div class="col-3">
        <MultipleSelectComponent
          v-model="selectedYears"
          :tabindex="4"
          :options="yearsAvailable"
          :disabled="true"
          prepend="mdi-calendar-blank-outline"
          class-style="yearPicker disabled-field"
          :chips="false"
          :name="$t(`common.years`)"
          obj-key="name"
          obj-val="value"
        />
      </div>
      <div id="search-col" class="col-7">
        <button class="btn btn-green btn-search" @click.stop="createStructure">
          {{ managementType === 'CID' ? $t(`commonDetails.createBook`) : $t(`commonDetails.createChapter`) }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="drodown-shadow" />
      </div>
      <div class="col-12">
        <div class="table-rfcs singleCommon">
          <v-treeview
            ref="tree"
            :items="draggableLevels"
            item-children="levels"
            item-key="id"
            item-text="nameEn"
            :open-on-click="true"
            :dense="true"
            :activatable="false"
            :hoverable="false"
            return-object
          >
            <template slot="label" slot-scope="props">
              <draggable
                :id="props.item.id"
                :list="draggableLevels"
                :group="'node' + props.item.parentId || ''"
                draggable=".drag-item"
                :data-parent="props.item.parentId"
                @end="checkEnd"
              >
                <div class="drag-item">
                  <span :class="{ hasChildren: props.item.levels.length > 0 }" />
                  <div class="treeItem" :class="{ isParent: !props.item.parentId }">
                    <span class="index">{{ props.item.csNumber }}</span>
                    {{ props.item.nameEn }}
                    <v-chip
                      v-for="(node, index) in props.item.tags"
                      :key="index"
                      class="ma-2"
                      close
                      close-icon="mdi-close"
                      label
                      small
                      @click:close="removeTag(props.item.id, node.id)"
                    >
                      {{ node.name }}
                    </v-chip>
                    <span class="edit-action" @click.stop="editLevel(props.item)" />
                    <span class="additional-options">
                      <span class="gold-actions newlevel" @click.stop="createLevel(props.item)" />
                      <span class="gold-actions sublevel" @click.stop="createSubLevel(props.item)" />
                      <span class="btn btn-link btn-remove" @click.stop="deleteLevelById(props.item)">{{
                        $t(`common.remove`)
                      }}</span>
                    </span>
                  </div>
                </div>
              </draggable>
            </template>
          </v-treeview>
        </div>
      </div>
    </div>
    <div v-if="current && current.levels" class="row">
      <div class="col-xs-8 col-sm-12">
        <div class="btns-container">
          <button class="btn btn-grey btn-medium mr-3" @click.stop="goBack">
            {{ $t(`common.back`) }}
          </button>
          <button class="btn btn-grey btn-medium" @click.stop="deleteStructure">
            {{ $t(`commonDetails.deleteStructure`) }}
          </button>
          <button
            v-if="draggableLevels.length > 0"
            class="btn btn-gold btn-medium btn-print mr-3 ml-3"
            @click.stop="duplicate"
          >
            {{ $t(`commonDetails.duplicateStructure`) }}
          </button>
        </div>
      </div>
    </div>
    <DuplicateStructure :is-modal-open="isModalDuplicateOpen" :close-dialog="closeDialog" :item="current" />
    <EditLevels
      :is-modal-open="isModalOpen"
      :close-dialog="closeDialog"
      :item="item"
      :get-data="getData"
      :name-en="item.nameEn"
      :is-book="item.isBook"
      :selected-tags="selectedTags"
      :type="managementType"
      :lowest-level="lowestLevelFound"
      :highest-level="highestLevelFound"
      :loading="setLoading"
      :initial-without-level="item.position < 0"
    />
    <ConfirmModal
      :is-modal-open="isConfirmModalOpen"
      :close-dialog="closeConfirmModal"
      :confirm-callback="confirmCallback"
      :lable-text="confirmLableText"
      style="z-index: 20001"
    />
  </DefaultLayout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import mixinCommon from './CommonList.data';
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import SelectComponent from '@/components/base/SelectComponent';
import MultipleSelectComponent from '@/components/base/MultipleSelectComponent';
import DuplicateStructure from './modals/DuplicateStructure';
import EditLevels from './modals/EditLevels';
import draggable from 'vuedraggable';
import LoadingComponent from '@/components/base/LoadingComponent';
import ConfirmModal from './modals/ConfirmModal';

export default {
  name: 'CommonDetails',
  components: {
    DefaultLayout,
    SelectComponent,
    MultipleSelectComponent,
    Headings,
    DuplicateStructure,
    EditLevels,
    draggable,
    LoadingComponent,
    ConfirmModal,
  },
  mixins: [mixinCommon],
  data() {
    return {
      managementType: '',
      isModalDuplicateOpen: false,
      isModalOpen: false,
      selectedYears: [],
      yearsAvailable: [],
      item: {},
      selectedTags: [],
      isLoading: false,
      lowestLevelFound: 0,
      highestLevelFound: 0,
      isConfirmModalOpen: false,
      confirmLableText: '',
      confirmCallback: null,
    };
  },
  computed: {
    ...mapState({
      current: (state) => state.structure.current,
      tags: (state) => state.tags.data,
    }),
    ...mapGetters({
      structures: 'structure/structuresList',
    }),
    draggableLevels() {
      return this.current && this.current.levels ? [...this.current.levels] : [];
    },
  },
  watch: {
    '$route.params.id'() {
      this.getSingleCS({
        id: this.$route.params.id,
        cb: (res) => {
          this.selectedYears = [res.year];
          this.yearsAvailable = [res.year];
          this.managementType = res.type;
        },
      });
    },
  },
  created() {
    this.getSingleCS({
      id: this.$route.params.id,
      cb: (res) => {
        this.selectedYears = [res.year];
        this.yearsAvailable = [res.year];
        this.managementType = res.type;
      },
    });
    this.getAllTags({});
  },
  methods: {
    ...mapActions('structure', [
      'getSingleCS',
      'deleteSingleCS',
      'createCS',
      'setType',
      'order',
      'createCSLevel',
      'deleteCSLevel',
      'removeTagToLevel',
      'moveCSLevel',
    ]),
    ...mapActions('tags', ['getAllTags']),
    goBack() {
      this.$router.push({
        name: 'CommonList',
      });
    },
    findTreeItem(items, id) {
      if (!items) {
        return;
      }
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        // Test current object
        if (item.id === id) {
          return item;
        }
        // Test children recursively
        const child = this.findTreeItem(item.levels, id);
        if (child) {
          return child;
        }
      }
    },
    checkEnd(evt) {
      const toLevelObject = this.findTreeItem(this.draggableLevels, evt.to.id);
      if (toLevelObject) {
        this.isLoading = true;
        const newPosition = toLevelObject.position;
        const data = {
          csId: this.$route.params.id,
          csLevelId: evt.from.id,
          newPosition: newPosition,
          parentId: toLevelObject.parentId,
        };
        this.moveCSLevel({
          data,
          cb: (res) => {
            if (!res.length && res.error) {
              this.$root.$emit('toast-error', res.error);
            }
            this.isLoading = false;
          },
        });
      }
    },
    createStructure() {
      this.isLoading = true;
      const data = {
        id: this.$route.params.id,
        parentId: null,
        position: -1,
        nameEn: this.managementType === 'CID' ? 'NEW FILE' : 'NEW LEVEL',
      };
      this.createCSLevel({
        data,
        cb: () => {
          this.getData();
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    duplicate() {
      this.isModalDuplicateOpen = true;
    },
    deleteStructure() {
      this.openConfirmModal(this.$t(`commonDetails.deleteStructureConfirmation`), () => {
        this.deleteSingleCS({
          id: this.current.id,
          cb: (res) => {
            if (res.error && res.error.includes('previously uploaded')) {
              this.$root.$emit('toast-error', this.$t('common.cannotDeleteCommonStructure'));
            }
            this.goBack();
          },
        });
        this.isConfirmModalOpen = false;
      });
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    setLoading(val) {
      this.isLoading = val;
    },
    closeDialog() {
      this.isModalDuplicateOpen = false;
      this.isModalOpen = false;
      this.clearCurrent();
    },
    clearCurrent() {
      this.item = {};
    },
    deleteLevelById(item) {
      this.openConfirmModal(this.$t(`commonDetails.deleteLevelConfirmation`), () => {
        const data = {
          levelId: item.id,
          id: this.$route.params.id,
        };
        this.deleteCSLevel({
          data,
          cb: () => {
            this.getData();
          },
        });
        this.isConfirmModalOpen = false;
      });
    },
    editLevel(item) {
      this.nameEn = item.nameEn;
      this.item = item;
      this.selectedTags = item.tags;
      // get all elements in the parent of the clicked one
      const parentId = item.parentId;
      const parent = this.findTreeItem(this.draggableLevels, parentId);

      if (parent) {
        const positionsArray = parent.levels.map((level) => level.position);
        this.lowestLevelFound = Math.min(...positionsArray);
        this.highestLevelFound = Math.max(...positionsArray);
      }
      this.toggleModal();
    },
    createLevel(item) {
      this.isLoading = true;
      const data = {
        id: this.$route.params.id,
        parentId: item.parentId,
        position: item.position < 0 ? 1 : +item.position + 1,
      };
      this.createCSLevel({
        data,
        cb: () => {
          this.getData();
        },
      });
    },
    createSubLevel(item) {
      this.isLoading = true;
      const data = {
        id: this.$route.params.id,
        parentId: item.id,
        position: 1,
      };
      this.createCSLevel({
        data,
        cb: () => {
          this.getData();
        },
      });
    },
    removeTag(levelId, tagId) {
      const data = {
        id: this.$route.params.id,
        levelId,
        tagId: tagId,
      };
      this.removeTagToLevel({
        data,
      });
      this.getData();
    },
    getData() {
      this.getSingleCS({
        id: this.$route.params.id,
        cb: () => {
          this.isLoading = false;
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    openConfirmModal(text, callback) {
      this.confirmLableText = text;
      this.confirmCallback = callback;
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
  },
};
</script>

<style scoped>
.btn-medium {
  height: 48px;
}

.btn-gold {
  padding: 0.5rem 2.5rem;
  font-size: 0.9rem;
  font-weight: bold;
}

>>> .v-input input:disabled {
  display: none;
}
</style>
