var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      key: _vm.item.id,
      attrs: {
        "is-modal-open": _vm.isModalOpen,
        "close-dialog": _vm.closeDialog,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("editLevels.editing", {
                      type: _vm.$t(
                        !_vm.item.isBook && _vm.type && _vm.type !== "NS"
                          ? "editLevels.chapter"
                          : "editLevels.file"
                      ),
                      num: _vm.item.csNumber,
                    })
                  ) +
                  " "
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "save_edited_country_btn",
                      on: { click: _vm.updateLevel },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.save")) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "delete_country_btn",
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("LoadingComponent", { attrs: { value: _vm.isLoading } }),
      _c("InputField", {
        staticClass: "selection-bold",
        attrs: {
          tabindex: 2,
          name: _vm.$t(
            !_vm.item.isBook
              ? "editLevels.chapter_name"
              : "editLevels.file_name"
          ),
          "use-model": true,
        },
        model: {
          value: _vm.currentName,
          callback: function ($$v) {
            _vm.currentName = $$v
          },
          expression: "currentName",
        },
      }),
      _vm.item.parentId
        ? _c("MultipleSelectComponent", {
            attrs: {
              tabindex: 4,
              options: _vm.tags,
              name: _vm.$t("tags.tags") + ":",
              "obj-key": "name",
              "obj-val": "id",
            },
            model: {
              value: _vm.selectedItems,
              callback: function ($$v) {
                _vm.selectedItems = $$v
              },
              expression: "selectedItems",
            },
          })
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("InputField", {
                staticClass: "selection-bold level-checkbox",
                attrs: {
                  tabindex: 2,
                  name: _vm.$t("editLevels.is_file"),
                  type: "checkbox",
                  "use-model": true,
                },
                model: {
                  value: _vm.isBookLocal,
                  callback: function ($$v) {
                    _vm.isBookLocal = $$v
                  },
                  expression: "isBookLocal",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _vm.item.parentId
                ? _c("InputField", {
                    staticClass: "selection-bold level-checkbox",
                    attrs: {
                      tabindex: 3,
                      name: _vm.$t("editLevels.is_level"),
                      type: "checkbox",
                      "use-model": true,
                    },
                    model: {
                      value: _vm.isWithoutLevel,
                      callback: function ($$v) {
                        _vm.isWithoutLevel = $$v
                      },
                      expression: "isWithoutLevel",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticStyle: { "max-width": "860px" },
        attrs: {
          items: _vm.externalLinks,
          headers: _vm.headers,
          "disable-sort": "",
          "disable-pagination": "",
          "hide-default-footer": "",
          dense: "",
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "pb-3 d-flex align-center" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "font-weight-medium",
                        staticStyle: { "font-size": "1.2rem" },
                      },
                      [_vm._v(_vm._s(_vm.$t("structure.externalLinks")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-3",
                        attrs: {
                          fab: "",
                          "x-small": "",
                          depressed: "",
                          color: "secondary",
                        },
                        on: { click: _vm.addLink },
                      },
                      [_c("v-icon", [_vm._v("mdi-plus")])],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (row) {
              return [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(row.item.application))]),
                  _c("td", [_vm._v(_vm._s(row.item.tooltip))]),
                  _c("td", [
                    _c(
                      "div",
                      {
                        staticClass: "text-truncate",
                        attrs: { title: row.item.url },
                      },
                      [_vm._v(_vm._s(row.item.url.replace(/^[^:]+:\/\//, "")))]
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      _c("v-img", {
                        attrs: {
                          src: row.item.logoUrl,
                          "max-width": "100",
                          "max-height": "64",
                          contain: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { attrs: { align: "center", width: "130", nowrap: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.editLink(row.item)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "error" },
                          on: {
                            click: function ($event) {
                              return _vm.removeLink(row.item)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close-circle")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "auto" },
          model: {
            value: _vm.dlgEditLink,
            callback: function ($$v) {
              _vm.dlgEditLink = $$v
            },
            expression: "dlgEditLink",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "frm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.updateLink.apply(null, arguments)
                },
              },
            },
            [
              _c("LoadingComponent", { attrs: { loading: _vm.isLoading } }),
              _c(
                "v-card",
                { attrs: { "min-width": "300" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-2 pr-1" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              (_vm.currentLink || {}).id
                                ? "structure.oldLink"
                                : "structure.newLink"
                            )
                          ) +
                          " "
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.dlgEditLink = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-0" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("structure.appName"),
                          outlined: "",
                          dense: "",
                          rules: [_vm.ruleRequired],
                        },
                        model: {
                          value: _vm.currentLink.application,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentLink,
                              "application",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "currentLink.application",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("structure.tooltip"),
                          outlined: "",
                          dense: "",
                          rules: [_vm.ruleRequired],
                        },
                        model: {
                          value: _vm.currentLink.tooltip,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentLink,
                              "tooltip",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "currentLink.tooltip",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          type: "url",
                          label: _vm.$t("structure.linkURL"),
                          outlined: "",
                          dense: "",
                          rules: [_vm.ruleRequired, _vm.validURL],
                        },
                        model: {
                          value: _vm.currentLink.url,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentLink,
                              "url",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "currentLink.url",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-around",
                        },
                        [
                          _vm.currentLink.newLogoUrl || _vm.currentLink.logoUrl
                            ? _c("v-img", {
                                attrs: {
                                  src:
                                    _vm.currentLink.newLogoUrl ||
                                    _vm.currentLink.logoUrl,
                                  "max-width": "100",
                                  "max-height": "64",
                                  contain: "",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            style: _vm.currentLink.newLogoUrl
                                              ? ""
                                              : "visibility: hidden;",
                                            attrs: { icon: "", color: "error" },
                                            on: {
                                              click: function ($event) {
                                                ;(_vm.currentLink.newLogoFile =
                                                  null),
                                                  (_vm.currentLink.newLogoUrl =
                                                    "")
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-close-circle"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("structure.tooltipLogo")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                color: "secondary",
                                depressed: "",
                                tag: "label",
                                for: "link_logo",
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-camera"),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.$t("structure.logo")) + " "
                              ),
                            ],
                            1
                          ),
                          _c("input", {
                            ref: "file_ctrl",
                            staticClass: "file_ctrl",
                            attrs: {
                              id: "link_logo",
                              type: "file",
                              accept: ".jpg,.jpeg,.png",
                            },
                            on: { change: _vm.chooseLogo },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "v-messages error--text mt-2" },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "message-transition" } },
                            [
                              _vm.errorLogo
                                ? _c(
                                    "div",
                                    { staticClass: "v-messages__message" },
                                    [_vm._v(" " + _vm._s(_vm.errorLogo) + " ")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "button",
                              {
                                staticClass: "save_edited_country_btn",
                                attrs: { type: "submit" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("common.save")) + " "
                                ),
                              ]
                            ),
                          ]),
                          _c("v-col", [
                            _c(
                              "button",
                              {
                                staticClass: "delete_country_btn",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.dlgEditLink = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("common.cancel")) + " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }