var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("Headings", {
        staticClass: "d-inline-block",
        attrs: {
          show: !_vm.isNew,
          title: _vm.$t(`common.ImAndRfcManagementInformation`),
          "back-location": "IMsList",
          subtitle: _vm.im.name,
        },
      }),
      _c("Headings", {
        staticClass: "d-inline-block",
        attrs: {
          show: _vm.isNew,
          title: _vm.$t(`common.ImAndRfcManagementInformation`),
          "back-location": "IMsList",
          subtitle: _vm.$t(`ims.newIM`),
        },
      }),
      _c("div", { staticClass: "row mt-5" }, [
        _c("div", { staticClass: "col-6 col-lg-3" }, [
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              {
                class: { preview: !!_vm.logoPreview },
                style: { "background-image": `url(${_vm.logoPreview})` },
                attrs: { for: "logo" },
              },
              [_vm._v(_vm._s(_vm.$t("ims.logo")))]
            ),
            _c("input", {
              attrs: {
                id: "logo",
                accept: "image/*",
                type: "file",
                name: "logo",
              },
              on: { change: (e) => _vm.pickFile(e) },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "im-label" }, [
              _vm._v(_vm._s(_vm.$t("ims.name")) + ":"),
            ]),
            _c("input", {
              staticClass: "username_input",
              attrs: { type: "text", required: "" },
              domProps: { value: _vm.im.name },
              on: { change: (e) => _vm.updateName(e.target.value) },
            }),
            _vm.nameRequired
              ? _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("validationRules.required_field")) + " "
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "im-label" }, [
              _vm._v(_vm._s(_vm.$t("ims.code")) + ":"),
            ]),
            _c("input", {
              staticClass: "username_input",
              attrs: { type: "text", required: "" },
              domProps: { value: _vm.im.localizedName },
              on: { change: (e) => _vm.updateCode(e.target.value) },
            }),
            _vm.shortNameRequired
              ? _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("validationRules.required_field")) + " "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.countries
            ? _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("MultipleSelectComponent", {
                    attrs: {
                      tabindex: 3,
                      options: _vm.countries,
                      chips: true,
                      name: _vm.$t(`common.country`),
                      "obj-key": "name",
                      "obj-val": "id",
                    },
                    on: {
                      change: (e) => {
                        _vm.filterCountries(e)
                        _vm.updateCountry(e)
                      },
                    },
                    model: {
                      value: _vm.selectedCountries,
                      callback: function ($$v) {
                        _vm.selectedCountries = $$v
                      },
                      expression: "selectedCountries",
                    },
                  }),
                  _vm.countryRequired
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("validationRules.required_field")) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isNew,
                expression: "!isNew",
              },
            ],
            staticClass: "col-5 offset-1",
          },
          [
            _c("p", { staticClass: "table-title users-with-access" }, [
              _vm._v(" " + _vm._s(_vm.$t("common.usersWithAccess")) + " "),
              _c("span", { staticClass: "total" }, [
                _vm._v("(" + _vm._s(_vm.totalItems) + ")"),
              ]),
            ]),
            _c("div", { staticClass: "hr" }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.totalItems,
                    expression: "totalItems",
                  },
                ],
                staticClass: "row",
              },
              _vm._l(_vm.im.documentManagers, function (user, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "col-12 user-with-access" },
                  [
                    _vm._v(
                      " " + _vm._s(user.firstName + " " + user.familyName) + " "
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "row bottom" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("div", { staticClass: "btns-container" }, [
            _c(
              "button",
              {
                staticClass: "back_user_btn",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.goBack.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isNew,
                    expression: "isNew",
                  },
                ],
                staticClass: "save_btn",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.create.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("common.add")) + " ")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isNew,
                    expression: "!isNew",
                  },
                ],
                staticClass: "save_btn",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.update.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("common.save")) + " ")]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isNew,
                    expression: "!isNew",
                  },
                ],
                staticClass: "delete_btn",
                on: { click: _vm.deleteIM },
              },
              [_vm._v(" " + _vm._s(_vm.$t("common.delete")) + " ")]
            ),
          ]),
        ]),
      ]),
      _c("ConfirmModal", {
        staticStyle: { "z-index": "20001" },
        attrs: {
          "is-modal-open": _vm.isConfirmModalOpen,
          "close-dialog": _vm.closeConfirmModal,
          "confirm-callback": _vm.confirmCallback,
          "lable-text": _vm.confirmLableText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }