<template>
  <Modal v-if="savedSearches" :is-modal-open="isModalOpen" :close-dialog="closeDialog">
    <template #header>
      <div class="save-title">
        {{ $t(`savedSearches.saveTitle`) }}
      </div>
    </template>

    <div class="subtitle">
      {{ $t(`savedSearches.savedSearchesSubtitle`) }}
    </div>

    <div class="document-type">
      {{ $t(`savedSearches.documentType`) }}
    </div>
    <v-select
      v-model="selectedType"
      class="saved-searches-select"
      :menu-props="{ bottom: true, offsetY: true, offset: 20 }"
      :items="types"
      return-object
    >
      <template #selection="{ item }">
        <v-row>
          <div v-if="item == 'NS'" class="document_type_ns">
            <img :src="nsSearch" />
          </div>
          <div v-else class="document_type_cid">
            <img :src="cidSearch" />
          </div>
          <span class="text-card ml-8 mt-5"> {{ item }} </span>
        </v-row>
      </template>
      <template #item="{ item }">
        <v-row>
          <div v-if="item == 'NS'" class="document_type_ns">
            <img :src="nsSearch" />
          </div>
          <div v-else class="document_type_cid">
            <img :src="cidSearch" />
          </div>
          <span class="text-card ml-4"> {{ item }} </span>
        </v-row>
      </template>
    </v-select>

    <v-list class="overflow-y-auto saved-searches-list mt-4" max-height="350">
      <v-list-item-group v-model="selectedItem" color="primary" class="saved-searches">
        <v-list-item v-for="(item, index) in filteredSearches" :key="index" :value="item" active-color="primary">
          <saved-search-card :item="item" :delete-item-callback="openDeleteSearchDialog" :open-info="openInfo" />
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <DeleteSearchModal
      :is-modal-open="isDeleteSearchOpen"
      :close-dialog="closeDeleteSearchDialog"
      :search="forDelete"
      :is-info="true"
      style="z-index: 20001"
    />

    <template #footer>
      <div class="d-flex modal-footer">
        <div class="p-2">
          <button class="btn btn-green btn-medium mr-2" @click="closeDialog">
            {{ $t('common.cancel') }}
          </button>
        </div>
        <div class="ml-auto p-2">
          <button class="btn btn-green btn-medium" :disabled="!selectedItem" @click="selectSavedSearch(selectedItem)">
            {{ 'Select' }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '@/components/base/Modal';
import nsSearch from '@/assets/icons/ns-search.svg';
import cidSearch from '@/assets/icons/cid-search.svg';
import SavedSearchCard from './SavedSearchCard';
import DeleteSearchModal from './DeleteSearchModal';

export default {
  name: 'ManageSearchModal',
  components: {
    Modal,
    SavedSearchCard,
    DeleteSearchModal,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function,
      default: null,
    },
    savedSearches: {
      type: Array,
      default: null,
    },
    openInfo: {
      type: Function,
      default: null,
    },
    selectSavedSearch: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      selectedType: 'NS',
      types: ['NS', 'CID'],
      selectedItem: null,
      nsSearch,
      cidSearch,
      savedSearchesLocal: [],
      isDeleteSearchOpen: false,
      infoSearch: null,
      forDelete: null,
    };
  },
  computed: {
    filteredSearches() {
      return this.savedSearchesLocal.filter((e) => e.searchParameters.type === this.selectedType).reverse();
    },
  },
  watch: {
    savedSearches: function (value) {
      this.savedSearchesLocal = value;
    },
  },
  created() {
    this.savedSearchesLocal = this.savedSearches;
  },
  methods: {
    ...mapActions('search', ['deleteSearch']),
    deleteSavedSearch(item) {
      this.deleteSearch({
        searchId: item.id,
        cb: () => {
          this.isLoading = false;
          location.reload();
        },
        ecb: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
    },
    openDeleteSearchDialog(item) {
      this.forDelete = item;
      this.isDeleteSearchOpen = true;
    },
    closeDeleteSearchDialog(action) {
      if (action === 'delete') {
        this.deleteSavedSearch(this.forDelete);
      }
      this.forDelete = null;
      this.isDeleteSearchOpen = false;
    },
  },
};
</script>

<style scoped>
.subtitle {
  font-size: 15px;
  margin-bottom: 2em;
  max-width: 600px;
}

label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.5em;
}

.search_name {
  position: relative;
}

.search_name .input-medium {
  padding-left: 46px;
}

.document_type_ns,
.document_type_cid {
  color: #fff;
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid;
}

.document_type_ns img,
.document_type_cid img {
  width: 48px;
  height: 48px;
  vertical-align: middle;
  padding: 0 !important;
}

.document_type_ns {
  background: #265f14 !important;
}

.document_type_cid {
  background: #bf873d !important;
}

h2 {
  font-weight: bold;
  font-size: 20px;
}

.parameter_section .section_title {
  font-size: 12px;
}

.parameter_section ul {
  padding: 0px;
  margin-bottom: 24px;
  max-width: 600px;
  line-height: 32px;
}

.parameter_section ul li {
  padding: 4px 12px;
  margin-right: 10px;
  background: #f0ffeb;
  border: 1px solid #265f14;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  white-space: nowrap;
}

.btn-green {
  font-size: 1em;
  margin-right: 0px;
}

.text-card {
  margin: 8px;
  padding-left: 32px;
}

.saved-searches-select {
  border: 1px solid #808080;
  border-radius: 8px;
  height: 50px !important;
  padding-top: 0;
  min-width: 720px;
}

.saved-searches-select >>> .v-input__slot::before,
.saved-searches-select >>> .v-input__slot::after {
  border: none;
  border-style: none !important;
}

.saved-searches-select >>> .v-input__append-inner {
  margin-top: 12px !important;
}

.saved-searches > .v-list-item:not(:last-child) {
  margin-bottom: 8px;
}

.saved-searches-list {
  background: #f3f3f3;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

.document-type {
  font-family: Montserrat, sans-serif;
  padding: 7px 5px;
  font-size: 1.4em;
  color: #828282;
}
</style>
