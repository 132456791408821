<template>
  <div class="form-group mb-0">
    <div v-if="name">
      <label class="select-label">{{ name }}</label>
    </div>
    <v-select
      v-model="selectModel"
      :class="classStyle"
      :items="options"
      :disabled="isDisabled"
      solo
      :item-text="objKey"
      :item-value="objVal"
      :prepend-inner-icon="prepend"
    />
    <span v-if="error" class="form-error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'VSelectComponent',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    classStyle: {
      type: String,
      default: '',
    },
    prepend: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    objKey: {
      type: String,
      required: true,
    },
    objVal: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectModel: {
      get() {
        return this.value;
      },
      set(val) {
        // eslint-disable-next-line vue/require-explicit-emits
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style scoped>
>>> input {
  border: none;
}

>>> .v-select__selections {
  /* border: solid 1px #e3e3e3; */
  border-radius: 8px;
  min-height: 46px;
  height: auto;
  background-color: #fff;
  background-image: linear-gradient(45deg, transparent 50%, grey 50%),
    linear-gradient(135deg, grey 50%, transparent 50%), linear-gradient(to right, #f8f8f8, #f8f8f8);
  background-position: calc(100% - 17px) center, calc(100% - 12px) center, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.2em 100%;
  background-repeat: no-repeat;
  padding-right: 35px;
}

>>> .v-input.multiple-select-input.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-select__selections {
  background-image: linear-gradient(45deg, grey 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, grey 50%), linear-gradient(to right, #f8f8f8, #f8f8f8);
  background-position: calc(100% - 12px) center, calc(100% - 17px) center, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.2em 100%;
}

>>> .v-input__append-inner {
  display: none;
}

>>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none;
  border: solid 1px #e3e3e3;
  border-radius: 8px;
  margin-bottom: 0px;
  padding-right: 0px;
}

>>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-select__selections
  > input {
  background-color: transparent;
  border: none !important;
}

>>> .v-text-field__details {
  display: none;
}
</style>
