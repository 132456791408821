var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group mb-0" },
    [
      _vm.name
        ? _c("div", [
            _c("label", { staticClass: "select-label" }, [
              _vm._v(_vm._s(_vm.name)),
            ]),
          ])
        : _vm._e(),
      _c("v-select", {
        class: _vm.classStyle,
        attrs: {
          items: _vm.options,
          disabled: _vm.isDisabled,
          solo: "",
          "item-text": _vm.objKey,
          "item-value": _vm.objVal,
          "prepend-inner-icon": _vm.prepend,
        },
        model: {
          value: _vm.selectModel,
          callback: function ($$v) {
            _vm.selectModel = $$v
          },
          expression: "selectModel",
        },
      }),
      _vm.error
        ? _c("span", { staticClass: "form-error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }