import {
  addCountryRequest,
  updateCountryRequest,
  deleteCountryRequest,
  getCountryRequest,
} from '@/services/countries.service';

// initial state
const state = () => ({
  data: [],
  current: {},
  totalItems: 0,
});

// getters
const getters = {
  totalItems: (state) => {
    if (!state.current) return 0;
    if (!state.current.documentManagers) return 0;
    return state.current.documentManagers.length;
  },
};

// actions
const actions = {
  getCountry({ commit }, { id, cb = null, ecb = null }) {
    getCountryRequest(
      id,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleCountry', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  updateCountry({ commit }, { data, cb = null, ecb = null }) {
    updateCountryRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) {
          commit('clearCurrent');
          commit('common/updateCountries', res, { root: true });
        }
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  currentItem({ commit, state }, id) {
    const currentItem = state.data.find((e) => {
      return e.id === id;
    });
    commit('setSingleCountry', currentItem);
  },
  addCountry({ commit }, { data, cb = null, ecb = null }) {
    addCountryRequest(
      data,
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) {
          commit('clearCurrent');
          commit('common/addCountry', res, { root: true });
          if (cb) cb(res);
        } else if (ecb) {
          ecb(res.error);
        }
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  deleteCountry({ commit }, { id, cb = null, ecb = null }) {
    deleteCountryRequest(
      id,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) {
          commit('clearCurrent');
          commit('common/deleteCountry', id, { root: true });
        }
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  updateName({ commit }, name) {
    commit('updateName', name);
  },
  updateCode({ commit }, name) {
    commit('updateCode', name);
  },
  clearCurrent({ commit }) {
    commit('clearCurrent');
  },
};

// mutations
const mutations = {
  setCountries(state, data) {
    state.data = data;
    state.totalItems = data.length;
  },
  setSingleCountry(state, data) {
    state.current = data;
  },
  updateName(state, name) {
    state.current.name = name;
  },
  updateCode(state, code) {
    state.current.code = code;
  },
  clearCurrent(state) {
    state.current = {
      name: ' ',
      code: ' ',
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
