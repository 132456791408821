var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("Headings", {
        attrs: {
          title: _vm.$t(`common.documentManagement`),
          subtitle: _vm.$t(`documentManagement.subtitle`),
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2", md: "2", lg: "2" } },
            [
              _c("SelectComponent", {
                staticClass: "select-big",
                attrs: {
                  tabindex: 1,
                  options: _vm.managementTypes,
                  name: _vm.$t("common.type") + ":",
                  "obj-key": "name",
                  "obj-val": "value",
                },
                model: {
                  value: _vm.searchForm.selectedType,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "selectedType", $$v)
                  },
                  expression: "searchForm.selectedType",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "5", md: "5", lg: "2" } },
            [
              _c("SelectComponent", {
                staticClass: "select-big",
                attrs: {
                  tabindex: 2,
                  options: _vm.years,
                  name: _vm.$t(`common.years`),
                  "obj-key": "value",
                  "obj-val": "value",
                },
                model: {
                  value: _vm.searchForm.selectedYear,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "selectedYear", $$v)
                  },
                  expression: "searchForm.selectedYear",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "5", md: "5", lg: "3" } },
            [
              _c("MultipleSelectComponent", {
                staticClass: "multiselect-big",
                attrs: {
                  tabindex: 3,
                  disabled: _vm.disableIMs,
                  options: _vm.imsList,
                  name: _vm.$t(`common.infrastructureManagers`),
                  "obj-key": "name",
                  "obj-val": "value",
                },
                model: {
                  value: _vm.searchForm.selectedIMs,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "selectedIMs", $$v)
                  },
                  expression: "searchForm.selectedIMs",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6", lg: "3" } },
            [
              _c("MultipleSelectComponent", {
                staticClass: "multiselect-big",
                attrs: {
                  tabindex: 4,
                  disabled: _vm.disableRFCs,
                  options: _vm.rfcList,
                  name: _vm.$t(`common.railFreightCorridors`),
                  "obj-key": "name",
                  "obj-val": "value",
                },
                model: {
                  value: _vm.searchForm.selectedRFCs,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "selectedRFCs", $$v)
                  },
                  expression: "searchForm.selectedRFCs",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "btn-search-container",
              attrs: { cols: "12", sm: "6", md: "4", lg: "2" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-green btn-search",
                  on: { click: _vm.searchByCriteria },
                },
                [
                  _c("img", {
                    staticClass: "search-icon",
                    attrs: {
                      src: require("@/assets/icons/grey-icons/search.svg"),
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.$t(`common.search`)) + " "),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("LabelWithCount", {
              attrs: {
                title: _vm.$t("documentManagement.documents"),
                count: _vm.totalItems,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "table-documents" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.headers, function (header, index) {
                    return _c(
                      "th",
                      {
                        key: index,
                        class: {
                          "header-bold": _vm.sortedColumn === header.name,
                        },
                        attrs: { scope: "col" },
                      },
                      [
                        _vm._v(" " + _vm._s(header.name) + " "),
                        _c("img", {
                          class: _vm.sortDirection(header.id),
                          attrs: {
                            id: header.id,
                            src: _vm.sortIcon(header.orderEnum),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortBy(header)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.allDocuments, function (doc, index) {
                  return _c(
                    "tr",
                    {
                      key: index,
                      staticClass: "table-clickable-row",
                      on: {
                        click: function ($event) {
                          return _vm.open(doc.id)
                        },
                      },
                    },
                    [
                      _c("td", [
                        _c("img", {
                          staticClass: "document",
                          attrs: {
                            src: require("@/assets/icons/grey-icons/documentmanagement.svg"),
                          },
                        }),
                        _c("img", {
                          staticClass: "documentGold",
                          attrs: {
                            src: require("@/assets/icons/gold-icons/documentmanagement.svg"),
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(doc.organizationName))]),
                      ]),
                      _c("td", [_vm._v(_vm._s(doc.type))]),
                      _c("td", [_vm._v(_vm._s(doc.year))]),
                      _c("td", [_vm._v(_vm._s(doc.version))]),
                      _c("td", [_vm._v(_vm._s(doc.status))]),
                      _c("td", [_vm._v(_vm._s(doc.lastPublished || "N/A"))]),
                      _c(
                        "td",
                        { staticStyle: { "white-space": "break-spaces" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("documentManagement.updatedBy", {
                                  updated:
                                    _vm.formatDate(doc.lastUpdatedOn) || "N/A",
                                  author: doc.lastUpdatedByUserName || "N/A",
                                })
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm.totalPages > 0 && _vm.totalPages > _vm.page + 1
                ? _c("tfoot", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "9" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "btn-loadmore",
                            on: { click: _vm.loadMore },
                          },
                          [_vm._v(_vm._s(_vm.$t(`common.loadMore`)))]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-12" }, [
          _c("div", { staticClass: "row" }, [
            _vm.isAdmin || (_vm.isDocManager && _vm.imsAccess.length)
              ? _c("div", { staticClass: "col-12 col-sm-6 col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-bottom-action btn-green",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.navigateToUploadType("NS")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t(`documentManagement.uploadNS`)) +
                          " "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.isAdmin || (_vm.isDocManager && _vm.rfcsAccess.length)
              ? _c("div", { staticClass: "col-12 col-sm-6 col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-bottom-action btn-green",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.navigateToUploadType("CID")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t(`documentManagement.uploadCID`)) +
                          " "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.isAdmin || (_vm.isDocManager && _vm.masterCIDAccess)
              ? _c(
                  "div",
                  {
                    staticClass: "col-12 col-sm-6 col-md-3 btn-upl-master-cid",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-bottom-action btn-green",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.navigateToMasterCIDUpload.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(`documentManagement.uploadMasterCID`)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.isAdmin || _vm.isDocManager
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-12 col-sm-6 col-md-5 btn-download-master-cid",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-bottom-action btn-white-border",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleMasterDownloadCIDModal.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(`documentManagement.downloadMasterCID`)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm.masterCIDAccess
        ? _c("UploadMasterCID", {
            attrs: {
              "is-modal-open": _vm.isMasterCIDModalOpen,
              "close-dialog": _vm.closeMasterCIDModal,
            },
          })
        : _vm._e(),
      _c("DownloadMasterCID", {
        attrs: {
          "is-modal-open": _vm.isDownloadCIDModalOpen,
          "close-dialog": _vm.closeMasterDownloadCIDModal,
          years: _vm.years,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }