var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group mb-0" },
    [
      _vm.name
        ? _c("div", {}, [
            _c("label", { staticClass: "select-label" }, [
              _vm._v(_vm._s(_vm.name)),
            ]),
          ])
        : _vm._e(),
      _c("v-combobox", {
        class: _vm.classStyle,
        attrs: {
          items: _vm.options,
          "item-text": _vm.objKey,
          "item-value": _vm.objVal,
          height: _vm.height,
          disabled: _vm.disabled,
          multiple: "",
          "hide-selected": _vm.hideSelected,
          chips: _vm.chips,
          "prepend-inner-icon": _vm.prepend,
          "append-icon": "",
          placeholder: _vm.placeholder,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          change: function ($event) {
            return _vm.$emit("change", $event)
          },
        },
        model: {
          value: _vm.selectModel,
          callback: function ($$v) {
            _vm.selectModel = $$v
          },
          expression: "selectModel",
        },
      }),
      _vm.error
        ? _c("span", { staticClass: "form-error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }