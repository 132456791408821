<template>
  <div class="form-group mb-0">
    <div v-if="name" class>
      <label class="select-label">{{ name }}</label>
    </div>

    <v-combobox
      v-model="selectModel"
      :class="classStyle"
      :items="options"
      :item-text="objKey"
      :item-value="objVal"
      :height="height"
      :disabled="disabled"
      multiple
      :hide-selected="hideSelected"
      :chips="chips"
      :prepend-inner-icon="prepend"
      append-icon=""
      :placeholder="placeholder"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
    <span v-if="error" class="form-error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'MultipleSelectComponent',
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    chips: {
      type: Boolean,
      default: true, // eslint-disable-line vue/no-boolean-default
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prepend: {
      type: String,
      default: '',
    },
    classStyle: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    objKey: {
      type: String,
      required: true,
    },
    objVal: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectModel: null,
    };
  },
  watch: {
    value(newValue) {
      this.selectModel = newValue;
    },
  },
  mounted() {
    this.selectModel = this.value;
  },
  methods: {
    noTypingInput(event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped>
/deep/ .v-select-list .v-list-item,
/deep/ .mdi-checkbox-marked::before {
  color: #4c8939 !important;
}

/deep/ .v-select__selections {
  border: solid 1px #e3e3e3;
  border-radius: 8px;
  min-height: 46px;
  height: auto;
  background-color: #fff;
  background-image: linear-gradient(45deg, transparent 50%, grey 50%),
    linear-gradient(135deg, grey 50%, transparent 50%), linear-gradient(to right, #f8f8f8, #f8f8f8);
  background-position: calc(100% - 17px) center, calc(100% - 12px) center, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.2em 100%;
  background-repeat: no-repeat;
  padding-right: 35px;
  padding-left: 5px;
}

/deep/
  .v-input.multiple-select-input.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-select__selections {
  background-image: linear-gradient(45deg, grey 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, grey 50%), linear-gradient(to right, #f8f8f8, #f8f8f8);
  background-position: calc(100% - 12px) center, calc(100% - 17px) center, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.2em 100%;
}

/deep/ .v-input__append-inner {
  display: none;
}

>>> .v-input input {
  min-height: 54px !important;
  max-height: unset;
  border: none !important;
  height: auto;
  padding: 10px;
}

/deep/ .v-text-field {
  padding-top: 0px;
}

/deep/ .v-text-field > .v-input__control > .v-input__slot::before,
/deep/ .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot::after {
  display: none;
}

/* as there doesn't seem to be a way to customize the icon */
/deep/ .mdi-close-circle::before {
  content: '\F0156';
  color: #4c8939;
}

/deep/ .v-chip {
  border-radius: 4px !important;
  border: solid 1px #4c8939 !important;
  background: #f0ffeb !important;
  margin-left: 10px;
  margin-top: 7px;
}

/deep/ .v-chip__content {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

/* select.has-error {
  border: 2px solid #ff000038;
} */

.multiple-select-input {
  min-height: 56px;
}

.select-label {
  font-family: Roboto, sans-serif;
  font-size: 1.4em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
}
</style>
