var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group", class: _vm.isCheckbox ? "checkbox" : "" },
    [
      _vm.name
        ? _c("label", {
            staticClass: "input-title",
            attrs: { for: _vm.guid },
            domProps: {
              innerHTML: _vm._s(
                `${_vm.$t(_vm.name)}${_vm.type != "checkbox" ? ":" : ""}`
              ),
            },
          })
        : _vm._e(),
      _vm.type === "checkbox" && _vm.useModel
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val,
                expression: "val",
              },
            ],
            ref: "input",
            class: { "has-error": _vm.error, "as-disabled": _vm.isDisabled },
            attrs: {
              id: _vm.guid,
              placeholder: _vm.$t(_vm.placeholder),
              tabindex: _vm.tabindex,
              required: "",
              disabled: _vm.isDisabled,
              readonly: _vm.readonly,
              type: "checkbox",
            },
            domProps: {
              checked: Array.isArray(_vm.val)
                ? _vm._i(_vm.val, null) > -1
                : _vm.val,
            },
            on: {
              keyup: function ($event) {
                return _vm.submitOnEnter($event)
              },
              change: function ($event) {
                var $$a = _vm.val,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.val = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.val = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.val = $$c
                }
              },
            },
          })
        : _vm.type === "radio" && _vm.useModel
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val,
                expression: "val",
              },
            ],
            ref: "input",
            class: { "has-error": _vm.error, "as-disabled": _vm.isDisabled },
            attrs: {
              id: _vm.guid,
              placeholder: _vm.$t(_vm.placeholder),
              tabindex: _vm.tabindex,
              required: "",
              disabled: _vm.isDisabled,
              readonly: _vm.readonly,
              type: "radio",
            },
            domProps: { checked: _vm._q(_vm.val, null) },
            on: {
              keyup: function ($event) {
                return _vm.submitOnEnter($event)
              },
              change: function ($event) {
                _vm.val = null
              },
            },
          })
        : _vm.useModel
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val,
                expression: "val",
              },
            ],
            ref: "input",
            class: { "has-error": _vm.error, "as-disabled": _vm.isDisabled },
            attrs: {
              id: _vm.guid,
              placeholder: _vm.$t(_vm.placeholder),
              tabindex: _vm.tabindex,
              required: "",
              disabled: _vm.isDisabled,
              readonly: _vm.readonly,
              type: _vm.type,
            },
            domProps: { value: _vm.val },
            on: {
              keyup: function ($event) {
                return _vm.submitOnEnter($event)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.val = $event.target.value
              },
            },
          })
        : _c("input", {
            ref: "input",
            attrs: {
              id: _vm.guid,
              type: _vm.type,
              tabindex: _vm.tabindex,
              placeholder: _vm.$t(_vm.placeholder),
              required: "",
              readonly: _vm.readonly,
            },
            domProps: { value: _vm.val },
            on: {
              input: _vm.changeData,
              keyup: function ($event) {
                return _vm.submitOnEnter($event)
              },
            },
          }),
      _vm.error
        ? _c("span", { staticClass: "form-error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }