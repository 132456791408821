<template>
  <DefaultLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <div class="row">
      <Headings
        class="col-6 p-0 d-inline-block"
        :title="$t(`documentManagement.docManagement`)"
        back-location="DocManagement"
        :query-params="{ search: $route.params.search }"
        :subtitle="
          $t('common.upload') +
          ' ' +
          (isMasterUpload ? $t('documentManagement.master') + ' ' : '') +
          (currentDocument.type || $t('editLevels.file'))
        "
      />
      <div class="col-6 d-inline-block">
        <div v-if="!isNewDocument && versions" class="version-control-container">
          <SelectComponent
            v-model="currentVersion"
            :tabindex="2"
            :options="versions"
            name="Version Control:"
            obj-key="displayValue"
            obj-val="id"
            class="select-big version-select-control"
            @change="changeVersion"
          />
        </div>
      </div>
    </div>
    <DocManagementForm
      ref="docManagementForm"
      :current-document="currentDocument"
      :set-loading="(flag) => (isLoading = flag)"
    />
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" sm="2">
            <button class="btn btn-grey btn-doc-upload-action" @click.prevent="goBack">
              {{ $t(`common.close`) }}
            </button>
          </v-col>
          <v-col v-if="buttonVisible && !isPublished" cols="12" sm="2">
            <button
              class="btn btn-green btn-doc-upload-action"
              :disabled="documentsNotProcessed"
              :class="documentsNotProcessed ? 'cursor-blocked' : ''"
              @click.prevent="saveFile()"
            >
              {{ $t(`common.save`) }}
            </button>
          </v-col>
          <v-col
            v-if="buttonVisible && !isPublished && currentVersion !== 'Master' && currentDocument.id"
            cols="12"
            sm="2"
          >
            <button
              :disabled="documentsNotProcessed || documentsErrorProcessing || hasUploadedFile"
              :class="
                documentsNotProcessed || documentsErrorProcessing
                  ? 'cursor-blocked'
                  : hasUploadedFile
                  ? 'btn-greyed cursor-blocked'
                  : ''
              "
              :title="
                documentsNotProcessed
                  ? $t('documentManagement.please_wait')
                  : documentsErrorProcessing
                  ? $t('common.extractionError')
                  : ''
              "
              class="btn btn-green btn-doc-upload-action"
              @click.prevent="toggleModal"
            >
              {{ $t(`common.publish`) }}
            </button>
          </v-col>
          <v-col v-if="buttonVisible" cols="12" sm="3">
            <button v-if="!isNewDocument" class="btn btn-white btn-doc-upload-action" @click.prevent="previewDocument">
              {{ $t(`documentManagement.previewDocument`) }}
            </button>
          </v-col>
          <v-col v-if="currentDocument.type === 'CID' && isMasterUpload" cols="12" sm="3">
            <button class="btn btn-green btn-doc-upload-action" @click.prevent="saveFile(true)">
              {{ $t(`common.upload`) }}
            </button>
          </v-col>
          <v-col v-if="buttonVisible" cols="12" sm="3">
            <button v-if="!isNewDocument" class="btn btn-white btn-doc-upload-action" @click.prevent="downloadDocument">
              {{ $t(`documentManagement.downloadOriginalDocument`) }}
            </button>
          </v-col>
          <v-col cols="12" sm="3">
            <button
              v-if="!isNewDocument"
              class="btn btn-white btn-doc-upload-action"
              @click.prevent="openCopyToNewVersionModal"
            >
              {{ $t(`documentManagement.copyToNewVersion`) }}
            </button>
          </v-col>
          <v-col v-if="isPublished" cols="12" sm="3">
            <button class="btn btn-red btn-doc-upload-action" @click.prevent="toggleUnpublishModal">
              {{ $t(`documentManagement.unpublishAction`) }}
            </button>
          </v-col>
          <v-col v-if="canDeleteWholeYear" cols="12" sm="3">
            <button class="btn btn-red btn-doc-upload-action" @click.prevent="toggleDeleteWholeYearModal">
              {{
                $t(`documentManagement.deleteWholeYearButton`, {
                  year: currentDocument.year,
                  organization: currentDocument.organizationName,
                })
              }}
            </button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <Modal :is-modal-open="isModalOpen" :close-dialog="closeDialog">
      <template #header>
        <div class="modal-heading mb-10">
          {{ $t(`documentManagement.publishConfirmation`) }}
        </div>
        <div class="modal-text">
          {{ $t(`documentManagement.publishProceed`) }}
        </div>
      </template>
      <template #footer>
        <div class="row">
          <div class="col-3" />
          <div class="col-3">
            <button class="modal-button-cancel" @click="confirmPublish">
              {{ $t(`common.yes`) }}
            </button>
          </div>
          <div class="col-3">
            <button class="modal-button-confirm" @click="closeDialog">
              {{ $t(`common.no`) }}
            </button>
          </div>
          <div class="col-3" />
        </div>
      </template>
    </Modal>

    <Modal :is-modal-open="isMissingFilesModalOpen" :close-dialog="closeMissingFilesModal">
      <template #header>
        <div class="modal-heading mb-10">
          {{ $t(`documentManagement.missingFilesModalHeading`) }}
        </div>
        <div class="modal-text">
          {{ $t(`documentManagement.missingFilesModalText`) }}
        </div>
        <div v-for="missingFile of missingFiles" :key="missingFile[0]" class="row">
          <div class="col-6">
            <SelectComponent
              :value="missingFiles.get(missingFile[0])"
              :tabindex="3"
              :options="missingFilesOptions.get(missingFile[0]) || []"
              :name="missingFile[0]"
              obj-key="year"
              obj-val="year"
              :is-disabled="missingFilesOptions.get(missingFile[0])?.length == 0"
              class="select-small mt-8"
              :change="
                (val) => {
                  isMissingFilesModified = !isMissingFilesModified;
                  missingFiles.set(missingFile[0], val);
                }
              "
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="row">
          <div class="col-1" />
          <div class="col-4">
            <button class="modal-button-cancel" :disabled="isCopyExistingFilesButtonDisabled" @click="copyExistingFile">
              {{ $t(`documentManagement.copyExistingFiles`) }}
            </button>
          </div>
          <div class="col-4">
            <button class="modal-button-confirm" @click="confirmPublish">
              {{ $t(`documentManagement.withoutFiles`) }}
            </button>
          </div>
          <div class="col-3">
            <button class="modal-button-confirm" @click="closeMissingFilesModal">
              {{ $t(`common.cancel`) }}
            </button>
          </div>
          <div class="col-3" />
        </div>
      </template>
    </Modal>

    <Modal :is-modal-open="isUnpublishModalOpen" :close-dialog="closeUnpublishModal">
      <template #header>
        <div class="modal-heading mb-10">
          {{ $t(`documentManagement.unpublishConfirmation`) }}
        </div>
        <div class="modal-text">
          {{ $t(`documentManagement.unpublishConfirmationDetails`) }}
        </div>
      </template>
      <template #footer>
        <div class="row">
          <div class="col-3" />
          <div class="col-3">
            <button class="modal-button-cancel" @click="confirmUnpublish">
              {{ $t(`common.yes`) }}
            </button>
          </div>
          <div class="col-3">
            <button class="modal-button-confirm" @click="closeUnpublishModal">
              {{ $t(`common.no`) }}
            </button>
          </div>
          <div class="col-3" />
        </div>
      </template>
    </Modal>

    <Modal :is-modal-open="isDeleteWholeYearModalOpen" :close-dialog="closeDeleteWholeYearModal">
      <template #header>
        <div class="modal-heading mb-10">
          {{
            $t(`documentManagement.deleteWholeYearConfirmation`, {
              year: currentDocument.year,
              organization: currentDocument.organizationName,
            })
          }}
        </div>
        <div class="modal-text">
          {{ $t(`documentManagement.deleteWholeYearConfirmationDetails`) }}
        </div>
      </template>
      <template #footer>
        <div class="row">
          <div class="col-3" />
          <div class="col-3">
            <button class="modal-button-cancel" @click="confirmDeleteWholeYear">
              {{ $t(`common.yes`) }}
            </button>
          </div>
          <div class="col-3">
            <button class="modal-button-confirm" @click="closeDeleteWholeYearModal">
              {{ $t(`common.no`) }}
            </button>
          </div>
          <div class="col-3" />
        </div>
      </template>
    </Modal>

    <Modal :is-modal-open="isPreviewModalOpen" :close-dialog="closePreviewDialog">
      <template #header>
        <div class="modal-heading mb-2">{{ $t('documentManagement.select_preview') }}:</div>
      </template>
      <template #footer>
        <div class="row">
          <template v-for="(book, index) in filteredBooksOnly">
            <div
              v-if="selectedBookFiles[index].status === 'PROCESSED'"
              :key="index"
              class="col-12 mb-4 preview-file-container"
              @click="goToPreviewDocument(index)"
            >
              {{ book.nameEn }}
            </div>
          </template>
        </div>
      </template>
    </Modal>

    <Modal :is-modal-open="isDownloadModalOpen" :close-dialog="closeDownloadDialog">
      <template #header>
        <div class="modal-heading mb-2">{{ $t('documentManagement.select_download') }}:</div>
      </template>
      <template #footer>
        <div class="row">
          <template v-for="(book, index) in filteredBooksOnly">
            <div
              v-if="selectedBookFiles[index].status === 'PROCESSED'"
              :key="index"
              class="col-12 mb-4 preview-file-container"
              @click="goToDownloadDocument(index)"
            >
              {{ book.nameEn }}
            </div>
          </template>
        </div>
      </template>
    </Modal>

    <Modal
      id="copyToNewVersionModal"
      :is-modal-open="isCopyToNewVersionModalOpen"
      :close-dialog="closeCopyToNewVersionModal"
    >
      <template #header>
        <div class="modal-heading mb-10">
          {{ $t(`documentManagement.copyToNewVersionHeading`) }}
        </div>
        <div class="modal-text">
          {{ $t(`documentManagement.copyToNewVersionText`) }}
        </div>
        <div class="row mt-10 centered">
          <div class="col-8">
            <InputField
              ref="newVersionField"
              name="documentManagement.newVersionField"
              :value.sync="newVersion"
              :use-model="true"
              :error="newVersionError"
              @update="validateVersionNumber($event)"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="row centered">
          <div class="col-6">
            <button
              class="modal-button-cancel"
              :disabled="!newVersion || !!newVersionError"
              @click="confirmCopyToNewVersion"
            >
              {{ $t(`documentManagement.copyToNewVersion`) }}
            </button>
          </div>
          <div class="col-3">
            <button class="modal-button-confirm" @click="closeCopyToNewVersionModal">
              {{ $t(`common.cancel`) }}
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import DocManagementForm from './DocManagementForm';
import Modal from '@/components/base/Modal';
import LoadingComponent from '@/components/base/LoadingComponent';
import SelectComponent from '@/components/base/SelectComponent';
import InputField from '@/components/base/InputField';
import { mapActions, mapState, mapMutations } from 'vuex';
import { isVersionValid } from '@/utils/utils';

export default {
  name: 'DocManagementUpload',
  components: {
    DefaultLayout,
    Headings,
    LoadingComponent,
    Modal,
    DocManagementForm,
    SelectComponent,
    InputField,
  },
  data() {
    return {
      isModalOpen: false,
      isMissingFilesModalOpen: false,
      isUnpublishModalOpen: false,
      isDeleteWholeYearModalOpen: false,
      isLoading: false,
      isNewDocument: false,
      currentVersion: {},
      isMasterUpload: false,
      isPreviewModalOpen: false,
      isDownloadModalOpen: false,
      isMissingFilesModified: false,
      missingFiles: new Map(),
      isCopyToNewVersionModalOpen: false,
      newVersion: null,
      isVersionFormatValid: true,
    };
  },
  computed: {
    ...mapState({
      currentDocument: (state) => state.documents.current,
      versions: (state) => {
        const versions = state.search.versioning;
        versions.sort((a, b) => Number(a.version) - Number(b.version));
        versions.forEach((v) => (v.displayValue = v.version + ' ' + v.status));
        return versions;
      },
      versionsRaw: (state) => {
        return state.search.versioning.map((v) => v.version);
      },
      hasUploadedFile: (state) => state.documents.hasUploadedFile,
      selectedBookFiles: (state) => state.documents.selectedBooks,
      documentsNotProcessed: (state) =>
        !!(
          state.documents.selectedBooks.find((book) => book.status === 'PROCESSING') ||
          (state.documents.selectedFile && state.documents.selectedFile.status === 'PROCESSING')
        ),
      documentsErrorProcessing: (state) =>
        !!(
          state.documents.selectedBooks.find((book) => book.status === 'EXTRACTION_ERROR') ||
          (state.documents.selectedFile && state.documents.selectedFile.status === 'EXTRACTION_ERROR')
        ),
      rfcs: (state) => state.rfcs.data,
      ims: (state) => state.ims.data,
      selectedCs: (state) => state.documents.selectedCommonStructure,
      filteredBooksOnly: (state) => {
        if (state.documents.selectedCommonStructure.levels) {
          return state.documents.selectedCommonStructure.levels.filter((level) => level.isBook);
        } else {
          return [];
        }
      },
      selectedFile: (state) => state.documents.selectedFile,
      missingFilesOptions: (state) => state.documents.missingFilesOptions,
    }),
    buttonVisible() {
      return this.currentDocument.type === 'NS' || (this.currentDocument.type === 'CID' && !this.isMasterUpload);
    },
    isPublished() {
      return this.currentDocument.status === 'PUBLISHED';
    },
    canDeleteWholeYear() {
      return this.currentDocument?.year < new Date().getFullYear();
    },
    areFilesMissing() {
      for (let i = 1; i < this.filteredBooksOnly.length; i++) {
        const book = this.filteredBooksOnly[i];
        if (this.selectedBookFiles[i].status == 'NOT_FOUND') {
          this.missingFiles.set(book.nameEn, null);
        }
      }

      return this.missingFiles.size > 0;
    },
    newVersionError() {
      if (!this.isVersionFormatValid) {
        return this.$t(`documentManagement.versionError`);
      } else {
        return this.versionsRaw.includes(this.newVersion) ? this.$t('documentManagement.newVersionFieldError') : '';
      }
    },
    isCopyExistingFilesButtonDisabled() {
      this.isMissingFilesModified; // this is a workaround for unsupported reactivity of Maps in Vue 2
      for (let missingFile of this.missingFiles) {
        if (!missingFile[1]) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    currentDocument(newVal) {
      if (newVal && newVal.type === 'CID') {
        this.getAllRFCs({});
      } else {
        this.getAllIMs({});
      }
    },
    formData: {
      handler() {
        if (this.isSubmitted) this.validateForm();
      },
      deep: true,
    },
  },
  created() {
    this.isMasterUpload = this.$route.query?.isMaster;
    this.clearDocumentUploadedData();
    this.isLoading = true;
    this.checkIfNewDocument();
  },
  methods: {
    ...mapActions('rfcs', ['getAllRFCs']),
    ...mapActions('ims', ['getAllIMs']),
    ...mapActions('documents', [
      'fetchDocumentById',
      'publishFile',
      'fetchMissingFilesOptions',
      'duplicateExistingFileToDocument',
    ]),
    ...mapActions('search', ['getVersioning', 'prevDocument', 'downloadDocumentArgs']),
    ...mapMutations('documents', [
      'updateCurrentDocument',
      'setUploadType',
      'setCurrentDocument',
      'clearDocumentUploadedData',
    ]),
    saveFile(isMaster = false) {
      this.$refs.docManagementForm.saveFile(isMaster);
    },
    publishFile() {
      this.$refs.docManagementForm.publish();
    },
    confirmPublish() {
      this.publishFile();
      this.closeDialog();
    },
    confirmUnpublish() {
      this.$refs.docManagementForm.unpublish();
      this.closeUnpublishModal();
    },
    confirmDeleteWholeYear() {
      this.$refs.docManagementForm.deleteWholeYear();
      this.closeDeleteWholeYearModal();
    },
    confirmCopyToNewVersion() {
      this.$store.dispatch('documents/createDocumentCopy', {
        sourceDocumentId: this.currentVersion,
        newVersion: this.newVersion,
        cb: (data) => {
          this.closeCopyToNewVersionModal();
          this.$router.push({
            path: this.$route.path,
            query: { ...this.$route.query, id: data.id },
          });
        },
      });
    },
    async toggleModal() {
      if (this.currentDocument.type == 'CID' && !this.currentDocument.isMasterFile && this.areFilesMissing) {
        const promises = [];
        for (const missingFile of this.missingFiles) {
          promises.push(
            this.fetchMissingFilesOptions({
              rfcId: this.rfcs.find((rfc) => rfc.name == this.currentDocument.organizationName).id,
              csLevelName: missingFile[0],
            })
          );
        }
        await Promise.all(promises);
        this.toggleMissingFilesModal();
      } else {
        this.isModalOpen = !this.isModalOpen;
      }
    },
    toggleMissingFilesModal() {
      this.isMissingFilesModalOpen = !this.isMissingFilesModalOpen;
    },
    toggleUnpublishModal() {
      this.isUnpublishModalOpen = !this.isUnpublishModalOpen;
    },
    toggleDeleteWholeYearModal() {
      this.isDeleteWholeYearModalOpen = !this.isDeleteWholeYearModalOpen;
    },
    togglePreviewModal() {
      this.isPreviewModalOpen = !this.isPreviewModalOpen;
    },
    toggleDownloadModal() {
      this.isDownloadModalOpen = !this.isDownloadModalOpen;
    },
    openCopyToNewVersionModal() {
      this.isCopyToNewVersionModalOpen = true;
    },
    closeDialog() {
      this.toggleModal();
    },
    closeMissingFilesModal() {
      this.selectedExistingIpYear = null;
      this.toggleMissingFilesModal();
    },
    closeUnpublishModal() {
      this.toggleUnpublishModal();
    },
    closePreviewDialog() {
      this.togglePreviewModal();
    },
    closeDownloadDialog() {
      this.toggleDownloadModal();
    },
    closeDeleteWholeYearModal() {
      this.toggleDeleteWholeYearModal();
    },
    closeCopyToNewVersionModal() {
      this.isCopyToNewVersionModalOpen = false;
    },
    validateForm() {
      return this.$refs.docManagementForm.validateForm();
    },
    changeVersion(event) {
      this.isLoading = true;
      this.clearDocumentUploadedData();
      const newDoc = this.versions.find((v) => v.id === event);
      this.fetchDocumentById({
        documentId: newDoc.id,
        cb: () => {
          // this.currentVersion = doc.id;
          this.isLoading = false;
        },
        ecb: () => {
          this.isLoading = false;
          this.goBack();
        },
      });
    },
    createNewDocument() {
      const type = this.determineUploadType(this.$route.query.type);
      const document = {
        organizationName: '',
        type: type,
        year: '',
        version: '1.0', // Default
        status: 'Draft', // Default
      };
      this.isLoading = false;
      this.setCurrentDocument(document);
      this.setUploadType(type);
    },
    determineUploadType(type) {
      if (type === 'CID') {
        this.getAllRFCs({});
      } else if (type === 'NS') {
        this.getAllIMs({});
      } else {
        // invalid route - go back
        this.goBack();
      }
      return type;
    },
    goBack() {
      const searchObj = {
        name: 'DocManagement',
      };
      const search = this.$route.params.search;
      if (search) {
        searchObj.params = { search: search };
      }
      this.$router.push(searchObj);
    },
    checkIfNewDocument() {
      const id = this.$route.query.id;
      if (!id) {
        this.isNewDocument = true;
        this.createNewDocument();
      } else {
        this.isNewDocument = false;
        this.getVersioning({ documentId: id });
        this.fetchDocumentById({
          documentId: id,
          cb: (doc) => {
            this.currentVersion = doc.id;
            this.isMasterUpload = doc.isMasterFile;
            this.isLoading = false;
          },
          ecb: () => {
            this.isLoading = false;
            this.goBack();
          },
        });
      }
    },
    previewDocument() {
      if (this.currentDocument.type === 'CID') {
        this.togglePreviewModal();
      } else {
        this.goToPreviewDocument(0);
      }
    },
    downloadDocument() {
      if (this.currentDocument.type === 'CID') {
        this.toggleDownloadModal();
      } else {
        this.goToDownloadDocument(0);
      }
    },
    goToDownloadDocument(index) {
      this.isLoading = true;
      const csLevelId = this.filteredBooksOnly[index].id;
      this.downloadDocumentArgs({
        csLevelId: csLevelId,
        documentId: this.currentDocument.id,
        name:
          this.currentDocument.organizationName + '-' + this.currentDocument.year + '-' + this.currentDocument.version,
        cb: () => {
          this.isLoading = false;
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    goToPreviewDocument(index) {
      this.isLoading = true;
      const csLevelId = this.filteredBooksOnly[index].id;
      this.prevDocument({
        csLevelId: csLevelId,
        documentId: this.currentDocument.id,
        cb: (link) => {
          this.isLoading = false;
          window.open(link, '_blank');
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    copyExistingFile() {
      for (const missingFile of this.missingFiles) {
        this.duplicateExistingFileToDocument({
          rfcId: this.rfcs.find((rfc) => rfc.name == this.currentDocument.organizationName).id,
          csLevelId: this.filteredBooksOnly.find((book) => book.nameEn == missingFile[0]).id,
          newDocumentId: this.currentDocument.id,
          year: missingFile[1],
          cb: () => {
            this.isLoading = false;
            this.closeMissingFilesModal();
          },
          ecb: () => {
            this.isLoading = false;
          },
        });
      }
    },
    validateVersionNumber(event) {
      if (isVersionValid(event)) {
        this.isVersionFormatValid = true;
      } else {
        this.isVersionFormatValid = false;
      }
      this.newVersion = event;
    },
  },
};
</script>

<style scoped>
>>> .input-big select {
  height: 56px;
}

>>> .selection-bold select,
>>> .selection-bold input {
  font-family: Montserrat, sans-serif;
  font-size: 1.4em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #080808;
  height: 56px;
  background-color: #fff;
}

.modal-heading {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.modal-text {
  font-size: 14px;
  line-height: 1.29;
  font-weight: normal;
  text-align: left;
}

.modal-content {
  min-width: 550px !important;
}

.btn-doc-upload-action {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: 45px;
}

.header {
  width: 100%;
}

>>> .version-select-control {
  max-width: 367px;
  margin-left: auto;
  margin-right: 0;
}

>>> .version-select-control select {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #080808;
}

.cursor-blocked {
  cursor: not-allowed;
}

.preview-file-container {
  height: 56px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  text-align: center;
  border: 2px solid #4c8939;
  background-color: #f0ffeb;
  color: #4c8939;
  display: flex;
  padding-top: 1em;
  flex-direction: column;
  cursor: pointer;
}

.btn-greyed {
  background-color: #838683 !important;
  cursor: not-allowed !important;
}

button:disabled {
  background-color: #838683 !important;
}

.centered {
  justify-content: center;
}
</style>
