<template>
  <div class="vld-parent">
    <Loading :active="loading" :is-full-page="fullPage" />
  </div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'LoadingComponent',
  components: {
    Loading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fullPage: true,
    };
  },
};
</script>
