import { axiosInstance } from '../utils/axios.js';
import {
  GET_CS_URL,
  CREATE_CS_URL,
  GET_SINGLE_CS_URL,
  DELETE_SINGLE_CS_URL,
  CREATE_CS_LEVEL_URL,
  UPDATE_CS_LEVEL_URL,
  DELETE_CS_LEVEL_URL,
  MOVE_CS_LEVEL_URL,
  ADD_CS_TAG_URL,
  REMOVE_CS_TAG_URL,
} from '../utils/api.endpoints';

export const getCSRequest = function (type, cb, ecb) {
  axiosInstance
    .get(GET_CS_URL(type))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const createCSRequest = function (data, cb, ecb) {
  const { id = null, ...dataObj } = data;
  axiosInstance
    .post(CREATE_CS_URL(id), dataObj)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const getCSDetailsRequest = function (id, cb, ecb) {
  axiosInstance
    .get(GET_SINGLE_CS_URL(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const deleteCSRequest = function (id, cb, ecb = null) {
  axiosInstance
    .delete(DELETE_SINGLE_CS_URL(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const createCSLevelRequest = function (data, cb, ecb = null) {
  const id = data.id;
  const dataObj = {
    complianceWeight: 0,
    isBook: false,
    nameEn: 'New - empty',
    nameLocal: null,
    csNumber: null,
    ...data,
  };
  axiosInstance
    .post(CREATE_CS_LEVEL_URL(id), dataObj)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const updateCSLevelRequest = function (data, cb, ecb = null) {
  const { id, levelId, ...dataObj } = data;

  axiosInstance
    .put(UPDATE_CS_LEVEL_URL(id, levelId), dataObj)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const deleteCSLevelRequest = function (data, cb, ecb = null) {
  axiosInstance
    .delete(DELETE_CS_LEVEL_URL(data.id, data.levelId))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const moveCSLevelRequest = function (data, cb, ecb = null) {
  const parentId = data.parentId || '';
  axiosInstance
    .put(MOVE_CS_LEVEL_URL(data.csId, data.csLevelId, data.newPosition, parentId))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const addTagRequest = function (data, cb, ecb = null) {
  axiosInstance
    .put(ADD_CS_TAG_URL(data.id, data.levelId, data.tagId))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const removeTagRequest = function (data, cb, ecb = null) {
  axiosInstance
    .delete(REMOVE_CS_TAG_URL(data.id, data.levelId, data.tagId))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};
