<template>
  <div v-if="isModalOpen" class="modal">
    <div v-if="stillLoading" class="lds-ellipsis">
      <div />
      <div />
      <div />
      <div />
    </div>
    <div class="modal-content" :class="!stillLoading ? 'd-block' : 'd-none'">
      <div class="close_btn">
        <span class="close" @click="closeDialog">{{ closeBtn }}</span>
      </div>
      <p class="header">
        <slot name="header" />
      </p>
      <div class="body">
        <slot />
      </div>
      <div class="footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      stillLoading: true,
    };
  },
  computed: {
    closeBtn() {
      return '\xD7';
    },
  },
  beforeMount() {
    this.stillLoading = true;
  },
  created() {
    this.$nextTick(() => {
      this.stillLoading = false;
    });
  },
};
</script>

<style>
.header {
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}

.form-group label {
  font-size: 1.1em;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
}

input:not([id^='input-']) {
  font-size: 1.4em;
  font-family: Roboto, sans-serif;
}
</style>
