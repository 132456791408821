<template>
  <DefaultLayout>
    <LoadingComponent :loading="loading" />
    <Headings
      :title="$t('translation.manage_translations')"
      :subtitle="$t('translation.languages')"
      back-location="LangList"
    >
      <v-spacer class="text-center font-weight-bold pt-4 text-h6">{{ languageInfo.name }}</v-spacer>
      <v-btn
        color="secondary"
        class="my-auto no-caps"
        height="48"
        width="220"
        :disabled="!modified"
        @click="saveChanges"
      >
        {{ $t('translation.save_changes') }}
      </v-btn>
    </Headings>
    <v-alert text prominent dense type="error" icon="mdi-alert-decagram-outline">
      <div class="text--error font-weight-bold text-uppercase pb-1">{{ $t('translation.warning') }}</div>
      <div>{{ $t('translation.warning_text') }} &nbsp; <strong>&lt; &nbsp; &gt; &nbsp; { &nbsp; }</strong></div>
    </v-alert>
    <div class="d-flex pb-4">
      <v-text-field
        v-model.trim="search"
        :label="$t('common.search')"
        outlined
        dense
        class="flex-grow-0 mx-auto"
        hide-details
        clearable
      />
    </div>
    <v-data-table
      :items="flatArray"
      :headers="langHeaders"
      :search="search"
      hide-default-footer
      :items-per-page="-1"
      item-key="ident"
      class="translation-table"
    >
      <template #item.action="{ item }">
        <v-btn icon color="secondary" @click="startEditing(item)">
          <v-icon>mdi-lead-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!-- Edit translation -->
    <v-dialog v-model="dlgTranslate" width="auto">
      <v-form ref="frm" @submit.prevent="saveTranslation">
        <v-card width="600" max-width="1024">
          <v-card-title class="pt-2 pr-1">
            {{ $t('translation.headers.localized_text') }}
            <v-spacer />
            <v-btn icon @click="dlgTranslate = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pb-0">
            <div class="font-weight-bold pb-2">{{ currentTranslation.ident }}</div>
            <div class="grey lighten-3 rounded pa-2">{{ currentTranslation.english }}</div>
            <v-textarea
              ref="local"
              v-model.trim="currentTranslation.translated"
              class="mt-4"
              outlined
              dense
              :rules="[ruleRequired]"
            />
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-row justify="center" no-gutters>
              <v-btn color="primary" class="px-4 py-2 mr-3" type="submit">{{ $t('common.save') }}</v-btn>
              <v-btn outlined class="px-4 py-2 ml-3" @click="dlgTranslate = false">{{ $t('common.cancel') }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Unsaved changes -->
    <v-dialog v-model="showUnsavedWarning" persistent width="auto">
      <v-card class="d-flex flex-column">
        <v-card-title class="pa-2">
          <v-spacer />
          <v-btn icon @click="showUnsavedWarning = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-2 pb-3 flex-grow-1">
          <div class="pb-6 black--text" style="font-size: 30px">{{ $t('translation.confirm_page_leave') }}</div>
          <div class="black--text" style="font-size: 18px">{{ $t('translation.unsaved_changes') }}</div>
        </v-card-text>
        <v-card-actions class="px-6 py-5">
          <v-row justify="center" no-gutters>
            <v-btn color="primary" class="px-4 py-2 mr-3" @click="ignoreUnsaved">{{ $t('common.ok') }}</v-btn>
            <v-btn outlined class="px-4 py-2 ml-3" @click="showUnsavedWarning = false">{{ $t('common.cancel') }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout.vue';
import Headings from '@/components/base/Headings.vue';
import LoadingComponent from '@/components/base/LoadingComponent.vue';
import mixinValidations from '@/utils/mixinValidations.js';

const prefixes = [];

export default {
  name: 'TranslationsPage',
  components: {
    DefaultLayout,
    Headings,
    LoadingComponent,
  },
  mixins: [mixinValidations],
  beforeRouteLeave(to, from, next) {
    if (this.modified) {
      this.nextRoute = next;
      this.showUnsavedWarning = true;
    } else next();
  },
  data() {
    return {
      loading: false,
      search: '',
      flatArray: [],
      modified: false,
      languageInfo: {},
      dlgTranslate: false,
      currentTranslation: {},
      nextRoute: null,
      showUnsavedWarning: false,
    };
  },
  computed: {
    langID() {
      return this.$route.params.id;
    },
    langHeaders() {
      return [
        {
          text: this.$t('translation.headers.identifier'),
          value: 'ident',
        },
        {
          text: this.$t('translation.headers.actions'),
          value: 'action',
          align: 'center',
        },
        {
          text: this.$t('translation.headers.localized_text'),
          value: 'translated',
        },
        {
          text: this.$t('translation.headers.english_text'),
          value: 'english',
        },
      ];
    },
  },
  watch: {
    flatArray: {
      deep: true,
      handler() {
        this.modified = true;
      },
    },
  },
  created() {
    window.addEventListener('beforeunload', this.pageLeave);
    this.fetchData();
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.pageLeave);
  },
  methods: {
    pageLeave(evt) {
      if (this.modified) {
        const confirmationMsg = this.$t('translation.leave_page');
        evt.returnValue = confirmationMsg;
        return confirmationMsg;
      }
    },
    ignoreUnsaved() {
      this.showUnsavedWarning = false;
      if (this.nextRoute) this.nextRoute();
    },
    fetchData() {
      this.loading = true;
      Promise.all([
        this.$axios.get(`languages/${this.langID}`),
        this.$axios.get(`languages/${this.langID}/translations`),
      ])
        .then((responses) => {
          // language info
          this.languageInfo = responses[0];
          // translations
          prefixes.splice();
          const engList = {};
          buildFlatList(engList, this.$root.$i18n.messages.zz); // our fallback locale is ZZ because we want to allow overriding it through a dedicated EN locale
          prefixes.splice();
          const translations = {};
          buildFlatList(translations, responses[1]);
          this.flatArray = Object.entries(engList)
            .map(([key, val]) => ({
              ident: key,
              english: val,
              translated: translations[key],
            }))
            .sort((a, b) => (a.ident < b.ident ? -1 : a.ident > b.ident ? +1 : 0));
          this.$nextTick(() => {
            this.modified = false;
          });
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    startEditing(translationItem) {
      this.currentTranslation = Object.assign({}, translationItem);
      this.dlgTranslate = true;
      setTimeout(() => {
        this.$refs.frm.resetValidation();
        this.$refs.local.focus();
      }, 90);
    },
    saveTranslation() {
      if (this.$refs.frm.validate()) {
        const ident = this.currentTranslation.ident;
        const translate = this.flatArray.find((item) => item.ident === ident);
        translate.translated = this.currentTranslation.translated;
        this.dlgTranslate = false;
      }
    },
    saveChanges() {
      this.loading = true;
      const result = {};
      this.flatArray.forEach((row) => {
        let current = result;
        const segments = row.ident.split('.');
        do {
          const segment = segments.shift();
          if (segments.length > 0) {
            if (!current[segment]) current[segment] = {};
            current = current[segment];
          } else {
            current[segment] = row.translated;
            break;
          }
        } while (segments.length > 0);
      });
      this.$axios
        .patch(`languages/${this.langID}/translations`, result)
        .then(() => {
          this.modified = false;
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};

function buildFlatList(result, obj) {
  // flatten the hierarchical tree into a flat array
  switch (typeof obj) {
    case 'object':
      for (const key in obj) {
        prefixes.push(key);
        buildFlatList(result, obj[key]);
        prefixes.pop();
      }
      break;
    case 'string':
      result[prefixes.join('.')] = obj;
      break;
  }
}
</script>

<style>
.translation-table {
  overflow-y: auto !important;
}
</style>
