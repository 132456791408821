var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "col-8 search-link",
                      on: {
                        click: function ($event) {
                          _vm.gotoLoad && _vm.gotoLoad(_vm.item)
                        },
                      },
                    },
                    [
                      _c("v-row", { staticClass: "search-link-details" }, [
                        _vm.item.searchParameters.type == "NS"
                          ? _c("div", { staticClass: "document_type_ns" }, [
                              _c("img", { attrs: { src: _vm.nsSearch } }),
                            ])
                          : _c("div", { staticClass: "document_type_cid" }, [
                              _c("img", { attrs: { src: _vm.cidSearch } }),
                            ]),
                        _c("span", { staticClass: "text-card" }, [
                          _vm._v(" " + _vm._s(_vm.item.name) + " "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "col-4" }, [
                    _c(
                      "span",
                      { staticClass: "icon-right" },
                      [
                        _c(
                          "v-icon",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.openInfo(_vm.item)
                              },
                            },
                          },
                          [_vm._v("mdi-information-outline")]
                        ),
                        _c(
                          "v-icon",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(_vm.item)
                              },
                            },
                          },
                          [_vm._v("mdi-delete-outline")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }