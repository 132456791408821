<template>
  <v-navigation-drawer id="nav2" permanent mini-variant-width="80px" :mini-variant="true" :right="true" class="bg-f8">
    <v-list nav dense>
      <v-list-item
        v-for="(item, index) in userMenuIcons"
        :key="index"
        color="white"
        class="item_menu"
        @click="goTo(item.type)"
      >
        <v-list-item-icon :class="`menu-icon-container ${item.class}`">
          <img :src="item.icon" :title="item.title" />
        </v-list-item-icon>
        <v-list-item-title :style="item.titleStyle" class="items_title">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import nsSearch from '@/assets/icons/ns-search.svg';
import cidSearch from '@/assets/icons/cid-search.svg';
import { mapMutations } from 'vuex';

export default {
  name: 'SearchNavDrawer',
  data() {
    return {
      userMenuIcons: [
        {
          type: 'NS',
          icon: nsSearch,
          title: 'NS',
          class: 'green-icon',
        },
        {
          type: 'CID',
          icon: cidSearch,
          title: 'CID',
          class: 'gold-icon',
        },
      ],
    };
  },
  methods: {
    ...mapMutations('search', ['clearSearch']),
    goTo(type) {
      if (type) {
        // this.clearSearch();
        // this.$router.go(0);
        this.$router.push({
          name: 'SearchStep2',
          params: {
            type,
          },
        });
      }
    },
  },
};
</script>

<style>
.items_title {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #404040;
  width: 100%;
}

.v-list.v-sheet.v-sheet--tile.theme--light.v-list--dense.v-list--nav {
  padding: 0px;
}

.bottom-icons {
  position: absolute;
  bottom: 20%;
  width: 100%;
}

.bottom-nav {
  height: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #404040;
  margin-top: 20px;
}

.bottom-nav img {
  max-height: 80px;
  max-width: 80px;
  position: absolute;
  left: 8px;
  top: 13%;
}

.bottom-nav .v-list-item.theme--light {
  padding-left: 0px;
}

#user_nav_fname {
  color: #4c8939;
}

#user_nav_lname {
  color: #080808;
  font-weight: 600;
}

.material-icons {
  margin-left: 8px;
}

.bottom-nav-content-wrapper,
.bottom-nav-content-wrapper .v-list-item,
.bottom-nav-content-wrapper .v-list-item .v-list-item__icon {
  height: 100%;
}

#bottom_item {
  color: white;
  margin-left: 20px;
  font-size: 2.2em;
  overflow: visible;
}

.menu-icon-container {
  margin-right: 10px !important;
}
</style>
