import {
  getCSRequest,
  createCSRequest,
  getCSDetailsRequest,
  deleteCSRequest,
  createCSLevelRequest,
  updateCSLevelRequest,
  deleteCSLevelRequest,
  moveCSLevelRequest,
  addTagRequest,
  removeTagRequest,
} from '@/services/cs.service';
import order from '../utils/order';

// initial state
const state = () => ({
  ...order.state,
  data: [],
  type: '',
  current: {},
});

// getters
const getters = {
  structuresList: (state) => {
    if (state.current) {
      const data = state.current;
      if (!Object.hasOwnProperty.call(data, 'levels')) return [];
      const returnData = data?.levels.map((item, index) => {
        return {
          index,
          name: item.nameEn,
        };
      });
      return returnData;
    }
    return [];
  },
};

// actions
const actions = {
  ...order.actions,
  getAllCSs({ commit }, { type = 'NS', cb = null }) {
    getCSRequest(type, (rfcs) => {
      commit('setCSs', rfcs);
      if (cb) cb();
    });
  },
  createCS({ commit }, { data, cb = null, ecb = null }) {
    createCSRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleCS', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  getSingleCS({ commit }, { id, cb = null, ecb = null }) {
    getCSDetailsRequest(
      id,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleCS', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  deleteSingleCS({ commit }, { id, cb = null, ecb = null }) {
    deleteCSRequest(
      id,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setNull');
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  createCSLevel({ commit }, { data, cb = null, ecb = null }) {
    createCSLevelRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setNull');
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  updateCSLevel({ commit }, { data, cb = null, ecb = null }) {
    updateCSLevelRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setNull');
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  deleteCSLevel({ commit }, { data, cb = null, ecb = null }) {
    deleteCSLevelRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setNull', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  moveCSLevel({ commit }, { data, cb = null, ecb = null }) {
    moveCSLevelRequest(
      data,
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) {
          commit('setSingleCsLevels', res);
        }
        if (cb) cb(res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  addTagToLevel({ commit }, { data, cb = null, ecb = null }) {
    addTagRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleCS', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  removeTagToLevel({ commit }, { data, cb = null, ecb = null }) {
    removeTagRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleCS', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  setType({ commit }, type) {
    commit('setType', type);
  },
  getStructure({ state }, index) {
    return state.current[index];
  },
};

// mutations
const mutations = {
  ...order.mutations,
  setCSs(state, data) {
    state.data = data;
  },
  setNull() {},
  setSingleCS(state, data) {
    state.current = data;
  },
  setType(state, data) {
    state.type = data;
  },
  setSingleCsLevels(state, levels) {
    state.current.levels = [...levels];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
