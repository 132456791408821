var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass:
        "px-8 py-4 d-flex flex-column justify-space-between align-center background--text fill-height",
      attrs: { outlined: "" },
    },
    [
      _c(
        "div",
        { staticClass: "font-weight-bold d-flex align-center pb-2" },
        [
          _vm._t("header", function () {
            return [_vm._v(_vm._s(_vm.caption))]
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "black--text text-h4 font-weight-bold" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }