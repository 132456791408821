<template>
  <Modal v-if="search && search.presentation" :is-modal-open="isModalOpen" :close-dialog="closeDialogCheck">
    <LoadingComponent :loading.sync="isLoading" />
    <template #header>
      <div class="save-title">
        {{ $t(`savedSearches.saveTitle`) }}
      </div>
    </template>

    <div class="subtitle">
      {{ $t(`savedSearches.saveSubtitle`) }}
    </div>

    <label>
      {{ $t(`savedSearches.nameLabel`) }}
    </label>

    <div class="search_name">
      <div v-if="search.presentation.type === 'NS'" class="document_type_ns"><img :src="nsSearch" /></div>
      <div v-if="search.presentation.type === 'CID'" class="document_type_cid"><img :src="cidSearch" /></div>
      <InputField
        v-if="!isInfo"
        v-model="saveSearchName"
        :tabindex="1"
        :use-model="true"
        class="input-medium input-style"
      />
      <div v-else class="rename-box">
        <input ref="input" v-model="saveSearchName" class="input-medium input-style rename-input" />
        <button class="rename-button" @click="renameSavedSearch">Rename</button>
      </div>
    </div>

    <h2>{{ $t(`savedSearches.parameters`) }}</h2>

    <div v-if="search.presentation.type === 'NS'">
      <div v-if="search.presentation.ims" class="parameter_section">
        <div class="section_title">{{ $t(`common.infrastructureManagers`) }}</div>
        <ul class="list-inline">
          <li v-for="(im, index) in search.presentation.ims" :key="index">{{ im.name }}</li>
        </ul>
      </div>
    </div>

    <div v-if="search.presentation.type === 'CID'">
      <div v-if="search.presentation.rfcs" class="parameter_section">
        <div class="section_title">{{ $t(`common.railFreightCorridors`) }}</div>
        <ul class="list-inline">
          <li v-for="(rfc, index) in search.presentation.rfcs" :key="index">{{ rfc.name }}</li>
        </ul>
      </div>
    </div>

    {{ search.presentation.yearsSelected }}

    <div v-if="search.presentation.years" class="parameter_section">
      <div class="section_title">{{ $t(`savedSearches.ttYear`) }}</div>
      <ul class="list-inline">
        <li v-for="(year, index) in search.presentation.years" :key="index">{{ year }}</li>
      </ul>
    </div>

    {{ search.presentation.selectedChapters }}

    <div v-if="search.presentation.chapters" class="parameter_section">
      <div class="section_title">{{ $t(`savedSearches.includedChapters`) }}</div>
      <v-layout class="overflow-y-auto" style="max-height: 100px">
        <ul class="list-inline pl-2">
          <li v-for="(chapter, index) in search.presentation.chapters" :key="index">
            {{
              chapter.name ? chapter.name : chapter.csNumber ? `${chapter.csNumber} ${chapter.nameEn}` : chapter.nameEn
            }}
          </li>
        </ul>
      </v-layout>
    </div>
    <ConfirmModal
      :is-modal-open="isConfirmModalOpen"
      :close-dialog="closeConfirmModal"
      :confirm-callback="confirmCallback"
      :lable-text="confirmLableText"
      style="z-index: 20001"
    />

    <template #footer>
      <div v-if="!isInfo" class="d-flex">
        <div class="p-2">
          <button class="btn btn-green btn-medium" @click="closeDialog">
            {{ $t('common.cancel') }}
          </button>
        </div>
        <div class="ml-auto p-2">
          <button class="btn btn-green btn-medium" @click="saveSearchButton">
            {{ $t('savedSearches.save') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import InputField from '@/components/base/InputField';
import Modal from '@/components/base/Modal';
import LoadingComponent from '@/components/base/LoadingComponent';
import nsSearch from '@/assets/icons/ns-search.svg';
import cidSearch from '@/assets/icons/cid-search.svg';
import ConfirmModal from '../common/modals/ConfirmModal';

export default {
  name: 'ManageSearchModal',
  components: {
    InputField,
    Modal,
    LoadingComponent,
    ConfirmModal,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function,
      default: null,
    },
    search: {
      type: Object,
      default: null,
    },
    isInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nsSearch,
      cidSearch,
      isLoading: false,
      savedSearches: [],
      saveSearchName: null,
      isConfirmModalOpen: false,
      confirmLableText: '',
      confirmCallback: null,
    };
  },
  watch: {
    isModalOpen(visible) {
      if (visible) {
        if (this.isInfo) {
          this.saveSearchName = this.search.name;
        }
      }
    },
  },
  created() {
    const searchJson = localStorage.getItem('savedSearches');
    this.savedSearches = searchJson ? JSON.parse(searchJson) : [];
  },
  methods: {
    ...mapActions('structure', ['createCS']),
    ...mapActions('search', ['saveSearch', 'renameSearch']),
    saveSearchButton() {
      if (!this.saveSearchName) {
        this.$root.$emit('toast-error', 'Name is required');
        return;
      }
      if (this.saveSearchName.length > 50) {
        this.$root.$emit('toast-error', "Name can't be more then 50 characters");
        return;
      }
      const commonStructures = [];
      for (const csId of this.search.data.yearsSelected) {
        const selectedChapters = [];
        const selectedRoots = [];
        for (const id of this.search.data.selectedChapters[csId]) {
          const chapter = this.search.presentation.chapters.filter((e) => e.id === id)[0];
          if (!chapter) {
            continue;
          }
          selectedChapters.push({
            id: chapter.id,
            name: chapter.csNumber ? `${chapter.csNumber} ${chapter.nameEn}` : chapter.nameEn,
          });
          if (chapter.levels.length) {
            selectedRoots.push({ id: chapter.levels[0].id, name: chapter.levels[0].nameEn });
            if (this.search.presentation.type === 'CID') {
              selectedRoots.push({ id: chapter.levels[1].id, name: chapter.levels[1].nameEn });
            }
          }
        }
        commonStructures.push({
          csId: csId,
          selectedChapters: selectedChapters,
          selectedRoots: selectedRoots,
        });
      }
      const country = this.search.presentation.countries.filter((e) => e.id === this.search.data.selectedCountry)[0];
      const search = {
        name: this.saveSearchName,
        searchParameters: {
          type: this.search.presentation.type,
          imIds: this.search.presentation.ims.map((e) => {
            return {
              id: e.value,
              name: e.name,
            };
          }),
          rfcIds: this.search.presentation.rfcs.map((e) => {
            return {
              id: e.value,
              name: e.name,
            };
          }),
          countryIds: country ? [{ id: country.id, name: country.name }] : [],
          yearsSelected: this.search.presentation.years,
          query: this.search.data.text,
          includeAnnexes: this.search.data.includeAnnexes,
          commonStructures: commonStructures,
        },
      };
      this.saveSearch({
        data: search,
        cb: () => {
          this.isLoading = false;
        },
        ecb: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });

      this.closeDialog();
      this.saveSearchName = null;
      location.reload();
    },
    renameSavedSearch() {
      if (!this.saveSearchName) {
        this.$root.$emit('toast-error', 'Name is required');
        return;
      }
      if (this.saveSearchName.length > 50) {
        this.$root.$emit('toast-error', "Name can't be more then 50 characters");
        return;
      }
      this.openConfirmModal('Are you sure you want to rename search ' + this.search.name + '?', () => {
        this.renameSearch({
          searchId: this.search.data.id,
          data: { name: this.saveSearchName },
          cb: () => {
            this.isLoading = false;
          },
          ecb: (error) => {
            this.isLoading = false;
            console.log(error);
          },
        });
        this.isConfirmModalOpen = false;
      });
    },
    useSavedSearch(search) {
      this.selectedIMs = search.selectedIMs;
      this.selectedRFCs = search.selectedRFCs;
      this.selectedCountry = search.selectedCountry;
      this.selectedChapters = search.selectedChapters;
      this.includeAnnexes = search.includeAnnexes;
      this.useDownloadView = search.useDownloadView;
      this.organizationTypesArray = search.organizationTypesArray;
      this.text = search.text;
      this.organizationTypeModel = search.organizationTypeModel;
      this.chaptersLocal = search.chaptersLocal;
      this.yearsSelected = search.yearsSelected;
      this.iterationDone = search.iterationDone;
      this.filteredChapters = search.filteredChapters;
      // TODO: close
      this.gotoSearchResults();
    },
    closeDialogCheck() {
      if (this.search.name !== this.saveSearchName) {
        location.reload();
      }
      this.closeDialog();
    },
    openConfirmModal(text, callback) {
      this.confirmLableText = text;
      this.confirmCallback = callback;
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
  },
};
</script>

<style scoped>
.subtitle {
  font-size: 15px;
  margin-bottom: 2em;
  max-width: 600px;
}

label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.5em;
}

.search_name {
  position: relative;
}

.search_name .input-medium {
  padding-left: 46px;
}

.document_type_ns,
.document_type_cid {
  color: #fff;
  width: 37px;
  height: 37px;
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid;
}

.document_type_ns img,
.document_type_cid img {
  width: 37px;
  height: 37px;
  vertical-align: middle;
}

.document_type_ns {
  background: #265f14;
}

.document_type_cid {
  background: #bf873d;
}

h2 {
  font-weight: bold;
  font-size: 20px;
}

.parameter_section .section_title {
  font-size: 12px;
}

.parameter_section ul {
  padding: 0px;
  margin-bottom: 24px;
  max-width: 600px;
  line-height: 32px;
}

.parameter_section ul li {
  padding: 4px 12px;
  margin-right: 10px;
  background: #f0ffeb;
  border: 1px solid #265f14;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  white-space: nowrap;
}

.btn-green {
  font-size: 1em;
  margin-right: 0px;
}

.input-style {
  padding-left: 45px !important;
}

.rename-button {
  margin-left: -70px;
  color: #265f14;
  font-weight: 600;
  font-size: 12px;
}
</style>
