<template>
  <div>
    <LoadingComponent :loading="loading" />
    <div class="d-flex justify-center py-3">
      <DateInput v-model="fromDate" :label="$t('statistics.search_org.since_date')" outlined dense classes="mx-2" />
      <DateInput v-model="toDate" :label="$t('statistics.search_org.until_date')" outlined dense classes="mx-2" />
      <v-select
        v-model="chosenRFC"
        :items="rfcList"
        outlined
        dense
        class="flex-grow-0 mx-2"
        menu-props="offsetY"
        clearable
        multiple
      />
      <v-btn color="primary" class="mx-2" @click="makeSearch">{{ $t('common.search') }}</v-btn>
    </div>
    <!-- Chapter tree -->
    <v-tabs v-model="activeTab" center-active>
      <v-tab v-for="(year, index) in structureYears" :key="index" :href="`#tab-${year.id}`">
        <v-checkbox
          v-model="yearsSelected"
          class="mx-2"
          :value="year.id"
          color="#bf873d"
          @change="checkDeselectEvent($event, year.id)"
        />
        {{ year.year }}
      </v-tab>
      <v-tab-item v-for="(year, index) in structureYears" :key="index" :value="'tab-' + year.id">
        <v-row>
          <v-col cols="9">
            <v-treeview
              ref="structure"
              v-model="selectedChapters[year.id]"
              class="mt-5"
              :items="structureYears[index].levels"
              :open.sync="expandedNodes[year.id]"
              item-children="levels"
              item-key="id"
              item-text="nameLocal"
              selection-type="leaf"
              selectable
              dense
              multiple-active
              selected-color="#bf873d"
              @input="onSingleSelect($event, year.id)"
            >
              <template #label="{ item }">
                <span :id="item.id">
                  <template v-if="item.parentId && item.csNumber">{{ item.csNumber }}.</template>
                </span>
                {{ item.nameLocal || item.nameEn }}
              </template>
            </v-treeview>
          </v-col>
          <v-col cols="3" class="content-right select-deselect">
            <div class="pt-1 pr-2">
              <a class="text-gold" @click.prevent="selectAll(year.id)">
                {{ $t(`common.selectAll`) }}
              </a>
              <a class="text-gray" @click.prevent="clearAll(year.id)">
                {{ $t(`common.clearAll`) }}
              </a>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
    <!-- Results -->
    <div class="d-flex justify-center pa-4">
      <v-simple-table class="stripe tbl_stats">
        <thead>
          <tr>
            <th>{{ $t(searchType === 'NS' ? 'common.im' : 'common.rfc') }}</th>
            <th nowrap>{{ $t('statistics.search_org.tt_year') }}</th>
            <th>{{ $t('statistics.search_org.chapter') }}</th>
            <th nowrap>{{ $t('statistics.search_org.number_searches') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, idx) in results" :key="idx">
            <td>{{ row.organization || $t('statistics.search_org.all_selected') }}</td>
            <td nowrap>{{ row.year || $t('statistics.search_org.full_period') }}</td>
            <td>{{ row.chapter }}</td>
            <td style="text-align: center">{{ row.numberOfSearches }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import LoadingComponent from '@/components/base/LoadingComponent.vue';
import DateInput from '@/components/base/DateInput.vue';

export default {
  name: 'StatSearchOrg',
  components: {
    LoadingComponent,
    DateInput,
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.fetchData();
  },
  data() {
    return {
      loading: false,
      rfcIM: [],
      results: [],
      fromDate: '',
      toDate: '',
      chosenRFC: [],
      yearsSelected: [],
      selectedChapters: {},
      activeTab: null,
      expandedNodes: [],
      chaptersLocal: {},
      previouslySelected: {},
      structureYears: [],
    };
  },
  computed: {
    searchType() {
      return this.$route.params.type.toUpperCase();
    },
    rfcList() {
      return this.rfcIM
        .map(
          {
            NS: this.mapIMs,
            CID: this.mapRFCs,
          }[this.searchType]
        )
        .slice()
        .sort((a, b) => (a.text < b.text ? -1 : a.text > b.text ? +1 : 0));
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    mapIMs(elem) {
      return {
        value: elem.id,
        text: elem.name,
      };
    },
    mapRFCs(elem) {
      return {
        value: elem.id,
        text: elem.shortName,
      };
    },
    getLayers(obj, arr) {
      arr.forEach((item) => {
        obj[item.id] = item;
        this.getLayers(obj, item.levels);
      });
    },
    fetchData() {
      // get all RFCs
      this.loading = true;
      Promise.all([
        this.$axios.get(this.searchType === 'NS' ? 'infrastructure_managers' : 'rail_freight_corridors'),
        this.$axios.get('search/search-cs?type=' + this.searchType),
      ])
        .then((responses) => {
          this.rfcIM = responses[0];
          this.structureYears = responses[1];
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    makeSearch() {
      this.loading = true;
      const selected = {};
      Object.values(this.selectedChapters).forEach(
        (
          chapters,
          index // selected leaf IDs for each TreeView (one per each year)
        ) => {
          // TreeView provides in selectedChapters only the leaf nodes - but we need the selected parent(s) instead
          const nodes = this.$refs.structure[index].nodes; // the flattened nodes of the current TreeView
          chapters.forEach((chapterId) => {
            let node = nodes[chapterId];
            while (node.parent && nodes[node.parent].isSelected) {
              // keep climbing the hierarchy until we find the topmost selected parent
              chapterId = node.parent;
              node = nodes[node.parent];
            }
            selected[chapterId] = true;
          });
        }
      );
      this.$axios
        .post('statistics/searches/' + this.searchType.toLowerCase(), {
          sinceDate: this.fromDate || '2000-01-01',
          untilDate: this.toDate || '2100-01-01',
          organizationIds: this.chosenRFC,
          csLevelIds: Object.keys(selected),
        })
        .then((response) => {
          this.results = response;
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkDeselectEvent(event, yearId) {
      if (this.yearsSelected.indexOf(yearId) === -1) {
        // year has been deselected - invoke clear all
        this.clearAll(yearId);
      }
    },
    onSingleSelect(event, yearId) {
      // select parent year
      if (event.length && this.yearsSelected.indexOf(yearId) === -1) {
        this.yearsSelected.push(yearId);
      }
      if (!this.chaptersLocal[yearId]) {
        this.chaptersLocal[yearId] = { selectedChapters: [] };
      }
      this.chaptersLocal[yearId].selectedChapters = event;
      if (this.previouslySelected[yearId]) {
        this.previouslySelected = this.previouslySelected[yearId].filter((s) => !event.includes(s));
      }
    },
    selectAll(index) {
      this.clearAll(index);
      const yearIndex = this.structureYears.findIndex((o) => o.id === index);
      this.structureYears[yearIndex].levels.forEach((obj) => this.recursiveAdd(index, obj));
      this.previouslySelected[index] = [];
      this.$forceUpdate();
    },
    clearAll(index) {
      if (!this.chaptersLocal[index]) {
        this.chaptersLocal[index] = { selectedChapters: [] };
      }
      this.chaptersLocal[index].selectedChapters = [];
      if (!this.selectedChapters[index]) {
        this.selectedChapters[index] = [];
      }
      this.selectedChapters[index] = [];
      this.previouslySelected[index] = [];
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "since_date": "Since date",
      "until_date": "Until date",
      "tt_year": "TT year",
      "chapter": "Chapter",
      "number_searches": "Number of searches",
      "all_selected": "All selected",
      "full_period": "Full period"
    }
  }
</i18n>
