<template>
  <Modal :is-modal-open="isModalOpen" :close-dialog="closeDialog">
    <template #header>
      <LoadingComponent :loading.sync="isLoading" />
      <div class="modal-heading mb-10">
        {{ $t(`documentManagement.uploadMasterCIDandAnnexFile`) }}
      </div>
      <div class="modal-text">
        <v-row>
          <v-col cols="12">
            <VSelectComponent
              :options="years"
              :value="selectedYear"
              :name="$t('documentManagement.upload_master_cid.specify_cid')"
              obj-key="value"
              obj-val="value"
              prepend="mdi-calendar-blank-outline"
              class-style="select-big"
              @change="fetchCsLevelsForYear($event)"
            />
            <!--
            <select-component
              class="selection-bold input-big"
              :tabindex="2"
              :options="years"
              :value="selectedYear"
              name="Specify CID Master Timetable Year:"
              objKey="value"
              objVal="value"
              @input="fetchCsLevelsForYear($event)"
            />
            -->
          </v-col>
          <v-col v-if="!selectedMasterCID" cols="12">
            <input
              ref="fileMasterCID"
              type="file"
              accept=".docx"
              class="file-picker"
              @change="onFileSelected($event)"
            />
            <button class="btn-attach-file" @click="$refs.fileMasterCID.click()">
              <document-icon />
              {{ $t('documentManagement.upload_master_cid.attach_master_cid') }}
            </button>
          </v-col>
          <v-col v-if="selectedMasterCID" :cols="10">
            <button class="btn-attached-file">
              <done-icon />
              {{ $t('documentManagement.upload_master_cid.master_cid_attached') }}
            </button>
          </v-col>
          <v-col v-if="selectedMasterCID" class="remove-file-container" :cols="undefined">
            <label class="remove-file-label" @click="removeMasterCID">{{ $t(`common.remove`) }}</label>
          </v-col>
          <v-col v-if="!selectedMasterAnnex" cols="6">
            <input
              ref="fileMasterCIDAnnex"
              type="file"
              accept=".docx,.doc,.xls,.xlsx,.pptx,.ppt,.txt,.pdf,.png,.jpg,.jpeg"
              class="file-picker"
              @change="onAnnexFileSelected($event)"
            />
            <button class="btn-attach-file" @click="$refs.fileMasterCIDAnnex.click()">
              <document-icon />
              {{ $t('documentManagement.upload_master_cid.attach_master_annex') }}
            </button>
          </v-col>
          <v-col v-if="selectedMasterAnnex" :cols="selectedMasterAnnex ? 4 : 6">
            <button class="btn-attached-file">
              <img :src="doneIcon" />
              {{ $t('documentManagement.upload_master_cid.master_annex_attached') }}
            </button>
          </v-col>
          <v-col cols="6">
            <MultipleSelectComponent
              class="multiselect-medium annex-books"
              :tabindex="5"
              :value="selectedAnnexChapters"
              :options="levels || []"
              :placeholder="$t('documentManagement.annexPlaceholder')"
              obj-key="nameEn"
              obj-val="id"
              :disabled="!levels"
              @change="addOrRemoveChapter($event)"
            />
          </v-col>
          <v-col v-if="selectedMasterAnnex" class="remove-file-container" :cols="undefined">
            <label class="remove-file-label" @click="removeMasterCIDAnnex">{{ $t(`common.remove`) }}</label>
          </v-col>
        </v-row>
      </div>
    </template>
    <template #footer>
      <div class="row">
        <div class="col-2" />
        <div class="col-4">
          <button class="btn btn-green btn-big w-100 btn-upload-master-cid" @click="confirmMasterCIDUpload">
            {{ $t(`common.upload`) }}
          </button>
        </div>
        <div class="col-4">
          <button class="btn btn-white btn-big w-100 btn-cancel-upload-master-cid" @click="closeDialog">
            {{ $t(`common.close`) }}
          </button>
        </div>
        <div class="col-2" />
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Modal from '@/components/base/Modal';
import VSelectComponent from '@/components/base/VSelectComponent';
import MultipleSelectComponent from '@/components/base/MultipleSelectComponent';
import LoadingComponent from '@/components/base/LoadingComponent';
import documentIcon from '@/assets/icons/gold-icons/documentmanagement.svg?int';
import doneIcon from '@/assets/icons/done.svg?int';

export default {
  name: 'UploadMasterCID',
  components: {
    Modal,
    VSelectComponent,
    MultipleSelectComponent,
    LoadingComponent,
    documentIcon,
    doneIcon,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      selectedYear: null,
      selectedMasterCID: null,
      selectedMasterAnnex: null,
      selectedAnnexChapters: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      years: (state) => state.documents.years,
      csLevels: (state) => state.documents.selectedCommonStructure,
      levels: (state) => state.documents.selectedCommonStructure.levels,
      filteredBooksOnly: (state) => {
        if (state.documents.selectedCommonStructure.levels) {
          return state.documents.selectedCommonStructure.levels.filter((level) => level.isBook);
        } else {
          return [];
        }
      },
    }),
  },
  created() {
    if (!this.years) {
      this.getYears();
    }
  },
  methods: {
    ...mapActions('documents', [
      'downloadDocuments',
      'getYears',
      'fetchCommonStructureForDate',
      'saveMasterCIDAndAnnex',
    ]),
    ...mapMutations('documents', ['setUploadType']),
    prepareSaveData() {
      const booksData = [];
      this.filteredBooksOnly.forEach((book, index) => {
        const b = this.selectedBookFiles[index];
        if (b && this.isFile(b)) {
          booksData.push({
            csLevel: book.id,
            file: b,
          });
        }
      });

      const requestData = {
        type: this.currentDocument.type,
        isMaster: true,
      };

      requestData.booksData = booksData.length ? booksData : undefined;
      const annexFormData = [];
      this.annexes.forEach((annex) => {
        const formData = new FormData();
        formData.append('filename', annex.file);
        formData.append('csLevels', annex.chapters);
        formData.append('version', this.currentDocument.version);
        formData.append('documentNote', this.currentDocument.note);

        if (this.currentDocument.type === 'CID') {
          formData.append('rfcId', this.getRfcIdByName(this.currentDocument.organizationName));
        } else {
          formData.append('imId', this.getImIdByName(this.currentDocument.organizationName));
        }
        annexFormData.push(formData);
      });

      return {
        annexes: annexFormData,
        type: this.currentDocument.type,
        fileData: requestData,
      };
    },
    confirmMasterCIDUpload() {
      this.isLoading = true;
      this.isSubmitted = true;
      const data = this.prepareSaveData();
      this.saveFileAndAnnexes({
        annexes: data.annexes,
        updatedAnnexes: data.updatedAnnexes,
        type: data.type,
        fileData: data.fileData,
        cback: (responseData) => {
          this.removeAllBooks();
          this.isLoading = false;
          this.navigateToStatusPage(responseData);
        },
        ecb: () => {
          this.removeAllBooks();
          this.isLoading = false;
        },
      });
      /*
        this.downloadDocuments({ year: this.selectedYear });
        const csLevelId = this.csLevels.id;
        const formData = new FormData();
        formData.append("filename", this.selectedMasterCID);
        formData.append("csLevel", csLevelId);

        // eslint-disable-next-line no-unused-vars
        const requestData = {
          formData: formData
        };

        const annexFormData = new FormData();
        annexFormData.append("filename", this.selectedMasterAnnex);

        const chapters = this.selectedAnnexChapters.map(ch => ch.id);
        annexFormData.append("csLevels", chapters);

        this.setUploadType("CID");

        this.saveMasterCIDAndAnnex({
          annex: annexFormData,
          fileData: requestData,
          cb: responseData => {
            // this.isLoading = false;
            this.navigateToStatusPage(responseData);
          },
          ecb: () => {
            // this.isLoading = false;
          }
        });
        */
    },
    onFileSelected(event) {
      this.selectedMasterCID = event.target.files[0];
    },
    onAnnexFileSelected(event) {
      this.selectedMasterAnnex = event.target.files[0];
    },
    fetchCsLevelsForYear(year) {
      const requestData = {
        type: 'CID',
        year: year,
      };
      this.fetchCommonStructureForDate({ data: requestData });
    },
    removeMasterCIDAnnex() {
      this.selectedMasterAnnex = null;
    },
    removeMasterCID() {
      this.selectedMasterCID = null;
    },
    addOrRemoveChapter(chapters) {
      this.selectedAnnexChapters = chapters;
    },
    navigateToStatusPage(responseData) {
      this.$router.push({
        name: 'DocManagementUploadStatus',
        params: {
          data: responseData,
          note: '',
          type: 'CID',
          isMaster: true,
        },
      });
    },
  },
};
</script>

<style scoped>
.w-600px {
  width: 600px;
}

.btn-bottom-action {
  padding: 2px 15px;
  width: 100%;
}

>>> .select-label {
  height: 18px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
  text-align: left;
}

.btn-search {
  font-family: Montserrat, sans-serif;
  font-size: 1.4em;
  font-weight: 500;
  min-width: 140px;
  height: 56px;
  float: right;
}

.btn-search-container {
  margin-top: 2.4em;
}

>>> select {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #080808;
}

>>> .annex-books.multiselect-medium > .v-input > .v-input__control > .v-input__slot {
  font-size: 14px;
}

.remove-file-label {
  line-height: 50px;
  text-align: center;
  text-decoration: underline;
  font-size: 12px;
  color: #808080;
  cursor: pointer;
}

.remove-file-container {
  max-width: 100px;
}

>>> .modal-content {
  max-width: 600px;
}

.btn-upload-master-cid,
.btn-cancel-upload-master-cid {
  font-size: 18px;
  padding: 2px 5px;
}

>>> .mdi-calendar-blank-outline {
  color: green !important;
}

>>> .yearPicker .v-select__selections {
  padding-left: 10px;
  font-weight: 600;
}

.btn-attach-file,
.btn-attached-file {
  height: 56px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  text-align: center;
}
</style>
