<template>
  <DefaultLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <Headings :title="$t(`common.ImAndRfcManagement`)" :subtitle="$t(`rfcs.rfcManagement`)" />
    <div id="inputs" class="row justify-content-between">
      <div class="col-4">
        <SelectComponent
          id="im-rfc-select"
          v-model="managementType"
          class="user-list-input"
          :tabindex="2"
          :options="managementTypes"
          :name="$t(`common.manageType`)"
          obj-key="name"
          obj-val="value"
          @change="changeUrl"
        />
      </div>
      <div v-if="isAdmin" id="search-col" class="offset-6 col-2">
        <button class="btn btn-add-new-rfc" @click.prevent="open({ id: 'new' })">
          {{ $t(`rfcs.addNewRfc`) }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <LabelWithCount :title="$t(`rfcs.rfcManagement`)" :count="rfcs.length" />
      </div>
      <div class="col-12">
        <div class="table-rfcs">
          <table>
            <thead class="table-head-shadow">
              <tr>
                <th />
                <th v-for="(header, index) in headers" :key="index">
                  {{ header.name }}
                  <img
                    :id="header.id"
                    :class="sortDirection(header.id)"
                    :src="sortIcon(header.id)"
                    @click="sortBy(header.id)"
                  />
                </th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rfc, index) in rfcs" :key="index" class="table-clickable-row" @click="open(rfc)">
                <td>
                  <img class="logo-preview" :src="getLogo(rfc.id)" alt />
                </td>
                <td>{{ rfc.name }}</td>
                <td>{{ rfc.shortName }}</td>
                <td>{{ mapCountries(rfc.countryIds) }}</td>
                <!--
                <td class="col-actions">
                  <v-btn class="mx-2" fab dark small color="#DBA153" @click="open(rfc)">
                    <v-icon dark>arrow_forward</v-icon>
                  </v-btn>
                </td>
                -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import { RFCS_LOGO_URL } from '@/utils/api.endpoints';
import { mapState, mapActions } from 'vuex';
import { orderTypesEnum } from '@/utils/enums';
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import LabelWithCount from '@/components/base/LabelWithCount';
import SelectComponent from '@/components/base/SelectComponent';
import LoadingComponent from '@/components/base/LoadingComponent';

const notSortedIcon = require('@/assets/icons/grey-icons/arrow.png');
const sortedIcon = require('@/assets/icons/gold-icons/arrow.png');

export default {
  name: 'RFCsList',
  components: {
    DefaultLayout,
    LabelWithCount,
    SelectComponent,
    Headings,
    LoadingComponent,
  },
  data() {
    return {
      managementType: '/rfcs',
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      rfcs: (state) => {
        if (state.auth.current.roles === 'ADMIN') {
          return state.rfcs.data;
        } else {
          const rfcAccess = state.auth.current.railFreightCorridorOverviews.map((rfcAccess) => rfcAccess.id);
          return state.rfcs.data.filter((r) => rfcAccess.includes(r.id));
        }
      },
      countries: (state) => state.common.countries,
      totalItems: (state) => state.rfcs.totalItems,
      orderBy: (state) => state.rfcs.orderBy,
      orderType: (state) => state.rfcs.orderType,
      sortIcon: (state) => (id) => {
        return state.rfcs.orderBy === id ? sortedIcon : notSortedIcon;
      },
      sortDirection: (state) => (id) => {
        return state.rfcs.orderBy === id && state.rfcs.orderType === orderTypesEnum.ASC ? '' : 'reversed';
      },
      isAdmin: (state) => state.auth.current.roles === 'ADMIN',
    }),
    managementTypes() {
      if (this.$store.state.auth.current.roles === 'ADMIN') {
        return [
          {
            name: this.$t('rfcs.rfcManagement'),
            value: '/rfcs',
          },
          {
            name: this.$t('ims.imsManagement'),
            value: '/ims',
          },
        ];
      } else {
        const result = [];
        const ims = this.$store.state.auth.current.infrastructureManagerOverviews.length;
        const rfcs = this.$store.state.auth.current.railFreightCorridorOverviews.length;
        if (rfcs) {
          result.push({
            name: this.$t('rfcs.rfcManagement'),
            value: '/rfcs',
          });
        }
        if (ims) {
          result.push({
            name: this.$t('ims.imsManagement'),
            value: '/ims',
          });
        }
        return result;
      }
    },
    headers() {
      return [
        {
          name: this.$t('headers.name'),
          id: 'name',
        },
        {
          name: this.$t('headers.code'),
          id: 'shortName',
        },
        {
          name: this.$t('headers.countries'),
          id: 'countryIds',
        },
      ];
    },
  },
  created() {
    this.isLoading = true;
    this.getCountries({
      cb: () => {
        this.getAllRFCs({
          cb: () => {
            this.isLoading = false;
          },
          ecb: () => {
            this.isLoading = false;
          },
        });
      },
    });
  },
  methods: {
    ...mapActions('rfcs', ['getAllRFCs', 'getSingleRFCLogo', 'order']),
    ...mapActions('common', ['getCountries']),
    open(r) {
      this.$router.push({
        name: 'RFCsDetails',
        params: { id: r.id },
      });
    },
    mapCountries(countryIds) {
      let result = '';
      countryIds.forEach((countryId) => {
        const country = this.countries.find((c) => c.id === countryId);
        result += country ? country.name + ', ' : '';
      });
      return result.slice(0, -2);
    },
    getLogo(id) {
      return `${this.$serverUrl}${RFCS_LOGO_URL(id)}`;
    },
    sortBy(id) {
      this.order(id);
    },
    changeUrl(val) {
      this.$router.push(val);
    },
  },
};
</script>

<style>
#im-rfc-select select {
  color: #000;
}
</style>

<style scoped>
select {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 1.1em;
}

.btn-add-new-rfc {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 7px 5px;
  font-size: 1.4em;
  min-width: 140px;
  height: 42px;
  background-color: #265f14;
}
</style>
