var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("Headings", {
        staticClass: "d-inline-block",
        attrs: {
          title: _vm.$t(`common.documentManagement`),
          "back-location": "DocManagement",
          subtitle: _vm.$t(`documentAnalysis.title`),
        },
      }),
      _c("div", [
        _c("h2", [
          _vm._v(
            _vm._s(
              _vm.documentType +
                " / " +
                _vm.organizationName +
                " / " +
                _vm.year +
                " / " +
                _vm.version
            )
          ),
        ]),
        _c("h3", [_vm._v(_vm._s(_vm.$t(`status`) + ": " + _vm.status))]),
      ]),
      _c("v-treeview", {
        staticClass: "mt-5",
        attrs: {
          items: _vm.chapters,
          "item-children": "subchapters",
          "item-key": "chapterId",
          "item-text": "chapterTitle",
          "selected-color": "#bf873d",
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function ({ item }) {
              return [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "8" } }, [
                      item.status == "PROCESSING"
                        ? _c("img", {
                            staticClass: "processing-icon mb-2",
                            attrs: {
                              src: require("@/assets/icons/grey-icons/settings.svg"),
                              alt: _vm.$t(`documentAnalysis.processing`),
                              title: _vm.$t(`documentAnalysis.processing`),
                            },
                          })
                        : _vm._e(),
                      item.status == "SUCCESS"
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: item.previewUrl,
                                target: "_blank",
                                rel: "noopener noreferrer",
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "success-icon mb-2",
                                attrs: {
                                  src: require("@/assets/icons/show.svg"),
                                  alt: _vm.$t(`documentAnalysis.preview`),
                                  title: _vm.$t(`documentAnalysis.preview`),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      item.status == "ERROR"
                        ? _c("img", {
                            staticClass: "error-icon mb-2",
                            attrs: {
                              src: require("@/assets/icons/close_btn.svg"),
                              alt: item.details,
                              title: item.details,
                            },
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "chapter-analysis" }, [
                        _vm._v(" " + _vm._s(item.chapterTitle)),
                      ]),
                    ]),
                    item.status == "ERROR"
                      ? _c("v-col", { attrs: { cols: "4" } }, [
                          _c(
                            "a",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { href: _vm.mailtoUrlChapter(item) },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(`documentAnalysis.sendErrorViaEmail`)
                                )
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("div", [_c("h2", [_vm._v(_vm._s(_vm.$t(`common.annexes`)))])]),
      _vm._l(_vm.annexes, function (annex) {
        return _c(
          "v-row",
          { key: annex.annexId },
          [
            _c("v-col", { attrs: { cols: "8" } }, [
              annex.status == "PROCESSING"
                ? _c("img", {
                    staticClass: "processing-icon mb-2",
                    attrs: {
                      src: require("@/assets/icons/grey-icons/settings.svg"),
                      alt: _vm.$t(`documentAnalysis.processing`),
                      title: _vm.$t(`documentAnalysis.processing`),
                    },
                  })
                : _vm._e(),
              annex.status == "SUCCESS"
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: annex.previewUrl,
                        target: "_blank",
                        rel: "noopener noreferrer",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "success-icon mb-2",
                        attrs: {
                          src: require("@/assets/icons/show.svg"),
                          alt: _vm.$t(`documentAnalysis.preview`),
                          title: _vm.$t(`documentAnalysis.preview`),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              annex.status == "ERROR"
                ? _c("img", {
                    staticClass: "error-icon mb-2",
                    attrs: {
                      src: require("@/assets/icons/close_btn.svg"),
                      alt: annex.details,
                      title: annex.details,
                    },
                  })
                : _vm._e(),
              _c("span", { staticClass: "annex-analysis" }, [
                _vm._v(" " + _vm._s(annex.annexTitle)),
              ]),
            ]),
            annex.status == "ERROR"
              ? _c("v-col", { attrs: { cols: "4" } }, [
                  _c(
                    "a",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { href: _vm.mailtoUrlAnnex(annex) },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t(`documentAnalysis.sendErrorViaEmail`))
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }