var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("Headings", {
        staticClass: "d-inline-block",
        attrs: {
          title: _vm.$t(`users.usersManagementProfile`),
          "back-location": "UsersList",
          subtitle: _vm.username,
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "5" } },
            [
              _c("h2", { staticClass: "heading3" }, [
                _vm._v(_vm._s(_vm.$t(`users.userProfile`))),
              ]),
              _c(
                "v-card",
                {
                  staticClass: "mr-0 mb-3 pt-0 pb-0",
                  attrs: {
                    id: "user_data_card",
                    "max-width": "400",
                    elevation: "0",
                  },
                },
                [
                  _c("v-card-text", [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "v-list-item-avatar",
                            { staticClass: "details-avatar" },
                            [
                              _c("img", {
                                attrs: { src: require("@/assets/avatar1.png") },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 pt-0 mb-4",
                          attrs: { id: "user_name_label" },
                        },
                        [
                          _c("span", { attrs: { id: "user_name" } }, [
                            _vm._v(_vm._s(_vm.username)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 pt-0 pb-0",
                          attrs: { id: "user_country_label" },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t(`search.country`)) + " "),
                          _c("span", { attrs: { id: "user_country" } }, [
                            _vm._v(_vm._s(_vm.userCountry)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 pt-0 pb-0",
                          attrs: { id: "user_company_label" },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t(`users.company`)) + " "),
                          _c("span", { attrs: { id: "user_company" } }, [
                            _c("u", [_vm._v(_vm._s(_vm.userCompany))]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 pt-0 pb-6",
                          attrs: { id: "user_entity_label" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t(`common.typeOfEntity`)) + " "
                          ),
                          _c("span", { attrs: { id: "user_entity" } }, [
                            _vm._v(_vm._s(_vm.userEntity)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 pt-0",
                          attrs: { id: "user_email_label" },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t(`common.username`)) + " "),
                          _c("span", { attrs: { id: "user_email" } }, [
                            _c("u", [_vm._v(_vm._s(_vm.userEmail))]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("v-col", { attrs: { sm: "12" } }, [
                _c("p", { attrs: { id: "date_of_registration" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`users.registered`)) +
                      " " +
                      _vm._s(_vm.userRegistrationDate) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "6" } },
            [
              _c("h2", { staticClass: "heading3" }, [
                _vm._v(_vm._s(_vm.$t(`users.accessRights`))),
              ]),
              _c(
                "v-card",
                {
                  staticClass: "mr-0 mb-3 pt-0 pb-0",
                  attrs: {
                    id: "user_inputs_card",
                    "max-width": "500",
                    color: "#F8F8F8",
                    elevation: "0",
                  },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pl-0" },
                    [
                      _c(
                        "div",
                        { staticClass: "role_container" },
                        [
                          _c("SelectComponent", {
                            staticClass: "selection-bold",
                            attrs: {
                              tabindex: 1,
                              options: _vm.roleTypes,
                              placeholder: _vm.roleType,
                              name: _vm.$t(`users.roleType`),
                              "obj-key": "name",
                              "obj-val": "value",
                            },
                            model: {
                              value: _vm.roleType,
                              callback: function ($$v) {
                                _vm.roleType = $$v
                              },
                              expression: "roleType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "update_email_container" },
                        [
                          _c("InputField", {
                            staticClass: "selection-bold",
                            attrs: {
                              tabindex: 2,
                              name: _vm.$t(`users.email`),
                              value: _vm.updatedEmail,
                              "use-model": true,
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.updatedEmail = $event
                              },
                              update: function ($event) {
                                _vm.updatedEmail = $event
                              },
                            },
                          }),
                          _c("p", { attrs: { id: "update_email_feedback" } }, [
                            _vm._v(_vm._s(_vm.emailFeedback)),
                          ]),
                        ],
                        1
                      ),
                      _vm.roleType == "DOCUMENT_MANAGER"
                        ? [
                            _c(
                              "div",
                              { staticClass: "access_im_container" },
                              [
                                _c("MultipleSelectComponent", {
                                  staticClass: "multiselect-big",
                                  attrs: {
                                    tabindex: 3,
                                    options: _vm.imTypes,
                                    name: _vm.$t(`users.accessToIms`),
                                    "obj-key": "name",
                                    "obj-val": "value",
                                  },
                                  model: {
                                    value: _vm.accessIMModel,
                                    callback: function ($$v) {
                                      _vm.accessIMModel = $$v
                                    },
                                    expression: "accessIMModel",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "access_rfc_container" },
                              [
                                _c("MultipleSelectComponent", {
                                  staticClass: "multiselect-big",
                                  attrs: {
                                    tabindex: 4,
                                    options: _vm.rfcTypes,
                                    name: _vm.$t(`users.accessToRfcs`),
                                    "obj-key": "name",
                                    "obj-val": "value",
                                  },
                                  model: {
                                    value: _vm.accessRFCModel,
                                    callback: function ($$v) {
                                      _vm.accessRFCModel = $$v
                                    },
                                    expression: "accessRFCModel",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "access_additional_container" },
                              [
                                _c(
                                  "label",
                                  { attrs: { id: "access_additional_label" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(`users.additionalAccess`)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "access_additional_switch_container",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "switch_container mt-1" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            attrs: {
                                              id: "access_additional_txt",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    `users.giveAccessToMasterCID`
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "switch_options_container",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                class: {
                                                  "switch-selected":
                                                    _vm.masterUploadRight,
                                                },
                                                attrs: {
                                                  id: "yes_option_label",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .$t(`common.yes`)
                                                        .toUpperCase()
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "label",
                                              { staticClass: "switch" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.masterUploadRight,
                                                      expression:
                                                        "masterUploadRight",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.masterUploadRight
                                                    )
                                                      ? _vm._i(
                                                          _vm.masterUploadRight,
                                                          null
                                                        ) > -1
                                                      : _vm.masterUploadRight,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.getAdditionalAccessStatus,
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.masterUploadRight,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.masterUploadRight =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.masterUploadRight =
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                        }
                                                      } else {
                                                        _vm.masterUploadRight =
                                                          $$c
                                                      }
                                                    },
                                                  },
                                                }),
                                                _c("span", {
                                                  staticClass: "slider round",
                                                  class: {
                                                    active:
                                                      _vm.masterUploadRight,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                class: {
                                                  "switch-selected":
                                                    !_vm.masterUploadRight,
                                                },
                                                attrs: {
                                                  id: "no_option_label",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .$t(`common.no`)
                                                        .toUpperCase()
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { sm: "12", md: "7" } }, [
            _c("div", { staticClass: "btns-container" }, [
              _c(
                "button",
                {
                  staticClass: "back_user_btn",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goBack.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`common.back`)) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "save_btn",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.updateUser.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`common.save`)) + " ")]
              ),
              _c(
                "button",
                { staticClass: "set_pass_btn", on: { click: _vm.openDialog } },
                [_vm._v(" " + _vm._s(_vm.$t(`users.setUserPassword`)) + " ")]
              ),
              _c(
                "button",
                { staticClass: "delete_btn", on: { click: _vm.deleteUser } },
                [_vm._v(" " + _vm._s(_vm.$t(`users.deleteUser`)) + " ")]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("ChangeUserPassword", {
        attrs: {
          "is-modal-open": _vm.isModalOpen,
          "close-dialog": _vm.closeDialog,
          "user-id": _vm.userID,
        },
      }),
      _c("ConfirmModal", {
        staticStyle: { "z-index": "20001" },
        attrs: {
          "is-modal-open": _vm.isConfirmModalOpen,
          "close-dialog": _vm.closeConfirmModal,
          "confirm-callback": _vm.confirmCallback,
          "lable-text": _vm.confirmLableText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }