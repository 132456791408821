var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultLayout", [
    _c(
      "div",
      { staticClass: "fill-height d-flex" },
      [
        _c(
          "v-card",
          { staticClass: "ma-auto", attrs: { outlined: "" } },
          [
            _c("v-card-title", [
              _vm._v(" " + _vm._s(_vm.$t("search.docNotFound.errorMsg")) + " "),
            ]),
            _c(
              "v-card-actions",
              { staticClass: "justify-center text-h6" },
              [
                _c("router-link", { attrs: { to: { name: "Search" } } }, [
                  _vm._v(_vm._s(_vm.$t("search.docNotFound.linkTitle"))),
                ]),
                _c("span", { staticClass: "px-1" }),
                _vm._v(_vm._s(_vm.$t("search.docNotFound.restText")) + " "),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }