<template>
  <AuthLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <Logo>
      <v-spacer />
      <LanguageSelector />
    </Logo>
    <v-row>
      <v-col cols="12">
        <p>{{ $t(`resetPassword.text`) }}</p>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation class="reset_form">
      <v-row>
        <v-col cols="12">
          <InputField
            :name="$t(`resetPassword.passwordRecoveryCode`)"
            :tabindex="1"
            :value.sync="confirmationKey"
            :use-model="true"
            type="text"
            required
            @update="confirmationKey = $event"
          />
        </v-col>
        <v-col cols="12">
          <InputField
            :name="$t(`common.username`)"
            :tabindex="2"
            :value.sync="email"
            :use-model="true"
            type="text"
            :readonly="true"
            :is-disabled="true"
          />
        </v-col>
        <v-col cols="12">
          <InputField
            :name="$t(`common.newPassword`)"
            :tabindex="3"
            :value.sync="newPasswordModel"
            :use-model="true"
            type="password"
            required
            @update="newPasswordModel = $event"
          />
        </v-col>
        <v-col cols="12">
          <InputField
            :name="$t(`common.confirmPassword`)"
            :tabindex="4"
            :value.sync="confirmNewPasswordModel"
            :use-model="true"
            type="password"
            required
            @update="confirmNewPasswordModel = $event"
          />
        </v-col>
        <v-col v-if="feedback" cols="12">
          <p id="feedback">{{ feedback }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <button class="btn btn-green" @click.prevent="submit">
            {{ $t(`common.submit`) }}
          </button>
        </v-col>
        <v-col cols="6">
          <router-link to="/">
            <button class="btn btn-grey">{{ $t(`common.back`) }}</button>
          </router-link>
        </v-col>
      </v-row>
    </v-form>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layout/AuthLayout';
import Logo from '@/components/layout/Logo';
import InputField from '@/components/base/InputField';
import LoadingComponent from '@/components/base/LoadingComponent';
import LanguageSelector from '@/components/layout/LanguageSelector.vue';
import { mapActions } from 'vuex';

export default {
  name: 'ResetPassword',
  components: {
    AuthLayout,
    Logo,
    InputField,
    LoadingComponent,
    LanguageSelector,
  },
  data() {
    return {
      confirmationKey: null,
      newPasswordModel: null,
      confirmNewPasswordModel: null,
      feedback: null,
      email: null,
      isLoading: false,
    };
  },
  created() {
    const email = this.$route.params.email;
    if (!email) {
      // TODO: redirect
    }
    this.email = email;
  },
  methods: {
    ...mapActions('auth', ['setNewPasswordRequest']),
    doPasswordsMatch(pass, confirmPass) {
      if (pass !== confirmPass) this.feedback = this.$t(`common.passwordError`);
      else this.feedback = null;
    },
    submit() {
      if (this.newPasswordModel && this.confirmNewPasswordModel) {
        this.isLoading = true;

        const userCredentials = {
          email: this.email,
          newPassword: this.newPasswordModel,
          resetPasswordCode: this.confirmationKey,
        };

        this.setNewPasswordRequest({
          data: userCredentials,
          cb: () => {
            this.isLoading = false;
            this.$router.push({
              name: 'Login',
              params: {
                isPassReset: true,
                message: this.$t(`common.passwordSuccess`),
              },
            });
          },
          ecb: (err) => {
            this.isLoading = false;
            this.$router.push({
              name: 'ResetPasswordMessage',
              params: {
                isPassReset: true,
                message: err,
              },
            });
          },
        });
      } else {
        this.feedback = this.$t(`common.missingDataError`);
      }
    },
  },
};
</script>

<style scoped>
input[readonly] {
  cursor: not-allowed;
  background-color: #f3f3f3;
}
</style>
