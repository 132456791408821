import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import { axiosInstance } from './utils/axios';

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.prototype.$serverUrl = process.env.VUE_APP_SERVER_URL;
Vuex.Store.prototype.$serverUrl = process.env.VUE_APP_SERVER_URL;
Vue.prototype.$debug = window.localStorage.getItem('debug') === '1';
Vue.prototype.$axios = axiosInstance;

new Vue({
  name: 'Root',
  i18n,
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
