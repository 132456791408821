var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Modal", {
    attrs: {
      "is-modal-open": _vm.isModalOpen,
      "close-dialog": _vm.closeDialog,
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-heading mb-10 text-left" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t(`documentManagement.downloadMasterCID`)) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "modal-text" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "10", "offset-md": "1" } },
                      [
                        _c("SelectComponent", {
                          staticClass: "select-big",
                          attrs: {
                            tabindex: 2,
                            options: _vm.yearsDisplay,
                            name: _vm.$t("common.selectYearCID"),
                            "obj-key": "value",
                            "obj-val": "value",
                          },
                          model: {
                            value: _vm.selectedYear,
                            callback: function ($$v) {
                              _vm.selectedYear = $$v
                            },
                            expression: "selectedYear",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("v-row", [
              _c("div", { staticClass: "col-1" }),
              _c("div", { staticClass: "col-10" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-green btn-big w-100 btn-upload-master-cid",
                    on: { click: _vm.confirmMasterCIDUpload },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("documentManagement.downloadMasterCID")) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }