<template>
  <AuthLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <v-container class="login_container">
      <Logo>
        <v-spacer />
        <LanguageSelector />
      </Logo>
      <InputField
        ref="user"
        name="common.username"
        :value.sync="username"
        :use-model="true"
        :error="errors.error('username')"
        @update="username = $event"
      />
      {{ errors['username'] }}
      <InputField
        name="common.password"
        :value.sync="password"
        :use-model="true"
        :type="showPass ? 'text' : 'password'"
        :error="errors.error('password')"
        class="mb-0"
        @update="password = $event"
      />
      <p id="forgotten_pass_label" @click="forgottenPassword">
        {{ $t('common.forgottenPassword') }}
      </p>
      <div class="form-group mb-5">
        <form action="?" method="POST" class="captcha">
          <div id="recaptcha-main" class="g-recaptcha" data-sitekey="6LcFS98ZAAAAAPp1m2QPlxd07FqDGYni1dSMyzJf" />
          <span v-if="errors.error('formData.recaptcha')" class="form-error">
            {{ errors.error('formData.recaptcha') }}
          </span>
        </form>
      </div>
      <div class="form-group">
        <button type="button" class="btn-login" @click.prevent="logInUser">
          {{ $t('common.login') }}
        </button>
        <router-link class="btn-register" to="/register">{{ $t('common.register') }}</router-link>
        <span v-if="errors.error('generic')" class="form-error generic">
          {{ errors.error('generic') }}
        </span>
      </div>
      <div class="form-group">
        <router-link class="btn-back" to="/">{{ $t('common.back') }}</router-link>
      </div>
    </v-container>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layout/AuthLayout';
import InputField from '@/components/base/InputField';
import Logo from '@/components/layout/Logo';
import LoadingComponent from '@/components/base/LoadingComponent';
import LanguageSelector from '@/components/layout/LanguageSelector.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  components: {
    AuthLayout,
    Logo,
    InputField,
    LoadingComponent,
    LanguageSelector,
  },
  data() {
    return {
      username: null,
      password: null,
      showPass: false,
      errors: [],
      isLoading: false,
    };
  },
  created() {
    this.$nextTick(() => {
      // eslint-disable-next-line no-undef
      grecaptcha.render('recaptcha-main');
    });
  },
  mounted() {
    this.$refs.user.$refs.input.focus();
  },
  methods: {
    ...mapActions('auth', ['logInRequest']),
    forgottenPassword() {
      this.$router.push({ name: 'ResetPasswordMessage' });
    },
    validateRecaptcha() {
      // eslint-disable-next-line no-undef
      const v = grecaptcha.getResponse();
      if (v.length > 0) return true;
      this.errors.addError(`formData.recaptcha`, this.$t(`common.captchaError`));
      return false;
    },
    logInUser() {
      this.errors = [];
      this.validateRecaptcha();
      if (!this.password) {
        this.errors.addError('password', this.$t(`common.missingDataError`));
      }
      if (!this.username) {
        this.errors.addError('username', this.$t(`common.missingDataError`));
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.username)) {
        this.errors.addError('username', this.$t(`common.emailError`));
      }
      if (this.errors.length > 0) return;

      if (this.username && this.password) {
        const userCredentials = {
          password: this.password,
          username: this.username,
        };

        this.isLoading = true;
        this.logInRequest({
          data: userCredentials,
          cb: (data) => {
            this.isLoading = false;
            const hasError = Object.prototype.hasOwnProperty.call(data, 'error');
            if (hasError) {
              this.errors.addError('generic', data.message);
              return;
            }
            this.setItem('sessionID', data.sessionId);
            this.removeItem('anonymous');
            this.removeItem('organizationTypeModel');
            this.$router.push(
              this.$route.query.redirect || {
                name: 'Search',
              }
            );
          },
          ecb: (err) => {
            this.isLoading = false;
            this.errors.addError('generic', err);
          },
        });
      } else {
        this.errors.addError('password', this.$t(`common.missingDataError`));
      }
    },
    setItem(prop, value) {
      window.localStorage.setItem(prop, value);
    },
    removeItem(prop) {
      window.localStorage.removeItem(prop);
    },
  },
};
</script>

<style>
label:not(.v-label) {
  font-size: 1.3em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

input:not([id^='input-']) {
  font-family: Roboto, sans-serif;
  font-size: 1.6em;
}
</style>

<style scoped>
#forgotten_pass_label {
  text-decoration: underline;
  text-align: right;
  margin-top: 1em;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
}

.btn-login,
.btn-login:hover,
.btn-login:focus {
  background-color: #265f14;
  color: white;
  text-align: center;
  border-radius: 8px;
  border: unset;
  outline: unset;
  padding: 12px 50px;
  height: 50px;
  font-size: 1.6em;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.g-recaptcha {
  width: 100%;
  transform-origin: 0 0;
}

.btn-register {
  color: #404040;
  text-decoration: underline;
  padding: 10px 10px;
  margin-left: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 1.4em;
  font-weight: 500;
}

.btn-back {
  margin-top: 80px;
  display: block;
  float: right;
  padding: 15px 28px;
  background: #e3e3e3;
  border-radius: 8px;
  color: #404040;
  font-size: 1.2em;
}

input[readonly]:not([id^='input-']) {
  cursor: not-allowed;
  background-color: #f3f3f3;
}
</style>
