var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "admin-content" }, [
    !_vm.anonymous
      ? _c(
          "div",
          { class: `navbar-col ${_vm.currentRoute}`, attrs: { id: "nav-col" } },
          [_c("UserNavDrawer")],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { class: `content-col ${_vm.currentRoute}` },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }