<template>
  <DefaultLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <v-row class="profile-container">
      <v-col cols="7" class="top-info-container">
        <v-row class="border-shadow pb-4">
          <v-col cols="12" sm="3" class="name-photo">
            <div class="name-photo-container">
              <span>{{ formattedFirstLettersOfName }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="9" class="name-role profile-page">
            <div class="name-role-container">
              <div class="name-container">
                <span class="first-name">
                  {{ profile ? profile.firstName : '' }}
                </span>
                <span class="last-name ml-2">
                  {{ profile ? profile.familyName : '' }}
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" class="mt-3">
                <div class="user-profile-title mb-3">
                  {{ $t(`users.userProfile`) }}
                </div>
                <v-form ref="formChangePassword" lazy-validation novalidate="true">
                  <v-row v-if="profile">
                    <v-col cols="12" sm="12" md="6">
                      <InputField
                        :name="$t(`common.firstName`)"
                        :tabindex="1"
                        :value.sync="profile.firstName"
                        :use-model="true"
                        class="font-weight-bold"
                        @update="userFirstName = $event"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <InputField
                        :name="$t(`common.lastName`)"
                        :tabindex="2"
                        :value.sync="profile.familyName"
                        :use-model="true"
                        class="font-weight-bold"
                        @update="userLastName = $event"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <InputField
                        :name="$t(`common.username`)"
                        :tabindex="3"
                        :value.sync="profile.email"
                        :use-model="true"
                        class="font-weight-bold"
                        @update="userEmail = $event"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <SelectComponent
                        v-model="countryID"
                        class="selection-bold"
                        :tabindex="4"
                        :options="countries"
                        :name="$t(`search.country`)"
                        obj-key="name"
                        obj-val="value"
                        @change="countryID = $event"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <!--
                      <select-component
                        class="selection-bold"
                        :tabindex="5"
                        :options="[...ims, ...rfcs]"
                        :name="$t(`common.companyName`)"
                        v-model="userCompany"
                        @input="userCompany = $event"
                        objKey="name"
                        objVal="name"
                      />
                      -->
                      <InputField
                        :name="$t(`common.companyName`)"
                        :value.sync="userCompany"
                        :use-model="true"
                        class="selection-bold"
                        @update="userCompany = $event"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <SelectComponent
                        v-model="userEntity"
                        class="selection-bold"
                        :tabindex="6"
                        :options="entities"
                        :name="$t(`common.typeOfEntity`)"
                        obj-key="name"
                        obj-val="value"
                        @change="userEntity = $event"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-3">
                <div class="user-profile-title mb-3">
                  {{ $t(`users.changePassword`) }}
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <InputField
                  :name="$t(`users.oldPassword`)"
                  :tabindex="7"
                  :value.sync="oldPassword"
                  :use-model="true"
                  class="font-weight-bold"
                  type="password"
                  @update="oldPassword = $event"
                />
              </v-col>
              <v-col cols="12" sm="0" md="6" />
              <v-col cols="12" sm="12" md="6">
                <InputField
                  :name="$t(`users.newPassword`)"
                  :tabindex="8"
                  :value.sync="password"
                  :use-model="true"
                  class="font-weight-bold"
                  type="password"
                  @update="password = $event"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <InputField
                  :name="$t(`users.confirmNewPassword`)"
                  :tabindex="9"
                  :value.sync="confirmPassword"
                  :use-model="true"
                  class="font-weight-bold"
                  type="password"
                  @update="confirmPassword = $event"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <div class="user-profile-title mb-3">
                  {{ $t(`users.deleteProfile`) }}
                </div>
                <button class="btn btn-medium btn-white" @click.prevent="deleteConfirm">
                  {{ $t(`users.deleteMyProfile`) }}
                </button>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <button class="btn btn-green" @click.prevent="updateUser">
                  {{ $t(`common.save`) }}
                </button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5" class="subscribe-sidebar">
        <div class="subscribe-wrapper">
          <div class="user-profile-title mb-3">
            {{ $t(`users.notificationSettings`) }}
          </div>
          <div class="access_additional_switch_container">
            <div class="switch_container mt-1">
              <v-row>
                <v-col cols="12" md="7" class="p-0">
                  <p id="access_additional_txt" class="mb-0">
                    {{ $t(`users.receiveNotifications`) }}
                  </p>
                </v-col>
                <v-col cols="12" md="5" class="switch-container p-0">
                  <div class="switch_options_container">
                    <p id="yes_option_label" :class="{ 'switch-selected': notificationsEnabled }">
                      {{ $t(`common.yes`).toUpperCase() }}
                    </p>
                    <label class="switch">
                      <input
                        v-model="notificationsEnabled"
                        type="checkbox"
                        :class="{ checked: notificationsEnabled }"
                      />
                      <span class="slider round" />
                    </label>
                    <p id="no_option_label" :class="{ 'switch-selected': !notificationsEnabled }">
                      {{ $t(`common.no`).toUpperCase() }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div class="switch_container mt-1">
              <v-row>
                <v-col cols="12" md="7" class="p-0">
                  <p id="access_additional_txt" class="mb-0">
                    {{ $t(`users.receiveInformationalEmails`) }}
                  </p>
                </v-col>
                <v-col cols="12" md="5" class="switch-container p-0">
                  <div class="switch_options_container">
                    <p id="yes_option_label" :class="{ 'switch-selected': subscribedNewsletter }">
                      {{ $t(`common.yes`).toUpperCase() }}
                    </p>
                    <label class="switch">
                      <input
                        v-model="subscribedNewsletter"
                        type="checkbox"
                        :class="{ checked: subscribedNewsletter }"
                      />
                      <span class="slider round" />
                    </label>
                    <p id="no_option_label" :class="{ 'switch-selected': !subscribedNewsletter }">
                      {{ $t(`common.no`).toUpperCase() }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-row>
            <v-col>
              <div class="user-profile-title mt-5 mb-0">
                {{ $t(`users.manageSubscriptions`) }}
              </div>
            </v-col>
            <v-col cols="12">
              <button class="btn btn-green" @click.prevent="isModalOpen = true">
                {{ $t(`users.manageSubscriptions`) }}
              </button>
            </v-col>
            <v-col cols="12">
              <span class="regular-text">{{ $t(`users.youHave`) }}</span>
              <span class="bold-text pl-1">{{ $t(`users.subscribedTo`) }}</span>
              <span class="gold-text pl-1">
                {{ subscribedIMsCount() || 0 }}
              </span>
              <span class="bold-text pl-1">{{ $t(`common.nss`) }}</span>
              <span class="regular-text pl-1">{{ $t(`common.and`) }}</span>
              <span class="gold-text pl-1">
                {{ subscribedRFCsCount() || 0 }}
              </span>
              <span class="bold-text pl-1">{{ $t(`common.cids`) }}</span>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <Modal :is-modal-open="deleteModalVisible" :close-dialog="toggleDeleteModal">
      <template #header>
        <div class="modal-heading mb-10">
          {{ $t('profile.really_delete') }}
        </div>
      </template>
      <template #footer>
        <div class="row">
          <div class="col-3" />
          <div class="col-3">
            <button class="modal-button-cancel" @click="confirmDelete">
              {{ $t(`common.yes`) }}
            </button>
          </div>
          <div class="col-3">
            <button class="modal-button-confirm" @click="toggleDeleteModal">
              {{ $t(`common.no`) }}
            </button>
          </div>
          <div class="col-3" />
        </div>
      </template>
    </Modal>

    <Modal :is-modal-open="deleteModalDoneVisible" :close-dialog="toggleDeleteModalDone">
      <template #header>
        <div class="modal-heading mb-10">{{ deleteStatus }}</div>
      </template>
      <template #footer>
        <div class="row">
          <div class="col-12">
            <button class="modal-button-cancel" @click="goToParentPage">
              {{ $t(`common.ok`) }}
            </button>
          </div>
        </div>
      </template>
    </Modal>

    <ManageSubscriptions :is-modal-open="isModalOpen" :close-dialog="closeDialog" />
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout';
import LoadingComponent from '@/components/base/LoadingComponent';
import InputField from '@/components/base/InputField';
import SelectComponent from '@/components/base/SelectComponent';
import ManageSubscriptions from '@/components/users/ManageSubscriptions';
import Modal from '@/components/base/Modal';
import { mapActions, mapState, mapGetters } from 'vuex';
import mixinValitations from '@/utils/mixinValidations';
import { isPasswordValid, isEmailValid } from '@/utils/utils.js';

export default {
  name: 'PersonalProfile',
  components: {
    DefaultLayout,
    LoadingComponent,
    InputField,
    SelectComponent,
    ManageSubscriptions,
    Modal,
  },
  mixins: [mixinValitations],
  data() {
    return {
      userID: null,
      userFirstName: null,
      userLastName: null,
      userEmail: null,
      countryID: null,
      userCompany: null,
      userEntity: null,
      password: null,
      confirmPassword: null,
      isLoading: false,
      notificationsEnabled: false,
      subscribedNewsletter: false,
      oldPassword: null,
      isModalOpen: false,
      deleteModalVisible: false,
      deleteModalDoneVisible: false,
      deleteStatus: '',
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.current,
      countries: (state) =>
        state.common.countries.map((country) => {
          return {
            name: country.name,
            value: country.id,
          };
        }),
      entities: (state) =>
        state.common.entities.map((entity) => {
          return {
            name: entity.name,
            value: entity.id,
          };
        }),
      ims: (state) =>
        state.ims.data.map((im) => {
          return {
            name: im.name,
            value: im.id,
          };
        }),
      rfcs: (state) =>
        state.rfcs.data.map((rfc) => {
          return {
            name: rfc.name,
            value: rfc.id,
          };
        }),
      formattedFirstLettersOfName: (state) => {
        const firstName = state.auth.current?.firstName;
        const lastName = state.auth.current?.familyName;
        if (firstName && lastName) {
          return firstName.charAt(0) + lastName.charAt(0);
        }
        return '';
      },
    }),
    userRole() {
      switch (this.profile.roles) {
        case 'ADMIN':
          return this.$t('profile.administrator');
        case 'REGISTERED_USER':
          return this.$t('profile.regular_user');
        case 'DOCUMENT_MANAGER':
          return this.$t('profile.document_manager');
        case 'TRANSLATOR':
          return this.$t('profile.translator');
        default:
          return '';
      }
    },
  },
  created() {
    this.isLoading = true;
    this.userID = this.$route.params.id;
    this.getCountries({});
    this.getEntities({});
    this.getAllIMs({});
    this.getAllRFCs({});
    this.getSubscribedToIMs({});
    this.getSubscribedToRFCs({});
    this.$nextTick(() => {
      this.setUserProperties();
    });
  },
  methods: {
    ...mapGetters({
      subscribedIMsCount: 'auth/subscribedIMsCount',
      subscribedRFCsCount: 'auth/subscribedRFCsCount',
    }),
    ...mapActions('users', ['getUserById', 'deleteUser']),
    ...mapActions('common', ['getCountries', 'getEntities']),
    ...mapActions('ims', ['getAllIMs']),
    ...mapActions('rfcs', ['getAllRFCs']),
    ...mapActions('auth', [
      'updateUserProfile',
      'updateUserPassword',
      'getSubscribedToIMs',
      'getSubscribedToRFCs',
      'logOutRequest',
    ]),
    deleteConfirm() {
      this.toggleDeleteModal();
    },
    toggleDeleteModal() {
      this.deleteModalVisible = !this.deleteModalVisible;
    },
    toggleDeleteModalDone() {
      this.deleteModalDoneVisible = !this.deleteModalDoneVisible;
    },
    goToParentPage() {
      this.logOutRequest(() => {
        window.localStorage.removeItem('sessionID');
        this.$router.push({
          name: 'LandingPage',
        });
      });
    },
    confirmDelete() {
      this.isLoading = true;
      this.toggleDeleteModal();
      this.deleteUser({
        cb: () => {
          this.deleteStatus = 'Your profile was successfully deleted!';
          this.toggleDeleteModalDone();
          this.isLoading = false;
        },
        ecb: () => {
          this.deleteStatus = 'There was an error while deleting your profile';
          this.toggleDeleteModalDone();
          this.isLoading = false;
        },
      });
    },
    setUserProperties() {
      this.countryID = this.profile.countryId;
      this.userEntity = this.profile.organizationType.id;
      this.userCompany = this.profile.companyName;
      this.notificationsEnabled = this.profile.subscribedNewUpdates;
      this.subscribedNewsletter = this.profile.subscribedNewsletter;
      this.userFirstName = this.profile.firstName;
      this.userLastName = this.profile.familyName;
      this.userEmail = this.profile.email;
      this.isLoading = false;
    },
    formatUsername() {
      return this.editedUser ? this.editedUser.firstName + ' ' + this.editedUser.familyName : '';
    },
    updateProfile() {
      const dataProfile = {
        companyName: this.userCompany,
        countryId: this.countryID,
        email: this.userEmail,
        familyName: this.userLastName,
        firstName: this.userFirstName,
        mobilePhone: this.profile.mobilePhone,
        organizationTypeId: this.userEntity,
        personalDataProcessingConsent: this.profile.personalDataProcessingConsent,
        subscribedNewUpdates: this.notificationsEnabled,
        subscribedNewsletter: this.subscribedNewsletter,
        userId: this.profile.userId,
      };
      this.updateUserProfile({
        data: dataProfile,
        cb: () => {
          this.isLoading = false;
          this.$root.$emit('toast-success', this.$t('common.success'));
        },
        ecb: (err) => {
          this.isLoading = false;
          this.$root.$emit('toast-error', err);
        },
      });
    },
    updateUser() {
      if (!isEmailValid(this.userEmail)) {
        this.$root.$emit('toast-error', this.$t('validationRules.invalid_email'));
        return;
      }
      if (this.oldPassword || this.password || this.confirmPassword) {
        if (!this.oldPassword) {
          this.$root.$emit('toast-error', this.$t('validationRules.old_password_required'));
        } else if (!isPasswordValid(this.password)) {
          this.$root.$emit('toast-error', this.$t('validationRules.new_password_notvalid'));
        } else if (this.password === this.confirmPassword) {
          this.isLoading = true;
          const dataPassword = {
            newPassword: this.password,
            oldPassword: this.oldPassword,
          };

          this.updateUserPassword({
            data: dataPassword,
            cb: () => {
              this.updateProfile();
            },
            ecb: () => {
              this.isLoading = false;
              this.$root.$emit('toast-error', this.$t('validationRules.passwords_err'));
            },
          });
        } else {
          this.$root.$emit('toast-error', this.$t('validationRules.passwords_differ'));
        }
      } else {
        this.isLoading = true;
        this.updateProfile();
      }
    },
    closeDialog() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
.name-photo {
  max-width: 145px !important;
}

.name-photo-container {
  width: 120px;
  height: 120px;
  background-color: rgb(128, 128, 128);
  border-radius: 40px;
  text-transform: uppercase;
}

.name-photo-container span {
  font-size: 3.8rem;
  font-weight: 400;
  line-height: 7.4rem;
  letter-spacing: normal;
  font-family: 'Roboto', sans-serif;
  color: white;
  margin-left: 1.3rem;
}

.name-container {
  margin-top: 35px;
}

.name-role {
  max-width: 240px;
}

.first-name,
.last-name {
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #080800;
}

.first-name {
  font-weight: 600;
}

.role-container {
  position: absolute;
  top: 60%;
}

.user-role,
.role {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #404040;
}

.role {
  font-weight: 600;
}

.top-info-container .border-shadow {
  box-shadow: 0px 14px 17px -19px rgba(0, 0, 0, 0.26);
}

.profile-container {
  height: 100%;
}

.user-profile-title {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #080800;
}

>>> .selection-bold select {
  font-weight: 600;
  color: #080800;
  text-overflow: ellipsis;
  word-break: normal;
  padding-right: 35px;
}

>>> .selection-bold input {
  font-weight: 600;
  color: #080800;
}

.subscribe-sidebar {
  background-color: #f8f8f8;
  position: relative;
}

.subscribe-wrapper {
  height: 100%;
  padding-top: 30vh;
  padding-left: 8%;
  padding-right: 10%;
}

>>> .personalprofile {
  padding: 0px !important;
  padding-left: 40px !important;
}

.top-info-container {
  padding: 60px 60px 0;
}

@media only screen and (max-width: 1244px) {
  .top-info-container {
    padding-top: 35px !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}

@media only screen and (max-width: 960px) {
  .switch-container {
    padding-top: 0px !important;
    padding-bottom: 30px !important;
    margin: 0 auto 0 25%;
  }
}

.switch_container {
  height: fit-content;
  width: fit-content;
  float: none;
}

#access_additional_txt {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-left: 40px;
}

.switch-container {
  padding-top: 25px;
  padding-bottom: 40px;
}

input[type='checkbox'] + .slider::before {
  transform: translateX(15px);
}

input.checked + .slider::before {
  transform: translateX(0px);
}

input[type='checkbox'] + .slider {
  background-color: white;
}

input.checked + .slider {
  background-color: rgb(198, 241, 184);
}

.regular-text,
.bold-text,
.gold-text {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
}

.bold-text {
  font-weight: bold;
  color: #080808;
}

.gold-text {
  color: #bf873d;
}

>>> .selection-bold > select {
  text-overflow: ellipsis;
  word-break: normal;
  padding-right: 35px !important;
}
</style>
