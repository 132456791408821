<template>
  <DefaultLayout class="anonymous_login__layout">
    <LoadingComponent :loading.sync="isLoading" />
    <div id="log_in_anonymous_container" class="modal_log_in_anonymous">
      <div class="log-in-anonymous-container">
        <div class="login_text font-weigth-normal">
          <!-- eslint-disable vue/no-v-html vue-i18n/no-v-html -->
          <span class="font-weigth-semibold" v-html="$t(`anonymousLogIn.welcome`)" />
          <span v-html="$t(`anonymousLogIn.welcomeText`)" />
          <!-- eslint-enable vue/no-v-html vue-i18n/no-v-html -->
        </div>
        <div class="type-container pt-10">
          <SelectComponent
            v-model="organizationTypeModel"
            :tabindex="1"
            :options="entities"
            :name="$t(`anonymousLogIn.organisationType`)"
            :placeholder="$t(`anonymousLogIn.organisationSelect`)"
            obj-key="name"
            obj-val="id"
            :error="errors.error('organizationTypeId')"
          />
        </div>

        <div id="captcha_row" class="row">
          <div id="captcha_col_anonym">
            <form action="?" method="POST" class="captcha text-center">
              <div
                id="recaptcha-main"
                class="g-recaptcha d-inline-block"
                data-sitekey="6LcFS98ZAAAAAPp1m2QPlxd07FqDGYni1dSMyzJf"
              />
              <span v-if="errors.error('recaptcha')" class="form-error">{{ errors.error('recaptcha') }}</span>
            </form>
          </div>
        </div>
        <div class="dialog_actions">
          <div class="row">
            <div class="col">
              <button id="next_login_btn" @click="goNext">
                {{ $t(`common.next`) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout';
import SelectComponent from '@/components/base/SelectComponent';
import { mapActions, mapState } from 'vuex';
import LoadingComponent from '@/components/base/LoadingComponent';

export default {
  name: 'AnonymousLogIn',
  components: {
    DefaultLayout,
    SelectComponent,
    LoadingComponent,
  },
  props: {
    choice: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      errors: [],
      organizationTypeModel: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      entities: (state) => state.common.entities,
    }),
  },
  beforeMount() {
    const organisation = window.localStorage.getItem('organizationTypeModel');
    if (organisation) {
      this.$router.push({
        name: 'SearchStep2',
        params: {
          type: this.choice,
        },
      });
    }
  },
  created() {
    this.$nextTick(() => {
      // eslint-disable-next-line no-undef
      grecaptcha.render('recaptcha-main');
    });
    this.getEntities({});
  },
  methods: {
    ...mapActions('auth', ['logInAnonymouslyRequest']),
    ...mapActions('common', ['getEntities']),
    validateRecaptcha() {
      // eslint-disable-next-line no-undef
      const v = grecaptcha.getResponse();
      if (v.length > 0) return true;
      this.errors.addError(`recaptcha`, this.$t(`common.captchaError`));
      return false;
    },
    goNext() {
      this.isLoading = true;
      this.errors = [];
      if (!this.organizationTypeModel) {
        this.errors.addError('organizationTypeId', this.$t(`common.missingDataError`));
      }
      this.validateRecaptcha();

      if (this.errors.length > 0) {
        this.isLoading = false;
        return;
      }

      const data = {
        organizationTypeId: this.organizationTypeModel,
      };

      this.logInAnonymouslyRequest({
        data,
        cb: (data) => {
          this.setItem('anonymous', true);
          this.setItem('sessionID', data.sessionId);
          this.setItem('organizationTypeModel', this.organizationTypeModel);
          // this.isLoading = false;
          this.$router.push({
            name: 'SearchStep2',
            params: {
              type: this.choice,
            },
          });
        },
        ecb: (err) => {
          console.log(err);
          this.errors.addError('generic', err);
          this.isLoading = false;
        },
      });
    },
    setItem(prop, value) {
      window.localStorage.setItem(prop, value);
    },
  },
};
</script>

<style>
.anonymous_login__layout {
  background-image: url('~@/assets/background.png');
}

div#captcha_row {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#captcha_col_anonym {
  margin-top: 12px;
}

.g-recaptcha {
  transform: scale(0.9);
  transform-origin: 0 0;
  position: relative;
  left: 2%;
}

.label {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
}

.login_text {
  font-size: 2em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
}

.select-input {
  height: 48px;
}

#next_login_btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  height: 48px;
  border-radius: 8px;
  background-color: #2b5f1b;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.modal_log_in_anonymous {
  position: fixed;
  /* Stay in place */
  /* z-index: 1;  Sit on top */
  padding-top: 22%;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}
/* Modal Content */
.log-in-anonymous-container,
.add-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 3% 4%;
  border: 1px solid #888;
  border-radius: 8px;
  width: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#edit_country_feedback {
  font-size: 12px;
  color: red;
  margin-left: 8px;
}

#country_name_label,
#country_code_label {
  margin: 8px 0px 0px 0px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #404040;
}

.type-container {
  width: 90%;
  margin: 0 auto;
  max-width: 420px;
}

.organization-label {
  font-size: 1.4em;
}

@media screen and (max-width: 1024px) {
  .g-recaptcha {
    transform: scale(0.97);
  }
}

@media screen and (max-width: 768px) {
  .g-recaptcha {
    transform: scale(0.75);
    left: 4%;
  }
}
</style>
