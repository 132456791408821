<template>
  <v-app>
    <router-view :key="$route.fullPath" />
    <!-- The key attribute allows for a refresh when changing the route query -->
    <v-snackbar
      v-model="showAlert"
      :color="msgType"
      :timeout="msgTimeout"
      top
      rounded
      content-class="font-weight-bold"
      >{{ msgText }}</v-snackbar
    >
  </v-app>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      showAlert: false,
      msgText: '',
      msgType: 'error',
      msgTimeout: 3000,
    };
  },
  computed: {
    ...mapGetters('languages', ['getCurLocale']),
  },
  created() {
    this.$root.$on('toast-error', this.showError);
    this.$root.$on('toast-success', this.showSuccess);
    this.getLanguageList();
  },
  beforeDestroy() {
    this.$root.$off('toast-error', this.showError);
    this.$root.$off('toast-success', this.showSuccess);
  },
  methods: {
    ...mapMutations('languages', ['setLanguages', 'setLocale']),
    showError(msg) {
      this.msgType = 'error';
      this.msgTimeout = 3200;
      this.msgText = typeof msg === 'object' ? msg.message || msg : msg;
      this.showAlert = true;
    },
    showSuccess(msg) {
      this.msgType = 'success';
      this.msgTimeout = 1600;
      this.msgText = typeof msg === 'object' ? msg.message || msg : msg;
      this.showAlert = true;
    },
    getLanguageList() {
      this.$axios
        .get('languages')
        .then((response) => {
          this.setLanguages(response);
          // load the preferred or default locale
          const query = new URLSearchParams(window.location.search);
          let lang = query.get('lang');
          if (!lang) lang = this.getCurLocale;
          if (!lang) lang = ((navigator.languages && navigator.languages[0]) || '').substr(0, 2);
          if (!lang) lang = 'en';
          const languageObj = response.find((item) => item.locale === lang);
          if (languageObj) {
            return this.$axios.get(`languages/${languageObj.id}/translations`).then((trans) => {
              this.$i18n.setLocaleMessage(lang, trans);
              this.$i18n.locale = lang;
              this.setLocale(lang);
            });
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },
  },
};
</script>

<style src="@/assets/styles/common.css"></style>
