<template>
  <div class="form-group" :class="isCheckbox ? 'checkbox' : ''">
    <!-- eslint-disable vue/no-v-html -->
    <label v-if="name" class="input-title" :for="guid" v-html="`${$t(name)}${type != 'checkbox' ? ':' : ''}`" />
    <!-- eslint-enable vue/no-v-html -->
    <input
      v-if="useModel"
      :id="guid"
      ref="input"
      v-model="val"
      :type="type"
      :placeholder="$t(placeholder)"
      :tabindex="tabindex"
      required
      :class="{ 'has-error': error, 'as-disabled': isDisabled }"
      :disabled="isDisabled"
      :readonly="readonly"
      @keyup="submitOnEnter($event)"
    />
    <input
      v-else
      :id="guid"
      ref="input"
      :type="type"
      :value="val"
      :tabindex="tabindex"
      :placeholder="$t(placeholder)"
      required
      :readonly="readonly"
      @input="changeData"
      @keyup="submitOnEnter($event)"
    />
    <span v-if="error" class="form-error">{{ error }}</span>
  </div>
</template>

<script>
import { uuidv4 } from '@/utils/utils';

export default {
  name: 'InputField',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    useModel: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    update: {
      type: Function,
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    enterCallback: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      val: '',
    };
  },
  computed: {
    isCheckbox() {
      return this.type === 'checkbox' || this.type === 'radio';
    },
    guid() {
      return uuidv4();
    },
  },
  watch: {
    value(newValue) {
      this.val = newValue;
    },
    val() {
      if (this.useModel) {
        this.changeData();
      } else {
        this.update(this.val);
      }
    },
  },
  created() {
    this.val = this.value;
  },
  methods: {
    changeData(e) {
      if (this.useModel) {
        this.$emit('input', this.val);
        this.$emit('update', this.val);
      } else this.update(e.target.value);
    },
    submitOnEnter(event) {
      if (event.keyCode === 13) {
        if (this.enterCallback) {
          this.enterCallback();
        } else {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    },
  },
};
</script>

<style scoped>
input {
  font-family: Roboto, sans-serif;
  font-size: 1.6em;
  height: 42px;
}

::placeholder {
  font-size: 0.8em;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c0c0c0;
}

.input-title {
  font-family: Roboto, sans-serif;
  font-size: 1.4em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
}

input.as-disabled {
  background-color: #f8f8f8;
}
</style>
