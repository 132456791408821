var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        id: "nav",
        permanent: "",
        fixed: "",
        "mini-variant": _vm.collapsed,
        "mini-variant-width": "80px",
        "expand-on-hover": _vm.expandOnHover,
      },
      on: {
        "update:miniVariant": function ($event) {
          _vm.collapsed = $event
        },
        "update:mini-variant": function ($event) {
          _vm.collapsed = $event
        },
      },
    },
    [
      _c(
        "v-list-item",
        { staticClass: "px-2", attrs: { "two-line": "" } },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "menu-icon-container menu-item-container-home" },
            [
              _c("img", {
                staticClass: "home-icon",
                attrs: { src: require("@/assets/icons/home.svg") },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.goToHome.apply(null, arguments)
                  },
                },
              }),
            ]
          ),
          _c(
            "v-list-item-content",
            { staticClass: "items_title" },
            [
              _c("v-list-item-title", { attrs: { id: "user_nav_fname" } }, [
                _vm._v(_vm._s(_vm.firstName)),
              ]),
              _c("v-list-item-subtitle", { attrs: { id: "user_nav_lname" } }, [
                _vm._v(_vm._s(_vm.familyName)),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-5",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.collapsed = !_vm.collapsed
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "36" } }, [
                _vm._v("mdi-chevron-left"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            "nudge-bottom": "4",
            origin: "top left",
            transition: "scale-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-list-item",
                    _vm._g({ staticClass: "px-2" }, on),
                    [
                      _vm.currentLocale && _vm.currentLocale.flagUrl
                        ? _c("v-list-item-avatar", [
                            _c("img", {
                              attrs: { src: _vm.currentLocale.flagUrl },
                            }),
                          ])
                        : _c("v-list-item-action-text", [
                            _vm._v(
                              _vm._s(
                                (
                                  (_vm.currentLocale || {}).locale || ""
                                ).toUpperCase()
                              )
                            ),
                          ]),
                      _c("v-list-item-title", [
                        _vm._v(_vm._s((_vm.currentLocale || {}).name)),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { staticClass: "pa-0" },
            _vm._l(_vm.sortedLanguages, function (lang) {
              return _c(
                "v-list-item",
                {
                  key: lang.id,
                  on: {
                    click: function ($event) {
                      return _vm.changeLanguage(lang)
                    },
                  },
                },
                [
                  _c("v-list-item-avatar", [
                    lang.flagUrl
                      ? _c("img", {
                          staticStyle: { border: "1px solid #ddd" },
                          attrs: { src: lang.flagUrl },
                        })
                      : _vm._e(),
                  ]),
                  _c("v-list-item-content", [_vm._v(_vm._s(lang.name))]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "div",
        { staticClass: "container_icons" },
        [
          _c(
            "v-list",
            { staticClass: "scrollable", attrs: { nav: "", dense: "" } },
            [
              _vm._l(_vm.userMenuIcons, function (item, index) {
                return _c(
                  "v-list-item",
                  {
                    key: index,
                    staticClass: "item_menu up_items_menu",
                    style:
                      _vm.$route.path === item.link
                        ? _vm.selectedMenuStyle
                        : "",
                    attrs: { color: "white" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.navigateTo(item)
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      { staticClass: "menu-icon-container" },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.$route.path === item.link
                                ? item.active_icon
                                : item.icon,
                            title: item.title,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "v-list-item-title",
                      { staticClass: "items_title", style: item.titleStyle },
                      [_vm._v(_vm._s(item.title))]
                    ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "bottom-icons" },
                [
                  _vm.bottomMenuIcons.profile
                    ? _c(
                        "v-list-item",
                        {
                          staticClass: "item_menu",
                          style:
                            _vm.$route.path ===
                            (_vm.bottomMenuIcons.profile || {}).link
                              ? _vm.selectedMenuStyle
                              : "",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.navigateTo({
                                linkName: "PersonalProfile",
                              })
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "menu-icon-container" },
                            [
                              _c("img", {
                                attrs: {
                                  id: "icons",
                                  src: (_vm.bottomMenuIcons.profile || {})[
                                    _vm.$route.path ===
                                    (_vm.bottomMenuIcons.profile || {}).link
                                      ? "active_icon"
                                      : "icon"
                                  ],
                                  title: _vm.$t("profile_settings"),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "v-list-item-title",
                            { staticClass: "items_title" },
                            [_vm._v(_vm._s(_vm.bottomMenuIcons.profile.title))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "item_menu",
                      style:
                        _vm.$route.path === _vm.bottomMenuIcons.logout.link
                          ? _vm.selectedMenuStyle
                          : "",
                      on: { click: _vm.logOut },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "menu-icon-container" },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.bottomMenuIcons.logout.icon,
                              title: _vm.$t("navigationDrawer.logout"),
                            },
                          }),
                        ]
                      ),
                      _c("v-list-item-title", { staticClass: "items_title" }, [
                        _vm._v(_vm._s(_vm.bottomMenuIcons.logout.title)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "item_menu",
                      on: { click: _vm.isNavBarLocked },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "menu-icon-container" },
                        [
                          _c("v-icon", { staticClass: "ml-0" }, [
                            _vm._v(_vm._s(_vm.navLockIcon)),
                          ]),
                        ],
                        1
                      ),
                      _c("v-list-item-title", { staticClass: "items_title" }, [
                        _vm._v(_vm._s(_vm.$t("navigationDrawer.navbar_lock"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "bottom-nav" },
            [
              _c(
                "v-list",
                {
                  staticClass: "bottom-nav-content-wrapper",
                  attrs: { nav: "", dense: "" },
                },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "item_menu", attrs: { color: "white" } },
                    [
                      _c("v-list-item-icon", [
                        _c("img", {
                          staticClass: "logo-img",
                          attrs: {
                            src: require("@/assets/icons/nav-logo.svg"),
                            alt: "",
                          },
                        }),
                      ]),
                      _c(
                        "v-list-item-title",
                        {
                          staticClass: "items_title",
                          attrs: { id: "bottom_item" },
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("navigationDrawer.rne"))),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.$t("navigationDrawer.nci")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("LoadingComponent", { attrs: { loading: _vm.loading } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }