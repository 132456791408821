<template>
  <Modal :is-modal-open="isModalOpen" :close-dialog="closeDialog">
    <div class="subtitle">
      {{ lableText }}
    </div>

    <template #footer>
      <div class="d-flex modal-footer">
        <div class="p-2">
          <button class="btn btn-green btn-medium mr-2" @click="confirmCallback">
            {{ $t('common.ok') }}
          </button>
        </div>
        <div class="ml-auto p-2">
          <button class="btn btn-grey btn-medium" @click="closeDialog">
            {{ $t('common.cancel') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/base/Modal';

export default {
  name: 'ConfirmModal',
  components: {
    Modal,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function,
      default: null,
    },
    confirmCallback: {
      type: Function,
      default: null,
    },
    lableText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>

<style scoped>
.subtitle {
  font-size: 15px;
  margin-bottom: 2em;
  max-width: 600px;
}

label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0.5em;
}

.search_name {
  position: relative;
}

.search_name .input-medium {
  padding-left: 46px;
}

.document_type_ns,
.document_type_cid {
  color: #fff;
  width: 37px;
  height: 37px;
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid;
}

.document_type_ns img,
.document_type_cid img {
  width: 37px;
  height: 37px;
  vertical-align: middle;
}

.document_type_ns {
  background: #265f14;
}

.document_type_cid {
  background: #bf873d;
}

h2 {
  font-weight: bold;
  font-size: 20px;
}

.parameter_section .section_title {
  font-size: 12px;
}

.parameter_section ul {
  padding: 0px;
  margin-bottom: 24px;
  max-width: 600px;
  line-height: 32px;
}

.parameter_section ul li {
  padding: 4px 12px;
  margin-right: 10px;
  background: #f0ffeb;
  border: 1px solid #265f14;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  white-space: nowrap;
}

.btn-green {
  font-size: 1em;
  margin-right: 0px;
}

.input-style {
  padding-left: 45px !important;
}

.rename-button {
  margin-left: -70px;
  color: #265f14;
  font-weight: 600;
  font-size: 12px;
}
</style>
