var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LoadingComponent", { attrs: { loading: _vm.loading } }),
      _c(
        "div",
        { staticClass: "d-flex justify-center py-3" },
        [
          _c("DateInput", {
            attrs: {
              label: _vm.$t("statistics.global_search.since_date"),
              outlined: "",
              dense: "",
              classes: "mx-2",
            },
            model: {
              value: _vm.fromDate,
              callback: function ($$v) {
                _vm.fromDate = $$v
              },
              expression: "fromDate",
            },
          }),
          _c("DateInput", {
            attrs: {
              label: _vm.$t("statistics.global_search.until_date"),
              outlined: "",
              dense: "",
              classes: "mx-2",
            },
            model: {
              value: _vm.toDate,
              callback: function ($$v) {
                _vm.toDate = $$v
              },
              expression: "toDate",
            },
          }),
          _c("v-select", {
            staticClass: "flex-grow-0 mx-2",
            attrs: {
              label: _vm.$t("statistics.global_search.group_by"),
              items: _vm.groupByList,
              outlined: "",
              dense: "",
              "menu-props": "offsetY",
            },
            model: {
              value: _vm.searchParams.groupBy,
              callback: function ($$v) {
                _vm.$set(_vm.searchParams, "groupBy", $$v)
              },
              expression: "searchParams.groupBy",
            },
          }),
          _vm.searchParams.groupBy === "PERIOD"
            ? _c("v-select", {
                staticClass: "flex-grow-0 mx-2",
                attrs: {
                  label: _vm.$t("statistics.global_search.group_by_period"),
                  items: _vm.groupByPeriodList,
                  outlined: "",
                  dense: "",
                  "menu-props": "offsetY",
                },
                model: {
                  value: _vm.searchParams.groupByPeriod,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchParams, "groupByPeriod", $$v)
                  },
                  expression: "searchParams.groupByPeriod",
                },
              })
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { color: "primary" },
              on: { click: _vm.fetchData },
            },
            [_vm._v(_vm._s(_vm.$t("common.search")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c("v-simple-table", { staticClass: "stripe tbl_stats" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [
                  _vm._v(
                    _vm._s(_vm.$t("statistics.global_search.document_type"))
                  ),
                ]),
                _c("th", { staticClass: "text-capitalize" }, [
                  _vm._v(_vm._s(_vm.groupLabel)),
                ]),
                _c("th", { attrs: { nowrap: "" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("statistics.global_search.number_searches"))
                  ),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.results, function (row, idx) {
                return _c("tr", { key: idx }, [
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        row.documentType ||
                          _vm.$t("statistics.global_search.total")
                      )
                    ),
                  ]),
                  _c("td", { attrs: { nowrap: "" } }, [
                    _vm._v(
                      _vm._s(
                        row[_vm.groupValue] ||
                          _vm.$t("statistics.global_search.full_period")
                      )
                    ),
                  ]),
                  _c("td", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(_vm._s(row.numberOfSearches)),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }