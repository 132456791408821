var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "logo_row" } }, [
    _c(
      "div",
      { staticClass: "logo_container d-flex align-center mb-8" },
      [_vm._m(0), _vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: require("@/assets/RNE-NCI-logo.svg") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }