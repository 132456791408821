import { orderTypesEnum } from '@/utils/enums';

// initial state
const state = {
  orderBy: 'name',
  orderType: orderTypesEnum.ASC,
};

const actions = {
  order({ commit, state }, col) {
    const [...data] = state.data;
    let orderType = state.orderType;
    const orderBy = state.orderBy;

    if (orderBy !== col) data.sortBy(col);
    if (orderBy === col && orderType === orderTypesEnum.ASC) {
      data.sortByDesc(col);
      orderType = orderTypesEnum.DESC;
    } else if (orderBy === col && orderType === orderTypesEnum.DESC) {
      data.sortBy(col);
      orderType = orderTypesEnum.ASC;
    }

    commit('updateRecords', data);
    commit('updateOrder', {
      orderBy: col,
      orderType,
    });
  },
  setOrderBy({ commit }, col) {
    commit('updateOrderBy', col);
  },
  setOrderType({ commit }, col) {
    commit('updateOrderType', col);
  },
};

const mutations = {
  updateOrder(state, payload) {
    state.orderBy = payload.orderBy;
    state.orderType = payload.orderType;
  },
  updateOrderBy(state, payload) {
    state.orderBy = payload;
  },
  updateOrderType(state, payload) {
    state.orderType = payload;
  },
  updateRecords(state, payload) {
    state.data = payload;
  },
};

export default {
  mutations,
  actions,
  state,
};
