var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", { attrs: { loading: _vm.isLoading } }),
      _c("Headings", {
        attrs: {
          title: _vm.$t(`users.usersManagement`),
          subtitle: _vm.$t(`users.users`),
        },
      }),
      _c("div", { staticClass: "row mb-2", attrs: { id: "download-col" } }, [
        _c(
          "button",
          {
            staticClass: "btn btn-green btn-search",
            on: { click: _vm.downloadUsers },
          },
          [_vm._v(" " + _vm._s(_vm.$t(`users.downloadUsersNewsletter`)) + " ")]
        ),
      ]),
      _c("div", { staticClass: "row", attrs: { id: "inputs" } }, [
        _c(
          "div",
          { staticClass: "col-3" },
          [
            _c("InputField", {
              staticClass: "user-list-input",
              attrs: {
                name: _vm.$t(`users.searchByUsername`),
                tabindex: 1,
                value: _vm.userNameModel,
                "use-model": true,
                "enter-callback": _vm.searchByCriteria,
              },
              on: {
                "update:value": function ($event) {
                  _vm.userNameModel = $event
                },
                update: function ($event) {
                  _vm.userNameModel = $event
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-3" },
          [
            _c("SelectComponent", {
              staticClass: "user-list-input",
              attrs: {
                tabindex: 2,
                options: _vm.roles,
                name: _vm.$t(`users.userRole`),
                "obj-key": "name",
                "obj-val": "value",
              },
              model: {
                value: _vm.userRoleModel,
                callback: function ($$v) {
                  _vm.userRoleModel = $$v
                },
                expression: "userRoleModel",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-3" },
          [
            _c("SelectComponent", {
              staticClass: "user-list-input",
              attrs: {
                tabindex: 3,
                options: _vm.entities,
                name: _vm.$t(`users.userType`),
                "obj-key": "name",
                "obj-val": "id",
              },
              model: {
                value: _vm.userTypeModel,
                callback: function ($$v) {
                  _vm.userTypeModel = $$v
                },
                expression: "userTypeModel",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-3" }, [
          _c("div", { attrs: { id: "search-col" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-green btn-search",
                on: { click: _vm.searchByCriteria },
              },
              [
                _c("img", {
                  staticClass: "search-icon",
                  attrs: {
                    src: require("@/assets/icons/grey-icons/search.svg"),
                  },
                }),
                _vm._v(" " + _vm._s(_vm.$t(`common.search`)) + " "),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("LabelWithCount", {
              attrs: {
                title: _vm.$t(`users.allUsers`),
                count: _vm.calculateTotalUsers,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "table-users" }, [
            _c("table", [
              _c("thead", { staticClass: "table-head-shadow" }, [
                _c(
                  "tr",
                  _vm._l(_vm.headers, function (header, index) {
                    return _c("th", { key: index }, [
                      _vm._v(" " + _vm._s(header.name) + " "),
                      _c("img", {
                        class: _vm.sortDirection(header.name),
                        attrs: {
                          id: header.id,
                          src: _vm.sortIcon(header.name),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.sort(header.name)
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ]),
              _c(
                "tbody",
                [
                  _c("tr", [
                    !_vm.isLoading && !_vm.usersList.length
                      ? _c(
                          "td",
                          {
                            staticClass: "no-data-col",
                            attrs: { colspan: "6" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t(`users.noResults`)) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._l(_vm.usersList, function (user, index) {
                    return _c(
                      "tr",
                      {
                        key: index,
                        staticClass: "user_row table-clickable-row",
                        on: {
                          click: function ($event) {
                            return _vm.open(user)
                          },
                        },
                      },
                      [
                        _c("td", { attrs: { id: "name_user" } }, [
                          _vm._v(
                            _vm._s(user.firstName) +
                              " " +
                              _vm._s(user.familyName)
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(user.companyName))]),
                        _c("td", [_vm._v(_vm._s(user.organizationType.name))]),
                        _c("td", [_vm._v(_vm._s(_vm.getUserRole(user.roles)))]),
                        _c("td", [_vm._v(_vm._s(user.email))]),
                      ]
                    )
                  }),
                ],
                2
              ),
              _vm.totalPages > 0 && _vm.totalPages > _vm.page + 1
                ? _c("tfoot", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "9" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "btn-loadmore",
                            on: { click: _vm.loadMore },
                          },
                          [_vm._v(_vm._s(_vm.$t(`common.loadMore`)))]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }