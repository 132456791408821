var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        "is-modal-open": _vm.isModalOpen,
        "close-dialog": _vm.closeDialog,
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _vm._v(
                " " +
                  _vm._s(_vm.$t(`duplicateStructure.duplicateCommonStruct`)) +
                  " "
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "save_edited_country_btn",
                      on: { click: _vm.duplicate },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.create")) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "delete_country_btn",
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("SelectComponent", {
        staticClass: "cs-type-select selection-bold",
        attrs: {
          tabindex: 2,
          options: _vm.managementTypes,
          name: _vm.$t(`common.type`),
          "obj-key": "name",
          "obj-val": "value",
        },
        model: {
          value: _vm.type,
          callback: function ($$v) {
            _vm.type = $$v
          },
          expression: "type",
        },
      }),
      _c("InputField", {
        staticClass: "selection-bold",
        attrs: {
          tabindex: 2,
          name: _vm.$t(`common.year`),
          value: _vm.year,
          "use-model": true,
        },
        on: {
          "update:value": function ($event) {
            _vm.year = $event
          },
          update: function ($event) {
            _vm.year = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }