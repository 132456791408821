<template>
  <div class="w-100">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col class="col-8 search-link" @click="gotoLoad && gotoLoad(item)">
            <v-row class="search-link-details">
              <div v-if="item.searchParameters.type == 'NS'" class="document_type_ns">
                <img :src="nsSearch" />
              </div>
              <div v-else class="document_type_cid">
                <img :src="cidSearch" />
              </div>
              <span class="text-card">
                {{ item.name }}
              </span>
            </v-row>
          </v-col>
          <v-col class="col-4">
            <span class="icon-right">
              <v-icon @click="openInfo(item)">mdi-information-outline</v-icon>
              <v-icon @click="deleteItem(item)">mdi-delete-outline</v-icon>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import nsSearch from '@/assets/icons/ns-search.svg';
import cidSearch from '@/assets/icons/cid-search.svg';

export default {
  name: 'SavedSearchCard',
  props: {
    item: {
      type: Object,
      default: null,
    },
    deleteItemCallback: {
      type: Function,
      default: null,
    },
    openInfo: {
      type: Function,
      default: null,
    },
    gotoLoad: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      nsSearch,
      cidSearch,
    };
  },
  methods: {
    deleteItem(item) {
      this.deleteItemCallback(item);
    },
  },
};
</script>

<style scoped>
.document_type_ns img,
.document_type_cid img {
  width: 42px;
  height: 42px;
  vertical-align: middle;
}

.document_type_ns,
.document_type_cid {
  border-radius: 8px;
  margin-top: 3px;
}

.document_type_ns {
  background: #265f14;
}

.document_type_cid {
  background: #bf873d;
}

.icon-right {
  float: right;
}

.text-card {
  margin: 8px;
  margin-top: 11px;
  vertical-align: middle;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-link {
  cursor: pointer;
}

.search-link-details {
  padding: 4px;
  align-items: center;
  flex-wrap: nowrap;
}
</style>
