<template>
  <AuthLayout>
    <v-container class="register_container">
      <Logo>
        <v-spacer />
        <LanguageSelector />
      </Logo>
      <label v-if="requestError" class="form-error form-error-r">{{ requestError }}</label>
      <v-form ref="form" lazy-validation novalidate="true">
        <div class="row">
          <div class="col-6">
            <InputField
              v-model="formData.firstName"
              :tabindex="1"
              :placeholder="$t(`common.firstName`)"
              :use-model="true"
              :error="errors.error('formData.firstName')"
            />
            <InputField
              v-model="formData.email"
              :placeholder="$t(`common.username`)"
              :tabindex="3"
              :use-model="true"
              :error="errors.error('formData.email')"
            />
            <SelectComponent
              v-model="formData.countryId"
              :tabindex="5"
              :options="countries"
              :placeholder="$t(`common.country`)"
              obj-key="name"
              obj-val="id"
              :error="errors.error('formData.countryId')"
              @input="setCountry($event)"
              @change="setCountry($event)"
            />
          </div>
          <div id="rightCol" class="col-6">
            <InputField
              v-model="formData.familyName"
              :placeholder="$t(`common.lastName`)"
              :tabindex="2"
              :use-model="true"
              :error="errors.error('formData.familyName')"
            />
            <InputField
              v-model="formData.companyName"
              :placeholder="$t(`common.companyName`)"
              :tabindex="4"
              :use-model="true"
              :error="errors.error('formData.companyName')"
            />
            <SelectComponent
              v-model="formData.organizationTypeId"
              :tabindex="6"
              :options="entities"
              :placeholder="$t(`common.typeOfEntity`)"
              obj-key="name"
              obj-val="id"
              :error="errors.error('formData.organizationTypeId')"
              @change="(formData.organizationTypeId = $event), clearValidation()"
            />
          </div>
          <div class="col-6 py-0 mb-10">
            <InputField
              v-model="formData.password"
              :tabindex="7"
              :placeholder="$t(`common.password`)"
              :use-model="true"
              :error="errors.error('formData.password')"
              type="password"
            />
          </div>
          <div class="col-6 py-0 mb-10">
            <InputField
              v-model="formData.confirm"
              :tabindex="8"
              :placeholder="$t(`common.confirmPassword`)"
              :use-model="true"
              :error="errors.error('formData.confirm')"
              type="password"
            />
          </div>
          <div class="col-12 py-0">
            <InputField
              v-model="formData.termsAndConditionsConsent"
              :tabindex="10"
              :name="$t(`registration.termsAndConditions`)"
              :use-model="true"
              type="checkbox"
              class="agreement-checkbox"
              :error="errors.error('formData.termsAndConditionsConsent')"
            />
          </div>
          <div class="col-12 py-0">
            <InputField
              v-model="formData.subscribedNewUpdates"
              :tabindex="12"
              :name="$t(`registration.personalDataConsent`)"
              :use-model="true"
              type="checkbox"
              class="agreement-checkbox"
            />
          </div>
          <div class="col-12 py-0">
            <InputField
              v-model="formData.subscribedNewsletter"
              :tabindex="13"
              :name="$t(`users.receiveInformationalEmails`)"
              :use-model="true"
              type="checkbox"
              class="agreement-checkbox"
            />
          </div>
          <div class="col-12">
            <form action="?" method="POST" class="captcha">
              <div
                id="recaptcha-main"
                class="g-recaptcha"
                data-sitekey="6LcFS98ZAAAAAPp1m2QPlxd07FqDGYni1dSMyzJf"
                data-tabindex="12"
              />
              <span v-if="errors.error('formData.recaptcha')" class="form-error">{{
                errors.error('formData.recaptcha')
              }}</span>
            </form>
          </div>
          <div class="col-6">
            <button tabindex="13" class="btn-reg" @click.prevent="registerNewUser">
              {{ $t(`common.register`) }}
            </button>
          </div>
          <div class="col-6">
            <router-link class="btn-back mt-0" to="/">{{ $t(`common.back`) }}</router-link>
          </div>
        </div>
      </v-form>
    </v-container>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layout/AuthLayout';
import InputField from '@/components/base/InputField';
import SelectComponent from '@/components/base/SelectComponent';
import Logo from '@/components/layout/Logo';
import LanguageSelector from '@/components/layout/LanguageSelector.vue';
import { isPasswordValid, isEmailValid } from '@/utils/utils.js';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Register',
  components: {
    AuthLayout,
    Logo,
    InputField,
    SelectComponent,
    LanguageSelector,
  },
  data() {
    return {
      formData: {
        firstName: '',
        familyName: '',
        countryId: null,
        companyName: '',
        email: '',
        organizationTypeId: null,
        password: '',
        confirm: '',
        termsAndConditionsConsent: false,
        personalDataProcessingConsent: true,
        subscribedNewUpdates: false,
        subscribedNewsletter: true,
      },
      errors: [],
      validate: [
        'formData.firstName',
        'formData.familyName',
        'formData.countryId',
        'formData.companyName',
        {
          field: 'formData.email',
          message: 'Field should be a valid email',
          regex: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,7})$/,
        },
        'formData.organizationTypeId',
        'formData.password',
        'formData.confirm',
        'formData.termsAndConditionsConsent',
      ],
      requestError: '',
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.common.countries,
      entities: (state) => state.common.entities,
    }),
  },
  mounted() {
    this.validate.valid(this);
  },
  created() {
    this.getCountries({});
    this.getEntities({});
    this.$nextTick(() => {
      // eslint-disable-next-line no-undef
      grecaptcha.render('recaptcha-main');
    });
  },
  methods: {
    ...mapActions('auth', ['registerRequest']),
    ...mapActions('common', ['getCountries', 'getEntities']),
    setCountry(val) {
      this.formData.countryId = val;
    },
    checkIfPasswordsMatch() {
      if (this.formData.password !== this.formData.confirm) {
        this.errors.addError('formData.confirm', this.$t('validationRules.passwords_differ'));
        return false;
      } else {
        delete this.errors.confirm;
        return true;
      }
    },
    clearValidation() {
      const arrayFields = ['organizationTypeId', 'countryId', 'termsAndConditionsConsent'];
      arrayFields.forEach((item) => {
        if (this.formData[item]) {
          this.errors = this.errors.filter((el) => el.field !== item);
        }
      });
    },
    validateRecaptcha() {
      // eslint-disable-next-line no-undef
      const v = grecaptcha.getResponse();
      if (v.length > 0) return true;
      this.errors.addError(`formData.recaptcha`, this.$t(`common.captchaError`));
      return false;
    },
    registerNewUser() {
      this.errors = [];
      const objKeys = Object.keys(this.formData);
      objKeys.forEach((k) => {
        if (!this.formData[k]) {
          if (k === 'termsAndConditionsConsent') {
            this.errors.addError(`formData.${k}`, this.$t(`common.agreementError`));
          } else if (!(k === 'subscribedNewUpdates' || k === 'subscribedNewsletter')) {
            this.errors.addError(`formData.${k}`, this.$t(`common.missingDataError`));
          }
        }
        // if (!this.formData[k])
        //   if (!this.formData[k] && checkboxes.includes(k))
        //     this.errors.addError(
        //       `formData.${k}`,
        //       this.$t(`common.agreementError`)
        //     );
      });
      this.validateRecaptcha();

      const filteredObj = Object.keys(this.errors).reduce((p, c) => {
        if (this.errors[c]) p[c] = this.errors[c];
        return p;
      }, {});

      const isValidPassword = this.isPasswordValidRegister() ? this.checkIfPasswordsMatch() : false;
      const isValidEmail = isEmailValid(this.formData.email);
      if (
        Object.keys(filteredObj).length === 0 &&
        filteredObj.constructor === Object &&
        isValidPassword &&
        isValidEmail
      ) {
        const userData = {
          ...this.formData,
          roles: 'REGULAR_USER',
          subscribedNewUpdates: this.formData.subscribedNewUpdates,
          subscribedNewsletter: this.formData.subscribedNewsletter,
        };

        delete userData.confirm;
        this.registerRequest({
          data: userData,
          cb: (data) => {
            if (data.error) {
              this.requestError = data.error;
            } else {
              this.feedback = data.message;
              this.setItem('sessionID', data.sessionId);
              this.removeItem('anonymous');
              this.removeItem('organizationTypeModel');
              this.$router.push({
                name: 'Search',
              });
            }
          },
          ecb: (err) => {
            console.log(err);
          },
        });
      } else {
        this.$root.$emit('toast-error', this.$t('common.registrationFailed'));
      }
    },
    isPasswordValidRegister() {
      if (isPasswordValid(this.formData.password)) return true;
      else {
        this.errors.addError('formData.password', this.$t('validationRules.password_rules'));
        return false;
      }
    },
    setItem(prop, value) {
      window.localStorage.setItem(prop, value);
    },
    removeItem(prop) {
      window.localStorage.removeItem(prop);
    },
  },
};
</script>

<style scoped>
input {
  font-size: 1.4em;
  font-family: Roboto, sans-serif;
  border: solid 1px #e3e3e3;
}

select {
  font-size: 1.3em;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c0c0c0;
  height: 42px;
  border: solid 1px #e3e3e3;
}

.agreement-checkbox {
  font-family: Roboto, sans-serif;
  font-size: 1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

>>> .agreement-checkbox label {
  padding-top: 10px;
  font-size: 1rem;
  color: #000;
}

.btn-back {
  margin-top: 80px;
  display: block;
  float: right;
  padding: 15px 28px;
  background: #e3e3e3;
  border-radius: 8px;
  color: #404040 !important;
  font-size: 1.2em;
}

.btn-back:hover {
  color: #404040;
}

.btn-reg,
.btn-reg:hover,
.btn-reg:focus {
  background-color: #265f14;
  color: white;
  text-align: center;
  border-radius: 8px;
  border: unset;
  outline: unset;
  padding: 12px 50px;
  height: 50px;
  font-size: 1.6em;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

input[readonly] {
  cursor: not-allowed;
  background-color: #f3f3f3;
}

.form-error-r {
  position: unset;
}
</style>
