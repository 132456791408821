Array.prototype.sortBy = (function () // eslint-disable-line no-extend-native
{
  return function (prop) {
    if (Array.isArray(this)) {
      return this.sort((a, b) => {
        if (a[prop] < b[prop]) return -1;
        if (a[prop] > b[prop]) return 1;
        return 0;
      });
    }
    return this;
  };
})();

Array.prototype.sortByDesc = (function () // eslint-disable-line no-extend-native
{
  return function (prop) {
    if (Array.isArray(this)) {
      return this.sort((a, b) => {
        if (a[prop] > b[prop]) return -1;
        if (a[prop] < b[prop]) return 1;
        return 0;
      });
    }
    return this;
  };
})();

Array.prototype.addError = (function () // eslint-disable-line no-extend-native
{
  return function (prop, value) {
    return this.push({
      field: prop,
      value,
    });
  };
})();

Array.prototype.error = (function () // eslint-disable-line no-extend-native
{
  return function (prop) {
    const matchObj = this.find((item) => item.field === prop);
    return matchObj?.value || null;
  };
})();

Array.prototype.valid = (function () // eslint-disable-line no-extend-native
{
  return function (that) {
    this.forEach((field) => baseValidate(that, field));
  };
})();

const baseValidate = (that, objField) => {
  let field = '';
  let message = 'Please, fill in the missing data.';
  const isObj = typeof objField === 'object';

  if (isObj) {
    field = objField.field;
    message = objField.message;
  } else field = objField;

  const filter = (arr, field) => {
    return arr.filter((x) => x.field !== field);
  };

  that.$watch(field, (newVal) => {
    const debug = that.$debug;
    if (debug) console.time('Execution time:');
    const hasValue = newVal && (newVal === true || newVal.length > 0);
    const hasError = that.errors && that.errors.findIndex((item) => item.field === field) > -1;
    const treatAsString = !hasValue && !hasError && isObj && !Object.hasOwnProperty.call(objField, 'regex');
    const validRegex = isObj && Object.hasOwnProperty.call(objField, 'regex') && objField.regex.test(newVal);
    const fieldIsEmpty = !hasValue && !hasError && !isObj;
    const stringIsNotEmptyAndHasError = hasValue && hasError && !isObj;
    const isObjectNotValidRegex = hasValue && !hasError && !validRegex && isObj;
    const isObjectValidRegex = hasValue && hasError && validRegex && isObj;

    if (debug) console.groupCollapsed(`Watch ${field}`);

    if (debug) console.log('newVal:', newVal);
    if (debug) console.log('message:', message);
    if (debug) console.log('regex:', objField.regex);
    if (debug) console.log('hasValue:', hasValue);
    if (debug) console.log('hasError:', hasError);
    if (debug) console.log('validRegex:', validRegex);
    if (debug) console.log('fieldIsEmpty:', fieldIsEmpty);
    if (debug) {
      console.log('stringIsNotEmptyAndHasError:', stringIsNotEmptyAndHasError);
    }
    if (debug) console.log('isObjectNotValidRegex:', isObjectNotValidRegex);
    if (debug) console.log('isObjectValidRegex:', isObjectValidRegex);
    if (debug) console.log('isObj:', isObj);

    if (fieldIsEmpty || isObjectNotValidRegex || treatAsString) {
      that.errors && that.errors.addError(field, message);
    } else if (stringIsNotEmptyAndHasError || isObjectValidRegex) {
      that.errors = filter(that.errors, field);
    }

    if (debug) console.groupCollapsed(`Analysis`);
    if (debug) console.timeEnd('Execution time:');
    if (debug) console.groupEnd();
    if (debug) console.groupEnd();
  });
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// we have to clear the INPUTs of type FILE after each selection - otherwise if we try to select the same file twice, the CHANGE event will not fire on the 2nd time
export function clearFileInput(ctrl) {
  if (!ctrl) return;
  try {
    ctrl.value = null;
  } catch (ex) {} // eslint-disable-line
  if (ctrl.value) ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
}

export function stringDate(text) {
  const arr = (text || '').split('-');
  const tmp = new Date(arr[0], arr[1] - 1, arr[2], 0, 0, 0, 0);
  return isNaN(tmp.getTime()) ? undefined : tmp;
}

export function dateAuto(s) {
  return typeof s === 'string' || typeof s === 'number'
    ? new Date(s.indexOf('T') > 0 ? s : s + 'T00:00:00' + formatZoneOffset(new Date().getTimezoneOffset()))
    : s;
}

export function formatZoneOffset(minutes) {
  const min = Math.abs(minutes) % 60;
  const hours = (Math.abs(minutes) - min) / 60;
  return (minutes > 0 ? '+' : '-') + String(hours).padStart(2, '0') + ':' + String(min).padStart(2, '0');
}

export function dateLocale(d, langCode) {
  if (!d) return '';
  return dateAuto(d).toLocaleString(langCode || undefined, {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
  });
}

export function isPasswordValid(password) {
  const pass = password || '';
  const condition =
    /^(?=.*[0-9])(?=.*[!@#$%^&*?.,<>/+-_=[\];:|{}\\()])[a-zA-Z0-9!@#$%^&*?.,<>/+-_=[\];:|{}\\()]{8,20}$/;
  return pass.match(condition);
}

export function isEmailValid(email) {
  return /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,7})$/.test(email);
}

export function isVersionValid(version) {
  const firstChar = version.charAt(0);
  const charAsNumber = parseFloat(firstChar);
  return !(isNaN(charAsNumber) || charAsNumber <= 0);
}

export function formatDuration(ms) {
  if (ms < 0) ms = -ms;
  const time = {
    d: Math.floor(ms / 86400000),
    h: Math.floor(ms / 3600000) % 24,
    m: Math.floor(ms / 60000) % 60,
    s: Math.floor(ms / 1000) % 60,
    ms: Math.floor(ms) % 1000,
  };
  return Object.entries(time)
    .filter((val) => val[1] !== 0)
    .map((val) => val[1] + val[0])
    .join(' ');
}
