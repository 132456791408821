import { isURL } from 'validator';

export default {
  methods: {
    ruleRequired(value) {
      return typeof value === 'number'
        ? true
        : (Array.isArray(value) ? value.length > 0 : !!value) || this.$t('validationRules.required_field');
    },
    validEmail(value) {
      return value
        ? /^([a-zA-Z0-9]+\.|[a-zA-Z0-9]+[_-]+[a-zA-Z0-9]+\.)*([a-zA-Z0-9]+|[a-zA-Z0-9]+[_-]+[a-zA-Z0-9]+)@(([a-zA-Z0-9]+|[a-zA-Z0-9]+[-]+[a-zA-Z0-9]+)\.)+[a-zA-Z]{2,}$/.test(
            value
          ) || this.$t('validationRules.invalid_email')
        : true;
    },
    validURL(url) {
      return url ? isURL(url) || this.$t('validationRules.invalid_url') : true;
    },
  },
};
