var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("v-col", { attrs: { cols: "12", sm: "12", md: "4" } }, [
        _c(
          "div",
          [
            _vm.componentVisible()
              ? _c("div", { staticClass: "mb-4" }, [
                  _c("label", { staticClass: "doc-label" }, [
                    _vm._v(_vm._s(_vm.$t(`common.status`))),
                  ]),
                  _c("label", { staticClass: "status-label" }, [
                    _vm._v(_vm._s(_vm.currentDocument.status)),
                  ]),
                ])
              : _vm._e(),
            _vm.componentVisible()
              ? _c("SelectComponent", {
                  staticClass: "selection-bold input-big cs-select",
                  attrs: {
                    tabindex: 1,
                    options: _vm.isCIDDocument ? _vm.rfcList : _vm.imsList,
                    value: _vm.currentDocument.organizationName,
                    name: _vm.isCIDDocument
                      ? _vm.$t(`common.railFreightCorridors`)
                      : _vm.$t(`common.infrastructureManagers`),
                    "is-disabled": _vm.isPublished,
                    "obj-key": "name",
                    "obj-val": _vm.isCIDDocument ? "nameOnly" : "name",
                    error: _vm.formErrors.organizationName,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateCurrentDocument({
                        key: "organizationName",
                        value: $event,
                      })
                    },
                  },
                })
              : _vm._e(),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    attrs: {
                      cols: "12",
                      sm: "12",
                      md: _vm.componentVisible() ? 8 : 12,
                    },
                  },
                  [
                    _c("SelectComponent", {
                      staticClass: "selection-bold input-big",
                      class: { "mt-5": !_vm.componentVisible() },
                      attrs: {
                        tabindex: 2,
                        options: _vm.years,
                        value: _vm.currentDocument.year,
                        name: _vm.$t(`common.year`),
                        "is-disabled":
                          _vm.isPublished || !!_vm.currentDocument.id,
                        "obj-key": "value",
                        "obj-val": "value",
                        error: _vm.formErrors.selectedYear,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.fetchCsLevelsForYear($event)
                        },
                      },
                    }),
                    _vm.formErrors.csLevels
                      ? _c("span", { staticClass: "form-error" }, [
                          _vm._v(" " + _vm._s(_vm.formErrors.csLevels) + " "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.componentVisible()
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "12", md: "4" } },
                      [
                        _c("InputField", {
                          staticClass: "selection-bold",
                          attrs: {
                            tabindex: 3,
                            name: _vm.$t(`common.version`),
                            "is-disabled":
                              _vm.isPublished || !!_vm.currentDocument.id,
                            value: _vm.currentDocument.version,
                            "use-model": true,
                            error: _vm.formErrors.version,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.currentDocument,
                                "version",
                                $event
                              )
                            },
                            update: function ($event) {
                              return _vm.validateVersionNumber($event)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.componentVisible()
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "doc-label" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`documentManagement.leaveNotes`)) +
                        " "
                    ),
                  ]),
                  _c("textarea", {
                    staticClass: "selection-bold notes-textarea",
                    attrs: { disabled: _vm.isPublished, cols: "40", rows: "3" },
                    domProps: { value: _vm.currentDocument.note },
                    on: {
                      change: function ($event) {
                        return _vm.updateDocumentProperty(
                          "note",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.currentDocument.type === "CID" &&
            _vm.filteredBooksOnly &&
            !_vm.isFetching
              ? _vm._l(_vm.filteredBooksOnly, function (book, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "attach-file-container mb-2" },
                    [
                      _vm.selectedBookFiles[index] &&
                      _vm.selectedBookFiles[index].file &&
                      _vm.selectedBookFiles[index].status !== "NOT_FOUND"
                        ? [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "mb-0",
                                    attrs: {
                                      cols: "11",
                                      sm: _vm.isFile(
                                        _vm.selectedBookFiles[index].file
                                      )
                                        ? 9
                                        : 11,
                                    },
                                  },
                                  [
                                    _c("input", {
                                      ref: _vm.generateRef(index),
                                      refInFor: true,
                                      staticClass: "file-picker",
                                      attrs: { type: "file", accept: ".docx" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onBookSelected(
                                            $event,
                                            index
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        class: _vm.getClassForAttachedFile(
                                          _vm.selectedBookFiles[index].status
                                        ),
                                        attrs: {
                                          title: _vm.getTitleMappingForStatus(
                                            _vm.selectedBookFiles[index].status
                                          ),
                                          disabled:
                                            _vm.selectedBookFiles[index]
                                              .status === "PROCESSING",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickUpload(index)
                                          },
                                        },
                                      },
                                      [
                                        _vm.selectedBookFiles[index].status !==
                                          "PROCESSING" &&
                                        _vm.selectedBookFiles[index].status !==
                                          "EXTRACTION_ERROR"
                                          ? _c("img", {
                                              staticClass: "pr-2",
                                              attrs: {
                                                src: require("@/assets/icons/done.svg"),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              book.nameEn +
                                                " " +
                                                _vm.getStatusText(
                                                  _vm.selectedBookFiles[index]
                                                    .status
                                                )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "align-items-center px-0",
                                    attrs: { cols: "1" },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-green btn-annex my-3 mx-0",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.navigateToAnalysisPage.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: _vm.showIcon },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm.isFile(_vm.selectedBookFiles[index].file)
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "12", sm: "3" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "remove-file-label",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onRemoveBook(index)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t(`common.remove`))
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      !_vm.selectedBookFiles[index] ||
                      !_vm.selectedBookFiles[index].file ||
                      _vm.selectedBookFiles[index].status === "NOT_FOUND"
                        ? [
                            _c("input", {
                              ref: _vm.generateRef(index),
                              refInFor: true,
                              staticClass: "file-picker",
                              attrs: { type: "file", accept: ".docx" },
                              on: {
                                change: function ($event) {
                                  return _vm.onBookSelected($event, index)
                                },
                              },
                            }),
                            !_vm.selectedBookFiles[index] ||
                            !_vm.selectedBookFiles[index].file ||
                            _vm.selectedBookFiles[index].status === "NOT_FOUND"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn-attach-file",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickUpload(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "document-icon",
                                      attrs: {
                                        src: require("@/assets/icons/gold-icons/documentmanagement-gold.svg"),
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("documentManagement.attach") +
                                            " " +
                                            book.nameEn
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.isSubmitted && !_vm.selectedBookFiles.length
                              ? _c("span", { staticClass: "form-error" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(`documentManagement.attachFiles`)
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                })
              : _vm._e(),
            _vm.currentDocument.type === "NS"
              ? [
                  _c(
                    "div",
                    { staticClass: "attach-file-container" },
                    [
                      _c("input", {
                        ref: "file",
                        staticClass: "file-picker",
                        attrs: { type: "file", accept: ".docx" },
                        on: {
                          change: function ($event) {
                            return _vm.onFileSelected($event)
                          },
                        },
                      }),
                      !_vm.selectedFile
                        ? _c(
                            "button",
                            {
                              staticClass: "btn-attach-file",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.file.click()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "document-icon",
                                attrs: {
                                  src: require("@/assets/icons/gold-icons/documentmanagement-gold.svg"),
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("documentManagement.attach") +
                                      " " +
                                      _vm.currentDocument.type +
                                      " " +
                                      _vm.$t("editLevels.file")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.selectedFile && !_vm.isFetching
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    cols: "11",
                                    sm: _vm.isPublished ? 11 : 8,
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      class: _vm.getClassForAttachedFile(
                                        _vm.selectedFile.status
                                      ),
                                      attrs: {
                                        title: _vm.getTitleMappingForStatus(
                                          _vm.selectedFile.status
                                        ),
                                      },
                                    },
                                    [
                                      _vm.selectedFile.status !==
                                        "PROCESSING" &&
                                      _vm.selectedFile.status !==
                                        "EXTRACTION_ERROR"
                                        ? _c("img", {
                                            staticClass: "pr-2",
                                            attrs: {
                                              src: require("@/assets/icons/done.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentDocument.type +
                                              " " +
                                              _vm.getStatusText(
                                                _vm.selectedFile.status
                                              )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "align-items-center px-0",
                                  attrs: { cols: "1" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-green btn-annex my-3 mx-0",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.navigateToAnalysisPage.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: _vm.showIcon },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              !_vm.isPublished
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "3" } },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "remove-file-label",
                                          on: { click: _vm.removeFile },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t(`common.remove`))
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isSubmitted && !_vm.selectedFile
                        ? _c("span", { staticClass: "form-error" }, [
                            _vm._v(
                              _vm._s(_vm.$t(`documentManagement.attachFiles`))
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12", md: "8" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("span", { staticClass: "annexes-label" }, [
                  _vm._v(_vm._s(_vm.$t(`documentManagement.appliedAnnexes`))),
                ]),
                _c("span", { staticClass: "annexes-label-count ml-1" }, [
                  _vm._v("(" + _vm._s(_vm.annexesCount) + ")"),
                ]),
              ]),
              _c("v-col", { attrs: { cols: "1" } }, [
                _c("span", {}, [
                  _vm._v(
                    _vm._s(_vm.$t(`documentManagement.includeOnDownload`))
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _vm._l(_vm.annexes, function (annex, index) {
                return _c(
                  "v-col",
                  {
                    key: index,
                    staticClass: "attach-file-container attach-annex-container",
                    attrs: { cols: "12" },
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", { attrs: { cols: "4" } }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn-attached-file",
                              attrs: {
                                disabled: _vm.isPublished || !annex.annexId,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAnnexDisplayNameChange(
                                    $event,
                                    index,
                                    annex
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "document-icon",
                                attrs: {
                                  src: require("@/assets/icons/done.svg"),
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    annex.annexFileName ||
                                      _vm.$t("documentManagement.annexFile") +
                                        " " +
                                        (index + 1)
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    annex.annexFileName === annex.displayName
                                      ? ""
                                      : annex.displayName
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c("v-col", { attrs: { cols: "1" } }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              disabled: _vm.isPublished,
                            },
                            domProps: { checked: annex.includeOnDownload },
                            on: {
                              change: function ($event) {
                                return _vm.handleIncludeOnDownload(
                                  $event,
                                  index,
                                  annex
                                )
                              },
                            },
                          }),
                        ]),
                        _c(
                          "v-col",
                          { attrs: { cols: 4 } },
                          [
                            _c("MultipleSelectComponent", {
                              staticClass: "multiselect-medium",
                              attrs: {
                                tabindex: 5,
                                value: annex.chapters,
                                options: _vm.levels || [],
                                placeholder: _vm.$t(
                                  `documentManagement.annexPlaceholder`
                                ),
                                "obj-key": "nameEn",
                                "obj-val": "id",
                                disabled: _vm.isPublished,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.addOrRemoveChapterOfAnnex(
                                    $event,
                                    index
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { cols: "1" } }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-gold btn-annex-small",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.downloadAnnexFE(index)
                                },
                              },
                            },
                            [_c("img", { attrs: { src: _vm.downloadIcon } })]
                          ),
                        ]),
                        annex.pdfPreviewUrl
                          ? _c("v-col", { attrs: { cols: "1" } }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-gold btn-annex-small",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.previewAnnexFE(index)
                                    },
                                  },
                                },
                                [_c("img", { attrs: { src: _vm.showIcon } })]
                              ),
                            ])
                          : _vm._e(),
                        !_vm.isPublished && index === _vm.indexOfVisiblePlus
                          ? _c("v-col", { attrs: { cols: "1" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "add-row-container",
                                  on: { click: _vm.addNewAnnex },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/icons/gold-icons/addrow.svg"),
                                    },
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        !_vm.isPublished
                          ? _c("v-col", { attrs: { cols: "1" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "add-row-container remove-annex-label",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAnnex(index)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "remove-file-label" },
                                    [_vm._v(_vm._s(_vm.$t(`common.remove`)))]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "v-col",
                {
                  staticClass: "attach-file-container attach-annex-container",
                  attrs: { cols: "12" },
                },
                [
                  !_vm.isPublished && !_vm.newAnnexFile
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("input", {
                              ref: "fileAnnex",
                              staticClass: "file-picker",
                              attrs: {
                                type: "file",
                                accept:
                                  ".docx,.doc,.xls,.xlsx,.pptx,.ppt,.txt,.pdf,.png,.jpg,.jpeg",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onAnnexFileSelected($event)
                                },
                              },
                            }),
                            !_vm.newAnnexFile
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn-attach-file",
                                    on: {
                                      drop: function ($event) {
                                        return _vm.dropHandler($event)
                                      },
                                      dragover: function ($event) {
                                        $event.preventDefault()
                                      },
                                      dragenter: function ($event) {
                                        $event.preventDefault()
                                      },
                                      click: _vm.attachNewAnnexFileClick,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "document-icon",
                                      attrs: {
                                        src: require("@/assets/icons/gold-icons/documentmanagement-gold.svg"),
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            `documentManagement.attachAnnexFile`
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.newAnnexFile
                              ? _c(
                                  "button",
                                  { staticClass: "btn-attached-file" },
                                  [
                                    _c("img", {
                                      staticClass: "document-icon",
                                      attrs: {
                                        src: require("@/assets/icons/done.svg"),
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            `documentManagement.annexFile`
                                          ) +
                                            " " +
                                            (_vm.annexes.length + 1)
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("MultipleSelectComponent", {
                                staticClass: "multiselect-medium",
                                attrs: {
                                  tabindex: 5,
                                  value: _vm.newAnnexChapters,
                                  options: _vm.levels || [],
                                  placeholder: _vm.$t(
                                    `documentManagement.annexPlaceholder`
                                  ),
                                  "obj-key": "nameEn",
                                  "obj-val": "id",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.addOrRemoveChapter($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.isPublished
                            ? _c("v-col", { attrs: { cols: "1" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "add-row-container",
                                    on: { click: _vm.addNewAnnex },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/icons/gold-icons/addrow.svg"),
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }