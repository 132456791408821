import {
  getAllRFCsRequest,
  getRFCRequest,
  updateRFCRequest,
  deleteRFCRequest,
  createRFCRequest,
  grantAccessToUserRequest,
  revokeAccessToUserRequest,
  uploadLogoRequest,
} from '@/services/rfcs.service';
import order from '../utils/order';

// initial state
const state = () => ({
  ...order.state,
  data: [],
  current: {},
  totalItems: 0,
});

// getters
const getters = {
  totalItems: (state) => {
    if (!state.current) return 0;
    if (!state.current.documentManagers) return 0;
    return state.current.documentManagers.length;
  },
};

// actions
const actions = {
  ...order.actions,
  getAllRFCs({ commit }, { cb = null, onlyWithDocumentManagerAccess = false }) {
    getAllRFCsRequest(onlyWithDocumentManagerAccess, (rfcs) => {
      commit('setRFCs', [...rfcs]);
      if (cb) cb();
    });
  },
  getSingleRFC({ commit }, { id, cb = null, ecb = null }) {
    getRFCRequest(
      id,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleRFC', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  updateSingleRFC({ commit }, { data, cb = null, ecb = null }) {
    updateRFCRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleRFC', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  createSingleRFC({ commit }, { data, cb = null, ecb = null }) {
    createRFCRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleRFC', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  deleteSingleRFC({ commit }, { id, cb = null, ecb = null }) {
    deleteRFCRequest(
      id,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleRFC', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  uploadLogo({ dispatch }, { data, cb = null, ecb = null }) {
    uploadLogoRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) dispatch('getSingleRFC', { id: data.id });
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  updateCountry({ commit }, name) {
    commit('updateCountry', name);
  },
  // eslint-disable-next-line no-unused-vars
  grantAccessForUser({ commit }, { data, cb = null, ecb = null }) {
    grantAccessToUserRequest(
      data,
      (res) => {
        if (cb) cb(res);
        // let hasError = Object.prototype.hasOwnProperty.call(res, "error");
        // if (!hasError) commit("setSingleRFC", res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  revokeAccessForUser({ commit }, { data, cb = null, ecb = null }) {
    revokeAccessToUserRequest(
      data,
      (res) => {
        if (cb) cb(res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  updateName({ commit }, name) {
    commit('updateName', name);
  },
  updateShortName({ commit }, name) {
    commit('updateShortName', name);
  },
  updateLogo({ commit }, name) {
    commit('updateLogo', name);
  },
  clearCurrent({ commit }) {
    commit('clearCurrent');
  },
};

// mutations
const mutations = {
  ...order.mutations,
  setRFCs(state, data) {
    state.data = data;
    state.totalItems = data.length;
  },
  setSingleRFC(state, data) {
    state.current = data;
  },
  updateName(state, name) {
    state.current.name = name;
  },
  updateLogo(state, name) {
    state.current.logo = name;
  },
  updateShortName(state, name) {
    state.current.shortName = name;
  },
  clearCurrent(state) {
    state.current = {};
  },
  updateCountry(state, name) {
    state.current.country = name;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
