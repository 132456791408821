var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bad_route d-flex full-width" }, [
    _c(
      "div",
      { staticClass: "ma-auto text-center" },
      [
        _c("h1", [_vm._v(_vm._s(_vm.$t("badRoute.title")))]),
        _c("p", { staticClass: "pb-3" }, [
          _vm._v(_vm._s(_vm.$t("badRoute.explanation"))),
        ]),
        _c("router-link", { attrs: { to: "/" } }, [
          _vm._v(_vm._s(_vm.$t("badRoute.homepage"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }