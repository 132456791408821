import { render, staticRenderFns } from "./BadRoute.vue?vue&type=template&id=7299df74&"
var script = {}
import style0 from "./BadRoute.vue?vue&type=style&index=0&id=7299df74&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/stefanvartolomeev/work/ws_devcraft/nci-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7299df74')) {
      api.createRecord('7299df74', component.options)
    } else {
      api.reload('7299df74', component.options)
    }
    module.hot.accept("./BadRoute.vue?vue&type=template&id=7299df74&", function () {
      api.rerender('7299df74', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BadRoute.vue"
export default component.exports