import { axiosInstance } from '../utils/axios.js';
import {
  SESSIONS_URL,
  REGISTER_USER_URL,
  RESET_PASSWORD_URL,
  SET_NEW_PASSWORD_URL,
  LOGIN_AS_GUEST_URL,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PASSWORD,
  CHANGE_USER_PASSWORD,
  GET_SUBSCRIBED_IMS,
  GET_SUBSCRIBED_RFCS,
  SUBSCRIBE_IMS,
  UNSUBSCRIBE_IMS,
  SUBSCRIBE_RFC,
  UNSUBSCRIBE_RFC,
} from '../utils/api.endpoints';

export const registerRequest = function (data, cb, ecb = null) {
  axiosInstance
    .post(REGISTER_USER_URL, data)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const loginRequest = function (data, cb, ecb = null) {
  axiosInstance
    .post(SESSIONS_URL, data)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const logInAnonymouslyRequest = function (data, cb, ecb = null) {
  axiosInstance
    .post(LOGIN_AS_GUEST_URL, data)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const logOutRequest = function (cb, ecb = null) {
  axiosInstance
    .delete(SESSIONS_URL)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      // it is expected to return an error if the session has expired
      if (ecb) ecb(error);
      else if (cb) cb(error);
    });
};

export const passwordResetRequest = function (userEmail, cb, ecb = null) {
  axiosInstance
    .put(RESET_PASSWORD_URL, userEmail)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error.response.data.error);
      else console.log('Error', error);
    });
};

export const setNewPasswordRequest = function (data, cb, ecb = null) {
  axiosInstance
    .post(SET_NEW_PASSWORD_URL, data)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const updateUserProfileRequest = function (data, cb = null, ecb = null) {
  axiosInstance
    .put(UPDATE_USER_PROFILE, data)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const updateUserPasswordRequest = function (data, cb = null, ecb = null) {
  axiosInstance
    .put(UPDATE_USER_PASSWORD, data)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const changeUserPassRequest = function (data, userId, cb = null, ecb = null) {
  axiosInstance
    .put(CHANGE_USER_PASSWORD(userId), data)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const fetchSubscribedToIMs = function (cb = null, ecb = null) {
  axiosInstance
    .get(GET_SUBSCRIBED_IMS)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const fetchSubscribedToRFCs = function (cb = null, ecb = null) {
  axiosInstance
    .get(GET_SUBSCRIBED_RFCS)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const subscribeIMRequest = function (id, cb = null, ecb = null) {
  axiosInstance
    .post(SUBSCRIBE_IMS(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const unsubscribeIMRequest = function (id, cb = null, ecb = null) {
  axiosInstance
    .delete(UNSUBSCRIBE_IMS(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const subscribeRFCRequest = function (id, cb = null, ecb = null) {
  axiosInstance
    .post(SUBSCRIBE_RFC(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const unsubscribeRFCRequest = function (id, cb = null, ecb = null) {
  axiosInstance
    .delete(UNSUBSCRIBE_RFC(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export default {};
