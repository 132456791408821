var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", { attrs: { loading: _vm.loading } }),
      _c("Headings", {
        attrs: {
          title: _vm.$t(`auditLog.auditLog`),
          subtitle: _vm.$t(`auditLog.subtitle`),
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-center align-end py-3" },
        [
          _c("DateInput", {
            attrs: {
              label: _vm.$t("auditLog.from_date"),
              outlined: "",
              dense: "",
              classes: "mx-2",
            },
            model: {
              value: _vm.fromDate,
              callback: function ($$v) {
                _vm.fromDate = $$v
              },
              expression: "fromDate",
            },
          }),
          _c("DateInput", {
            attrs: {
              label: _vm.$t("auditLog.to_date"),
              outlined: "",
              dense: "",
              classes: "mx-2",
            },
            model: {
              value: _vm.toDate,
              callback: function ($$v) {
                _vm.toDate = $$v
              },
              expression: "toDate",
            },
          }),
          _c("InputField", {
            staticClass: "user-list-input",
            staticStyle: { "margin-bottom": "26px" },
            attrs: {
              value: _vm.email,
              "use-model": true,
              name: _vm.$t(`auditLog.search_by_email`),
              tabindex: 1,
            },
            on: {
              "update:value": function ($event) {
                _vm.email = $event
              },
              update: function ($event) {
                _vm.email = $event
              },
            },
          }),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              staticStyle: { "margin-bottom": "29px" },
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.fetchData()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.search")))]
          ),
          _c(
            "v-btn",
            {
              staticStyle: { "margin-bottom": "29px" },
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.downloadData()
                },
              },
            },
            [_c("img", { attrs: { src: _vm.downloadIcon, width: "28px" } })]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-logs" },
        [
          _c(
            "v-simple-table",
            { staticClass: "audit-table", attrs: { dense: "" } },
            [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("auditLog.createdAt")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("auditLog.email")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("auditLog.executionTime")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("auditLog.eventName")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("auditLog.details")))]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.events, function (event, idx) {
                  return _c(
                    "tr",
                    {
                      key: `event-${idx}`,
                      class: { expanded: _vm.expandedRow == idx },
                      on: {
                        click: function ($event) {
                          _vm.expandedRow = _vm.expandedRow == idx ? null : idx
                        },
                      },
                    },
                    [
                      _c("td", { attrs: { nowrap: "" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              event.createdAt
                                ? new Date(event.createdAt).toLocaleDateString(
                                    "en-uk",
                                    {
                                      day: "numeric",
                                      year: "numeric",
                                      month: "short",
                                    }
                                  )
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", { attrs: { nowrap: "" } }, [
                        _vm._v(_vm._s(event.email)),
                      ]),
                      _c("td", { attrs: { nowrap: "" } }, [
                        _vm._v(
                          _vm._s(
                            event.executionTime
                              ? _vm.formatDuration(event.executionTime)
                              : ""
                          )
                        ),
                      ]),
                      _c("td", { attrs: { nowrap: "" } }, [
                        _vm._v(_vm._s(event.eventName)),
                      ]),
                      _c("td", { attrs: { nowrap: "" } }, [
                        _c("div", { staticClass: "event-details" }, [
                          _vm._v(_vm._s(event.details)),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }