import Vue from 'vue';
import VueRouter from 'vue-router';
import { isLoggedIn } from '@/utils/auth';
import EmptyComp from '@/components/EmptyComp';
import BadRoute from '@/components/BadRoute';
import LandingPage from '@/components/LandingPage';
import Register from '@/components/auth/Register';
import Login from '@/components/auth/Login';
import AnonymousLogIn from '@/components/auth/AnonymousLogIn';
import ResetPassword from '@/components/auth/ResetPassword';
import ResetPasswordMessage from '@/components/auth/ResetPasswordMessage';
import UsersList from '@/components/users/UsersList';
import UserDetails from '@/components/users/UserDetails';
import PersonalProfile from '@/components/users/PersonalProfile';
import CommonList from '@/components/common/CommonList';
import CommonDetails from '@/components/common/CommonDetails';
import DocManagement from '@/components/document/DocManagement';
import DocManagementUpload from '@/components/document/DocManagementUpload';
import DocManagementUploadStatus from '@/components/document/DocManagementUploadStatus';
import DocumentAnalysis from '@/components/document/DocumentAnalysis';
import CountriesList from '@/components/countries/CountriesList';
import TagsList from '@/components/tags/TagsList';
import RFCsList from '@/components/rfcs/RFCsList';
import RFCsDetails from '@/components/rfcs/RFCsDetails';
import IMsList from '@/components/ims/IMsList';
import IMsDetails from '@/components/ims/IMsDetails';
import Search from '@/components/search/Search';
import SearchResults from '@/components/search/SearchResults';
import SearchStep2 from '@/components/search/SearchStep2';
import SearchDocumentPreview from '@/components/search/SearchDocumentPreview';
import ChapterError from '@/components/search/ChapterError';
import StatsPage from '@/components/analytics/StatsPage.vue';
import StatsGlobal from '@/components/analytics/StatsGlobal.vue';
import StatsGlobalSearch from '@/components/analytics/StatsGlobalSearch.vue';
import StatSearchOrg from '@/components/analytics/StatSearchOrg.vue';
import AuditLogPage from '@/components/audit-log/AuditLogPage.vue';
import LanguagesPage from '@/components/translation/LanguagesPage.vue';
import TranslationsPage from '@/components/translation/TranslationsPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    meta: { allowAnonymous: true },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { allowAnonymous: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { allowAnonymous: true },
  },
  {
    path: '/login/anonymous',
    name: 'AnonymousLogIn',
    component: AnonymousLogIn,
    props: (route) => ({
      choice: route.query.choice,
    }),
    meta: { allowAnonymous: true },
  },
  {
    path: '/password-reset',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { allowAnonymous: true },
  },
  {
    path: '/password-recovery',
    name: 'ResetPasswordMessage',
    component: ResetPasswordMessage,
    meta: { allowAnonymous: true },
  },
  {
    path: '/users',
    name: 'UsersList',
    component: UsersList,
    meta: { validRoles: ['ADMIN'] },
  },
  {
    path: '/users/:id',
    name: 'UserDetails',
    component: UserDetails,
    meta: { validRoles: ['ADMIN'] },
  },
  {
    path: '/profile',
    name: 'PersonalProfile',
    component: PersonalProfile,
    meta: {
      validRoles: ['ADMIN', 'REGULAR_USER', 'DOCUMENT_MANAGER', 'TRANSLATOR'],
    },
  },
  {
    path: '/common',
    name: 'CommonList',
    component: CommonList,
    meta: { validRoles: ['ADMIN'] },
  },
  {
    path: '/common/:id',
    name: 'CommonDetails',
    component: CommonDetails,
    meta: { validRoles: ['ADMIN'] },
  },
  {
    path: '/documents',
    name: 'DocManagement',
    component: DocManagement,
    meta: { validRoles: ['ADMIN', 'DOCUMENT_MANAGER'] },
  },
  {
    path: '/documents/upload/',
    name: 'DocManagementUpload',
    component: DocManagementUpload,
    meta: { validRoles: ['ADMIN', 'DOCUMENT_MANAGER'] },
  },
  {
    path: '/documents/analyse',
    name: 'DocumentAnalysis',
    component: DocumentAnalysis,
    meta: { validRoles: ['ADMIN', 'DOCUMENT_MANAGER'] },
  },
  {
    path: '/documents/upload/status/',
    name: 'DocManagementUploadStatus',
    component: DocManagementUploadStatus,
    meta: { validRoles: ['ADMIN', 'DOCUMENT_MANAGER'] },
  },
  {
    path: '/countries',
    name: 'CountriesList',
    component: CountriesList,
    meta: { validRoles: ['ADMIN'] },
  },
  {
    path: '/tags',
    name: 'TagsList',
    component: TagsList,
    meta: { validRoles: ['ADMIN'] },
  },
  {
    path: '/rfcs',
    name: 'RFCsList',
    component: RFCsList,
    meta: { validRoles: ['ADMIN', 'DOCUMENT_MANAGER'] },
  },
  {
    path: '/rfcs/:id',
    name: 'RFCsDetails',
    component: RFCsDetails,
    meta: { validRoles: ['ADMIN', 'DOCUMENT_MANAGER'] },
  },
  {
    path: '/ims',
    name: 'IMsList',
    component: IMsList,
  },
  {
    path: '/ims/:id',
    name: 'IMsDetails',
    component: IMsDetails,
    meta: { validRoles: ['ADMIN', 'DOCUMENT_MANAGER'] },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: { allowBoth: true },
  },
  {
    path: '/search/:type',
    name: 'SearchStep2',
    redirect: {
      name: 'noResults',
    },
    component: SearchStep2,
    children: [
      {
        path: '',
        name: 'noResults',
        component: EmptyComp,
        meta: { allowBoth: true },
      },
      {
        path: 'results',
        name: 'SearchResults',
        component: SearchResults,
        props: (route) => ({
          type: route.query.type,
          years: route.query.years,
          cs: route.query.cs,
          text: route.query.text,
          country: route.query.country,
          annexes: route.query.annexes,
          ims: route.query.ims,
          rfcs: route.query.rfcs,
          yearsDropdown: route.query.yearsDropdown,
        }),
        meta: { allowBoth: true },
      },
    ],
    meta: { allowBoth: true },
  },
  {
    path: '/search/results/preview',
    name: 'SearchDocumentPreview',
    component: SearchDocumentPreview,
    props: (route) => ({
      result: route.query.result,
    }),
    meta: { allowBoth: true },
  },
  {
    path: '/chapter/:type/:orgID/:year/:book/:chapterID/:csNumber',
    name: 'PreviewDocument',
    component: SearchDocumentPreview,
    meta: { allowBoth: true },
  },
  {
    path: '/chapter-error',
    name: 'ChapterFailed',
    component: ChapterError,
    meta: { allowBoth: true },
  },
  {
    path: '/statistics',
    name: 'Statistics',
    redirect: {
      name: 'StatGlobal',
    },
    component: StatsPage,
    meta: {
      validRoles: ['ADMIN', 'REGULAR_USER', 'DOCUMENT_MANAGER'],
    },
    children: [
      {
        path: 'global',
        name: 'StatGlobal',
        component: StatsGlobal,
        meta: {
          validRoles: ['ADMIN', 'REGULAR_USER', 'DOCUMENT_MANAGER'],
        },
      },
      {
        path: 'global-search',
        name: 'StatGlobalSearch',
        component: StatsGlobalSearch,
        meta: {
          validRoles: ['ADMIN', 'REGULAR_USER', 'DOCUMENT_MANAGER'],
        },
      },
      {
        path: 'search-org/:type',
        name: 'StatSearchOrg',
        component: StatSearchOrg,
        meta: {
          validRoles: ['ADMIN', 'REGULAR_USER', 'DOCUMENT_MANAGER'],
        },
      },
    ],
  },
  {
    path: '/audit-log',
    name: 'AuditLog',
    component: AuditLogPage,
    meta: {
      validRoles: ['ADMIN'],
    },
  },
  {
    path: '/languages',
    name: 'LangList',
    component: LanguagesPage,
    meta: {
      validRoles: ['ADMIN', 'TRANSLATOR'],
    },
  },
  {
    path: '/translations/:id',
    name: 'Translations',
    component: TranslationsPage,
    meta: {
      validRoles: ['ADMIN', 'TRANSLATOR'],
    },
  },

  {
    path: '*', // should be last, otherwise matches everything
    component: BadRoute,
    meta: { allowBoth: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isLogged = await isLoggedIn(to);
  const anonymous = localStorage.getItem('anonymous');
  const redirectLoggedInUsersFrom = routes.filter((x) => x.meta?.allowAnonymous).map((x) => x.name);
  if (redirectLoggedInUsersFrom.includes(to.name) && isLogged) {
    next({
      name: 'Search',
    });
  } else if (
    (!to.meta.allowAnonymous && !to.meta.allowBoth && !isLogged) ||
    (to.meta.allowBoth && anonymous !== 'true' && !isLogged)
  ) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

// https://stackoverflow.com/a/63263736 - ignore NavigationDuplicated errors but keep other errors
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (
      [VueRouter.NavigationFailureType.duplicated, VueRouter.NavigationFailureType.redirected].includes(
        (err || {}).type
      )
    ) {
      return err;
    }

    return Promise.reject(err);
  });
};

export default router;
