var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AuthLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "v-container",
        { staticClass: "register_container" },
        [
          _c("Logo", [_c("v-spacer"), _c("LanguageSelector")], 1),
          _vm.message
            ? _c("div", { staticClass: "message mb-5" }, [
                _vm._v(_vm._s(_vm.message)),
              ])
            : _vm._e(),
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "reset_form",
              attrs: { "lazy-validation": "" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("InputField", {
                        attrs: {
                          name: _vm.$t(`resetPassword.resetEmail`),
                          tabindex: 1,
                          value: _vm.userEmailModel,
                          "use-model": true,
                          type: "email",
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.userEmailModel = $event
                          },
                          update: function ($event) {
                            ;(_vm.userEmailModel = $event), (_vm.feedback = "")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.feedback || _vm.requestErrMessage
                ? _c(
                    "v-row",
                    [
                      _vm.feedback
                        ? _c("v-col", { attrs: { cols: "12" } }, [
                            _c("p", { staticClass: "feedback" }, [
                              _vm._v(_vm._s(_vm.feedback)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.requestErrMessage
                        ? _c("v-col", { attrs: { cols: "12" } }, [
                            _c("p", { staticClass: "feedback" }, [
                              _vm._v(_vm._s(_vm.requestErrMessage)),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-green",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.sendRecoverEmailRequest.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t(`resetPassword.recoverPassword`)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "btn-back",
                            staticStyle: { "margin-top": "16px" },
                            attrs: { to: "/Login" },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }