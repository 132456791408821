<template>
  <DefaultLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <Headings
      :show="!isNew"
      class="d-inline-block"
      :title="$t(`common.ImAndRfcManagementInformation`)"
      back-location="RFCsList"
      :subtitle="rfc.name"
    />
    <Headings
      :show="isNew"
      class="d-inline-block"
      :title="$t(`common.ImAndRfcManagementInformation`)"
      back-location="RFCsList"
      :subtitle="$t(`rfcs.newRFC`)"
    />
    <div class="row mt-5">
      <div class="col-6 col-lg-3">
        <div class="form-group">
          <label
            for="logo"
            :class="{ preview: !!logoPreview }"
            :style="{ 'background-image': `url(${logoPreview})` }"
            >{{ $t('rfcs.logo') }}</label
          >
          <input id="logo" accept="image/*" type="file" name="logo" required @change="(e) => pickFile(e)" />
        </div>
        <div class="form-group">
          <label class="rfc-label">{{ $t('rfcs.name') }}:</label>
          <input type="text" :value="rfc.name" required @change="(e) => updateName(e.target.value)" />
          <span v-if="nameRequired" class="text-danger">{{ $t('validationRules.required_field') }}</span>
        </div>
        <div class="form-group">
          <label class="rfc-label">{{ $t('rfcs.shortName') }}:</label>
          <input type="text" :value="rfc.shortName" required @change="(e) => updateShortName(e.target.value)" />
          <span v-if="shortNameRequired" class="text-danger">{{ $t('validationRules.required_field') }}</span>
        </div>
        <div v-if="countries" class="form-group rfc-details-country">
          <MultipleSelectComponent
            v-model="selectedCountries"
            :tabindex="3"
            :options="countries"
            :chips="true"
            :name="$t(`common.country`)"
            obj-key="name"
            obj-val="id"
            @change="updateCountry"
          />
          <span v-if="countryRequired" class="text-danger">{{ $t('validationRules.required_field') }}</span>
        </div>
      </div>
      <div v-show="!isNew" class="col-5 offset-1">
        <p class="table-title users-with-access">
          {{ $t(`common.usersWithAccess`) }}
          <span class="total">({{ totalItems }})</span>
        </p>
        <div class="hr" />
        <div v-show="totalItems" class="row">
          <div v-for="(user, index) in rfc.documentManagers" :key="index" class="col-12 user-with-access">
            {{ user.firstName + ' ' + user.familyName }}
          </div>
        </div>
      </div>
    </div>
    <div class="row bottom">
      <div class="col-5">
        <div class="btns-container">
          <button class="back_user_btn" @click.prevent="goBack">
            {{ $t(`common.back`) }}
          </button>
          <button v-show="isNew" class="save_btn" @click.prevent="create">
            {{ $t(`common.add`) }}
          </button>
          <button v-show="!isNew" class="save_btn" @click.prevent="update">
            {{ $t(`common.save`) }}
          </button>
          <button v-show="!isNew" class="delete_btn" @click="deleteRFC">
            {{ $t(`common.delete`) }}
          </button>
        </div>
      </div>
    </div>
    <ConfirmModal
      :is-modal-open="isConfirmModalOpen"
      :close-dialog="closeConfirmModal"
      :confirm-callback="confirmCallback"
      :lable-text="confirmLableText"
      style="z-index: 20001"
    />
  </DefaultLayout>
</template>

<script>
import Headings from '@/components/base/Headings';
import DefaultLayout from '@/components/layout/DefaultLayout';
import { mapActions, mapState, mapGetters } from 'vuex';
import { RFCS_LOGO_URL } from '@/utils/api.endpoints';
import LoadingComponent from '@/components/base/LoadingComponent';
import MultipleSelectComponent from '@/components/base/MultipleSelectComponent';
import ConfirmModal from '../common/modals/ConfirmModal';

export default {
  name: 'RFCsDetails',
  components: {
    Headings,
    DefaultLayout,
    LoadingComponent,
    MultipleSelectComponent,
    ConfirmModal,
  },
  data() {
    return {
      logoPreview: null,
      selectedFile: null,
      isLoading: false,
      nameRequired: false,
      shortNameRequired: false,
      countryRequired: false,
      selectedCountries: [],
      isConfirmModalOpen: false,
      confirmLableText: '',
      confirmCallback: null,
    };
  },
  computed: {
    ...mapState({
      rfc: (state) => state.rfcs.current,
      countries: (state) => state.common.countries,
    }),
    ...mapGetters({
      totalItems: 'rfcs/totalItems',
    }),
    myLogo() {
      return function (id) {
        return `${this.$serverUrl}${RFCS_LOGO_URL(id)}`;
      };
    },
    isNew() {
      return this.$route.params.id === 'new';
    },
  },
  beforeDestroy() {
    this.clearCurrent();
  },
  created() {
    if (!this.countries.length) this.getCountries({});
    if (!this.isNew) {
      this.getSingleRFC({
        id: this.$route.params.id,
        cb: (res) => {
          this.logoPreview = this.myLogo(res.id);
          this.selectedCountries = this.countries.filter((c) => {
            return res.countryIds.includes(c.id);
          });
        },
      });
    } else {
      this.updateName('');
      this.updateShortName('');
    }
  },
  methods: {
    ...mapActions('rfcs', [
      'getSingleRFC',
      'updateSingleRFC',
      'updateName',
      'uploadLogo',
      'updateShortName',
      'deleteSingleRFC',
      'createSingleRFC',
      'clearCurrent',
      'updateCountry',
    ]),
    ...mapActions('common', ['getCountries']),
    create() {
      if (this.validate()) {
        this.isLoading = true;
        const data = {
          logo: this.selectedFile,
          data: {
            countryIds: this.selectedCountries.map((c) => c.id),
            shortName: this.rfc.shortName,
            name: this.rfc.name,
          },
        };

        this.createSingleRFC({
          data: data,
          cb: () => {
            this.isLoading = false;
            this.goBack();
          },
          ecb: (err) => {
            this.$root.$emit('toast-error', err);
            this.isLoading = false;
            this.goBack();
          },
        });
      }
    },
    update() {
      if (this.validate()) {
        this.isLoading = true;
        const data = {
          logo: this.selectedFile,
          data: {
            countryIds: this.selectedCountries.map((c) => c.id),
            shortName: this.rfc.shortName,
            name: this.rfc.name,
          },
          id: this.rfc.id,
        };
        this.updateSingleRFC({
          data: data,
          cb: () => {
            this.isLoading = false;
            this.goBack();
          },
          ecb: () => {
            this.isLoading = false;
            this.goBack();
          },
        });
      }
    },
    deleteRFC() {
      this.openConfirmModal(this.$t(`rfcs.deleteConfirmation`), () => {
        this.deleteSingleRFC({
          id: this.rfc.id,
          cb: () => {
            this.goBack();
          },
        });
        this.isConfirmModalOpen = false;
      });
    },
    validate() {
      let valid = false;
      if (this.rfc.shortName && this.rfc.name && this.selectedCountries.length) {
        valid = true;
        this.shortNameRequired = false;
        this.nameRequired = false;
        this.countryRequired = false;
      } else {
        if (!this.rfc.shortName) this.shortNameRequired = true;
        if (!this.rfc.name) this.nameRequired = true;
        if (!this.selectedCountries.length) this.countryRequired = true;
      }
      return valid;
    },
    pickFile(e) {
      const file = e.target.files;
      if (file && file[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logoPreview = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        // eslint-disable-next-line vue/require-explicit-emits
        this.$emit('input', file[0]);
        this.selectedFile = file[0];
      }
    },
    goBack() {
      this.$router.push({ name: 'RFCsList' });
    },
    openConfirmModal(text, callback) {
      this.confirmLableText = text;
      this.confirmCallback = callback;
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
  },
};
</script>

<style scoped>
.text-danger {
  color: red;
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
}

>>> .select-label {
  font-family: Roboto, sans-serif;
  font-size: 14px !important;
  color: #808080;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

>>> .rfc-details-country .v-text-field__details {
  display: none;
}

.rfc-label {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
}
</style>
