// Default roles
export default {
  computed: {
    managementTypes() {
      return [
        {
          name: 'NS',
          value: 'NS',
          title: this.$t('structure.ns_structure'),
        },
        {
          name: 'CID',
          value: 'CID',
          title: this.$t('structure.cid_structure'),
        },
      ];
    },
    headers() {
      return [
        {
          name: this.$t('common.type').toUpperCase(),
          id: 'type',
        },
        {
          name: this.$t('statistics.global_search.header_year').toUpperCase(),
          id: 'year',
        },
      ];
    },
  },
};
