var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SearchLayout",
    [
      _c("LoadingComponent", { attrs: { loading: _vm.spin > 0 } }),
      _c(
        "Headings",
        {
          attrs: {
            title: _vm.$t(
              _vm.docType === "CID" ? `search.titleCID` : `search.title`
            ),
            back: true,
            subtitle:
              (_vm.fullChapterName
                ? _vm.fullChapterName.toUpperCase() + " "
                : "") + _vm.organizationName,
            "use-gold-back-style": true,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "row justify-content-between",
              attrs: { id: "inputs" },
            },
            [
              _c("div", { staticClass: "col-1 col-md-1 col-lg-1 col-xl-2" }),
              _c(
                "div",
                { staticClass: "col-11 col-md-8 col-lg-7 col-xl-6" },
                [
                  _c("SelectComponent", {
                    staticClass: "select-big version-select-control",
                    attrs: {
                      tabindex: 1,
                      options: _vm.versions,
                      name: _vm.$t(`search.versionControl`),
                      "obj-key": "displayValue",
                      "obj-val": "id",
                    },
                    model: {
                      value: _vm.currentVersionId,
                      callback: function ($$v) {
                        _vm.currentVersionId = $$v
                      },
                      expression: "currentVersionId",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-1 d-md-none" }),
              _c("div", { staticClass: "col-11 col-md-3 col-lg-4 col-xl-4" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("div", {}, [
                    _c("label", { staticClass: "select-label" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t(`search.originalDocument`)) + " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "d-flex actions-container" }, [
                    _c("div", { staticClass: "mr-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-preview",
                          class: _vm.shortPreview ? "btn-gold" : "btn-green",
                          on: { click: _vm.previewDocument },
                        },
                        [_c("img", { attrs: { src: _vm.showIcon } })]
                      ),
                    ]),
                    _c("div", { staticClass: "ml-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-gold btn-preview",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.downloadDoc.apply(null, arguments)
                            },
                          },
                        },
                        [_c("img", { attrs: { src: _vm.downloadIcon } })]
                      ),
                    ]),
                    _c("div", { staticClass: "ml-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-gold btn-preview",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.copyLink.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("v-icon", { staticClass: "copy-icon" }, [
                            _vm._v("mdi-content-copy"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "v-row",
        { staticClass: "top" },
        [
          _c("v-col", { staticClass: "flex-grow-0 pb-0" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-green btn-medium download-button text-no-wrap",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.downloadChapterWithAnnexes.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t(`search.downloadChapter`)) + " ")]
            ),
          ]),
          _c(
            "v-col",
            {
              staticClass: "download_disclaimer pl-1 pb-0",
              attrs: { cols: "5" },
            },
            [
              _c("div", { staticClass: "text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("disclaimer.title"))),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.$t("disclaimer.body")))]),
            ]
          ),
          _c(
            "v-col",
            { staticClass: "pb-0" },
            [
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                _vm._l(_vm.externalLinks, function (link) {
                  return _c(
                    "v-tooltip",
                    {
                      key: link.id,
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-col",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "flex-grow-0 d-flex flex-column justify-end align-center",
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: link.url,
                                          target: "_blank",
                                          rel: "noopener noreferrer",
                                        },
                                      },
                                      [
                                        link.logoUrl
                                          ? _c("v-img", {
                                              attrs: {
                                                src: link.logoUrl,
                                                "max-width": "100",
                                                "max-height": "64",
                                                contain: "",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-truncate text-center pt-2",
                                        staticStyle: { "max-width": "100px" },
                                      },
                                      [_vm._v(_vm._s(link.application))]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_vm._v(" " + _vm._s(link.tooltip) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row mb-60" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "table-preview-shadow" }, [
            _vm.shortPreview
              ? _c(
                  "div",
                  {
                    class: `pdf-preview ${
                      _vm.shortPreview ? "short-preview" : ""
                    }`,
                  },
                  _vm._l(_vm.chapterNumPages, function (i) {
                    return _c("pdf", {
                      key: "short_" + i,
                      attrs: { page: i, src: _vm.chapterSrc },
                    })
                  }),
                  1
                )
              : _c(
                  "div",
                  {
                    class: `pdf-preview ${
                      _vm.shortPreview ? "short-preview" : ""
                    }`,
                  },
                  _vm._l(_vm.documentNumPages, function (i) {
                    return _c("pdf", {
                      key: "long_" + i,
                      attrs: { page: i, src: _vm.documentSrc },
                    })
                  }),
                  1
                ),
          ]),
          _vm.currentVersionObject.annexes &&
          _vm.currentVersionObject.annexes.length > 0
            ? _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "annexes-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t(`common.annexes`)) +
                      " (" +
                      _vm._s(_vm.currentVersionObject.annexes.length) +
                      ")"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.currentVersionObject.annexes &&
          _vm.currentVersionObject.annexes.length > 0
            ? _c(
                "div",
                { staticClass: "col-12" },
                _vm._l(_vm.annexesLocal, function (annex) {
                  return _c(
                    "div",
                    { key: annex.id, staticClass: "annex-info mb-3" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            annex.name +
                              (annex.displayName &&
                              annex.name !== annex.displayName
                                ? " (" + annex.displayName + ")"
                                : "")
                          ) +
                          " "
                      ),
                      annex.pdfPreviewUrl
                        ? _c(
                            "div",
                            {
                              staticClass: "open-close",
                              on: {
                                click: function ($event) {
                                  return _vm.setVisible(annex)
                                },
                              },
                            },
                            [
                              !annex.visible
                                ? _c("img", { attrs: { src: _vm.addNew } })
                                : _c("div", { staticClass: "close_btn" }, [
                                    _c("span", { staticClass: "close" }, [
                                      _vm._v(_vm._s(_vm.closeBtn)),
                                    ]),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                      annex.downloadUrl
                        ? _c("div", { staticClass: "download-annex" }, [
                            _c("img", {
                              attrs: { src: _vm.downloadIcon },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadAnnexFE(annex)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "preview",
                          class: annex.visible ? "vis" : "hid",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pdf-preview-annex" },
                            _vm._l(annex.pages, function (i) {
                              return _c("pdf", {
                                key: i + "_" + annex.id,
                                attrs: { page: i, src: annex.pdf },
                              })
                            }),
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "row bottom" }, [
          _c("div", { staticClass: "col-xs-12 col-md-5" }, [
            _c("div", { staticClass: "btns-container" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-grey btn-medium mr-3",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goBack.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`common.back`)) + " ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }