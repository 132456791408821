var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Modal", {
    attrs: {
      "is-modal-open": _vm.isModalOpen,
      "close-dialog": _vm.closeDialog,
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("LoadingComponent", {
              attrs: { loading: _vm.isLoading },
              on: {
                "update:loading": function ($event) {
                  _vm.isLoading = $event
                },
              },
            }),
            _c("div", { staticClass: "modal-heading mb-10" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(`documentManagement.uploadMasterCIDandAnnexFile`)
                  ) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "modal-text" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("VSelectComponent", {
                          attrs: {
                            options: _vm.years,
                            value: _vm.selectedYear,
                            name: _vm.$t(
                              "documentManagement.upload_master_cid.specify_cid"
                            ),
                            "obj-key": "value",
                            "obj-val": "value",
                            prepend: "mdi-calendar-blank-outline",
                            "class-style": "select-big",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.fetchCsLevelsForYear($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.selectedMasterCID
                      ? _c("v-col", { attrs: { cols: "12" } }, [
                          _c("input", {
                            ref: "fileMasterCID",
                            staticClass: "file-picker",
                            attrs: { type: "file", accept: ".docx" },
                            on: {
                              change: function ($event) {
                                return _vm.onFileSelected($event)
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn-attach-file",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.fileMasterCID.click()
                                },
                              },
                            },
                            [
                              _c("document-icon"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "documentManagement.upload_master_cid.attach_master_cid"
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.selectedMasterCID
                      ? _c("v-col", { attrs: { cols: 10 } }, [
                          _c(
                            "button",
                            { staticClass: "btn-attached-file" },
                            [
                              _c("done-icon"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "documentManagement.upload_master_cid.master_cid_attached"
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.selectedMasterCID
                      ? _c(
                          "v-col",
                          {
                            staticClass: "remove-file-container",
                            attrs: { cols: undefined },
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "remove-file-label",
                                on: { click: _vm.removeMasterCID },
                              },
                              [_vm._v(_vm._s(_vm.$t(`common.remove`)))]
                            ),
                          ]
                        )
                      : _vm._e(),
                    !_vm.selectedMasterAnnex
                      ? _c("v-col", { attrs: { cols: "6" } }, [
                          _c("input", {
                            ref: "fileMasterCIDAnnex",
                            staticClass: "file-picker",
                            attrs: {
                              type: "file",
                              accept:
                                ".docx,.doc,.xls,.xlsx,.pptx,.ppt,.txt,.pdf,.png,.jpg,.jpeg",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onAnnexFileSelected($event)
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn-attach-file",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.fileMasterCIDAnnex.click()
                                },
                              },
                            },
                            [
                              _c("document-icon"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "documentManagement.upload_master_cid.attach_master_annex"
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.selectedMasterAnnex
                      ? _c(
                          "v-col",
                          { attrs: { cols: _vm.selectedMasterAnnex ? 4 : 6 } },
                          [
                            _c("button", { staticClass: "btn-attached-file" }, [
                              _c("img", { attrs: { src: _vm.doneIcon } }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "documentManagement.upload_master_cid.master_annex_attached"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("MultipleSelectComponent", {
                          staticClass: "multiselect-medium annex-books",
                          attrs: {
                            tabindex: 5,
                            value: _vm.selectedAnnexChapters,
                            options: _vm.levels || [],
                            placeholder: _vm.$t(
                              "documentManagement.annexPlaceholder"
                            ),
                            "obj-key": "nameEn",
                            "obj-val": "id",
                            disabled: !_vm.levels,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.addOrRemoveChapter($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.selectedMasterAnnex
                      ? _c(
                          "v-col",
                          {
                            staticClass: "remove-file-container",
                            attrs: { cols: undefined },
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "remove-file-label",
                                on: { click: _vm.removeMasterCIDAnnex },
                              },
                              [_vm._v(_vm._s(_vm.$t(`common.remove`)))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-2" }),
              _c("div", { staticClass: "col-4" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-green btn-big w-100 btn-upload-master-cid",
                    on: { click: _vm.confirmMasterCIDUpload },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(`common.upload`)) + " ")]
                ),
              ]),
              _c("div", { staticClass: "col-4" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-white btn-big w-100 btn-cancel-upload-master-cid",
                    on: { click: _vm.closeDialog },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(`common.close`)) + " ")]
                ),
              ]),
              _c("div", { staticClass: "col-2" }),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }