<template>
  <div class="form-group">
    <div v-if="name">
      <label class="select-label">{{ name }}</label>
    </div>
    <select
      v-model="selectModel"
      :class="{ 'has-error': error, changed: changed, 'as-disabled': isDisabled }"
      :disabled="isDisabled"
      :tabindex="tabindex"
    >
      <option v-if="placeholder" :value="null" selected hidden>{{ placeholder }}</option>
      <option v-for="(a, index) in options" :key="index" :value="a[objVal]" :disabled="a.disabled">
        {{ a[objKey] }}
      </option>
    </select>
    <span v-if="error" class="form-error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Object],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    change: {
      type: Function,
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    objKey: {
      type: String,
      required: true,
    },
    objVal: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      changed: false,
    };
  },
  computed: {
    selectModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val); // eslint-disable-line vue/require-explicit-emits
        if (this.change) this.change(val);
        else this.$emit('change', val); // eslint-disable-line vue/require-explicit-emits
        this.changed = true;
      },
    },
  },
};
</script>

<style scoped>
select {
  font-family: Roboto, sans-serif;
  font-size: 1.4em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #c0c0c0;
  height: 42px;
  border: solid 1px #e3e3e3;
  border-radius: 8px;
  line-height: 30px;
  width: 100%;
  padding: 5px 10px;
  background-image: linear-gradient(45deg, transparent 50%, grey 50%),
    linear-gradient(135deg, grey 50%, transparent 50%), linear-gradient(to right, #f8f8f8, #f8f8f8);
  background-position: calc(100% - 17px) center, calc(100% - 12px) center, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 100%;
  background-repeat: no-repeat;
}

select.changed {
  color: #000;
}

select:focus {
  background-image: linear-gradient(45deg, grey 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, grey 50%), linear-gradient(to right, #f8f8f8, #f8f8f8);
  background-position: calc(100% - 12px) center, calc(100% - 17px) center, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 100%;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}

select.has-error {
  border: 2px solid #ff000038;
}

.select-label {
  font-family: Roboto, sans-serif;
  font-size: 1.4em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
}

select.as-disabled {
  background-color: #f8f8f8;
}
</style>
