<template>
  <div id="logo_row">
    <div class="logo_container d-flex align-center mb-8">
      <div class="logo">
        <img src="@/assets/RNE-NCI-logo.svg" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
};
</script>
