<template>
  <div class="admin-content">
    <div v-if="!anonymous" class="nav-col navbar-col">
      <UserNavDrawer />
    </div>
    <div class="content-col">
      <slot />
    </div>
    <div class="nav-col" :class="`navbar-col ${currentRoute}`">
      <SearchNavDrawer />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UserNavDrawer from '@/components/layout/UserNavDrawer';
import SearchNavDrawer from '@/components/layout/SearchNavDrawer';

export default {
  name: 'SearchLayout',
  components: {
    UserNavDrawer,
    SearchNavDrawer,
  },
  computed: {
    ...mapState({
      anonymous: (state) => state.auth.anonymous,
    }),
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>

<style scooped src="@/assets/styles/layout.css"></style>
