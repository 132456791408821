import {
  logOutRequest,
  loginRequest,
  registerRequest,
  passwordResetRequest,
  setNewPasswordRequest,
  logInAnonymouslyRequest,
  updateUserProfileRequest,
  updateUserPasswordRequest,
  changeUserPassRequest,
  fetchSubscribedToIMs,
  fetchSubscribedToRFCs,
  subscribeIMRequest,
  unsubscribeIMRequest,
  subscribeRFCRequest,
  unsubscribeRFCRequest,
} from '@/services/auth.service';
// initial state
const state = () => ({
  current: null,
  anonymous: true,
  errors: [],
  imsSubscriptions: [],
  rfcsSubscriptions: [],
});

// getters
const getters = {
  firstName: (state) => {
    if (!state.current) return '';
    return state.current?.firstName;
  },
  subscribedIMsCount: (state) => {
    if (!state.imsSubscriptions) return 0;
    return state.imsSubscriptions.length;
  },
  subscribedRFCsCount: (state) => {
    if (!state.rfcsSubscriptions) return 0;
    return state.rfcsSubscriptions.length;
  },
  familyName: (state) => {
    if (!state.current) return '';
    return state.current?.familyName;
  },
  acronym: (state) => {
    const text = state.current ? `${state.current.firstName} ${state.current.familyName}` : '';
    return text.split(/\s/).reduce(function (accumulator, word) {
      return accumulator + word.charAt(0);
    }, '');
  },
  role: (state) => {
    if (!state.current) return '';
    return state.current.roles;
  },
};

// actions
const actions = {
  passwordResetRequest({ commit }, { data, cb = null, ecb = null }) {
    passwordResetRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('reset', res.user);
      },
      (err) => {
        console.log(err);
        if (ecb) ecb(err);
      }
    );
  },
  setNewPasswordRequest({ commit }, { data, cb = null, ecb = null }) {
    setNewPasswordRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setNewPass', res.user);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  logInRequest({ commit }, { data, cb = null, ecb = null }) {
    loginRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('logIn', res.user);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  logInAnonymouslyRequest({ commit }, { data, cb = null, ecb = null }) {
    logInAnonymouslyRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('logInAnonymous', res.user);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  registerRequest({ commit }, { data, cb = null, ecb = null }) {
    registerRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('register', res.user);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  logOutRequest({ commit }, cb = null) {
    logOutRequest(() => {
      if (cb) cb();
      commit('logOut');
    });
  },
  updateUserProfile({ commit }, { data, cb = null, ecb = null }) {
    updateUserProfileRequest(
      data,
      (response) => {
        if (!response.error) {
          commit('hydrateUser', response);
          if (cb) cb();
        } else if (ecb) ecb(response.error);
      },
      (err) => {
        if (ecb) ecb(err);
        else console.log(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  updateUserPassword({ commit }, { data, cb = null, ecb = null }) {
    updateUserPasswordRequest(
      data,
      (response) => {
        if (!response.error) {
          if (cb) cb();
        }
      },
      (err) => {
        if (ecb) ecb(err);
        else console.log(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  changeUserPass({ commit }, { data, userId, cb = null, ecb = null }) {
    changeUserPassRequest(
      data,
      userId,
      (response) => {
        if (!response.error) {
          if (cb) cb();
        }
      },
      (err) => {
        if (ecb) ecb(err);
        else console.log(err);
      }
    );
  },
  getSubscribedToIMs({ commit }, { cb = null, ecb = null }) {
    fetchSubscribedToIMs(
      (response) => {
        commit('setSubscribedToIMs', response);
        if (cb) cb();
      },
      (err) => {
        if (ecb) ecb(err);
        else console.log(err);
      }
    );
  },
  getSubscribedToRFCs({ commit }, { cb = null, ecb = null }) {
    fetchSubscribedToRFCs(
      (response) => {
        commit('setSubscribedToRFCs', response);
        if (cb) cb();
      },
      (err) => {
        if (ecb) ecb(err);
        else console.log(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  subscribeToIM({ commit, dispatch }, { id, cb = null, ecb = null }) {
    subscribeIMRequest(
      id,
      (response) => {
        if (cb) cb(response);
        dispatch('getSubscribedToIMs', {
          cb,
          ecb,
        });
      },
      (err) => {
        if (ecb) ecb(err);
        else console.log(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  unsubscribeToIM({ commit, dispatch }, { id, cb = null, ecb = null }) {
    unsubscribeIMRequest(
      id,
      (response) => {
        if (cb) cb(response);
        dispatch('getSubscribedToIMs', {
          cb,
          ecb,
        });
      },
      (err) => {
        if (ecb) ecb(err);
        else console.log(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  subscribeToRFC({ commit, dispatch }, { id, cb = null, ecb = null }) {
    subscribeRFCRequest(
      id,
      (response) => {
        if (cb) cb(response);
        dispatch('getSubscribedToRFCs', {
          cb,
          ecb,
        });
      },
      (err) => {
        if (ecb) ecb(err);
        else console.log(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  unsubscribeToRFC({ commit, dispatch }, { id, cb = null, ecb = null }) {
    unsubscribeRFCRequest(
      id,
      (response) => {
        if (cb) cb(response);
        dispatch('getSubscribedToRFCs', {
          cb,
          ecb,
        });
      },
      (err) => {
        if (ecb) ecb(err);
        else console.log(err);
      }
    );
  },
};

// mutations
const mutations = {
  logIn(state, data) {
    state.current = data;
    state.anonymous = false;
  },
  logInAnonymous(state) {
    state.current = null;
    state.anonymous = true;
  },
  register(state, data) {
    state.anonymous = false;
    state.current = data;
  },
  hydrateUser(state, data) {
    state.anonymous = false;
    state.current = data;
  },
  setErrors(state, data) {
    state.errors.push(data);
  },
  logOut(state) {
    state.current = null;
  },
  reset(state, data) {
    state.current = data;
  },
  setNewPass(state, data) {
    state.current = data;
  },
  setSubscribedToIMs(state, data) {
    state.imsSubscriptions = data;
  },
  setSubscribedToRFCs(state, data) {
    state.rfcsSubscriptions = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
