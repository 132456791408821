var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    { staticClass: "saved-search-layout" },
    [
      !_vm.savedSearches.length
        ? _c(
            "div",
            [
              _c("Headings", {
                attrs: {
                  title: _vm.$t(`common.search`),
                  subtitle: _vm.$t(`search.selectOne`),
                },
              }),
              _c("div", { staticClass: "search-selection" }, [
                _c(
                  "div",
                  {
                    staticClass: "cols left",
                    on: {
                      click: function ($event) {
                        return _vm.goto("NS")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "icon-selection icon-ns" }, [
                      _c("img", { attrs: { src: _vm.nsSearch } }),
                    ]),
                    _c("div", { staticClass: "type pb-0" }, [
                      _c("b", [
                        _vm._v(_vm._s(_vm.$t(`common.networkStatements`))),
                      ]),
                    ]),
                    _c("div", { staticClass: "type mt-0 pt-0" }, [
                      _vm._v(_vm._s(_vm.$t(`common.search`))),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "cols right",
                    on: {
                      click: function ($event) {
                        return _vm.goto("CID")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "icon-selection icon-cid" }, [
                      _c("img", { attrs: { src: _vm.cidSearch } }),
                    ]),
                    _c("div", { staticClass: "type pb-0" }, [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t(`common.corridorInformationDocuments`))
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "type mt-0 pt-0" }, [
                      _vm._v(_vm._s(_vm.$t(`common.search`))),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _c("div", { staticClass: "search-selection-saved" }, [
            _c(
              "div",
              { staticClass: "cols left" },
              [
                _c("Headings", {
                  attrs: {
                    title: _vm.$t(`common.search`),
                    subtitle: _vm.$t(`search.selectOne`),
                  },
                }),
                _c(
                  "div",
                  [
                    _c(
                      "v-container",
                      {
                        staticClass: "mb-4 search-link",
                        on: {
                          click: function ($event) {
                            return _vm.goto("NS")
                          },
                        },
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { justify: "start" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "icon-selection-saved-searches icon-ns",
                              },
                              [_c("img", { attrs: { src: _vm.nsSearch } })]
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                { staticClass: "type saved-search-font pb-0" },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.$t(`common.networkStatements`))
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "type saved-search-font saved-search-subtitle mt-0 pt-0",
                                },
                                [_vm._v(_vm._s(_vm.$t(`common.newSearch`)))]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-container",
                      {
                        staticClass: "search-link",
                        on: {
                          click: function ($event) {
                            return _vm.goto("CID")
                          },
                        },
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { justify: "start" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "icon-selection-saved-searches icon-cid",
                              },
                              [_c("img", { attrs: { src: _vm.cidSearch } })]
                            ),
                            _c("v-col", [
                              _c(
                                "div",
                                { staticClass: "type saved-search-font pb-0" },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          `common.corridorInformationDocuments`
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "type saved-search-font saved-search-subtitle mt-0 pt-0",
                                },
                                [_vm._v(_vm._s(_vm.$t(`common.newSearch`)))]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "cols right" },
              [
                _c("Headings", {
                  attrs: { title: "\u2800", subtitle: "Saved searches" },
                }),
                _c(
                  "v-list",
                  {
                    staticClass: "overflow-y-auto saved-searches",
                    attrs: { "max-height": "300" },
                  },
                  _vm._l(_vm.savedSearches, function (item, index) {
                    return _c(
                      "v-list-item",
                      {
                        key: index,
                        attrs: { value: item, "active-color": "primary" },
                      },
                      [
                        _c("saved-search-card", {
                          attrs: {
                            item: item,
                            "delete-item-callback": _vm.openDeleteSearchDialog,
                            "open-info": _vm.openInfo,
                            "goto-load": _vm.gotoLoad,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
      !_vm.isLoggedIn
        ? _c("div", { staticClass: "login-block" }, [
            _c(
              "div",
              {
                staticClass:
                  "btn btn-white btn-medium btn-login-search btn-login-register",
                on: { click: _vm.gotoLogin },
              },
              [_vm._v(" " + _vm._s(_vm.$t(`common.login`)) + " ")]
            ),
            _c(
              "div",
              {
                staticClass:
                  "btn btn-white btn-medium btn-register-search btn-login-register",
                on: { click: _vm.gotoRegister },
              },
              [_vm._v(" " + _vm._s(_vm.$t(`common.register`)) + " ")]
            ),
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.$t(`search.notifications`)) },
            }),
          ])
        : _vm._e(),
      _c("ManageSearchModal", {
        staticStyle: { "z-index": "20001" },
        attrs: {
          "is-modal-open": _vm.isInfoSearchOpen,
          "close-dialog": _vm.closeInfoSearchDialog,
          search: _vm.infoSearch,
          "is-info": true,
        },
      }),
      _c("DeleteSearchModal", {
        staticStyle: { "z-index": "20001" },
        attrs: {
          "is-modal-open": _vm.isDeleteSearchOpen,
          "close-dialog": _vm.closeDeleteSearchDialog,
          search: _vm.forDelete,
          "is-info": true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }