import axios from 'axios';
import { mocks } from './mocks';
import router from '../router';

const API_URL = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_SERVER_URL;
export const axiosInstance = axios.create({
  baseURL: API_URL,
  //timeout: 300000
});

axiosInstance.interceptors.request.use(
  function (config) {
    const sessionID = window.localStorage.getItem('sessionID');
    config.headers = {
      'Content-Type': 'application/json',
      ...config.headers,
      'x-auth-token': sessionID,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (!Object.hasOwnProperty.call(error, 'response')) {
      return Promise.reject(error);
    } else if (error.response.status === 401) {
      if (window.localStorage.getItem('anonymous') !== 'true') {
        window.localStorage.removeItem('sessionID');
      }
      return error.response.data;
    } else if (error.response.status === 403) {
      window.localStorage.removeItem('sessionID');
      router.push({
        name: 'LandingPage',
      });
      return Promise.reject((error.response.data || {}).message || error);
    } else if (error.response.status === 400) {
      return Promise.reject((error.response.data || {}).message || error);
    }
    return Promise.reject(error);
  }
);

mocks(axiosInstance);

export const handleRestAPIError = function (error) {
  console.error('Error', error);
};
