var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isModalOpen
    ? _c("div", { staticClass: "modal" }, [
        _vm.stillLoading
          ? _c("div", { staticClass: "lds-ellipsis" }, [
              _c("div"),
              _c("div"),
              _c("div"),
              _c("div"),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "modal-content",
            class: !_vm.stillLoading ? "d-block" : "d-none",
          },
          [
            _c("div", { staticClass: "close_btn" }, [
              _c(
                "span",
                { staticClass: "close", on: { click: _vm.closeDialog } },
                [_vm._v(_vm._s(_vm.closeBtn))]
              ),
            ]),
            _c("p", { staticClass: "header" }, [_vm._t("header")], 2),
            _c("div", { staticClass: "body" }, [_vm._t("default")], 2),
            _c("div", { staticClass: "footer" }, [_vm._t("footer")], 2),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }