<template>
  <DefaultLayout>
    <v-tabs center-active class="flex-grow-0">
      <v-tab :to="{ name: 'StatGlobal' }">{{ $t('statistics.tab_names.global_statistics') }}</v-tab>
      <v-tab :to="{ name: 'StatGlobalSearch' }">{{ $t('statistics.tab_names.global_search_statistics') }}</v-tab>
      <v-tab :to="{ name: 'StatSearchOrg', params: { type: 'NS' } }">{{
        $t('statistics.tab_names.ns_statistics')
      }}</v-tab>
      <v-tab :to="{ name: 'StatSearchOrg', params: { type: 'CID' } }">{{
        $t('statistics.tab_names.cid_statistics')
      }}</v-tab>
    </v-tabs>
    <v-tabs-items :value="0" class="bordered_tab_items flex-grow-1">
      <v-tab-item>
        <router-view :key="$route.path" />
      </v-tab-item>
    </v-tabs-items>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout.vue';

export default {
  name: 'StatsPage',
  components: {
    DefaultLayout,
  },
};
</script>

<style lang="scss">
.content-col.statglobal,
.content-col.statglobalsearch,
.content-col.statsearchns,
.content-col.statsearchcid {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.v-tabs-items.bordered_tab_items {
  height: 100%;

  .v-window-item--active {
    flex-grow: 1;
    border: 1px solid #e3e3e3;
    border-top-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.tbl_stats.v-data-table table {
  border: 1px solid #e0e0e0;
  width: auto;

  th,
  td {
    width: auto;
  }
}
</style>
