var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "headings" },
        [
          _c(
            "div",
            {
              staticClass: "h-container",
              class: { "h-container-gold": _vm.useGoldBackStyle },
            },
            [
              _vm.backLocation || _vm.back
                ? _c(
                    "div",
                    {
                      staticClass: "back-arrow-container",
                      class: { "gold-button-container": _vm.useGoldBackStyle },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "material-icons btn-heading-back d-inline-block back-icon",
                          class: { "back-icon-gold": _vm.useGoldBackStyle },
                          on: { click: _vm.goBack },
                        },
                        [_vm._v(_vm._s(_vm.icons.arrowBack) + " ")]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { class: { "text-headings-container": _vm.useGoldBackStyle } },
                [
                  _c(
                    "h3",
                    {
                      staticClass: "heading1 mb-2 mt-0 btn-heading-back",
                      on: { click: _vm.goBack },
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                  _c("h2", { staticClass: "heading2 mt-0" }, [
                    _vm._v(_vm._s(_vm.subtitle)),
                  ]),
                ]
              ),
            ]
          ),
          _vm._t("default"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }