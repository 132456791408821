<template>
  <div>
    <LoadingComponent :loading.sync="isLoading" />
    <Modal :is-modal-open="isModalOpen" :close-dialog="closeDialog" class="subscriptions-modal">
      <template #header> {{ $t(`common.password`) }} </template>
      <v-row>
        <InputField
          :name="$t(`common.password`)"
          :tabindex="2"
          :use-model="true"
          :error="error"
          type="password"
          required
          class="font-weight-bold py-0 mb-10 w-100"
          @update="setUserPassword($event)"
        />
      </v-row>
      <v-row>
        <InputField
          :name="$t(`common.confirmPassword`)"
          :tabindex="2"
          :use-model="true"
          :error="error"
          type="password"
          class="font-weight-bold w-100"
          @update="setUserConfirmPassword($event)"
        />
      </v-row>
      <template #footer>
        <v-row>
          <v-col cols="12">
            <button class="btn btn-green btn-change-password" @click="changePassword">
              {{ $t(`common.setPassword`) }}
            </button>
            <button class="btn" @click="closeDialog">
              {{ $t(`common.close`) }}
            </button>
          </v-col>
        </v-row>
        <span class="change-pass-error">{{ errorRequest }}</span>
      </template>
    </Modal>

    <Modal :is-modal-open="isModalConfirmOpen" :close-dialog="closeConfirmDialog">
      <template #header>
        <div class="modal-heading mb-10">
          {{ $t('profile.change_user_password') }}
        </div>
      </template>
      <template #footer>
        <div class="row">
          <div class="col-3" />
          <div class="col-3">
            <button class="modal-button-cancel" @click="confirmChangePassword">
              {{ $t(`common.yes`) }}
            </button>
          </div>
          <div class="col-3">
            <button class="modal-button-confirm" @click="closeConfirmDialog">
              {{ $t(`common.no`) }}
            </button>
          </div>
          <div class="col-3" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '@/components/base/Modal';
import InputField from '@/components/base/InputField';
import LoadingComponent from '@/components/base/LoadingComponent';
import { isPasswordValid } from '@/utils/utils.js';

export default {
  name: 'ChangeUserPassword',
  components: {
    Modal,
    InputField,
    LoadingComponent,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      userPassword: null,
      userConfirmPassword: null,
      isModalConfirmOpen: false,
      error: null,
      errorRequest: '',
      isLoading: false,
    };
  },
  methods: {
    ...mapActions('auth', ['changeUserPass']),
    setUserPassword(value) {
      this.userPassword = value;
      this.error = null;
    },
    setUserConfirmPassword(value) {
      this.userConfirmPassword = value;
      this.error = null;
    },
    changePassword() {
      this.errorRequest = null;
      if (!this.userPassword || !this.userConfirmPassword) {
        this.error = "Can't submit empty password";
        return;
      }
      if (this.userPassword === this.userConfirmPassword) {
        const isValidPassword = isPasswordValid(this.userPassword);
        if (!isValidPassword) {
          this.error = this.$t('validationRules.password_rules');
          return;
        }
        this.isModalConfirmOpen = true;
      } else {
        this.error = this.$t('validationRules.passwords_differ');
      }
    },
    confirmChangePassword() {
      this.isLoading = true;
      const data = {
        newPassword: this.userPassword,
      };

      this.changeUserPass({
        data,
        userId: this.userId,
        cb: () => {
          this.isLoading = false;
          this.closeConfirmDialog();
          this.closeDialog();
        },
        ecb: () => {
          this.isLoading = false;
          this.closeConfirmDialog();
          this.errorRequest = this.$t(`profile.change_pass_error`);
        },
      });
    },
    closeConfirmDialog() {
      this.isModalConfirmOpen = false;
    },
  },
};
</script>

<style>
.btn-change-password,
.btn-change-password:hover,
.btn-change-password:focus {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}

.change-pass-error {
  color: firebrick;
  display: block;
  height: 12px;
  text-align: center;
  font-size: 1.1em;
}
</style>
