var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "admin-content" }, [
    !_vm.anonymous
      ? _c(
          "div",
          { staticClass: "nav-col navbar-col" },
          [_c("UserNavDrawer")],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "content-col" }, [_vm._t("default")], 2),
    _c(
      "div",
      { staticClass: "nav-col", class: `navbar-col ${_vm.currentRoute}` },
      [_c("SearchNavDrawer")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }