import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import DialogPromise from 'vuetify-dialog-promise';

Vue.use(Vuetify);
Vue.use(DialogPromise, {
  locale: 'en',
  snackbarX: 'center',
  snackbarY: 'bottom',
});

export default new Vuetify({
  theme: {
    disable: true,
  },
});
