<template>
  <Modal :is-modal-open="isModalOpen" :close-dialog="closeDialog" class="subscriptions-modal">
    <template #header> {{ $t(`users.subscriptions`) }} ({{ totalSubscriptions || 0 }}) </template>
    <v-row>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          background-color="white"
          class="tabs-header"
          :centered="false"
          :grow="false"
          :vertical="false"
          :right="false"
        >
          <v-tabs-slider />

          <v-tab href="#tab-im">{{ $t(`common.ns`) }}</v-tab>
          <v-tab href="#tab-rfc">{{ $t(`common.cid`) }}</v-tab>

          <v-tab-item value="tab-im">
            <v-card flat tile>
              <v-card-text>
                <div class="table-ims">
                  <table>
                    <thead class="table-head-shadow">
                      <tr>
                        <th v-for="(header, index) in imHeaders" :key="index">
                          {{ header.name }}
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(im, index) in ims" :key="index">
                        <td>
                          <img class="logo-preview" :src="getIMLogo(im.id)" alt />
                          {{ im.name }}
                        </td>
                        <td class="subscribe-button-container" :set="(subscribed = isSubscribed('im', im.id))">
                          <button
                            :class="subscribed ? 'btn-white' : 'btn-gold'"
                            class="btn subscribe-button"
                            @click="subscribeOrUnsubscribe(subscribed, 'im', im.id)"
                          >
                            {{ $t(subscribed ? `users.unsubscribe` : `users.subscribe`) }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-rfc">
            <v-card flat tile>
              <v-card-text>
                <div class="table-rfcs">
                  <table>
                    <thead class="table-head-shadow">
                      <tr>
                        <th v-for="(header, index) in rfcHeaders" :key="index">
                          {{ header.name }}
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(rfc, index) in rfcs" :key="index">
                        <td>
                          <img class="logo-preview" :src="getRFCLogo(rfc.id)" alt />
                          {{ rfc.name }}
                        </td>
                        <td class="subscribe-button-container" :set="(subscribed = isSubscribed('rfc', rfc.id))">
                          <button
                            :class="subscribed ? 'btn-gold' : 'btn-white'"
                            class="btn subscribe-button"
                            @click="subscribeOrUnsubscribe(subscribed, 'rfc', rfc.id)"
                          >
                            {{ $t(subscribed ? `users.unsubscribe` : `users.subscribe`) }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <template #footer>
      <v-row>
        <v-col cols="12">
          <button class="btn btn-green btn-save-subscriptions" @click="closeDialog">
            {{ $t(`common.save`) }}
          </button>
        </v-col>
      </v-row>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/base/Modal';
import { IMS_LOGO_URL, RFCS_LOGO_URL } from '@/utils/api.endpoints';

export default {
  name: 'ManageSubscriptions',
  components: {
    Modal,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      tab: null,
      newSubscribedIMs: [],
      newSubscribedRFCs: [],
    };
  },
  computed: {
    ...mapState({
      subscribedIMs(state) {
        this.newSubscribedIMs = state.auth.imsSubscriptions;
        return state.auth.imsSubscriptions;
      },
      subscribedRFCs(state) {
        this.newSubscribedRFCs = state.auth.rfcsSubscriptions;
        return state.auth.rfcsSubscriptions;
      },
      totalSubscriptions: (state) => state.auth.imsSubscriptions.length + state.auth.rfcsSubscriptions.length,
      rfcs: (state) => state.rfcs.data,
      ims: (state) => state.ims.data,
    }),
    imHeaders() {
      return [
        {
          name: this.$t('ims.name'),
          id: 'id',
        },
      ];
    },
    rfcHeaders() {
      return [
        {
          name: this.$t('rfcs.name'),
          id: 'id',
        },
      ];
    },
  },
  created() {
    this.getAllRFCs({});
    this.getAllIMs({});
    this.getSubscribedToIMs({});
    this.getSubscribedToRFCs({});
  },
  methods: {
    ...mapActions('auth', [
      'getSubscribedToIMs',
      'getSubscribedToRFCs',
      'subscribeToIM',
      'unsubscribeToIM',
      'subscribeToRFC',
      'unsubscribeToRFC',
    ]),
    ...mapActions('rfcs', ['getAllRFCs']),
    ...mapActions('ims', ['getAllIMs']),
    getIMLogo(id) {
      return `${this.$serverUrl}${IMS_LOGO_URL(id)}`;
    },
    getRFCLogo(id) {
      return `${this.$serverUrl}${RFCS_LOGO_URL(id)}`;
    },
    isSubscribed(type, id) {
      return type === 'im'
        ? this.subscribedIMs?.some((im) => im.id === id)
        : this.subscribedRFCs?.some((im) => im.id === id);
    },
    save() {
      //
    },
    subscribeOrUnsubscribe(isSubscribed, type, id) {
      if (this.isSubscribed(type, id)) {
        if (type === 'im') {
          this.unsubscribeToIM({ id });
        } else {
          this.unsubscribeToRFC({ id });
        }
      } else {
        if (type === 'im') {
          this.subscribeToIM({ id });
        } else {
          this.subscribeToRFC({ id });
        }
      }
    },
  },
};
</script>

<style scoped>
>>> .v-tabs-bar__content {
  background: transparent;
}

>>> .modal-content {
  max-height: 85vh;
}

>>> .v-tabs-items {
  overflow-y: scroll;
  max-height: 51vh;
}

table {
  table-layout: auto;
  width: 800px;
}

.subscribe-button-container {
  padding: 10px;
}

.subscribe-button {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #404040;
  height: 32px;
  width: 100px;
  padding-left: 5px;
  padding-right: 5px;
}

.btn-save-subscriptions {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}

>>> .footer {
  margin-top: 0px !important;
}

>>> .tabs-header {
  border-bottom: 1px solid #e3e3e3;
}

.btn-white {
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>
