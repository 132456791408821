var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "v-row",
        { staticClass: "profile-container" },
        [
          _c(
            "v-col",
            { staticClass: "top-info-container", attrs: { cols: "7" } },
            [
              _c(
                "v-row",
                { staticClass: "border-shadow pb-4" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "name-photo",
                      attrs: { cols: "12", sm: "3" },
                    },
                    [
                      _c("div", { staticClass: "name-photo-container" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formattedFirstLettersOfName)),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "name-role profile-page",
                      attrs: { cols: "12", sm: "9" },
                    },
                    [
                      _c("div", { staticClass: "name-role-container" }, [
                        _c("div", { staticClass: "name-container" }, [
                          _c("span", { staticClass: "first-name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.profile ? _vm.profile.firstName : ""
                                ) +
                                " "
                            ),
                          ]),
                          _c("span", { staticClass: "last-name ml-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.profile ? _vm.profile.familyName : ""
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "mt-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                { staticClass: "user-profile-title mb-3" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t(`users.userProfile`)) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-form",
                                {
                                  ref: "formChangePassword",
                                  attrs: {
                                    "lazy-validation": "",
                                    novalidate: "true",
                                  },
                                },
                                [
                                  _vm.profile
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "12",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("InputField", {
                                                staticClass: "font-weight-bold",
                                                attrs: {
                                                  name: _vm.$t(
                                                    `common.firstName`
                                                  ),
                                                  tabindex: 1,
                                                  value: _vm.profile.firstName,
                                                  "use-model": true,
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.profile,
                                                      "firstName",
                                                      $event
                                                    )
                                                  },
                                                  update: function ($event) {
                                                    _vm.userFirstName = $event
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "12",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("InputField", {
                                                staticClass: "font-weight-bold",
                                                attrs: {
                                                  name: _vm.$t(
                                                    `common.lastName`
                                                  ),
                                                  tabindex: 2,
                                                  value: _vm.profile.familyName,
                                                  "use-model": true,
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.profile,
                                                      "familyName",
                                                      $event
                                                    )
                                                  },
                                                  update: function ($event) {
                                                    _vm.userLastName = $event
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "12",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("InputField", {
                                                staticClass: "font-weight-bold",
                                                attrs: {
                                                  name: _vm.$t(
                                                    `common.username`
                                                  ),
                                                  tabindex: 3,
                                                  value: _vm.profile.email,
                                                  "use-model": true,
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.profile,
                                                      "email",
                                                      $event
                                                    )
                                                  },
                                                  update: function ($event) {
                                                    _vm.userEmail = $event
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "12",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("SelectComponent", {
                                                staticClass: "selection-bold",
                                                attrs: {
                                                  tabindex: 4,
                                                  options: _vm.countries,
                                                  name: _vm.$t(
                                                    `search.country`
                                                  ),
                                                  "obj-key": "name",
                                                  "obj-val": "value",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.countryID = $event
                                                  },
                                                },
                                                model: {
                                                  value: _vm.countryID,
                                                  callback: function ($$v) {
                                                    _vm.countryID = $$v
                                                  },
                                                  expression: "countryID",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "12",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("InputField", {
                                                staticClass: "selection-bold",
                                                attrs: {
                                                  name: _vm.$t(
                                                    `common.companyName`
                                                  ),
                                                  value: _vm.userCompany,
                                                  "use-model": true,
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    _vm.userCompany = $event
                                                  },
                                                  update: function ($event) {
                                                    _vm.userCompany = $event
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "12",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c("SelectComponent", {
                                                staticClass: "selection-bold",
                                                attrs: {
                                                  tabindex: 6,
                                                  options: _vm.entities,
                                                  name: _vm.$t(
                                                    `common.typeOfEntity`
                                                  ),
                                                  "obj-key": "name",
                                                  "obj-val": "value",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.userEntity = $event
                                                  },
                                                },
                                                model: {
                                                  value: _vm.userEntity,
                                                  callback: function ($$v) {
                                                    _vm.userEntity = $$v
                                                  },
                                                  expression: "userEntity",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "mt-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                { staticClass: "user-profile-title mb-3" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t(`users.changePassword`)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("InputField", {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  name: _vm.$t(`users.oldPassword`),
                                  tabindex: 7,
                                  value: _vm.oldPassword,
                                  "use-model": true,
                                  type: "password",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    _vm.oldPassword = $event
                                  },
                                  update: function ($event) {
                                    _vm.oldPassword = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-col", {
                            attrs: { cols: "12", sm: "0", md: "6" },
                          }),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("InputField", {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  name: _vm.$t(`users.newPassword`),
                                  tabindex: 8,
                                  value: _vm.password,
                                  "use-model": true,
                                  type: "password",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    _vm.password = $event
                                  },
                                  update: function ($event) {
                                    _vm.password = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("InputField", {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  name: _vm.$t(`users.confirmNewPassword`),
                                  tabindex: 9,
                                  value: _vm.confirmPassword,
                                  "use-model": true,
                                  type: "password",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    _vm.confirmPassword = $event
                                  },
                                  update: function ($event) {
                                    _vm.confirmPassword = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "user-profile-title mb-3" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t(`users.deleteProfile`)) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-medium btn-white",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteConfirm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t(`users.deleteMyProfile`)) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-green",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.updateUser.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t(`common.save`)) + " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "subscribe-sidebar", attrs: { cols: "5" } },
            [
              _c(
                "div",
                { staticClass: "subscribe-wrapper" },
                [
                  _c("div", { staticClass: "user-profile-title mb-3" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t(`users.notificationSettings`)) + " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "access_additional_switch_container" },
                    [
                      _c(
                        "div",
                        { staticClass: "switch_container mt-1" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "p-0",
                                  attrs: { cols: "12", md: "7" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mb-0",
                                      attrs: { id: "access_additional_txt" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(`users.receiveNotifications`)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "switch-container p-0",
                                  attrs: { cols: "12", md: "5" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "switch_options_container" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          class: {
                                            "switch-selected":
                                              _vm.notificationsEnabled,
                                          },
                                          attrs: { id: "yes_option_label" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .$t(`common.yes`)
                                                  .toUpperCase()
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("label", { staticClass: "switch" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.notificationsEnabled,
                                              expression:
                                                "notificationsEnabled",
                                            },
                                          ],
                                          class: {
                                            checked: _vm.notificationsEnabled,
                                          },
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.notificationsEnabled
                                            )
                                              ? _vm._i(
                                                  _vm.notificationsEnabled,
                                                  null
                                                ) > -1
                                              : _vm.notificationsEnabled,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.notificationsEnabled,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.notificationsEnabled =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.notificationsEnabled =
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                }
                                              } else {
                                                _vm.notificationsEnabled = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "slider round",
                                        }),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          class: {
                                            "switch-selected":
                                              !_vm.notificationsEnabled,
                                          },
                                          attrs: { id: "no_option_label" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .$t(`common.no`)
                                                  .toUpperCase()
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "switch_container mt-1" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "p-0",
                                  attrs: { cols: "12", md: "7" },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mb-0",
                                      attrs: { id: "access_additional_txt" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              `users.receiveInformationalEmails`
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "switch-container p-0",
                                  attrs: { cols: "12", md: "5" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "switch_options_container" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          class: {
                                            "switch-selected":
                                              _vm.subscribedNewsletter,
                                          },
                                          attrs: { id: "yes_option_label" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .$t(`common.yes`)
                                                  .toUpperCase()
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("label", { staticClass: "switch" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.subscribedNewsletter,
                                              expression:
                                                "subscribedNewsletter",
                                            },
                                          ],
                                          class: {
                                            checked: _vm.subscribedNewsletter,
                                          },
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.subscribedNewsletter
                                            )
                                              ? _vm._i(
                                                  _vm.subscribedNewsletter,
                                                  null
                                                ) > -1
                                              : _vm.subscribedNewsletter,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.subscribedNewsletter,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.subscribedNewsletter =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.subscribedNewsletter =
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                }
                                              } else {
                                                _vm.subscribedNewsletter = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _c("span", {
                                          staticClass: "slider round",
                                        }),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          class: {
                                            "switch-selected":
                                              !_vm.subscribedNewsletter,
                                          },
                                          attrs: { id: "no_option_label" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .$t(`common.no`)
                                                  .toUpperCase()
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "user-profile-title mt-5 mb-0" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t(`users.manageSubscriptions`)) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-green",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.isModalOpen = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t(`users.manageSubscriptions`)) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("span", { staticClass: "regular-text" }, [
                          _vm._v(_vm._s(_vm.$t(`users.youHave`))),
                        ]),
                        _c("span", { staticClass: "bold-text pl-1" }, [
                          _vm._v(_vm._s(_vm.$t(`users.subscribedTo`))),
                        ]),
                        _c("span", { staticClass: "gold-text pl-1" }, [
                          _vm._v(
                            " " + _vm._s(_vm.subscribedIMsCount() || 0) + " "
                          ),
                        ]),
                        _c("span", { staticClass: "bold-text pl-1" }, [
                          _vm._v(_vm._s(_vm.$t(`common.nss`))),
                        ]),
                        _c("span", { staticClass: "regular-text pl-1" }, [
                          _vm._v(_vm._s(_vm.$t(`common.and`))),
                        ]),
                        _c("span", { staticClass: "gold-text pl-1" }, [
                          _vm._v(
                            " " + _vm._s(_vm.subscribedRFCsCount() || 0) + " "
                          ),
                        ]),
                        _c("span", { staticClass: "bold-text pl-1" }, [
                          _vm._v(_vm._s(_vm.$t(`common.cids`))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("Modal", {
        attrs: {
          "is-modal-open": _vm.deleteModalVisible,
          "close-dialog": _vm.toggleDeleteModal,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-heading mb-10" }, [
                  _vm._v(" " + _vm._s(_vm.$t("profile.really_delete")) + " "),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-3" }),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-cancel",
                        on: { click: _vm.confirmDelete },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.yes`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-confirm",
                        on: { click: _vm.toggleDeleteModal },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.no`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Modal", {
        attrs: {
          "is-modal-open": _vm.deleteModalDoneVisible,
          "close-dialog": _vm.toggleDeleteModalDone,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-heading mb-10" }, [
                  _vm._v(_vm._s(_vm.deleteStatus)),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-cancel",
                        on: { click: _vm.goToParentPage },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.ok`)) + " ")]
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("ManageSubscriptions", {
        attrs: {
          "is-modal-open": _vm.isModalOpen,
          "close-dialog": _vm.closeDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }