import { axiosInstance } from '../utils/axios.js';
import {
  GET_ADMIN_USERS,
  GET_USERS_BY_CRITERIA,
  GET_USER_BY_ID,
  UPDATE_USER_BY_ID,
  GRANT_MASTER_UPLOAD_ACCESS_TO_USER_BY_ID,
  REVOKE_MASTER_UPLOAD_ACCESS_TO_USER_BY_ID,
  DELETE_USER,
  DELETE_USER_BY_ID,
  ENTITIES_URL,
} from '../utils/api.endpoints';

export const getAllAdminUsersRequest = function (cb, ecb = null) {
  axiosInstance
    .get(GET_ADMIN_USERS)
    .then((response) => {
      const rfcs = response;
      if (cb) cb(rfcs);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const deleteUserRequest = function (cb = null) {
  axiosInstance.delete(DELETE_USER).then((resp) => {
    console.log(resp);
    if (cb) cb();
  });
};

export const deleteUserByIdRequest = function (id, cb = null, ecb = null) {
  axiosInstance
    .delete(DELETE_USER_BY_ID(id))
    .then(() => {
      if (cb) cb();
    })
    .catch(() => {
      if (ecb) ecb();
    });
};

export const fetchTypesOfEntitiesRequest = function (cb = null, ecb = null) {
  axiosInstance
    .get(ENTITIES_URL)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((err) => {
      if (ecb) ecb(err);
    });
};

export const searchUsersByCriteria = function (
  username,
  organizationType,
  role,
  orderBy,
  order,
  page,
  pageSize,
  cb,
  ecb = null
) {
  axiosInstance
    .get(GET_USERS_BY_CRITERIA(encodeURIComponent(username), organizationType, role, orderBy, order, page, pageSize))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const getUserByIdRequest = function (userId, cb, ecb) {
  axiosInstance
    .get(GET_USER_BY_ID(userId))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const updateUserByIdRequest = function (data, cb, ecb) {
  const { userId, ...dataObj } = data;
  axiosInstance
    .put(UPDATE_USER_BY_ID(userId), dataObj)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const grantMasterCIDPermissionsRequest = function (userId, cb, ecb) {
  axiosInstance
    .put(GRANT_MASTER_UPLOAD_ACCESS_TO_USER_BY_ID(userId))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const revokeMasterCIDPermissionsRequest = function (userId, cb, ecb) {
  axiosInstance
    .put(REVOKE_MASTER_UPLOAD_ACCESS_TO_USER_BY_ID(userId))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export default {};
