<template>
  <div>
    <LoadingComponent :loading="loading" />
    <div class="d-flex justify-center py-3">
      <DateInput v-model="fromDate" :label="$t('statistics.global_search.since_date')" outlined dense classes="mx-2" />
      <DateInput v-model="toDate" :label="$t('statistics.global_search.until_date')" outlined dense classes="mx-2" />
      <v-select
        v-model="searchParams.groupBy"
        :label="$t('statistics.global_search.group_by')"
        :items="groupByList"
        outlined
        dense
        class="flex-grow-0 mx-2"
        menu-props="offsetY"
      />
      <v-select
        v-if="searchParams.groupBy === 'PERIOD'"
        v-model="searchParams.groupByPeriod"
        :label="$t('statistics.global_search.group_by_period')"
        :items="groupByPeriodList"
        outlined
        dense
        class="flex-grow-0 mx-2"
        menu-props="offsetY"
      />
      <v-btn color="primary" class="mx-2" @click="fetchData">{{ $t('common.search') }}</v-btn>
    </div>
    <div class="d-flex justify-center">
      <v-simple-table class="stripe tbl_stats">
        <thead>
          <tr>
            <th>{{ $t('statistics.global_search.document_type') }}</th>
            <th class="text-capitalize">{{ groupLabel }}</th>
            <th nowrap>{{ $t('statistics.global_search.number_searches') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, idx) in results" :key="idx">
            <td>{{ row.documentType || $t('statistics.global_search.total') }}</td>
            <td nowrap>{{ row[groupValue] || $t('statistics.global_search.full_period') }}</td>
            <td style="text-align: center">{{ row.numberOfSearches }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import LoadingComponent from '@/components/base/LoadingComponent.vue';
import DateInput from '@/components/base/DateInput.vue';

export default {
  name: 'StatsGlobalSearch',
  components: {
    LoadingComponent,
    DateInput,
  },
  data() {
    return {
      loading: false,
      results: [],
      fromDate: '',
      toDate: '',
      groupBy: 'PERIOD',
      groupByPeriod: 'MONTHLY',
      searchParams: {
        groupBy: 'PERIOD',
        groupByPeriod: 'MONTHLY',
      },
    };
  },
  computed: {
    groupValue() {
      return {
        PERIOD: 'period',
        ORGANIZATION: 'organization',
        TYPE_OF_USER: 'typeOfUser',
      }[this.groupBy];
    },
    groupLabel() {
      switch (this.groupBy) {
        case 'ORGANIZATION':
          return this.$t('statistics.global_search.group_organization');
        case 'TYPE_OF_USER':
          return this.$t('statistics.global_search.user_type');
        default:
          return {
            DAILY: this.$t('statistics.global_search.header_day'),
            WEEKLY: this.$t('statistics.global_search.header_week'),
            MONTHLY: this.$t('statistics.global_search.header_month'),
            YEARLY: this.$t('statistics.global_search.header_year'),
          }[this.groupByPeriod];
      }
    },
    groupByList() {
      return [
        {
          value: 'PERIOD',
          text: this.$t('statistics.global_search.group_period'),
        },
        {
          value: 'ORGANIZATION',
          text: this.$t('statistics.global_search.group_organization'),
        },
        {
          value: 'TYPE_OF_USER',
          text: this.$t('statistics.global_search.group_user'),
        },
      ];
    },
    groupByPeriodList() {
      return [
        {
          value: 'DAILY',
          text: this.$t('statistics.global_search.period_daily'),
        },
        {
          value: 'WEEKLY',
          text: this.$t('statistics.global_search.period_weekly'),
        },
        {
          value: 'MONTHLY',
          text: this.$t('statistics.global_search.period_monthly'),
        },
        {
          value: 'YEARLY',
          text: this.$t('statistics.global_search.period_yearly'),
        },
      ];
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.groupBy = this.searchParams.groupBy;
      this.groupByPeriod = this.searchParams.groupByPeriod;
      this.$axios
        .get('statistics/searches', {
          params: {
            sinceDate: this.fromDate || '2000-01-01',
            untilDate: this.toDate || '2100-01-01',
            groupBy: this.groupBy,
            groupByPeriod: this.groupByPeriod,
          },
        })
        .then((response) => {
          this.results = response;
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
