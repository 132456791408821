var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { class: _vm.leftColumnClass, attrs: { id: "container_col" } },
      [_vm._t("default")],
      2
    ),
    _c(
      "div",
      { class: _vm.rightColumnClass, attrs: { id: "background_image_col" } },
      [
        _c("img", {
          staticClass: "backgound_image",
          attrs: { src: require("@/assets/background.png") },
        }),
        _vm.show
          ? _c("div", { attrs: { id: "headings" } }, [
              _c("h2", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t(`landingPage['RNE NCI']`)),
                },
              }),
              _c("h2", [_vm._v(_vm._s(_vm.$t(`landingPage.${_vm.route}`)))]),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }