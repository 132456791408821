var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _vm.name
      ? _c("div", [
          _c("label", { staticClass: "select-label" }, [
            _vm._v(_vm._s(_vm.name)),
          ]),
        ])
      : _vm._e(),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectModel,
            expression: "selectModel",
          },
        ],
        class: {
          "has-error": _vm.error,
          changed: _vm.changed,
          "as-disabled": _vm.isDisabled,
        },
        attrs: { disabled: _vm.isDisabled, tabindex: _vm.tabindex },
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.selectModel = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      [
        _vm.placeholder
          ? _c(
              "option",
              {
                attrs: { selected: "", hidden: "" },
                domProps: { value: null },
              },
              [_vm._v(_vm._s(_vm.placeholder))]
            )
          : _vm._e(),
        _vm._l(_vm.options, function (a, index) {
          return _c(
            "option",
            {
              key: index,
              attrs: { disabled: a.disabled },
              domProps: { value: a[_vm.objVal] },
            },
            [_vm._v(" " + _vm._s(a[_vm.objKey]) + " ")]
          )
        }),
      ],
      2
    ),
    _vm.error
      ? _c("span", { staticClass: "form-error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }