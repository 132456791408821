var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LoadingComponent", { attrs: { loading: _vm.loading } }),
      _c(
        "div",
        { staticClass: "d-flex justify-center py-3" },
        [
          _c("DateInput", {
            attrs: {
              label: _vm.$t("statistics.search_org.since_date"),
              outlined: "",
              dense: "",
              classes: "mx-2",
            },
            model: {
              value: _vm.fromDate,
              callback: function ($$v) {
                _vm.fromDate = $$v
              },
              expression: "fromDate",
            },
          }),
          _c("DateInput", {
            attrs: {
              label: _vm.$t("statistics.search_org.until_date"),
              outlined: "",
              dense: "",
              classes: "mx-2",
            },
            model: {
              value: _vm.toDate,
              callback: function ($$v) {
                _vm.toDate = $$v
              },
              expression: "toDate",
            },
          }),
          _c("v-select", {
            staticClass: "flex-grow-0 mx-2",
            attrs: {
              items: _vm.rfcList,
              outlined: "",
              dense: "",
              "menu-props": "offsetY",
              clearable: "",
              multiple: "",
            },
            model: {
              value: _vm.chosenRFC,
              callback: function ($$v) {
                _vm.chosenRFC = $$v
              },
              expression: "chosenRFC",
            },
          }),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { color: "primary" },
              on: { click: _vm.makeSearch },
            },
            [_vm._v(_vm._s(_vm.$t("common.search")))]
          ),
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          attrs: { "center-active": "" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _vm._l(_vm.structureYears, function (year, index) {
            return _c(
              "v-tab",
              { key: index, attrs: { href: `#tab-${year.id}` } },
              [
                _c("v-checkbox", {
                  staticClass: "mx-2",
                  attrs: { value: year.id, color: "#bf873d" },
                  on: {
                    change: function ($event) {
                      return _vm.checkDeselectEvent($event, year.id)
                    },
                  },
                  model: {
                    value: _vm.yearsSelected,
                    callback: function ($$v) {
                      _vm.yearsSelected = $$v
                    },
                    expression: "yearsSelected",
                  },
                }),
                _vm._v(" " + _vm._s(year.year) + " "),
              ],
              1
            )
          }),
          _vm._l(_vm.structureYears, function (year, index) {
            return _c(
              "v-tab-item",
              { key: index, attrs: { value: "tab-" + year.id } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "9" } },
                      [
                        _c("v-treeview", {
                          ref: "structure",
                          refInFor: true,
                          staticClass: "mt-5",
                          attrs: {
                            items: _vm.structureYears[index].levels,
                            open: _vm.expandedNodes[year.id],
                            "item-children": "levels",
                            "item-key": "id",
                            "item-text": "nameLocal",
                            "selection-type": "leaf",
                            selectable: "",
                            dense: "",
                            "multiple-active": "",
                            "selected-color": "#bf873d",
                          },
                          on: {
                            "update:open": function ($event) {
                              return _vm.$set(
                                _vm.expandedNodes,
                                year.id,
                                $event
                              )
                            },
                            input: function ($event) {
                              return _vm.onSingleSelect($event, year.id)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "span",
                                      { attrs: { id: item.id } },
                                      [
                                        item.parentId && item.csNumber
                                          ? [
                                              _vm._v(
                                                _vm._s(item.csNumber) + "."
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(item.nameLocal || item.nameEn) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.selectedChapters[year.id],
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedChapters, year.id, $$v)
                            },
                            expression: "selectedChapters[year.id]",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "content-right select-deselect",
                        attrs: { cols: "3" },
                      },
                      [
                        _c("div", { staticClass: "pt-1 pr-2" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-gold",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectAll(year.id)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(`common.selectAll`)) + " "
                              ),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text-gray",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.clearAll(year.id)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(`common.clearAll`)) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-center pa-4" },
        [
          _c("v-simple-table", { staticClass: "stripe tbl_stats" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        _vm.searchType === "NS" ? "common.im" : "common.rfc"
                      )
                    )
                  ),
                ]),
                _c("th", { attrs: { nowrap: "" } }, [
                  _vm._v(_vm._s(_vm.$t("statistics.search_org.tt_year"))),
                ]),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("statistics.search_org.chapter"))),
                ]),
                _c("th", { attrs: { nowrap: "" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("statistics.search_org.number_searches"))
                  ),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.results, function (row, idx) {
                return _c("tr", { key: idx }, [
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        row.organization ||
                          _vm.$t("statistics.search_org.all_selected")
                      )
                    ),
                  ]),
                  _c("td", { attrs: { nowrap: "" } }, [
                    _vm._v(
                      _vm._s(
                        row.year || _vm.$t("statistics.search_org.full_period")
                      )
                    ),
                  ]),
                  _c("td", [_vm._v(_vm._s(row.chapter))]),
                  _c("td", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(_vm._s(row.numberOfSearches)),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }