<template>
  <Modal :is-modal-open="isModalOpen" :close-dialog="closeDialog">
    <template #header>
      <div class="modal-heading mb-10 text-left">
        {{ $t(`documentManagement.downloadMasterCID`) }}
      </div>
      <div class="modal-text">
        <v-row>
          <v-col cols="10" offset-md="1">
            <SelectComponent
              v-model="selectedYear"
              :tabindex="2"
              :options="yearsDisplay"
              :name="$t('common.selectYearCID')"
              obj-key="value"
              obj-val="value"
              class="select-big"
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <template #footer>
      <v-row>
        <div class="col-1" />
        <div class="col-10">
          <button class="btn btn-green btn-big w-100 btn-upload-master-cid" @click="confirmMasterCIDUpload">
            {{ $t('documentManagement.downloadMasterCID') }}
          </button>
        </div>
      </v-row>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '@/components/base/Modal';
import SelectComponent from '@/components/base/SelectComponent';

export default {
  name: 'DownloadMasterCID',
  components: {
    Modal,
    SelectComponent,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function,
      default: null,
    },
    years: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedYear: null,
    };
  },
  computed: {
    yearsDisplay() {
      return this.years.filter((y) => y.value !== 'All');
    },
  },
  methods: {
    ...mapActions('documents', ['downloadDocuments']),
    confirmMasterCIDUpload() {
      this.downloadDocuments({ year: this.selectedYear });
    },
  },
};
</script>

<style scoped>
.w-600px {
  width: 600px;
}

>>> .modal-content {
  width: 700px;
}

>>> .modal-content .footer {
  margin-top: 0px;
}

>>> .modal-content .header {
  margin-bottom: 0px;
}

>>> .select-label {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

>>> select {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.btn-bottom-action {
  padding: 2px 15px;
  width: 100%;
}

.btn-search {
  font-family: Montserrat, sans-serif;
  font-size: 1.4em;
  font-weight: 500;
  min-width: 140px;
  height: 56px;
  float: right;
}

.btn-search-container {
  margin-top: 2.4em;
}

.btn-upload-master-cid,
.btn-cancel-upload-master-cid {
  font-size: 18px;
  padding: 2px 5px;
}
</style>
