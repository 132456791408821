import FileDownload from 'js-file-download';
import '@/utils/utils';
import {
  getCommonStructureSearch,
  searchResults,
  downloadResults,
  downloadChapter,
  downloadDocument,
  downloadAnnex,
  getVersioningRequest,
  getVersioningPublishedRequest,
  prevDocumentRequest,
  fetchDocumentByIdRequest,
  downloadChapterWithAnnexesRequest,
  downloadDocumentOriginal,
  downloadFullDocumentZip,
  fetchUserSearches,
  saveUserSearch,
  convertInfo,
  renameUserSearch,
  deleteUserSearch,
} from '@/services/search.service';
import { GET_CHAPTER_PDF, GET_ANNEX_PDF, GET_DOCUMENT_PDF } from '@/utils/api.endpoints';

const initialQueryState = {
  type: '',
  ims: [],
  rfcs: [],
  country: null,
  text: '',
  includeAnnexes: true,
  chapters: {},
  years: [],
  versioning: [],
  chaptersLocal: {},
  selectedChapters: {},
  lastQuerySearchType: '',
};

const initialState = {
  query: JSON.parse(JSON.stringify(initialQueryState)),
  results: [],
  structureYears: {},
  current: {},
  currentVersionObject: {},
  currentPage: 0,
  currentTab: null,
  selectedItem: null,
  versioning: [],
  annexes: [],
  versioningPublished: [],
  fullChapterName: '',
  organizationName: '',
  externalLinks: [],
};

function cleanFileName(filename) {
  return filename.replaceAll('/', '');
}

// initial state
const state = () => JSON.parse(JSON.stringify(initialState));

// getters
const getters = {
  getCurrentSearch(state) {
    return state.current;
  },
  getVersions(state) {
    return state.versioningPublished;
  },
  getSearchResults(state) {
    return state.results;
  },
  getExternalLinks(state) {
    return state.externalLinks;
  },
  getChapterName(state) {
    return state.fullChapterName;
  },
  getOrgName(state) {
    return state.organizationName;
  },
};

// actions
const actions = {
  getCommonStructureSearch({ commit }, { data, cb, ecb }) {
    getCommonStructureSearch(
      data,
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) return;
        commit('setStructureYears', res);
        if (cb) cb(res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  fetchDocumentById({ commit }, { documentId, cb = null, ecb = null }) {
    fetchDocumentByIdRequest(documentId, cb, ecb, (res) => {
      commit('setCurrent', res);
      if (cb) cb(res);
    });
  },
  getSearchResults({ commit }, { data, page, cb, ecb }) {
    searchResults(
      data,
      page,
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) return;
        commit('setResults', res);
        if (cb) cb(res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  // eslint-disable-next-line no-empty-pattern
  downloadSearchResults({}, { data, cb, ecb }) {
    downloadResults(
      data,
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) return;
        FileDownload(res, 'search-results.pdf');
        if (cb) cb();
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  fetchUserSearches(_, { cb, ecb }) {
    fetchUserSearches(
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) return;
        if (cb) cb(res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  saveSearch(_, { data, cb, ecb }) {
    saveUserSearch(
      data,
      (res) => {
        console.log('search saved: ', res);
        if (cb) cb();
      },
      ecb
    );
  },
  renameSearch(_, { searchId, data, cb, ecb }) {
    console.log(searchId);
    console.log(data);
    renameUserSearch(
      searchId,
      data,
      (res) => {
        console.log('search saved: ', res);
        if (cb) cb();
      },
      ecb
    );
  },
  deleteSearch(_, { searchId, cb, ecb }) {
    console.log(searchId);
    deleteUserSearch(
      searchId,
      (res) => {
        console.log('search saved: ', res);
        if (cb) cb();
      },
      ecb
    );
  },
  downloadFullDocumentZip(_, { data, cb, ecb }) {
    downloadFullDocumentZip(
      data,
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) {
          if (ecb) ecb(res);
          return;
        }

        const organizationName = data.imName || data.rfcName;
        const year = data.year;
        FileDownload(res, cleanFileName(`${organizationName} ${year}.zip`));
        if (cb) cb();
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  setQueryField({ commit }, { prop, value }) {
    commit('setQueryField', {
      prop,
      value,
    });
  },
  setCurrent({ commit }, data) {
    commit('setCurrent', data);
  },
  setCurrentVersionObject({ commit }, data) {
    commit('setCurrentVersionObject', data);
  },
  setCurrentPage({ commit }, data) {
    commit('setCurrentPage', data);
  },
  setSelectedItem({ commit }, data) {
    commit('setSelectedItem', data);
  },
  downloadChapter({ state }) {
    downloadChapter(state.current.documentToCSId, (res) => {
      const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
      if (hasError) return;
      FileDownload(
        res,
        cleanFileName(`${state.organizationName} - ${state.fullChapterName} - ${state.current.year}.pdf`)
      );
    });
  },
  downloadChapterWithAnnexes({ state }) {
    downloadChapterWithAnnexesRequest(
      state.currentVersionObject.documentToCsLevelId,
      state.currentVersionObject.annexes
        .map((a) => {
          return a.id;
        })
        .join(','),
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) return;
        FileDownload(
          res,
          cleanFileName(`${state.organizationName} - ${state.fullChapterName} - ${state.current.year}.pdf`)
        );
      }
    );
  },
  getVersioning({ commit, state }, { documentId }) {
    const id = documentId || state.current.documentId;
    getVersioningRequest(id, (res) => {
      const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
      if (hasError) return;
      commit('setVersioning', res);
    });
  },
  getVersioningPublished({ commit, state }) {
    return new Promise((resolve) => {
      getVersioningPublishedRequest(state.current, (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setVersioningPublished', res);
        resolve(true);
      });
    });
  },
  downloadDocument({ state }, { cb = null }) {
    downloadDocument(
      {
        documentId: state.currentVersionObject.id,
        levelId: state.currentVersionObject.csLevelId,
      },
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) return;
        FileDownload(res, `${state.organizationName}-${state.current.year}.pdf`);
        if (cb) cb();
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  prevDocument({ state }, { cb = null, ecb = null }) {
    prevDocumentRequest(
      state.currentVersionObject.id,
      state.currentVersionObject.csLevelId,
      cb,
      ecb,
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) return;
        if (cb) cb();
      },
      () => {
        if (ecb) ecb();
      }
    );
  },
  downloadAnnex(_, { filename, annexDownloadUrl, cb = null }) {
    downloadAnnex({ annexDownloadUrl: annexDownloadUrl }, (res) => {
      const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
      if (hasError) return;
      FileDownload(res, filename);
      if (cb) cb();
    });
  },
  downloadDocumentArgs(_, { documentId, csLevelId, name, cb = null, ecb = null }) {
    downloadDocumentOriginal(
      {
        documentId: documentId,
        levelId: csLevelId,
      },
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) {
          if (ecb) {
            ecb();
          }
          return;
        }
        FileDownload(res, `${name}.docx`);
        if (cb) cb();
      }
    );
  },
  getChapterUrl({ state }) {
    return `${this.$serverUrl}${GET_CHAPTER_PDF(state.current.documentToCSId)}`;
  },
  // eslint-disable-next-line no-empty-pattern
  getAnnexUrl({}, { id }) {
    return `${this.$serverUrl}${GET_ANNEX_PDF(id)}`;
  },
  getDocumentUrl({ state }) {
    return `${this.$serverUrl}${GET_DOCUMENT_PDF({
      documentId: state.current.documentId,
      levelId: state.current.csLevelId,
    })}`;
  },
  convertInfo(_, item) {
    return convertInfo(item);
  },
};

// mutations
const mutations = {
  setResults(state, data) {
    state.results.push(...data.content);
  },
  setSearchParams(state, data) {
    state.query = { ...data };
  },
  setVersioning(state, data) {
    state.versioning = data;
  },
  setVersioningPublished(state, data) {
    state.versioningPublished = (data.versions || [])
      .map((ver) => {
        ver.displayValue = ver.version + ' ' + ver.status;
        return ver;
      })
      .sort((a, b) => {
        const l = a.version
          .split('.')
          .map((v) => v.padStart(4, '0'))
          .join('');
        const r = b.version
          .split('.')
          .map((v) => v.padStart(4, '0'))
          .join('');
        return l < r ? -1 : l > r ? +1 : 0;
      });
    state.externalLinks = data.externalLinks;
    state.fullChapterName = data.fullChapterName;
    state.organizationName = data.organizationName;
  },
  setQueryField(state, { prop, value }) {
    const newObj = state.query;
    newObj[prop] = value;
    state.query = Object.assign({}, newObj);
  },
  setStructureYears(state, data) {
    state.structureYears = data;
  },
  clearStructureYears(state) {
    state.structureYears = {};
  },
  clearSearchParams(state) {
    state.query = Object.assign({}, initialQueryState);
  },
  setCurrent(state, data) {
    state.current = data;
  },
  setCurrentVersionObject(state, data) {
    state.currentVersionObject = data;
  },
  setCurrentPage(state, data) {
    state.currentPage = data;
  },
  setCurrentTab(state, data) {
    state.currentTab = data;
  },
  setSelectedItem(state, data) {
    state.selectedItem = data;
  },
  setLastQuerySearchType(state, type) {
    state.query.lastQuerySearchType = type;
  },
  clearSearch(state) {
    // eslint-disable-next-line no-unused-vars
    state = JSON.parse(JSON.stringify(initialState));
  },
  clearResults(state) {
    state.results = [];
  },
  setAnnexes(state, annexes) {
    state.annexes = annexes;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
