<template>
  <Modal :is-modal-open="isModalOpen" :close-dialog="closeDialog">
    <LoadingComponent :loading.sync="isLoading" />
    <template #header>
      {{ $t(`duplicateStructure.duplicateCommonStruct`) }}
    </template>
    <SelectComponent
      v-model="type"
      class="cs-type-select selection-bold"
      :tabindex="2"
      :options="managementTypes"
      :name="$t(`common.type`)"
      obj-key="name"
      obj-val="value"
    />
    <InputField
      class="selection-bold"
      :tabindex="2"
      :name="$t(`common.year`)"
      :value.sync="year"
      :use-model="true"
      @update="year = $event"
    />
    <template #footer>
      <div class="row">
        <div class="col-6">
          <button class="save_edited_country_btn" @click="duplicate">
            {{ $t('common.create') }}
          </button>
        </div>
        <div class="col-6">
          <button class="delete_country_btn" @click="closeDialog">
            {{ $t('common.close') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import mixinCommon from '../CommonList.data';
import InputField from '@/components/base/InputField';
import SelectComponent from '@/components/base/SelectComponent';
import Modal from '@/components/base/Modal';
import LoadingComponent from '@/components/base/LoadingComponent';

export default {
  name: 'DuplicateStructure',
  components: {
    InputField,
    Modal,
    SelectComponent,
    LoadingComponent,
  },
  mixins: [mixinCommon],
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    closeDialog: {
      type: Function,
      default: null,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      type: '',
      year: null,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions('structure', ['createCS']),
    duplicate() {
      const data = {
        type: this.type,
        year: this.year,
      };
      if (this.item) data.id = this.item.id;
      this.isLoading = true;
      this.createCS({
        data,
        cb: (res) => {
          if (Object.hasOwnProperty.call(res, 'id')) {
            this.$router.push({
              name: 'CommonDetails',
              params: { id: res.id },
            });
            this.isLoading = false;
            this.closeDialog();
          } else if (Object.hasOwnProperty.call(res, 'error')) {
            this.$root.$emit('toast-error', res.error);
            this.isLoading = false;
            this.closeDialog();
          }
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
  },
};
</script>

<style scoped>
>>> .cs-type-select select {
  color: #080800;
}
</style>
