var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.search && _vm.search.name
    ? _c(
        "Modal",
        {
          attrs: {
            "is-modal-open": _vm.isModalOpen,
            "close-dialog": _vm.closeDialog,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", { staticClass: "save-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t(`savedSearches.deleteTitle`)) + " "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c("div", { staticClass: "d-flex modal-footer" }, [
                      _c("div", { staticClass: "p-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-green btn-medium mr-2",
                            on: { click: _vm.closeDialog },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]
                        ),
                      ]),
                      _c("div", { staticClass: "ml-auto p-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-red btn-medium",
                            on: {
                              click: function ($event) {
                                return _vm.closeDialog("delete")
                              },
                            },
                          },
                          [_vm._v("Delete ")]
                        ),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            2073034884
          ),
        },
        [
          _c("div", { staticClass: "subtitle" }, [
            _vm._v(" " + _vm._s(_vm.$t(`savedSearches.deleteSubtitle`)) + " "),
          ]),
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t(`savedSearches.deleteLabel`)) + " "),
          ]),
          _c("div", { staticClass: "search_name" }, [
            _vm.search.searchParameters.type === "NS"
              ? _c("div", { staticClass: "document_type_ns" }, [
                  _c("img", { attrs: { src: _vm.nsSearch } }),
                ])
              : _vm._e(),
            _vm.search.searchParameters.type === "CID"
              ? _c("div", { staticClass: "document_type_cid" }, [
                  _c("img", { attrs: { src: _vm.cidSearch } }),
                ])
              : _vm._e(),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.saveSearchName,
                  expression: "saveSearchName",
                },
              ],
              ref: "input",
              staticClass: "input-medium input-style rename-input",
              attrs: { readonly: "" },
              domProps: { value: _vm.saveSearchName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.saveSearchName = $event.target.value
                },
              },
            }),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }