<template>
  <div class="row">
    <div id="container_col" :class="leftColumnClass">
      <slot />
    </div>
    <div id="background_image_col" :class="rightColumnClass">
      <img class="backgound_image" src="@/assets/background.png" />
      <!-- <div class="opacityLayer"></div> -->
      <div v-if="show" id="headings">
        <!-- eslint-disable vue/no-v-html vue-i18n/no-v-html -->
        <h2 v-html="$t(`landingPage['RNE NCI']`)" />
        <!-- eslint-enable vue/no-v-html -->
        <h2>{{ $t(`landingPage.${route}`) }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
  data() {
    return {
      loginDisplay: '/login',
      registerDisplay: '/register',
    };
  },
  computed: {
    route() {
      return this.$route.path;
    },
    show() {
      return [this.loginDisplay, this.registerDisplay].includes(this.route) ? this.route : '';
    },
    leftColumnClass() {
      const authPath = this.show;
      if (authPath) {
        if (authPath === this.loginDisplay) return 'col-6';
        // register case
        else return 'col-7';
      }
      return 'col-11 col-md-7';
      // return authPath ? "col-6" : "col-11 col-md-7";
    },
    rightColumnClass() {
      const authPath = this.show;
      if (authPath) {
        if (authPath === this.loginDisplay) return 'col-6';
        // register case
        else return 'col-5';
      }
      return 'col-1 col-md-5';
      // return authPath ? "col-6" : "col-1 col-md-5";
    },
  },
};
</script>

<style src="@/assets/styles/authLayout.css"></style>
