<template>
  <DefaultLayout>
    <LoadingComponent :loading="loading" />
    <Headings :title="$t('translation.manage_translations')" :subtitle="$t('translation.languages')">
      <button v-if="isAdmin" class="add_country_btn" @click="editLanguage({})">
        {{ $t('translation.add_language') }}
      </button>
    </Headings>
    <v-data-table :items="languages" :headers="langHeaders" disable-pagination hide-default-footer disable-sort>
      <template #item.flagUrl="{ value }">
        <img :src="value" style="max-width: 64px; max-height: 64px; border: 1px solid #ddd" />
      </template>
      <template #item.active="{ value }">
        <v-icon :color="value ? 'primary' : 'error'">{{ value ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
      </template>
      <template #item.action="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              icon
              color="primary"
              :to="{ name: 'Translations', params: { id: item.id } }"
              style="text-decoration: none !important"
              v-on="on"
            >
              <v-icon>mdi-web</v-icon>
            </v-btn>
          </template>
          {{ $t('translation.manage_translations') }}
        </v-tooltip>
        <v-tooltip v-if="isAdmin" top>
          <template #activator="{ on }">
            <v-btn icon color="secondary" v-on="on" @click="editLanguage(item)">
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
          </template>
          {{ $t('translation.edit_language') }}
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- Language dialog -->
    <v-dialog v-model="dlgLang" width="auto">
      <v-form ref="frm" @submit.prevent="saveLanguage">
        <v-card min-width="300">
          <v-card-title class="pt-2 pr-1">
            {{ $t(currentLanguage.id ? 'translation.edit_language' : 'translation.add_language') }}
            <v-spacer />
            <v-btn icon @click="dlgLang = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-text-field
              v-model.trim="currentLanguage.name"
              :label="$t('translation.headers.language_name')"
              outlined
              dense
              :rules="[ruleRequired]"
            />
            <v-text-field
              v-model.trim="currentLanguage.locale"
              :label="$t('translation.headers.locale')"
              outlined
              dense
              :rules="[ruleRequired, validLocale]"
            />
            <v-checkbox
              v-model="currentLanguage.active"
              :label="$t('translation.headers.active')"
              class="mt-0 pt-0"
              hide-details
            />
            <!-- Locale flag -->
            <div class="d-flex align-center justify-space-around mt-3">
              <v-img
                v-if="currentLanguage.newFlagUrl || currentLanguage.flagUrl"
                :src="currentLanguage.newFlagUrl || currentLanguage.flagUrl"
                max-width="64"
                max-height="64"
                contain
                style="border: 1px solid #ddd"
              />
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    color="error"
                    :style="currentLanguage.newFlagUrl ? '' : 'visibility: hidden;'"
                    v-on="on"
                    @click="(currentLanguage.newFlagFile = null), (currentLanguage.newFlagUrl = '')"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                {{ $t('structure.tooltipLogo') }}
              </v-tooltip>
              <v-btn color="secondary" depressed tag="label" for="link_logo" class="no-caps" style="cursor: pointer">
                <v-icon class="mr-2">mdi-camera</v-icon>
                {{ $t('translation.locale_flag') }}
              </v-btn>
              <input
                id="link_logo"
                ref="file_ctrl"
                type="file"
                class="file_ctrl"
                accept=".jpg,.jpeg,.png,.svg"
                @change="chooseFlag"
              />
            </div>
            <div class="v-messages error--text mt-2">
              <transition name="message-transition">
                <div v-if="errorFlag" class="v-messages__message">
                  {{ errorFlag }}
                </div>
              </transition>
            </div>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-row justify="center" no-gutters>
              <v-btn color="primary" class="px-4 py-2 mr-3" type="submit">{{ $t('common.save') }}</v-btn>
              <v-btn outlined class="px-4 py-2 ml-3" @click="dlgLang = false">{{ $t('common.cancel') }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import LoadingComponent from '@/components/base/LoadingComponent.vue';
import { mapGetters, mapMutations } from 'vuex';
import mixinValidations from '@/utils/mixinValidations.js';
import { clearFileInput } from '@/utils/utils.js';

export default {
  name: 'LanguagesPage',
  components: {
    DefaultLayout,
    Headings,
    LoadingComponent,
  },
  mixins: [mixinValidations],
  data() {
    return {
      loading: false,
      languages: [],
      dlgLang: false,
      currentLanguage: {},
      errorFlag: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['role']),
    isAdmin() {
      return this.role === 'ADMIN';
    },
    langHeaders() {
      return [
        {
          text: this.$t('translation.headers.flag'),
          value: 'flagUrl',
        },
        {
          text: this.$t('translation.headers.locale'),
          value: 'locale',
        },
        {
          text: this.$t('translation.headers.language_name'),
          value: 'name',
        },
        {
          text: this.$t('translation.headers.active'),
          value: 'active',
          align: 'center',
        },
        {
          text: this.$t('translation.headers.actions'),
          value: 'action',
          align: 'center',
        },
      ];
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapMutations('languages', ['setLanguages']),
    validLocale(val) {
      return val ? /^[a-zA-Z]{2}$/.test(val) || this.$t('validationRules.invalid_locale') : true;
    },
    fetchData() {
      this.loading = true;
      this.$axios
        .get('languages?includeInactive=true')
        .then((response) => {
          this.languages = response;
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editLanguage(language) {
      this.currentLanguage = Object.assign(
        {
          id: undefined,
          name: '',
          locale: '',
          active: false,
          flagUrl: null,
          newFlagUrl: null,
          newFlagFile: null,
        },
        language
      );
      this.dlgLang = true;
      setTimeout(() => {
        this.$refs.frm.resetValidation();
      }, 90);
    },
    saveLanguage() {
      if (this.$refs.frm.validate()) {
        this.loading = true;
        this.$axios[this.currentLanguage.id ? 'put' : 'post'](
          this.currentLanguage.id ? 'languages/' + this.currentLanguage.id : 'languages',
          {
            active: this.currentLanguage.active,
            name: this.currentLanguage.name,
            locale: this.currentLanguage.locale.toLowerCase(),
          }
        )
          .then((response) => {
            if (this.currentLanguage.id) {
              const idx = this.languages.findIndex((item) => item.id === response.id);
              if (idx !== -1) this.languages.splice(idx, 1, response);
            } else this.languages.push(response);
            // update flag
            if (this.currentLanguage.newFlagFile) {
              const form = new FormData();
              form.append('logo', this.currentLanguage.newFlagFile);
              return this.$axios.put(`languages/${response.id}/flag`, form).then((langInfo) => {
                const lang = this.languages.find((item) => item.id === response.id);
                lang.flagUrl = langInfo.flagUrl + '?t=' + Date.now();
              });
            }
          })
          .then(() => {
            this.dlgLang = false;
            this.setLanguages(this.languages);
          })
          .catch((err) => {
            this.$root.$emit('toast-error', err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    chooseFlag(evt) {
      const file = (evt.dataTransfer || evt.target).files[0];
      if (file.size > 1e5) this.errorFlag = this.$t('translation.flag_too_big');
      else if (!/\.(png|jpg|jpeg|svg)$/i.test(file.name)) this.errorLogo = this.$t('translation.flag_file_type');
      else if (file.size > 100) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentLanguage.newFlagUrl = e.target.result;
        };
        reader.readAsDataURL(file);
        this.currentLanguage.newFlagFile = file;
      }
      clearFileInput(this.$refs.file_ctrl);
    },
  },
};
</script>
