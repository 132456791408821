var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", { attrs: { loading: _vm.loading } }),
      _c(
        "Headings",
        {
          attrs: {
            title: _vm.$t("translation.manage_translations"),
            subtitle: _vm.$t("translation.languages"),
          },
        },
        [
          _vm.isAdmin
            ? _c(
                "button",
                {
                  staticClass: "add_country_btn",
                  on: {
                    click: function ($event) {
                      return _vm.editLanguage({})
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("translation.add_language")) + " ")]
              )
            : _vm._e(),
        ]
      ),
      _c("v-data-table", {
        attrs: {
          items: _vm.languages,
          headers: _vm.langHeaders,
          "disable-pagination": "",
          "hide-default-footer": "",
          "disable-sort": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item.flagUrl",
            fn: function ({ value }) {
              return [
                _c("img", {
                  staticStyle: {
                    "max-width": "64px",
                    "max-height": "64px",
                    border: "1px solid #ddd",
                  },
                  attrs: { src: value },
                }),
              ]
            },
          },
          {
            key: "item.active",
            fn: function ({ value }) {
              return [
                _c(
                  "v-icon",
                  { attrs: { color: value ? "primary" : "error" } },
                  [
                    _vm._v(
                      _vm._s(value ? "mdi-check-circle" : "mdi-close-circle")
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.action",
            fn: function ({ item }) {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticStyle: {
                                      "text-decoration": "none !important",
                                    },
                                    attrs: {
                                      icon: "",
                                      color: "primary",
                                      to: {
                                        name: "Translations",
                                        params: { id: item.id },
                                      },
                                    },
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-web")])],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("translation.manage_translations")) +
                        " "
                    ),
                  ]
                ),
                _vm.isAdmin
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { icon: "", color: "secondary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editLanguage(item)
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-lead-pencil")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("translation.edit_language")) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "auto" },
          model: {
            value: _vm.dlgLang,
            callback: function ($$v) {
              _vm.dlgLang = $$v
            },
            expression: "dlgLang",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "frm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveLanguage.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-card",
                { attrs: { "min-width": "300" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-2 pr-1" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              _vm.currentLanguage.id
                                ? "translation.edit_language"
                                : "translation.add_language"
                            )
                          ) +
                          " "
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.dlgLang = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-0" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("translation.headers.language_name"),
                          outlined: "",
                          dense: "",
                          rules: [_vm.ruleRequired],
                        },
                        model: {
                          value: _vm.currentLanguage.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentLanguage,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "currentLanguage.name",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("translation.headers.locale"),
                          outlined: "",
                          dense: "",
                          rules: [_vm.ruleRequired, _vm.validLocale],
                        },
                        model: {
                          value: _vm.currentLanguage.locale,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentLanguage,
                              "locale",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "currentLanguage.locale",
                        },
                      }),
                      _c("v-checkbox", {
                        staticClass: "mt-0 pt-0",
                        attrs: {
                          label: _vm.$t("translation.headers.active"),
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.currentLanguage.active,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentLanguage, "active", $$v)
                          },
                          expression: "currentLanguage.active",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-around mt-3",
                        },
                        [
                          _vm.currentLanguage.newFlagUrl ||
                          _vm.currentLanguage.flagUrl
                            ? _c("v-img", {
                                staticStyle: { border: "1px solid #ddd" },
                                attrs: {
                                  src:
                                    _vm.currentLanguage.newFlagUrl ||
                                    _vm.currentLanguage.flagUrl,
                                  "max-width": "64",
                                  "max-height": "64",
                                  contain: "",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            style: _vm.currentLanguage
                                              .newFlagUrl
                                              ? ""
                                              : "visibility: hidden;",
                                            attrs: { icon: "", color: "error" },
                                            on: {
                                              click: function ($event) {
                                                ;(_vm.currentLanguage.newFlagFile =
                                                  null),
                                                  (_vm.currentLanguage.newFlagUrl =
                                                    "")
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-close-circle"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("structure.tooltipLogo")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "no-caps",
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                color: "secondary",
                                depressed: "",
                                tag: "label",
                                for: "link_logo",
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-camera"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("translation.locale_flag")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c("input", {
                            ref: "file_ctrl",
                            staticClass: "file_ctrl",
                            attrs: {
                              id: "link_logo",
                              type: "file",
                              accept: ".jpg,.jpeg,.png,.svg",
                            },
                            on: { change: _vm.chooseFlag },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "v-messages error--text mt-2" },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "message-transition" } },
                            [
                              _vm.errorFlag
                                ? _c(
                                    "div",
                                    { staticClass: "v-messages__message" },
                                    [_vm._v(" " + _vm._s(_vm.errorFlag) + " ")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-4 py-2 mr-3",
                              attrs: { color: "primary", type: "submit" },
                            },
                            [_vm._v(_vm._s(_vm.$t("common.save")))]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-4 py-2 ml-3",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  _vm.dlgLang = false
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }