var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", { attrs: { loading: _vm.loading } }),
      _c(
        "Headings",
        {
          attrs: {
            title: _vm.$t("translation.manage_translations"),
            subtitle: _vm.$t("translation.languages"),
            "back-location": "LangList",
          },
        },
        [
          _c(
            "v-spacer",
            { staticClass: "text-center font-weight-bold pt-4 text-h6" },
            [_vm._v(_vm._s(_vm.languageInfo.name))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "my-auto no-caps",
              attrs: {
                color: "secondary",
                height: "48",
                width: "220",
                disabled: !_vm.modified,
              },
              on: { click: _vm.saveChanges },
            },
            [_vm._v(" " + _vm._s(_vm.$t("translation.save_changes")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "v-alert",
        {
          attrs: {
            text: "",
            prominent: "",
            dense: "",
            type: "error",
            icon: "mdi-alert-decagram-outline",
          },
        },
        [
          _c(
            "div",
            { staticClass: "text--error font-weight-bold text-uppercase pb-1" },
            [_vm._v(_vm._s(_vm.$t("translation.warning")))]
          ),
          _c("div", [
            _vm._v(_vm._s(_vm.$t("translation.warning_text")) + "   "),
            _c("strong", [_vm._v("<   >   {   }")]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "d-flex pb-4" },
        [
          _c("v-text-field", {
            staticClass: "flex-grow-0 mx-auto",
            attrs: {
              label: _vm.$t("common.search"),
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "translation-table",
        attrs: {
          items: _vm.flatArray,
          headers: _vm.langHeaders,
          search: _vm.search,
          "hide-default-footer": "",
          "items-per-page": -1,
          "item-key": "ident",
        },
        scopedSlots: _vm._u([
          {
            key: "item.action",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", color: "secondary" },
                    on: {
                      click: function ($event) {
                        return _vm.startEditing(item)
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-lead-pencil")])],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "auto" },
          model: {
            value: _vm.dlgTranslate,
            callback: function ($$v) {
              _vm.dlgTranslate = $$v
            },
            expression: "dlgTranslate",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "frm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveTranslation.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-card",
                { attrs: { width: "600", "max-width": "1024" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pt-2 pr-1" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("translation.headers.localized_text")) +
                          " "
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.dlgTranslate = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-0" },
                    [
                      _c("div", { staticClass: "font-weight-bold pb-2" }, [
                        _vm._v(_vm._s(_vm.currentTranslation.ident)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "grey lighten-3 rounded pa-2" },
                        [_vm._v(_vm._s(_vm.currentTranslation.english))]
                      ),
                      _c("v-textarea", {
                        ref: "local",
                        staticClass: "mt-4",
                        attrs: {
                          outlined: "",
                          dense: "",
                          rules: [_vm.ruleRequired],
                        },
                        model: {
                          value: _vm.currentTranslation.translated,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentTranslation,
                              "translated",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "currentTranslation.translated",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-4 py-2 mr-3",
                              attrs: { color: "primary", type: "submit" },
                            },
                            [_vm._v(_vm._s(_vm.$t("common.save")))]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-4 py-2 ml-3",
                              attrs: { outlined: "" },
                              on: {
                                click: function ($event) {
                                  _vm.dlgTranslate = false
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "auto" },
          model: {
            value: _vm.showUnsavedWarning,
            callback: function ($$v) {
              _vm.showUnsavedWarning = $$v
            },
            expression: "showUnsavedWarning",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "d-flex flex-column" },
            [
              _c(
                "v-card-title",
                { staticClass: "pa-2" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.showUnsavedWarning = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", { staticClass: "pt-2 pb-3 flex-grow-1" }, [
                _c(
                  "div",
                  {
                    staticClass: "pb-6 black--text",
                    staticStyle: { "font-size": "30px" },
                  },
                  [_vm._v(_vm._s(_vm.$t("translation.confirm_page_leave")))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "black--text",
                    staticStyle: { "font-size": "18px" },
                  },
                  [_vm._v(_vm._s(_vm.$t("translation.unsaved_changes")))]
                ),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "px-6 py-5" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-4 py-2 mr-3",
                          attrs: { color: "primary" },
                          on: { click: _vm.ignoreUnsaved },
                        },
                        [_vm._v(_vm._s(_vm.$t("common.ok")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-4 py-2 ml-3",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              _vm.showUnsavedWarning = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }