import { axiosInstance } from '../utils/axios.js';
import { GET_TAGS_URL, GET_TAG_DETAILS, CREATE_TAG_URL, UPDATE_TAG_URL, DELETE_TAG_URL } from '../utils/api.endpoints';

export const getAll = function (cb, ecb = null) {
  axiosInstance
    .get(GET_TAGS_URL)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const get = function (id, cb, ecb = null) {
  axiosInstance
    .get(GET_TAG_DETAILS(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const update = function (data, cb, ecb = null) {
  const { id, ...objData } = data;

  axiosInstance
    .put(UPDATE_TAG_URL(id), objData)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const deleteItem = function (id, cb, ecb = null) {
  axiosInstance
    .delete(DELETE_TAG_URL(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const create = function (obj, cb, ecb = null) {
  axiosInstance
    .post(CREATE_TAG_URL, obj)
    .then((response) => {
      const im = response;
      if (cb) cb(im);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};
