<template>
  <AuthLayout>
    <v-container>
      <Logo>
        <v-spacer />
        <LanguageSelector />
      </Logo>
      <!-- eslint-disable vue/no-v-html vue-i18n/no-v-html -->
      <div class="row">
        <div class="col-12 welcome_text_wrapper pb-0">
          <div class="welcome_text d-inline-block" v-html="$t('landingPage.welcome')" />
          <div class="welcome_rne d-inline-block pl-0 pr-1 pr-sm-2 pl-sm-2" v-html="$t('landingPage.RNE')" />
          <div class="welcome_rne d-inline-block" v-html="$t('landingPage.NCI')" />
        </div>
        <div class="col-12 pt-3 pb-1">
          <p class="welcome_subtext" v-html="$t('landingPage.networkStatement')" />
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-6">
          <a class="font-headings-btns network_btn" @click="goTo('NS')">
            <span icon_main>
              <img class="search_icon" src="@/assets/icons/grey-icons/search.svg" />
            </span>
            <span class="heading-btn-text" v-html="$t('landingPage.network statements btn')" />
          </a>
        </div>
        <div class="col col-sm-6">
          <a class="font-headings-btns corridor_btn" @click="goTo('CID')">
            <span>
              <img class="search_icon" src="@/assets/icons/grey-icons/search.svg" />
            </span>
            <span class="heading-btn-text" v-html="$t('landingPage.corridor information btn')" />
          </a>
        </div>
      </div>
      <div class="row info-text-container">
        <div class="col-12">
          <router-link class="login_link d-inline-block mr-2" to="/login">{{ $t(`common.login`) }}</router-link>
          <router-link class="register_link d-inline-block" to="/register">{{ $t(`common.register`) }}</router-link>
        </div>
      </div>
      <div class="row">
        <div id="bottom_text" class="col mt-3">
          <p class="landing-page-sub-text" v-html="$t(`landingPage.dearVisitor`)" />
          <br />
          <p class="landing-page-sub-text" v-html="$t(`landingPage.needSupport`)" />
          <br />
          <p class="brand_txt mb-2" v-html="$t(`landingPage.copyRights`, { year })" />
          <p class="brand_txt" v-html="'Version: ' + currentVersion" />
        </div>
      </div>
      <!-- eslint-enable vue/no-v-html -->
    </v-container>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/components/layout/AuthLayout';
import Logo from '@/components/layout/Logo';
import LanguageSelector from '@/components/layout/LanguageSelector.vue';
import { mapActions } from 'vuex';
import { version } from '@/../package.json';

export default {
  name: 'LandingPage',
  components: {
    AuthLayout,
    Logo,
    LanguageSelector,
  },
  data() {
    return {
      currentVersion: '',
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    /*
    show()
    {
      return this.$route.path === '/login' || this.$route.path === '/register';
    }
    */
  },
  created() {
    this.currentVersion = version;
  },
  methods: {
    ...mapActions('auth', ['logInAnonymouslyRequest']),
    goTo(type) {
      const organisation = window.localStorage.getItem('organizationTypeModel');
      if (!organisation) {
        this.$router.push({
          name: 'AnonymousLogIn',
          query: {
            choice: type,
          },
        });
        return;
      }
      const data = {
        organizationTypeId: organisation,
      };
      this.logInAnonymouslyRequest({
        data,
        cb: (data) => {
          window.localStorage.setItem('anonymous', true);
          window.localStorage.setItem('sessionID', data.sessionId);
          this.$router.push({
            name: 'SearchStep2',
            params: {
              type,
            },
          });
        },
        ecb: (err) => {
          console.log(err);
          this.errors.addError('generic', err);
        },
      });
    },
  },
};
</script>

<style scoped>
input[readonly] {
  cursor: not-allowed;
  background-color: #f3f3f3;
}

.landing-page-sub-text {
  font-size: 22px;
  font-weight: 400;
  color: #000;
}

.icon_main {
  width: 50px;
}
</style>
