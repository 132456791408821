<template>
  <DefaultLayout class="saved-search-layout">
    <div v-if="!savedSearches.length">
      <Headings :title="$t(`common.search`)" :subtitle="$t(`search.selectOne`)" />
      <div class="search-selection">
        <!-- eslint-disable-next-line vuetify/no-deprecated-classes -->
        <div class="cols left" @click="goto('NS')">
          <div class="icon-selection icon-ns">
            <img :src="nsSearch" />
          </div>
          <div class="type pb-0">
            <b>{{ $t(`common.networkStatements`) }}</b>
          </div>
          <div class="type mt-0 pt-0">{{ $t(`common.search`) }}</div>
        </div>
        <!-- eslint-disable-next-line vuetify/no-deprecated-classes -->
        <div class="cols right" @click="goto('CID')">
          <div class="icon-selection icon-cid">
            <img :src="cidSearch" />
          </div>
          <div class="type pb-0">
            <b>{{ $t(`common.corridorInformationDocuments`) }}</b>
          </div>
          <div class="type mt-0 pt-0">{{ $t(`common.search`) }}</div>
        </div>
      </div>
    </div>
    <div v-else class="search-selection-saved">
      <div class="cols left">
        <Headings :title="$t(`common.search`)" :subtitle="$t(`search.selectOne`)" />
        <div>
          <!-- eslint-disable-next-line vuetify/no-deprecated-classes -->
          <v-container class="mb-4 search-link" @click="goto('NS')">
            <v-row justify="start">
              <div class="icon-selection-saved-searches icon-ns">
                <img :src="nsSearch" />
              </div>
              <v-col>
                <div class="type saved-search-font pb-0">
                  <b>{{ $t(`common.networkStatements`) }}</b>
                </div>
                <div class="type saved-search-font saved-search-subtitle mt-0 pt-0">{{ $t(`common.newSearch`) }}</div>
              </v-col>
            </v-row>
          </v-container>
          <!-- eslint-disable-next-line vuetify/no-deprecated-classes -->
          <v-container class="search-link" @click="goto('CID')">
            <v-row justify="start">
              <div class="icon-selection-saved-searches icon-cid">
                <img :src="cidSearch" />
              </div>
              <v-col>
                <div class="type saved-search-font pb-0">
                  <b>{{ $t(`common.corridorInformationDocuments`) }}</b>
                </div>
                <div class="type saved-search-font saved-search-subtitle mt-0 pt-0">{{ $t(`common.newSearch`) }}</div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <div class="cols right">
        <Headings :title="'\u2800'" subtitle="Saved searches" />
        <v-list class="overflow-y-auto saved-searches" max-height="300">
          <v-list-item v-for="(item, index) in savedSearches" :key="index" :value="item" active-color="primary">
            <saved-search-card
              :item="item"
              :delete-item-callback="openDeleteSearchDialog"
              :open-info="openInfo"
              :goto-load="gotoLoad"
            />
          </v-list-item>
        </v-list>
      </div>
    </div>
    <div v-if="!isLoggedIn" class="login-block">
      <div class="btn btn-white btn-medium btn-login-search btn-login-register" @click="gotoLogin">
        {{ $t(`common.login`) }}
      </div>
      <div class="btn btn-white btn-medium btn-register-search btn-login-register" @click="gotoRegister">
        {{ $t(`common.register`) }}
      </div>
      <!-- eslint-disable-next-line vue/no-v-html vue-i18n/no-v-html -->
      <p v-html="$t(`search.notifications`)" />
    </div>
    <ManageSearchModal
      :is-modal-open="isInfoSearchOpen"
      :close-dialog="closeInfoSearchDialog"
      :search="infoSearch"
      :is-info="true"
      style="z-index: 20001"
    />
    <DeleteSearchModal
      :is-modal-open="isDeleteSearchOpen"
      :close-dialog="closeDeleteSearchDialog"
      :search="forDelete"
      :is-info="true"
      style="z-index: 20001"
    />
  </DefaultLayout>
</template>

<script>
import nsSearch from '@/assets/icons/ns-search.svg';
import cidSearch from '@/assets/icons/cid-search.svg';
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import SavedSearchCard from '@/components/search/SavedSearchCard';
import ManageSearchModal from '@/components/search/ManageSearchModal';
import DeleteSearchModal from './DeleteSearchModal';
import { mapActions } from 'vuex';

export default {
  name: 'Search',
  components: {
    DefaultLayout,
    Headings,
    SavedSearchCard,
    ManageSearchModal,
    DeleteSearchModal,
  },
  data() {
    return {
      nsSearch,
      cidSearch,
      isLoggedIn: false,
      savedSearches: [],
      infoSearch: null,
      isInfoSearchOpen: false,
      isDeleteSearchOpen: false,
      forDelete: null,
    };
  },
  created() {
    this.isLoggedIn = !window.localStorage.getItem('anonymous');

    const anonymous = localStorage.getItem('anonymous');
    if (anonymous !== 'true') {
      this.fetchUserSearches({
        cb: (newValue) => {
          if (newValue) {
            this.savedSearches = newValue.reverse();
            for (const search of this.savedSearches) {
              search.searchParameters = JSON.parse(search.searchParameters);
            }
          }
          this.isLoading = false;
        },
        ecb: (error) => {
          console.log(error);
          this.isLoading = false;
        },
      });
    }
  },
  methods: {
    ...mapActions('search', ['convertInfo', 'fetchUserSearches', 'deleteSearch']),
    goto(type) {
      this.$router.push({
        name: 'SearchStep2',
        params: {
          type,
        },
      });
    },
    deleteSavedSearch(item) {
      this.deleteSearch({
        searchId: item.id,
        cb: () => {
          this.isLoading = false;
          location.reload();
        },
        ecb: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
    },
    async openInfo(item) {
      this.infoSearch = await this.convertInfo(item);
      this.isInfoSearchOpen = true;
    },
    closeInfoSearchDialog() {
      this.isInfoSearchOpen = false;
    },
    gotoLoad(item) {
      localStorage.setItem('load_search', JSON.stringify(item));
      this.$router.push({
        name: 'SearchStep2',
        params: {
          type: item.searchParameters.type,
        },
      });
    },
    openDeleteSearchDialog(item) {
      this.forDelete = item;
      this.isDeleteSearchOpen = true;
    },
    closeDeleteSearchDialog(action) {
      if (action === 'delete') {
        this.deleteSavedSearch(this.forDelete);
      }
      this.forDelete = null;
      this.isDeleteSearchOpen = false;
    },
    gotoLogin() {
      window.localStorage.removeItem('sessionID');
      window.localStorage.setItem('anonymous', null);
      this.$router.push({
        name: 'Login',
      });
    },
    gotoRegister() {
      window.localStorage.removeItem('sessionID');
      window.localStorage.setItem('anonymous', null);
      this.$router.push({
        name: 'Register',
      });
    },
  },
};
</script>

<style scoped>
.btn-login-search {
  min-width: 140px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.btn-register-search {
  min-width: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.btn-login-register {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #404040 !important;
  background-color: #fff !important;
  padding: 0px !important;
}

.saved-searches-icon {
  max-width: 40px;
  max-height: 40px;
}

.saved-search-font {
  font-size: 16px !important;
}

.saved-search-subtitle {
  color: #808080;
}

.saved-search-layout {
  width: 100vw;
}

.saved-searches {
  background-color: transparent;
}

.saved-searches > .v-list-item:not(:last-child) {
  margin-bottom: 8px;
}

.search-link {
  cursor: pointer;
}
</style>
