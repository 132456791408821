<template>
  <DefaultLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <Headings
      class="d-inline-block"
      :title="$t(`common.documentManagement`)"
      back-location="DocManagement"
      :subtitle="$t(`documentAnalysis.title`)"
    />

    <div>
      <h2>{{ documentType + ' / ' + organizationName + ' / ' + year + ' / ' + version }}</h2>
      <h3>{{ $t(`status`) + ': ' + status }}</h3>
    </div>

    <v-treeview
      class="mt-5"
      :items="chapters"
      item-children="subchapters"
      item-key="chapterId"
      item-text="chapterTitle"
      selected-color="#bf873d"
    >
      <template slot="label" slot-scope="{ item }">
        <v-row>
          <v-col cols="8">
            <img
              v-if="item.status == 'PROCESSING'"
              src="@/assets/icons/grey-icons/settings.svg"
              class="processing-icon mb-2"
              :alt="$t(`documentAnalysis.processing`)"
              :title="$t(`documentAnalysis.processing`)"
            />
            <a v-if="item.status == 'SUCCESS'" :href="item.previewUrl" target="_blank" rel="noopener noreferrer">
              <img
                src="@/assets/icons/show.svg"
                class="success-icon mb-2"
                :alt="$t(`documentAnalysis.preview`)"
                :title="$t(`documentAnalysis.preview`)"
              />
            </a>
            <img
              v-if="item.status == 'ERROR'"
              src="@/assets/icons/close_btn.svg"
              class="error-icon mb-2"
              :alt="item.details"
              :title="item.details"
            />
            <span class="chapter-analysis"> {{ item.chapterTitle }}</span>
          </v-col>
          <v-col v-if="item.status == 'ERROR'" cols="4">
            <a :href="mailtoUrlChapter(item)" style="margin-left: 10px">{{
              $t(`documentAnalysis.sendErrorViaEmail`)
            }}</a>
          </v-col>
        </v-row>
      </template>
    </v-treeview>

    <div>
      <h2>{{ $t(`common.annexes`) }}</h2>
    </div>
    <v-row v-for="annex in annexes" :key="annex.annexId">
      <v-col cols="8">
        <img
          v-if="annex.status == 'PROCESSING'"
          src="@/assets/icons/grey-icons/settings.svg"
          class="processing-icon mb-2"
          :alt="$t(`documentAnalysis.processing`)"
          :title="$t(`documentAnalysis.processing`)"
        />
        <a v-if="annex.status == 'SUCCESS'" :href="annex.previewUrl" target="_blank" rel="noopener noreferrer">
          <img
            src="@/assets/icons/show.svg"
            class="success-icon mb-2"
            :alt="$t(`documentAnalysis.preview`)"
            :title="$t(`documentAnalysis.preview`)"
          />
        </a>
        <img
          v-if="annex.status == 'ERROR'"
          src="@/assets/icons/close_btn.svg"
          class="error-icon mb-2"
          :alt="annex.details"
          :title="annex.details"
        />
        <span class="annex-analysis"> {{ annex.annexTitle }}</span>
      </v-col>
      <v-col v-if="annex.status == 'ERROR'" cols="4">
        <a :href="mailtoUrlAnnex(annex)" style="margin-left: 10px">{{ $t(`documentAnalysis.sendErrorViaEmail`) }}</a>
      </v-col>
    </v-row>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import LoadingComponent from '@/components/base/LoadingComponent';
// import { mapState, mapActions, mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: 'DocumentAnalysis',
  components: {
    DefaultLayout,
    Headings,
    LoadingComponent,
  },
  data() {
    return {
      documentId: null,
      documentType: null,
      organizationName: null,
      year: null,
      version: null,
      status: '',
      chapters: [],
      annexes: [],
      isLoading: true,
    };
  },
  created() {
    this.documentId = this.$route.query.documentId;
    this.documentType = this.$route.query.documentType;
    this.organizationName = this.$route.query.organizationName;
    this.year = this.$route.query.year;
    this.version = this.$route.query.version;

    const internalData = this;
    this.fetchDocumentAnalysis({
      documentId: this.documentId,
      cb: function (data) {
        internalData.status = data.status;
        internalData.chapters = data.chapters;
        internalData.annexes = data.annexes;
        internalData.isLoading = false;
      },
    });
  },
  methods: {
    ...mapActions('documents', ['fetchDocumentAnalysis']),
    goBack() {
      this.$router.push({
        name: 'DocManagement',
      });
    },
    mailtoUrlChapter(chapter) {
      const documentInfo = this.documentType + ' / ' + this.organizationName + ' / ' + this.year + ' / ' + this.version;
      return (
        'mailto:support.nci@rne.eu?subject=NCI%20processing%20problem&body=There is a problem with ' +
        documentInfo +
        ' chapter: ' +
        chapter.chapterTitle +
        '; Error: ' +
        chapter.details
      );
    },
    mailtoUrlAnnex(annex) {
      const documentInfo = this.documentType + ' / ' + this.organizationName + ' / ' + this.year + ' / ' + this.version;
      return (
        'mailto:support.nci@rne.eu?subject=NCI%20processing%20problem&body=There is a problem with ' +
        documentInfo +
        ' annex: ' +
        annex.annexTitle +
        '; Error: ' +
        annex.details
      );
    },
  },
};
</script>

<style scoped>
.chapter-analysis,
.annex-analysis {
  font-size: 2em;
  color: #000;
}

.processing-icon {
  background-color: #c1893a;
  width: 2em;
  border-radius: 8px;
}

.success-icon {
  background-color: #265f14;
  width: 2em;
  border-radius: 8px;
}

.error-icon {
  background-color: #7f2614;
  width: 2em;
  border-radius: 8px;
}
</style>
