<template>
  <AuthLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <v-container class="register_container">
      <Logo>
        <v-spacer />
        <LanguageSelector />
      </Logo>
      <div v-if="message" class="message mb-5">{{ message }}</div>
      <v-form ref="form" lazy-validation class="reset_form">
        <v-row>
          <v-col cols="12">
            <InputField
              :name="$t(`resetPassword.resetEmail`)"
              :tabindex="1"
              :value.sync="userEmailModel"
              :use-model="true"
              type="email"
              @update="(userEmailModel = $event), (feedback = '')"
            />
          </v-col>
        </v-row>
        <v-row v-if="feedback || requestErrMessage">
          <v-col v-if="feedback" cols="12">
            <p class="feedback">{{ feedback }}</p>
          </v-col>
          <v-col v-if="requestErrMessage" cols="12">
            <p class="feedback">{{ requestErrMessage }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <button class="btn btn-green" @click.prevent="sendRecoverEmailRequest">
              {{ $t(`resetPassword.recoverPassword`) }}
            </button>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="form-group">
              <router-link class="btn-back" to="/Login" style="margin-top: 16px">
                {{ $t('common.back') }}
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </AuthLayout>
</template>

<script>
import { mapActions } from 'vuex';
import AuthLayout from '@/components/layout/AuthLayout';
import Logo from '@/components/layout/Logo';
import InputField from '@/components/base/InputField';
import LoadingComponent from '@/components/base/LoadingComponent';
import LanguageSelector from '@/components/layout/LanguageSelector.vue';
import { isEmailValid } from '../../utils/utils';

export default {
  name: 'ResetPasswordMessage',
  components: {
    AuthLayout,
    Logo,
    InputField,
    LoadingComponent,
    LanguageSelector,
  },
  data() {
    return {
      userEmailModel: null,
      message: null,
      feedback: null,
      isLoading: false,
      requestErrMessage: null,
    };
  },
  created() {
    this.message = this.$route.params.message;
  },
  methods: {
    ...mapActions('auth', ['passwordResetRequest']),

    checkIfEmailIsValid(email) {
      this.requestErrMessage = null;
      return isEmailValid(email);
    },
    goToLogin() {
      this.$router.push({ name: 'Login' });
    },
    sendRecoverEmailRequest() {
      if (this.userEmailModel && this.checkIfEmailIsValid(this.userEmailModel)) {
        this.isLoading = true;
        const userResetEmail = {
          email: this.userEmailModel,
        };

        this.passwordResetRequest({
          data: userResetEmail,
          cb: () => {
            this.isLoading = false;
            this.$router.push({
              name: 'ResetPassword',
              params: userResetEmail,
            });
          },
          ecb: (err) => {
            this.requestErrMessage = err;
            this.isLoading = false;
          },
        });
      } else {
        this.feedback = 'Not a valid email';
      }
    },
  },
};
</script>

<style src="@/assets/styles/common.css"></style>

<style scoped>
.message {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #404040;
}

.btn-back {
  margin-top: 80px;
  display: block;
  float: right;
  padding: 15px 28px;
  background: #e3e3e3;
  border-radius: 8px;
  color: #404040;
  font-size: 1.2em;
}

input[readonly] {
  cursor: not-allowed;
  background-color: #f3f3f3;
}
</style>
