import FileDownload from 'js-file-download';
import {
  getCsByTypeAndDateRequest,
  //uploadDocumentRequest,
  getAllDocumentsRequest,
  getDocumentByIdRequest,
  publishDocumentTypeRequest,
  getCsYears,
  downloadDocumentsRequest,
  uploadMasterCIDRequest,
  saveFileAndAnnexesRequest,
  saveMasterCIDAndAnnexesRequest,
  unpublishDocumentRequest,
  deleteWholeYearRequest,
  fetchUploadedBooksRequest,
  createDocumentIfNewRequest,
  createMasterDocumentIfNewRequest,
  saveMasterFileAndAnnexesRequest,
  downloadAnnexById,
  fetchDocumentAnalysisRequest,
  previewAnnexById,
  fetchExistingFiles,
  duplicateFileToDocument,
  createDocumentCopyRequest,
} from '@/services/documents.service';
import generic from '../utils/order';

// initial state
const state = () => ({
  ...generic.state,
  orderBy: 'organizationName', // default order
  data: [],
  selectedCommonStructure: {},
  current: {},
  currentPage: 0,
  pageSize: 10,
  totalItems: 0,
  totalPages: 0,
  submitStatus: '',
  uploadType: '',
  annexes: [],
  years: [],
  selectedFile: null,
  selectedBooks: [],
  deletedAnnexes: [],
  hasUploadedFile: false,
  missingFilesOptions: new Map(),
});

// getters
const getters = {};

// actions
const actions = {
  ...generic.actions,
  publishFile({ commit, state }, { type, fileData, cb, ecb = null }) {
    let append = `?csId=${state.selectedCommonStructure.id}&version=${fileData.formData.get('version')}`;
    const rfcOrIm =
      type === 'CID' ? `&rfcId=${fileData.formData.get('rfcId')}` : `&imId=${fileData.formData.get('imId')}`;

    append += rfcOrIm;

    publishDocumentTypeRequest(
      type,
      append,
      (response) => {
        commit('setSubmitStatus', 'Success');
        if (cb) cb(response);
      },
      (err) => {
        commit('setSubmitStatus', 'Error');
        console.log(err);
        if (ecb) ecb();
      }
    );
  },
  unpublishDocument(_, { documentId, cb = null, ecb = null }) {
    unpublishDocumentRequest(documentId, cb, ecb);
  },
  doDeleteWholeYear(_, { documentId, cb = null, ecb = null }) {
    deleteWholeYearRequest(documentId, cb, ecb);
  },
  setSelectedUploadType({ commit }, { data }) {
    commit('setUploadType', data);
  },
  // eslint-disable-next-line no-unused-vars
  uploadMasterCID({ commit }, { data, cb = null, ecb = null }) {
    uploadMasterCIDRequest(
      data,
      (res) => {
        if (cb) cb(res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  downloadDocuments({ commit }, { year }) {
    downloadDocumentsRequest(year, (res) => {
      const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
      if (hasError) return;
      FileDownload(res, `MASTER-CID-${year}.zip`);
    });
  },
  // eslint-disable-next-line no-unused-vars
  downloadAnnex({ commit }, data) {
    downloadAnnexById(
      data,
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) {
          data.ecb && data.ecb();
          return;
        }
        FileDownload(res, data.filename);
        data.cb();
      },
      data.ecb
    );
  },
  // eslint-disable-next-line no-unused-vars
  previewAnnex({ commit }, data) {
    previewAnnexById(
      data.annexId,
      (res) => {
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (hasError) {
          data.ecb && data.ecb();
          return;
        }
        data.cb && data.cb(res);
      },
      data.ecb
    );
  },
  fetchAllDocuments({ commit, state }, { data, cb = null, ecb = null }) {
    if (data.type && data.type !== 'NS_AND_CID') {
      const type = data.type;
      if (data.isSearch) {
        getAllDocumentsRequest(
          data,
          (documents) => {
            // data.sortBy("name");
            commit('setAllDocuments', documents.data);
            commit('setTotalItems', documents.length);
            commit('setCurrentPage', documents.currentPage);
            commit('setPageSize', documents.pageSize);
            commit('setTotalItems', documents.totalItems);
            commit('setTotalPages', documents.totalPages);
            if (cb) cb();
          },
          () => {
            if (ecb) ecb();
          }
        );
      } else {
        getAllDocumentsRequest(
          data,
          (documents) => {
            const newArr = [...[...state.data].filter((r) => r.type === type), ...documents.data];
            const dataArr = Array.from(new Set(newArr.map((a) => a.id))).map((id) => {
              return newArr.find((a) => a.id === id);
            });
            // data.sortBy("name");
            commit('setAllDocuments', dataArr);
            commit('setTotalItems', documents.length);
            commit('setCurrentPage', documents.currentPage);
            commit('setPageSize', documents.pageSize);
            commit('setTotalItems', documents.totalItems);
            commit('setTotalPages', documents.totalPages);
            if (cb) cb();
          },
          () => {
            if (ecb) ecb();
          }
        );
      }
    } else if (data.isSearch) {
      getAllDocumentsRequest(
        data,
        (documents) => {
          // data.sortBy("name");
          commit('setAllDocuments', documents.data);
          commit('setTotalItems', documents.length);
          commit('setCurrentPage', documents.currentPage);
          commit('setPageSize', documents.pageSize);
          commit('setTotalItems', documents.totalItems);
          commit('setTotalPages', documents.totalPages);
          if (cb) cb();
        },
        () => {
          if (ecb) ecb();
        }
      );
    } else {
      getAllDocumentsRequest(
        data,
        (documents) => {
          const dataArray = [...state.data, ...documents.data];
          const arr = Array.from(new Set(dataArray.map((a) => a.id)));
          const data = arr.map((id) => {
            return dataArray.find((a) => a.id === id);
          });

          // data.sortBy("name");
          commit('setAllDocuments', data);
          commit('setTotalItems', documents.length);
          commit('setCurrentPage', documents.currentPage);
          commit('setPageSize', documents.pageSize);
          commit('setTotalItems', documents.totalItems);
          commit('setTotalPages', documents.totalPages);
          if (cb) cb();
        },
        () => {
          if (ecb) ecb();
        }
      );
    }
  },
  fetchCommonStructureForDate({ commit }, { data, cb = null, ecb = null }) {
    getCsByTypeAndDateRequest(
      data,
      (cs) => {
        commit('setSelectedCs', cs);
        if (cb) cb();
      },
      () => {
        if (ecb) ecb();
      }
    );
  },
  fetchUploadedBooks({ commit, state }, { csLevelIds, documentId, cb = null, ecb = null }) {
    fetchUploadedBooksRequest(
      csLevelIds,
      documentId,
      (response) => {
        response.forEach((val, index) => {
          if (state.uploadType === 'NS') {
            commit('addSelectedFileStatus', val);
          } else {
            if (val) {
              commit('addBook', {
                index: index,
                file: {},
                status: val,
              });
            }
          }
        });
        if (cb) cb();
      },
      () => {
        if (ecb) ecb();
      }
    );
  },
  async fetchMissingFilesOptions({ commit }, { rfcId, csLevelName, cb = null, ecb = null }) {
    await fetchExistingFiles(
      rfcId,
      csLevelName,
      (response) => {
        commit('setMissingFilesOptions', {
          csLevelName,
          existing: response.map((year) => {
            return {
              year,
            };
          }),
        });
        if (cb) cb();
      },
      () => {
        if (ecb) ecb();
      }
    );
  },
  duplicateExistingFileToDocument(_, { rfcId, csLevelId, newDocumentId, year, cb = null, ecb = null }) {
    duplicateFileToDocument(
      rfcId,
      csLevelId,
      newDocumentId,
      year,
      () => {
        if (cb) cb();
      },
      () => {
        if (ecb) ecb();
      }
    );
  },
  fetchDocumentById({ commit, dispatch }, { documentId, cb = null, ecb = null }) {
    getDocumentByIdRequest(
      documentId,
      (document) => {
        commit('setCurrentDocument', document);
        commit('setUploadType', document.type);
        commit('addSelectedFile', {});
        if (document.documentAnnexes && document.documentAnnexes.length) {
          // push current annexes
          document.documentAnnexes.forEach((annex) => {
            commit('addSelectedAnnex', {
              file: {},
              chapters: annex.csLevelsIds,
              annexId: annex.documentAnnexId,
              annexFileName: annex.filename,
              displayName: annex.displayName,
              pdfPreviewUrl: annex.pdfPreviewUrl,
              includeOnDownload: annex.includeOnDownload,
              initialChapters: annex.csLevelsIds,
              isUpdated: false,
            });
          });
        }
        const csData = {
          type: document.type,
          year: document.year,
        };
        dispatch('fetchCommonStructureForDate', { data: csData });

        cb(document);
      },
      ecb
    );
  },
  getYears({ commit }, { cb, ecb }) {
    getCsYears(
      (resp) => {
        if (cb) cb(resp);
        resp = resp.map((val) => {
          return { value: val };
        });
        commit('setYears', resp);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  createDocumentIfNew({ commit }, { type, append, documentNote, cb, ecb }) {
    createDocumentIfNewRequest(type, append, documentNote, (response) => {
      cb(response);
    });
  },
  // eslint-disable-next-line no-unused-vars
  createDocumentCopy(_, { sourceDocumentId, newVersion, cb, ecb }) {
    createDocumentCopyRequest(sourceDocumentId, newVersion, (response) => {
      cb(response);
    });
  },
  // eslint-disable-next-line no-unused-vars
  createMasterDocumentIfNew({ commit }, { append, cb, ecb }) {
    createMasterDocumentIfNewRequest(append, (response) => {
      cb(response);
    });
  },
  saveMasterFileAndAnnexes(
    { commit, dispatch, state },
    { annexes, fileData, cb, ecb = null } // eslint-disable-line no-unused-vars
  ) {
    const append = `?csId=${state.selectedCommonStructure.id}`;
    dispatch('createMasterDocumentIfNew', {
      append: append,
      cb: (r) => {
        fileData.formData.append('documentId', r);
        saveMasterFileAndAnnexesRequest(
          annexes,
          fileData,
          (response) => {
            commit('setSubmitStatus', 'Success');
            cb(response);
          },
          (err) => {
            commit('setSubmitStatus', 'Error');
            console.log(err);
          }
        );
      },
    });
  },
  saveFileAndAnnexes(
    { commit, dispatch, state },
    { annexes, updatedAnnexes, type, fileData, cback, ecb = null } // eslint-disable-line no-unused-vars
  ) {
    let append = `?csId=${state.selectedCommonStructure.id}&version=${fileData.formData.get('version')}&isMaster=${
      fileData.isMaster
    }`;
    const rfcOrIm =
      type === 'CID' ? `&rfcId=${fileData.formData.get('rfcId')}` : `&imId=${fileData.formData.get('imId')}`;
    append += rfcOrIm;

    const notes = fileData.formData.get('documentNote');
    dispatch('createDocumentIfNew', {
      type: type,
      append: append,
      documentNote: notes,
      cb: (r) => {
        if (r.error) cback([{ result: { error: r.error } }]);
        fileData.formData.append('documentId', r);
        saveFileAndAnnexesRequest(
          annexes,
          updatedAnnexes,
          state.deletedAnnexes,
          type,
          fileData,
          (response) => {
            commit('setSubmitStatus', 'Success');
            cback(response);
          },
          (err) => {
            commit('setSubmitStatus', 'Error');
            console.log(err);
          }
        );
      },
    });
  },
  // eslint-disable-next-line no-unused-vars
  saveMasterCIDAndAnnex({ commit }, { annex, fileData, cb, ecb = null }) {
    saveMasterCIDAndAnnexesRequest(
      annex,
      fileData,
      (response) => {
        commit('setSubmitStatus', 'Success');
        cb(response);
      },
      (err) => {
        commit('setSubmitStatus', 'Error');
        console.log(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  fetchDocumentAnalysis({ commit }, { documentId, cb, ecb = null }) {
    fetchDocumentAnalysisRequest(documentId, cb, ecb);
  },
};

// mutations
const mutations = {
  ...generic.mutations,
  clearDocumentData(state) {
    state.selectedFile = null;
    state.data = [];
    state.current = [];
    state.selectedCommonStructure = {};
    state.submitStatus = '';
    state.uploadType = '';
    state.annexes = [];
  },
  addSelectedFile(state, file) {
    state.selectedFile = file;
  },
  addSelectedFileStatus(state, status) {
    if (state.selectedFile) {
      const copy = Object.assign({}, state.selectedFile);
      copy.status = status;
      state.selectedFile = copy;
    } else {
      state.selectedFile = {
        status: status,
      };
    }
  },
  addSelectedAnnex(state, annex) {
    state.annexes.push(annex);
  },
  updateExistingAnnexChapters(state, data) {
    state.annexes[data.index].chapters = [...data.chapters];
    state.annexes[data.index].isUpdated = true;
  },
  updateAnnexIncludeOnDownload(state, data) {
    state.annexes[data.index].includeOnDownload = data.includeOnDownload;
    state.annexes[data.index].isUpdated = true;
  },
  updateAnnexDisplayName(state, data) {
    state.annexes[data.index].displayName = data.displayName;
    state.annexes[data.index].isUpdated = true;
  },
  removeSelectedAnnex(state, index) {
    const annex = state.annexes[index];
    if (annex.annexId) {
      state.deletedAnnexes.push(annex.annexId);
    }
    state.annexes.splice(index, 1);
  },
  removeSelectedFile(state) {
    state.selectedFile = null;
  },
  setSelectedCs(state, data) {
    state.selectedCommonStructure = data;
  },
  setAllDocuments(state, data) {
    state.data = data;
  },
  setCurrentPage(state, data) {
    state.currentPage = data;
  },
  setPageSize(state, data) {
    state.pageSize = data;
  },
  setTotalItems(state, data) {
    state.totalItems = data;
  },
  setTotalPages(state, data) {
    state.totalPages = data;
  },
  setSubmitStatus(state, status) {
    state.submitStatus = status;
  },
  setUploadType(state, status) {
    state.uploadType = status;
  },
  setYears(state, data) {
    state.years = data;
  },
  setCurrentDocument(state, document) {
    state.current = document;
  },
  setMissingFilesOptions(state, data) {
    state.missingFilesOptions.set(data.csLevelName, data.existing);
  },
  updateCurrentDocument(state, { key, value }) {
    if (!state.current) state.current = {};
    state.current[key] = value;
  },
  addBook(state, { index, file, status }) {
    if (!status) {
      state.selectedBooks[index] = {
        file: file,
        status: status,
      };
    } else {
      state.selectedBooks.splice(index, 0, {
        file: file,
        status: status,
      });
    }
  },
  removeBook(state, { index }) {
    state.selectedBooks[index] = null;
  },
  removeAllBooks(state) {
    state.selectedBooks = [];
  },
  setHasUploadedFile(state, status = true) {
    state.hasUploadedFile = status;
  },
  clearDocumentUploadedData(state) {
    state.annexes = [];
    state.selectedFile = null;
    state.selectedBooks = [];
    state.deletedAnnexes = [];
    state.hasUploadedFile = false;
  },
  setIsMasterUpload(state, data) {
    state.current.isMasterFile = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
