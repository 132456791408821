<template>
  <DefaultLayout>
    <div class="fill-height d-flex">
      <v-card class="ma-auto" outlined>
        <v-card-title>
          {{ $t('search.docNotFound.errorMsg') }}
        </v-card-title>
        <v-card-actions class="justify-center text-h6">
          <router-link :to="{ name: 'Search' }">{{ $t('search.docNotFound.linkTitle') }}</router-link
          ><span class="px-1" />{{ $t('search.docNotFound.restText') }}
        </v-card-actions>
      </v-card>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout';

export default {
  name: 'ChapterError',
  components: {
    DefaultLayout,
  },
};
</script>
