var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("Headings", {
        attrs: {
          title: _vm.$t(`common.ImAndRfcManagement`),
          subtitle: _vm.$t(`rfcs.rfcManagement`),
        },
      }),
      _c(
        "div",
        { staticClass: "row justify-content-between", attrs: { id: "inputs" } },
        [
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("SelectComponent", {
                staticClass: "user-list-input",
                attrs: {
                  id: "im-rfc-select",
                  tabindex: 2,
                  options: _vm.managementTypes,
                  name: _vm.$t(`common.manageType`),
                  "obj-key": "name",
                  "obj-val": "value",
                },
                on: { change: _vm.changeUrl },
                model: {
                  value: _vm.managementType,
                  callback: function ($$v) {
                    _vm.managementType = $$v
                  },
                  expression: "managementType",
                },
              }),
            ],
            1
          ),
          _vm.isAdmin
            ? _c(
                "div",
                { staticClass: "offset-6 col-2", attrs: { id: "search-col" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-add-new-rfc",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.open({ id: "new" })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(`rfcs.addNewRfc`)) + " ")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("LabelWithCount", {
              attrs: {
                title: _vm.$t(`rfcs.rfcManagement`),
                count: _vm.rfcs.length,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "table-rfcs" }, [
            _c("table", [
              _c("thead", { staticClass: "table-head-shadow" }, [
                _c(
                  "tr",
                  [
                    _c("th"),
                    _vm._l(_vm.headers, function (header, index) {
                      return _c("th", { key: index }, [
                        _vm._v(" " + _vm._s(header.name) + " "),
                        _c("img", {
                          class: _vm.sortDirection(header.id),
                          attrs: {
                            id: header.id,
                            src: _vm.sortIcon(header.id),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.sortBy(header.id)
                            },
                          },
                        }),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.rfcs, function (rfc, index) {
                  return _c(
                    "tr",
                    {
                      key: index,
                      staticClass: "table-clickable-row",
                      on: {
                        click: function ($event) {
                          return _vm.open(rfc)
                        },
                      },
                    },
                    [
                      _c("td", [
                        _c("img", {
                          staticClass: "logo-preview",
                          attrs: { src: _vm.getLogo(rfc.id), alt: "" },
                        }),
                      ]),
                      _c("td", [_vm._v(_vm._s(rfc.name))]),
                      _c("td", [_vm._v(_vm._s(rfc.shortName))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.mapCountries(rfc.countryIds))),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }