var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LoadingComponent", { attrs: { loading: _vm.spin > 0 } }),
      _c("Headings", {
        staticClass: "d-inline-block mt-6",
        staticStyle: { "margin-bottom": "10px !important" },
        attrs: { subtitle: _vm.$t("search.search_results") },
      }),
      _c("div", { staticClass: "row search-disclaimer" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm._v(" " + _vm._s(_vm.$t(`search.disclaimerResults`)) + " "),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xs-8 col-sm-12" }, [
          _c("div", { staticClass: "btns-container" }, [
            _vm.isLoggedIn
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-green btn-medium mx-3",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.saveSearchUI.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(`search.saveSearch`)) + " ")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn btn-green btn-medium mx-3",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.download.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t(`common.download`)) + " ")]
            ),
            _c("span", { staticClass: "select-chapters-text" }, [
              _vm._v(" " + _vm._s(_vm.$t("search.select_chapters")) + " "),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "table-search-results" }, [
            _c("table", { attrs: { cellspacing: "0" } }, [
              _c("thead", { staticClass: "table-head-shadow" }, [
                _c(
                  "tr",
                  { staticClass: "table-header-solid" },
                  [
                    _c("th", { staticStyle: { width: "75px" } }),
                    _vm._l(_vm.headers, function (header, index) {
                      return _c("th", { key: index }, [
                        _c("span", { staticClass: "header-text" }, [
                          _vm._v(_vm._s(header.name)),
                        ]),
                        _c("img", {
                          attrs: { id: header.id },
                          on: {
                            click: function ($event) {
                              return _vm.sortBy(header.id)
                            },
                          },
                        }),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _vm.currentResults.length
                ? _c(
                    "tbody",
                    _vm._l(_vm.currentResults, function (a, index) {
                      return _c(
                        "tr",
                        {
                          key: index,
                          staticClass: "table-clickable-row",
                          attrs: { id: a.documentToCSId },
                        },
                        [
                          _c(
                            "td",
                            [
                              _c("v-checkbox", {
                                staticClass: "mx-2",
                                attrs: {
                                  value: a.documentToCSId,
                                  multiple: "",
                                  color: "rgb(191, 135, 61)",
                                },
                                model: {
                                  value: _vm.selectedChapters,
                                  callback: function ($$v) {
                                    _vm.selectedChapters = $$v
                                  },
                                  expression: "selectedChapters",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.open(a)
                                },
                              },
                            },
                            [_vm._v(_vm._s(a.organizationName))]
                          ),
                          _c(
                            "td",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.open(a)
                                },
                              },
                            },
                            [_vm._v(_vm._s(a.year))]
                          ),
                          _c(
                            "td",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.open(a)
                                },
                              },
                            },
                            [_vm._v(_vm._s(a.numberOfAnnexes))]
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "document-preview",
                              on: {
                                click: function ($event) {
                                  return _vm.open(a)
                                },
                              },
                            },
                            [
                              _c("strong", [_vm._v(_vm._s(a.csLevelName))]),
                              _c("p", [
                                _vm._v(_vm._s(a.documentPlainTextContent)),
                              ]),
                            ]
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "copy-icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyLink(a)
                                    },
                                  },
                                },
                                [_vm._v("mdi-content-copy")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              !_vm.currentResults.length
                ? _c("tbody", [
                    _c("tr", [
                      _vm.spin === 0
                        ? _c(
                            "td",
                            {
                              staticClass: "no-data-col",
                              attrs: { colspan: "6" },
                            },
                            [_vm._v(" " + _vm._s(_vm.noResultsMessage) + " ")]
                          )
                        : _c(
                            "td",
                            {
                              staticClass: "no-data-col",
                              attrs: { colspan: "6" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(`search.searching`)) + " "
                              ),
                            ]
                          ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xs-8 col-sm-12 mt-5" }, [
          _c("div", { staticClass: "btns-container" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-green btn-medium mx-3",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.download.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t(`common.download`)) + " ")]
            ),
            _c("span", { staticClass: "select-chapters-text" }, [
              _vm._v(" " + _vm._s(_vm.$t("search.select_chapters")) + " "),
            ]),
          ]),
        ]),
      ]),
      _c("ManageSearchModal", {
        attrs: {
          "is-modal-open": _vm.isSaveSearchOpen,
          "close-dialog": _vm.closeSaveSearchDialog,
          search: _vm.searchInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }