import { render, staticRenderFns } from "./LoadingComponent.vue?vue&type=template&id=5ca2c142&"
import script from "./LoadingComponent.vue?vue&type=script&lang=js&"
export * from "./LoadingComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/stefanvartolomeev/work/ws_devcraft/nci-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ca2c142')) {
      api.createRecord('5ca2c142', component.options)
    } else {
      api.reload('5ca2c142', component.options)
    }
    module.hot.accept("./LoadingComponent.vue?vue&type=template&id=5ca2c142&", function () {
      api.rerender('5ca2c142', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/LoadingComponent.vue"
export default component.exports