<template>
  <DefaultLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <Headings :title="$t(`common.documentManagement`)" :subtitle="$t(`documentManagement.subtitle`)" />
    <v-row>
      <v-col cols="12" sm="2" md="2" lg="2">
        <SelectComponent
          v-model="searchForm.selectedType"
          :tabindex="1"
          :options="managementTypes"
          :name="$t('common.type') + ':'"
          obj-key="name"
          obj-val="value"
          class="select-big"
        />
      </v-col>
      <v-col cols="12" sm="5" md="5" lg="2">
        <SelectComponent
          v-model="searchForm.selectedYear"
          :tabindex="2"
          :options="years"
          :name="$t(`common.years`)"
          obj-key="value"
          obj-val="value"
          class="select-big"
        />
      </v-col>
      <v-col cols="12" sm="5" md="5" lg="3">
        <MultipleSelectComponent
          v-model="searchForm.selectedIMs"
          :tabindex="3"
          :disabled="disableIMs"
          :options="imsList"
          :name="$t(`common.infrastructureManagers`)"
          obj-key="name"
          obj-val="value"
          class="multiselect-big"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3">
        <MultipleSelectComponent
          v-model="searchForm.selectedRFCs"
          :tabindex="4"
          :disabled="disableRFCs"
          :options="rfcList"
          :name="$t(`common.railFreightCorridors`)"
          obj-key="name"
          obj-val="value"
          class="multiselect-big"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" class="btn-search-container">
        <button class="btn btn-green btn-search" @click="searchByCriteria">
          <img class="search-icon" src="@/assets/icons/grey-icons/search.svg" />
          {{ $t(`common.search`) }}
        </button>
      </v-col>
    </v-row>
    <div class="row">
      <div class="col-12">
        <LabelWithCount :title="$t('documentManagement.documents')" :count="totalItems" />
      </div>
      <div class="col-12">
        <div class="table-documents">
          <table class="table">
            <thead>
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="index"
                  scope="col"
                  :class="{ 'header-bold': sortedColumn === header.name }"
                >
                  {{ header.name }}
                  <img
                    :id="header.id"
                    :class="sortDirection(header.id)"
                    :src="sortIcon(header.orderEnum)"
                    @click="sortBy(header)"
                  />
                </th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(doc, index) in allDocuments" :key="index" class="table-clickable-row" @click="open(doc.id)">
                <td>
                  <img class="document" src="@/assets/icons/grey-icons/documentmanagement.svg" />
                  <img class="documentGold" src="@/assets/icons/gold-icons/documentmanagement.svg" />
                  <span>{{ doc.organizationName }}</span>
                </td>
                <td>{{ doc.type }}</td>
                <td>{{ doc.year }}</td>
                <td>{{ doc.version }}</td>
                <td>{{ doc.status }}</td>
                <td>{{ doc.lastPublished || 'N/A' }}</td>
                <td style="white-space: break-spaces">
                  {{
                    $t('documentManagement.updatedBy', {
                      updated: formatDate(doc.lastUpdatedOn) || 'N/A',
                      author: doc.lastUpdatedByUserName || 'N/A',
                    })
                  }}
                </td>
              </tr>
            </tbody>
            <tfoot v-if="totalPages > 0 && totalPages > page + 1">
              <tr>
                <td colspan="9">
                  <span class="btn-loadmore" @click="loadMore">{{ $t(`common.loadMore`) }}</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="row">
          <div v-if="isAdmin || (isDocManager && imsAccess.length)" class="col-12 col-sm-6 col-md-2">
            <button class="btn btn-bottom-action btn-green" @click.prevent="navigateToUploadType('NS')">
              {{ $t(`documentManagement.uploadNS`) }}
            </button>
          </div>
          <div v-if="isAdmin || (isDocManager && rfcsAccess.length)" class="col-12 col-sm-6 col-md-2">
            <button class="btn btn-bottom-action btn-green" @click.prevent="navigateToUploadType('CID')">
              {{ $t(`documentManagement.uploadCID`) }}
            </button>
          </div>
          <div v-if="isAdmin || (isDocManager && masterCIDAccess)" class="col-12 col-sm-6 col-md-3 btn-upl-master-cid">
            <button class="btn btn-bottom-action btn-green" @click.prevent="navigateToMasterCIDUpload">
              {{ $t(`documentManagement.uploadMasterCID`) }}
            </button>
          </div>
          <div v-if="isAdmin || isDocManager" class="col-12 col-sm-6 col-md-5 btn-download-master-cid">
            <button class="btn btn-bottom-action btn-white-border" @click.prevent="toggleMasterDownloadCIDModal">
              {{ $t(`documentManagement.downloadMasterCID`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <UploadMasterCID v-if="masterCIDAccess" :is-modal-open="isMasterCIDModalOpen" :close-dialog="closeMasterCIDModal" />
    <DownloadMasterCID
      :is-modal-open="isDownloadCIDModalOpen"
      :close-dialog="closeMasterDownloadCIDModal"
      :years="years"
    />
  </DefaultLayout>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import DefaultLayout from '@/components/layout/DefaultLayout';
import DownloadMasterCID from '@/components/document/modals/DownloadMasterCID';
import UploadMasterCID from '@/components/document/modals/UploadMasterCID';
import Headings from '@/components/base/Headings';
import LabelWithCount from '@/components/base/LabelWithCount';
import SelectComponent from '@/components/base/SelectComponent';
import MultipleSelectComponent from '@/components/base/MultipleSelectComponent';
import LoadingComponent from '@/components/base/LoadingComponent';

const notSortedIcon = require('@/assets/icons/grey-icons/arrow.png');
const sortedIcon = require('@/assets/icons/gold-icons/arrow.png');

export default {
  name: 'DocManagement',
  components: {
    DefaultLayout,
    LabelWithCount,
    Headings,
    SelectComponent,
    MultipleSelectComponent,
    DownloadMasterCID,
    UploadMasterCID,
    LoadingComponent,
  },
  data() {
    return {
      managementType: '',
      disableRFCs: true,
      disableIMs: true,
      searchForm: {
        selectedType: 'NS_AND_CID',
        selectedYear: 'All',
        selectedRFCs: [],
        selectedIMs: [],
      },
      isMasterCIDModalOpen: false,
      isDownloadCIDModalOpen: false,
      sortedColumn: '',
      isLoading: false,
      search: {},
      orderType: 'DESC', // Default
      orderBy: 'LAST_UPDATED_ON',
    };
  },
  computed: {
    ...mapState({
      rfcList: (state) => {
        if (state.auth.current.roles === 'ADMIN') {
          return state.rfcs.data.map((rfc) => {
            return {
              value: rfc.id, // no id is provided from the service for the document details call - only name
              name: rfc.name + ' ( ' + rfc.shortName + ' )',
            };
          });
        } else {
          const rfcAccess = state.auth.current.railFreightCorridorOverviews.map((rfcAccess) => rfcAccess.id);
          return state.rfcs.data
            .filter((r) => rfcAccess.includes(r.id))
            .map((rfc) => {
              return {
                value: rfc.id, // no id is provided from the service for the document details call - only name
                name: rfc.name + ' ( ' + rfc.shortName + ' )',
              };
            });
        }
      },
      managementTypes: (state) => {
        if (state.auth.current.roles === 'ADMIN') {
          return [
            {
              name: 'CID',
              value: 'CID',
            },
            {
              name: 'NS',
              value: 'NS',
            },
            {
              name: 'NS & CID',
              value: 'NS_AND_CID',
            },
          ];
        } else {
          const result = [];
          const ims = state.auth.current.infrastructureManagerOverviews.length;
          const rfcs = state.auth.current.railFreightCorridorOverviews.length;
          if (ims) {
            result.push({
              name: 'NS',
              value: 'NS',
            });
          }
          if (rfcs) {
            result.push({
              name: 'CID',
              value: 'CID',
            });
          }
          if (ims && rfcs) {
            result.push({
              name: 'NS & CID',
              value: 'NS_AND_CID',
            });
          }

          return result;
        }
      },
      imsAccess: (state) => state.auth.current.infrastructureManagerOverviews,
      rfcsAccess: (state) => state.auth.current.railFreightCorridorOverviews,
      isAdmin: (state) => state.auth.current.roles === 'ADMIN',
      isDocManager: (state) => state.auth.current.roles === 'DOCUMENT_MANAGER',
      masterCIDAccess: (state) =>
        state.auth.current.roles === 'ADMIN' ||
        (state.auth.current.roles === 'DOCUMENT_MANAGER' && state.auth.current.masterUploadRight),
      years: (state) => {
        const updated = [...state.documents.years];
        updated.splice(0, 0, {
          value: 'All',
          id: null,
        });
        return updated;
      },
      imsList: (state) => {
        if (state.auth.current.roles === 'ADMIN') {
          return state.ims.data.map((im) => {
            return {
              value: im.id, // no id is provided from the service for the document details call - only name
              name: im.name,
            };
          });
        } else {
          const imsAccess = state.auth.current.infrastructureManagerOverviews.map((imAccess) => imAccess.id);
          return state.ims.data
            .filter((i) => imsAccess.includes(i.id))
            .map((im) => {
              return {
                value: im.id, // no id is provided from the service for the document details call - only name
                name: im.name,
              };
            });
        }
      },
      allDocuments: (state) => state.documents.data,
      allDocumentsLength: (state) => state.documents.data.length,
      page: (state) => state.documents.currentPage,
      totalPages: (state) => state.documents.totalPages,
      totalItems: (state) => state.documents.totalItems,
      // orderBy: state => state.documents.orderBy,
      // orderType: state => state.documents.orderType,
      headers() {
        return [
          {
            name: (this.$t('ims.name') + '/' + this.$t('rfcs.name')).toUpperCase(),
            id: 'organizationName',
            orderEnum: 'ORGANIZATION_NAME',
          },
          {
            name: this.$t('common.type').toUpperCase(),
            id: 'type',
            orderEnum: 'TYPE',
          },
          {
            name: this.$t('statistics.search_org.tt_year'),
            id: 'year',
            orderEnum: 'TIMETABLE_YEAR',
          },
          // { name: "ORGANIZATION NAME", id: "organizationName"},
          {
            name: this.$t('common.version').toUpperCase(),
            id: 'version',
            orderEnum: 'VERSION',
          },
          {
            name: this.$t('common.status').toUpperCase(),
            id: 'status',
            orderEnum: 'STATUS',
          },
          {
            name: this.$t('documentManagement.last_published').toUpperCase(),
            id: 'lastPublished',
            orderEnum: 'LAST_PUBLISHED',
          },
          {
            name: this.$t('documentManagement.last_updated').toUpperCase(),
            id: 'lastUpdatedOn',
            orderEnum: 'LAST_UPDATED_ON',
          },
          // { name: "LAST UPDATED BY", id: "lastUpdatedByUserName" }
        ];
      },
    }),
  },
  watch: {
    'searchForm.selectedType'(val) {
      this.setVisibleFilters(val);
    },
  },
  created() {
    this.isLoading = true;
    // clear state if any
    this.removeAllBooks();
    this.clearDocumentUploadedData();
    this.clearDocumentData();
    this.filterTypes();
    const search = this.$route.params?.search;

    if (search && Object.keys(search).length) {
      const parsedSearch = JSON.parse(atob(search));
      this.searchForm.selectedType = parsedSearch.selectedType;
      this.searchForm.selectedYear = parsedSearch.selectedYear;
      this.searchForm.selectedIMs = this.imsList.filter((im) => parsedSearch.selectedIms.includes(im.value));
      this.searchForm.selectedRFCs = this.rfcList.filter((rfc) => parsedSearch.selectedRFCs.includes(rfc.value));
      this.searchByCriteria();
    } else {
      this.fetchAllDocuments({
        data: {
          type: this.searchForm.selectedType,
          orderBy: this.orderBy,
          orderType: this.orderType,
        },
        cb: () => {
          this.isLoading = false;
          this.filterTypes();
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    }

    this.getAllIMs({});
    this.getAllRFCs({});
    this.getYears({});
    // this.filterTypes();
  },
  methods: {
    ...mapActions('documents', ['setSelectedUploadType', 'fetchAllDocuments', 'order', 'getYears']),
    ...mapActions('rfcs', ['getAllRFCs']),
    ...mapActions('ims', ['getAllIMs']),
    ...mapMutations('documents', ['clearDocumentData', 'removeAllBooks', 'clearDocumentUploadedData']),
    sortIcon(id) {
      if (this.orderBy === id) return sortedIcon;
      else return notSortedIcon;
    },
    sortDirection(id) {
      if (this.orderBy === id && this.orderType === 'ASC') return '';
      else return 'reversed';
    },
    open(documentId) {
      this.$router.push({
        name: 'DocManagementUpload',
        query: { id: documentId },
        params: { search: this.search },
      });
    },
    generateSearchObject() {
      const searchObj = {};
      searchObj.selectedIms = this.searchForm.selectedIMs.map((im) => im.value);
      searchObj.selectedRFCs = this.searchForm.selectedRFCs.map((rfc) => rfc.value);
      searchObj.selectedType = this.searchForm.selectedType;
      searchObj.selectedYear = this.searchForm.selectedYear;
      this.search = btoa(JSON.stringify(searchObj));
    },
    navigateToUploadType(type) {
      this.setSelectedUploadType({ data: type });
      this.$router.push({
        name: 'DocManagementUpload',
        query: { type: type },
      });
    },
    navigateToMasterCIDUpload() {
      this.$router.push({
        name: 'DocManagementUpload',
        query: {
          type: 'CID',
          isMaster: true,
        },
      });
    },
    formatDate(date) {
      // NOTE: Date object was parsing the date incorrectly, so we simply split the provided string
      return date ? date.split('T')[0] : '';
    },
    sortBy(header) {
      this.isLoading = true;
      this.sortedColumn = header.name;
      // this.order(header.id);
      this.orderType = this.orderType === 'ASC' ? 'DESC' : 'ASC';
      this.orderBy = header.orderEnum;
      this.$forceUpdate();
      this.searchByCriteria();
    },
    changeUrl() {
      this.$router.push({
        path: this.managementType,
      });
    },
    searchByCriteria() {
      this.isLoading = true;
      const ims = this.searchForm.selectedIMs.map((item) => item.value);
      const rfcs = this.searchForm.selectedRFCs.map((item) => item.value);
      const data = {
        ims,
        rfcs,
        type: this.searchForm.selectedType,
        year: this.searchForm.selectedYear === 'All' ? '' : this.searchForm.selectedYear,
        isSearch: true,
        orderBy: this.orderBy,
        orderType: this.orderType,
      };
      this.fetchAllDocuments({
        data: data,
        cb: () => {
          this.generateSearchObject();
          this.isLoading = false;
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    closeMasterCIDModal() {
      this.toggleMasterCIDUploadModal();
    },
    closeMasterDownloadCIDModal() {
      this.toggleMasterDownloadCIDModal();
    },
    toggleMasterDownloadCIDModal() {
      this.isDownloadCIDModalOpen = !this.isDownloadCIDModalOpen;
    },
    toggleMasterCIDUploadModal() {
      this.isMasterCIDModalOpen = !this.isMasterCIDModalOpen;
    },
    loadMore() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const ims = this.searchForm.selectedIMs.map((item) => item.value);
      const rfcs = this.searchForm.selectedRFCs.map((item) => item.value);
      const data = {
        ims,
        rfcs,
        type: this.searchForm.selectedType,
        year: this.searchForm.selectedYear === 'All' ? '' : this.searchForm.selectedYear,
        page: this.page + 1,
        pageSize: 10,
        orderBy: this.orderBy,
        orderType: this.orderType,
      };
      this.fetchAllDocuments({
        data: data,
        cb: () => {
          this.isLoading = false;
        },
        ecb: () => {
          this.isLoading = false;
        },
      });
    },
    filterTypes() {
      if (this.managementTypes.length === 1) {
        this.searchForm.selectedType = this.managementTypes[0].value;
      }
      this.setVisibleFilters(this.searchForm.selectedType);
    },
    setVisibleFilters(val) {
      this.disableRFCs = true;
      this.disableIMs = true;
      switch (val) {
        case 'NS':
          this.disableIMs = false;
          break;
        case 'CID':
          this.disableRFCs = false;
          break;
        case 'NS_AND_CID':
          this.disableRFCs = false;
          this.disableIMs = false;
      }
    },
  },
};
</script>

<style scoped>
.btn-bottom-action {
  padding: 2px 15px;
  width: 100%;
}

.btn-search {
  font-family: Montserrat, sans-serif;
  font-size: 1.4em;
  font-weight: 500;
  height: 56px;
  padding: 2px 26px;
  float: left;
  min-width: 165px;
}

.btn-search-container {
  margin-top: 2.4em;
}

.btn-upload-master-cid,
.btn-cancel-upload-master-cid {
  font-size: 18px;
  padding: 2px 5px;
}

.btn-loadmore {
  margin: 0 auto;
  cursor: pointer;
  width: 200px;
  display: block;
  color: #265f14;
  font-weight: 600;
  text-shadow: 1px 1px rgba(255, 255, 255, 1);
  font-size: 1.1em;
}

tfoot tr:hover {
  background: rgb(255, 255, 255);
}

tfoot tr td {
  text-align: center;
}

>>> .select-big > select {
  font-weight: 600;
  color: black;
}

.header-bold {
  font-weight: 600;
  opacity: 1;
  color: #080808;
}

.btn-upl-master-cid {
  max-width: 250px;
}

.btn-download-master-cid {
  max-width: 300px;
}

>>> .multiselect-big > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections {
  height: 56px !important;
  padding-right: 36px;
}

>>> .multiselect-big > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > input {
  height: 56px !important;
  padding-right: 36px;
}

.search-icon {
  height: 36px;
  width: 36px;
  display: inline-block;
  vertical-align: middle;
  filter: brightness(0) invert(1);
}
</style>
