import {
  getAllAdminUsersRequest,
  searchUsersByCriteria,
  getUserByIdRequest,
  updateUserByIdRequest,
  grantMasterCIDPermissionsRequest,
  revokeMasterCIDPermissionsRequest,
  deleteUserRequest,
  deleteUserByIdRequest,
  fetchTypesOfEntitiesRequest,
} from '@/services/users.service';
import order from '../utils/order';

// initial state
const state = () => ({
  ...order.state,
  orderBy: 'firstName', // default order
  data: [],
  current: {},
  currentPage: 1,
  totalPages: 1,
  totalItems: 1,
  entities: [],
});

// getters
const getters = {};

// actions
const actions = {
  ...order.actions,
  getAllAdminUsers({ commit }) {
    getAllAdminUsersRequest((users) => {
      updateUsersListStateOnFetch(commit, users.data);
      commit('updatePagination', users);
    });
  },
  clearUserState({ commit }) {
    commit('clearUser');
  },
  getTypesOfEntities({ commit }, { cb = null, ecb = null }) {
    fetchTypesOfEntitiesRequest(
      (entities) => {
        commit('setEntities', entities);
        if (cb) cb();
      },
      () => {
        if (ecb) ecb();
      }
    );
  },
  getFilteredUsers(
    { commit },
    {
      username = '',
      organizationType = '',
      role = '',
      orderBy = '',
      order = 'DESC',
      page,
      pageSize,
      cb = null,
      ecb = null,
    }
  ) {
    // const orderBy = mapOrderByForUsersList(state.orderBy);
    searchUsersByCriteria(
      username,
      organizationType,
      role,
      orderBy,
      order,
      page,
      pageSize,
      (users) => {
        if (cb) cb();
        updateUsersListStateOnFetch(commit, users.data);
        commit('updatePagination', users);
      },
      () => {
        if (ecb) ecb();
      }
    );
  },
  getUserById({ commit }, { userId }) {
    return new Promise((resolve) => {
      getUserByIdRequest(userId, (user) => {
        commit('setEditedUser', user);
        resolve();
      });
    });
  },
  updateUserById({ commit }, { data, cb, ecb = null }) {
    updateUserByIdRequest(data, cb, ecb, (response) => {
      commit('setEditedUser', response);
    });
  },
  // eslint-disable-next-line no-unused-vars
  deleteUser({ commit }, { cb = null, ecb = null }) {
    deleteUserRequest(
      () => {
        if (cb) cb();
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  deleteUserById({ commit }, { id, cb = null, ecb = null }) {
    deleteUserByIdRequest(
      id,
      () => {
        if (cb) cb();
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  grantMasterCIDPermissions({ commit }, { userId, cb, ecb = null }) {
    grantMasterCIDPermissionsRequest(
      userId,
      cb,
      ecb,
      () => {
        if (cb) cb();
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  // eslint-disable-next-line no-unused-vars
  revokeMasterCIDPermissions({ commit }, { userId, cb, ecb = null }) {
    revokeMasterCIDPermissionsRequest(
      userId,
      cb,
      ecb,
      () => {
        if (cb) cb();
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
};

// mutations
const mutations = {
  ...order.mutations,
  clearUser(state) {
    state.current = {};
  },
  setUsersList(state, data) {
    state.data = data;
  },
  setEditedUser(state, data) {
    state.current = data;
  },
  updatePagination(state, data) {
    state.currentPage = data.currentPage;
    state.totalPages = data.totalPages;
    state.totalItems = data.totalItems;
  },
  setEntities(state, data) {
    state.entities = data;
  },
};

// const mapOrderByForUsersList = function (orderBy) {
//   switch (orderBy) {
//     case "firstName":
//       return "USERNAME";
//     case "companyName":
//       return "COMPANY";
//     case "organizationType":
//       return "TYPE";
//     case "roles":
//       return "ROLE";
//     case "email":
//       return "EMAIL";
//   }
// };

const updateUsersListStateOnFetch = function (commit, response) {
  const data = [...response];
  commit('setUsersList', data);
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
