import {
  getCountries,
  getMemberCountries,
  getEntities,
  //getTagsRequest
} from '@/services/common.service';
import '@/utils/utils';

// initial state
const state = () => ({
  countries: [],
  memberCountries: [],
  tags: [],
  foundCountries: [],
  entities: [],
});

// getters
const getters = {
  countriesCount: (state) => {
    return state.countries.length;
  },
  filterCountries: (state) => (index, country) => {
    return state.foundCountries.splice(index, 1, country);
  },
};

// actions
const actions = {
  getCountries({ commit }, { cb = null, ecb = null }) {
    getCountries(
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setCountries', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  getMemberCountries({ commit }, { cb = null, ecb = null, type = null }) {
    getMemberCountries(
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setMemberCountries', res);
      },
      (err) => {
        if (ecb) ecb(err);
      },
      type
    );
  },
  /*
  getTags({ commit }, { cb = null, ecb = null })
  {
    getTagsRequest(
      res =>
      {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setTags', res);
      },
      err =>
      {
        if (ecb) ecb(err);
      }
    );
  },
  */
  getEntities({ commit }, { cb = null, ecb = null }) {
    getEntities(
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setEntities', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
};

// mutations
const mutations = {
  updateCountries(state, data) {
    const items = state.countries.filter((x) => x.id !== data.id);
    state.countries = [...items, data].sortBy('name');
    state.foundCountries = state.countries;
  },
  deleteCountry(state, data) {
    const items = state.countries.filter((x) => x.id !== data);
    state.countries = [...items].sortBy('name');
    state.foundCountries = state.countries;
  },
  addCountry(state, data) {
    state.countries = [...state.countries, data].sortBy('name');
    state.foundCountries = state.countries;
  },
  setCountries(state, data) {
    state.countries = data;
    state.foundCountries = data;
  },
  setMemberCountries(state, data) {
    state.memberCountries = data;
    state.foundMemberCountries = data;
  },
  setTags(state, data) {
    state.tags = data;
  },
  setEntities(state, data) {
    state.entities = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
