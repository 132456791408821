<template>
  <DefaultLayout>
    <LoadingComponent :loading="loading" />
    <Headings :title="$t(`auditLog.auditLog`)" :subtitle="$t(`auditLog.subtitle`)" />
    <div class="d-flex justify-center align-end py-3">
      <DateInput v-model="fromDate" :label="$t('auditLog.from_date')" outlined dense classes="mx-2" />
      <DateInput v-model="toDate" :label="$t('auditLog.to_date')" outlined dense classes="mx-2" />
      <!-- Due to limited customizability of the input elements, specific
        margin bottom styles have been added to align the properly filters -->
      <InputField
        :value.sync="email"
        :use-model="true"
        class="user-list-input"
        :name="$t(`auditLog.search_by_email`)"
        :tabindex="1"
        style="margin-bottom: 26px"
        @update="email = $event"
      />
      <v-btn color="primary" class="mx-2" style="margin-bottom: 29px" @click="fetchData()">{{
        $t('common.search')
      }}</v-btn>
      <v-btn color="primary" style="margin-bottom: 29px" @click="downloadData()">
        <img :src="downloadIcon" width="28px" />
      </v-btn>
    </div>
    <div class="table-logs">
      <v-simple-table class="audit-table" dense>
        <thead>
          <tr>
            <th>{{ $t('auditLog.createdAt') }}</th>
            <th>{{ $t('auditLog.email') }}</th>
            <th>{{ $t('auditLog.executionTime') }}</th>
            <th>{{ $t('auditLog.eventName') }}</th>
            <th>{{ $t('auditLog.details') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(event, idx) in events"
            :key="`event-${idx}`"
            :class="{ expanded: expandedRow == idx }"
            @click="expandedRow = expandedRow == idx ? null : idx"
          >
            <td nowrap>
              {{
                event.createdAt
                  ? new Date(event.createdAt).toLocaleDateString('en-uk', {
                      day: 'numeric',
                      year: 'numeric',
                      month: 'short',
                    })
                  : ''
              }}
            </td>
            <td nowrap>{{ event.email }}</td>
            <td nowrap>{{ event.executionTime ? formatDuration(event.executionTime) : '' }}</td>
            <td nowrap>{{ event.eventName }}</td>
            <td nowrap>
              <div class="event-details">{{ event.details }}</div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout.vue';
import LoadingComponent from '@/components/base/LoadingComponent.vue';
import Headings from '@/components/base/Headings';
import DateInput from '@/components/base/DateInput.vue';
import InputField from '@/components/base/InputField';
import { formatDuration } from '@/utils/utils.js';
import downloadIcon from '@/assets/icons/download.svg';

export default {
  name: 'AuditLogPage',
  components: {
    DefaultLayout,
    LoadingComponent,
    Headings,
    DateInput,
    InputField,
  },
  data() {
    return {
      loading: false,
      // Request
      fromDate: null,
      toDate: null,
      email: null,
      page: 0,
      pageSize: 10,
      // Response
      events: [],
      toatlEvents: 0,
      totalPages: 0,
      downloadIcon,
      expandedRow: null,
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    window.addEventListener('scroll', this.visibilityChanged, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.visibilityChanged);
  },
  methods: {
    downloadData() {
      this.loading = true;
      this.$axios
        .post(
          'admin-users/event-logs/excel',
          {
            email: this.email,
            from: this.fromDate,
            until: this.toDate,
          },
          {
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'eventLogs.xlsx'); // filename is hardcoded due to the response interceptor, we cannot access the content-disposition filename value
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchData(append) {
      this.loading = true;
      if (!append) {
        this.page = 0;
      }
      this.$axios
        .post(`admin-users/event-logs?page=${this.page}&pageSize=${this.pageSize}`, {
          email: this.email,
          from: this.fromDate,
          until: this.toDate,
        })
        .then((response) => {
          if (append) this.events.push(...response.data);
          else this.events = response.data;
          this.totalEvents = response.totalItems;
          this.totalPages = response.totalPages;

          setTimeout(() => {
            this.visibilityChanged(); // check again if we are close to the bottom of the page - to try loading the next page
          }, 90);
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    visibilityChanged() {
      if (this.spin > 0) return;
      if (document.scrollingElement.scrollTop + window.innerHeight > document.scrollingElement.scrollHeight - 100) {
        if (this.totalPages > 0 && this.totalPages > this.page + 1 && this.totalEvents > this.events.length)
          this.loadMore();
      }
    },
    loadMore() {
      if (this.loading) {
        return;
      }
      this.page++;
      this.fetchData(true);
    },
    formatDuration(ms) {
      return formatDuration(ms);
    },
  },
};
</script>

<style lang="scss">
.audit-table table {
  table-layout: auto;
}

.audit-table .v-data-table__wrapper {
  min-width: 200em;
  max-width: 300em;
}

.statglobal .v-data-table__wrapper {
  min-width: 50em !important;
  max-width: 300em;
}

.audit-table tbody {
  vertical-align: baseline;
}

.audit-table td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.audit-table tbody tr {
  cursor: pointer;
}

.btn-loadmore {
  margin: 0 auto;
  cursor: pointer;
  width: 200px;
  display: block;
  color: #265f14;
  font-weight: 600;
  text-shadow: 1px 1px rgba(255, 255, 255, 1);
  font-size: 1.1em;
}

.table-logs {
  max-width: 100%;
  overflow-x: scroll;
}

.expanded > * {
  height: 150px !important;
}

.expanded .event-details {
  width: 80em;
  white-space: pre;
}

.v-data-table .v-data-table__wrapper {
  overflow-x: hidden;
}
</style>
