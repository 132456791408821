<template>
  <DefaultLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <Headings :title="$t('tags.tags_management')" :subtitle="$t('tags.manage_tags')">
      <button class="add_country_btn" @click.prevent="openDialog()">
        {{ $t('tags.add_tag') }}
      </button>
    </Headings>
    <div class="row">
      <div class="col-12">
        <LabelWithCount :title="$t('tags.tags')" :count="totalTags" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-users">
          <table class="tags-table">
            <thead>
              <tr>
                <th v-for="(header, index) in headers" :key="index">
                  {{ header.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(_tag, index) in tagsArray"
                :key="index"
                class="country_row table-clickable-row"
                @click.stop="openDialog(_tag.id)"
              >
                <td id="name_country">
                  <b>{{ _tag.name }}</b>
                </td>
                <!--
                <td class="col-actions">
                  <v-btn class="mx-2" fab dark small color="#DBA153">
                    <v-icon dark>arrow_forward</v-icon>
                  </v-btn>
                </td>
                -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Modal :key="tag.id" :is-modal-open="isModalOpen" :close-dialog="closeDialog">
      <template #header>
        {{ $t(tag.id ? 'tags.edit_tag' : 'tags.add_tag') }}
      </template>
      <InputField
        :name="$t('tags.tag_name')"
        :value.sync="tag.name"
        :update="($event) => validateAndSetInput($event)"
        :error="nameError"
      />
      <!--
      <input-field
        :name="$t(`common.countryCode`)"
        :value.sync="tag.code"
        :update="updateCode"
        :error="errors.error('country.code')"
      />
      -->
      <template #footer>
        <div v-if="!tag.id" class="row">
          <div class="col-6">
            <button class="save_edited_country_btn" @click="add">
              {{ $t('common.add') }}
            </button>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-6">
            <button class="save_edited_country_btn" @click="update">
              {{ $t('common.save') }}
            </button>
          </div>
          <div class="col-6">
            <button class="delete_country_btn" @click="deleteItem">
              {{ $t('common.delete') }}
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <ConfirmModal
      :is-modal-open="isConfirmModalOpen"
      :close-dialog="closeConfirmModal"
      :confirm-callback="confirmCallback"
      :lable-text="confirmLableText"
      style="z-index: 20001"
    />
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/layout/DefaultLayout';
import Headings from '@/components/base/Headings';
import LabelWithCount from '@/components/base/LabelWithCount';
import Modal from '@/components/base/Modal';
import InputField from '@/components/base/InputField';
import { mapState, mapActions } from 'vuex';
import LoadingComponent from '@/components/base/LoadingComponent';
import ConfirmModal from '../common/modals/ConfirmModal';

const imageOne = require('@/assets/icons/grey-icons/arrow.png');

export default {
  name: 'TagsList',
  components: {
    DefaultLayout,
    LabelWithCount,
    Headings,
    Modal,
    InputField,
    LoadingComponent,
    ConfirmModal,
  },
  data() {
    return {
      headers: [
        {
          name: 'TAG',
          id: 'username_sort',
          image: imageOne,
        },
      ],
      isModalOpen: false,
      //errors: [],
      validate: ['tag.name', 'tag.code'],
      tag: {},
      isLoading: false,
      nameError: false,
      isConfirmModalOpen: false,
      confirmLableText: '',
      confirmCallback: null,
    };
  },
  computed: {
    ...mapState({
      tagsArray: (state) => state.tags.data,
      totalTags: (state) => state.tags.data.length,
    }),
  },
  mounted() {
    this.validate.valid(this);
  },
  created() {
    this.isLoading = true;
    this.getAllTags({
      cb: () => {
        this.isLoading = false;
      },
    });
    this.image = this.imageOne;
  },
  methods: {
    ...mapActions('tags', ['getAllTags', 'createSingleTag', 'getSingleTag', 'deleteSingleTag', 'updateSingleTag']),
    validateAndSetInput(event) {
      if (this.nameError && event.length) {
        this.nameError = '';
      }
      this.tag.name = event;
    },
    add() {
      if (!this.tag.name) {
        this.nameError = 'Please provide a name for the tag.';
        return;
      } else if (this.tagsArray.find((t) => t.name === this.tag.name)) {
        this.nameError = 'Tag name already exists.';
        return;
      }
      this.isLoading = true;
      this.createSingleTag({
        data: this.tag,
        cb: (data) => {
          this.addCountryFeedback = data.message;
          this.toggleModal();
          this.tag = {};
          this.getAllTags({
            cb: () => {
              this.isLoading = false;
            },
          });
        },
        ecb: (err) => {
          console.log(err);
          this.addCountryFeedback = err.error;
          this.isLoading = false;
        },
      });
    },
    update() {
      this.updateSingleTag({
        data: this.tag,
        cb: (data) => {
          this.getAllTags({
            cb: () => {
              this.addCountryFeedback = data.message;
              this.toggleModal();
              this.isLoading = false;
            },
          });
        },
        ecb: (err) => {
          console.log(err);
          this.addCountryFeedback = err.error;
        },
      });
    },
    deleteItem() {
      const { id } = this.tag;
      if (id) {
        this.openConfirmModal(this.$t(`tags.deleteConfirm`), () => {
          this.isLoading = true;
          this.deleteSingleTag({
            id,
            cb: () => {
              this.tag = {};
              this.getAllTags({
                cb: () => {
                  this.toggleModal();
                  this.isLoading = false;
                },
              });
            },
          });
          this.isConfirmModalOpen = false;
        });
      }
    },
    toggleModal(isEdit = false) {
      if (!isEdit) {
        this.tag = {};
      }
      this.nameError = '';
      this.isModalOpen = !this.isModalOpen;
    },
    openDialog(id) {
      if (id) {
        this.isLoading = true;
        this.getSingleTag({
          id,
          cb: (val) => {
            this.tag = { ...val };
            this.isLoading = false;
            this.toggleModal(true);
          },
        });
      } else {
        this.toggleModal();
      }
    },
    closeDialog() {
      this.toggleModal();
      // this.clearCurrent();
    },
    openConfirmModal(text, callback) {
      this.confirmLableText = text;
      this.confirmCallback = callback;
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
  },
};
</script>

<style scoped>
.tags-table td {
  height: 32px;
}
</style>
