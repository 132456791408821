import { axiosInstance } from '../utils/axios.js';
import { COUNTRIES_URL, ENTITIES_URL, GET_MEMBERS_COUNTRIES_URL } from '../utils/api.endpoints';

export const getCountries = function (cb, ecb) {
  axiosInstance
    .get(COUNTRIES_URL)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const getMemberCountries = function (cb, ecb, type) {
  let url = GET_MEMBERS_COUNTRIES_URL;
  if (type) {
    url += `&searchType=${type}`;
  }
  axiosInstance
    .get(url)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const addCountry = function (data, cb, ecb) {
  axiosInstance
    .post(COUNTRIES_URL, data)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const getEntities = function (cb, ecb) {
  axiosInstance
    .get(ENTITIES_URL)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};
