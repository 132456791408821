var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("Headings", {
        attrs: {
          title: _vm.$t(`common.commonStructures`),
          subtitle: _vm.$t(`common.management`),
          "back-location": "CommonList",
        },
      }),
      _c(
        "div",
        { staticClass: "row justify-content-between", attrs: { id: "inputs" } },
        [
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c("SelectComponent", {
                staticClass: "user-list-input disabled-field select-big",
                attrs: {
                  tabindex: 2,
                  "is-disabled": true,
                  options: _vm.managementTypes,
                  name: _vm.$t(`common.type`),
                  "obj-key": "name",
                  "obj-val": "value",
                },
                model: {
                  value: _vm.managementType,
                  callback: function ($$v) {
                    _vm.managementType = $$v
                  },
                  expression: "managementType",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-3" },
            [
              _c("MultipleSelectComponent", {
                attrs: {
                  tabindex: 4,
                  options: _vm.yearsAvailable,
                  disabled: true,
                  prepend: "mdi-calendar-blank-outline",
                  "class-style": "yearPicker disabled-field",
                  chips: false,
                  name: _vm.$t(`common.years`),
                  "obj-key": "name",
                  "obj-val": "value",
                },
                model: {
                  value: _vm.selectedYears,
                  callback: function ($$v) {
                    _vm.selectedYears = $$v
                  },
                  expression: "selectedYears",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "col-7", attrs: { id: "search-col" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-green btn-search",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.createStructure.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.managementType === "CID"
                        ? _vm.$t(`commonDetails.createBook`)
                        : _vm.$t(`commonDetails.createChapter`)
                    ) +
                    " "
                ),
              ]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "drodown-shadow" }),
        ]),
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "table-rfcs singleCommon" },
            [
              _c("v-treeview", {
                ref: "tree",
                attrs: {
                  items: _vm.draggableLevels,
                  "item-children": "levels",
                  "item-key": "id",
                  "item-text": "nameEn",
                  "open-on-click": true,
                  dense: true,
                  activatable: false,
                  hoverable: false,
                  "return-object": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function (props) {
                      return [
                        _c(
                          "draggable",
                          {
                            attrs: {
                              id: props.item.id,
                              list: _vm.draggableLevels,
                              group: "node" + props.item.parentId || "",
                              draggable: ".drag-item",
                              "data-parent": props.item.parentId,
                            },
                            on: { end: _vm.checkEnd },
                          },
                          [
                            _c("div", { staticClass: "drag-item" }, [
                              _c("span", {
                                class: {
                                  hasChildren: props.item.levels.length > 0,
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "treeItem",
                                  class: { isParent: !props.item.parentId },
                                },
                                [
                                  _c("span", { staticClass: "index" }, [
                                    _vm._v(_vm._s(props.item.csNumber)),
                                  ]),
                                  _vm._v(" " + _vm._s(props.item.nameEn) + " "),
                                  _vm._l(
                                    props.item.tags,
                                    function (node, index) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: index,
                                          staticClass: "ma-2",
                                          attrs: {
                                            close: "",
                                            "close-icon": "mdi-close",
                                            label: "",
                                            small: "",
                                          },
                                          on: {
                                            "click:close": function ($event) {
                                              return _vm.removeTag(
                                                props.item.id,
                                                node.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(node.name) + " ")]
                                      )
                                    }
                                  ),
                                  _c("span", {
                                    staticClass: "edit-action",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.editLevel(props.item)
                                      },
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "additional-options" },
                                    [
                                      _c("span", {
                                        staticClass: "gold-actions newlevel",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.createLevel(props.item)
                                          },
                                        },
                                      }),
                                      _c("span", {
                                        staticClass: "gold-actions sublevel",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.createSubLevel(
                                              props.item
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "btn btn-link btn-remove",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.deleteLevelById(
                                                props.item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t(`common.remove`))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.current && _vm.current.levels
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xs-8 col-sm-12" }, [
              _c("div", { staticClass: "btns-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-grey btn-medium mr-3",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.goBack.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(`common.back`)) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-grey btn-medium",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.deleteStructure.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`commonDetails.deleteStructure`)) +
                        " "
                    ),
                  ]
                ),
                _vm.draggableLevels.length > 0
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-gold btn-medium btn-print mr-3 ml-3",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.duplicate.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t(`commonDetails.duplicateStructure`)) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("DuplicateStructure", {
        attrs: {
          "is-modal-open": _vm.isModalDuplicateOpen,
          "close-dialog": _vm.closeDialog,
          item: _vm.current,
        },
      }),
      _c("EditLevels", {
        attrs: {
          "is-modal-open": _vm.isModalOpen,
          "close-dialog": _vm.closeDialog,
          item: _vm.item,
          "get-data": _vm.getData,
          "name-en": _vm.item.nameEn,
          "is-book": _vm.item.isBook,
          "selected-tags": _vm.selectedTags,
          type: _vm.managementType,
          "lowest-level": _vm.lowestLevelFound,
          "highest-level": _vm.highestLevelFound,
          loading: _vm.setLoading,
          "initial-without-level": _vm.item.position < 0,
        },
      }),
      _c("ConfirmModal", {
        staticStyle: { "z-index": "20001" },
        attrs: {
          "is-modal-open": _vm.isConfirmModalOpen,
          "close-dialog": _vm.closeConfirmModal,
          "confirm-callback": _vm.confirmCallback,
          "lable-text": _vm.confirmLableText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }