var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AuthLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("Logo", [_c("v-spacer"), _c("LanguageSelector")], 1),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("p", [_vm._v(_vm._s(_vm.$t(`resetPassword.text`)))]),
          ]),
        ],
        1
      ),
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "reset_form",
          attrs: { "lazy-validation": "" },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("InputField", {
                    attrs: {
                      name: _vm.$t(`resetPassword.passwordRecoveryCode`),
                      tabindex: 1,
                      value: _vm.confirmationKey,
                      "use-model": true,
                      type: "text",
                      required: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        _vm.confirmationKey = $event
                      },
                      update: function ($event) {
                        _vm.confirmationKey = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("InputField", {
                    attrs: {
                      name: _vm.$t(`common.username`),
                      tabindex: 2,
                      value: _vm.email,
                      "use-model": true,
                      type: "text",
                      readonly: true,
                      "is-disabled": true,
                    },
                    on: {
                      "update:value": function ($event) {
                        _vm.email = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("InputField", {
                    attrs: {
                      name: _vm.$t(`common.newPassword`),
                      tabindex: 3,
                      value: _vm.newPasswordModel,
                      "use-model": true,
                      type: "password",
                      required: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        _vm.newPasswordModel = $event
                      },
                      update: function ($event) {
                        _vm.newPasswordModel = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("InputField", {
                    attrs: {
                      name: _vm.$t(`common.confirmPassword`),
                      tabindex: 4,
                      value: _vm.confirmNewPasswordModel,
                      "use-model": true,
                      type: "password",
                      required: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        _vm.confirmNewPasswordModel = $event
                      },
                      update: function ($event) {
                        _vm.confirmNewPasswordModel = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.feedback
                ? _c("v-col", { attrs: { cols: "12" } }, [
                    _c("p", { attrs: { id: "feedback" } }, [
                      _vm._v(_vm._s(_vm.feedback)),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "6" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-green",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(`common.submit`)) + " ")]
                ),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("button", { staticClass: "btn btn-grey" }, [
                      _vm._v(_vm._s(_vm.$t(`common.back`))),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }