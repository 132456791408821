var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("Headings", {
            staticClass: "col-6 p-0 d-inline-block",
            attrs: {
              title: _vm.$t(`documentManagement.docManagement`),
              "back-location": "DocManagement",
              "query-params": { search: _vm.$route.params.search },
              subtitle:
                _vm.$t("common.upload") +
                " " +
                (_vm.isMasterUpload
                  ? _vm.$t("documentManagement.master") + " "
                  : "") +
                (_vm.currentDocument.type || _vm.$t("editLevels.file")),
            },
          }),
          _c("div", { staticClass: "col-6 d-inline-block" }, [
            !_vm.isNewDocument && _vm.versions
              ? _c(
                  "div",
                  { staticClass: "version-control-container" },
                  [
                    _c("SelectComponent", {
                      staticClass: "select-big version-select-control",
                      attrs: {
                        tabindex: 2,
                        options: _vm.versions,
                        name: "Version Control:",
                        "obj-key": "displayValue",
                        "obj-val": "id",
                      },
                      on: { change: _vm.changeVersion },
                      model: {
                        value: _vm.currentVersion,
                        callback: function ($$v) {
                          _vm.currentVersion = $$v
                        },
                        expression: "currentVersion",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("DocManagementForm", {
        ref: "docManagementForm",
        attrs: {
          "current-document": _vm.currentDocument,
          "set-loading": (flag) => (_vm.isLoading = flag),
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", sm: "2" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-grey btn-doc-upload-action",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.goBack.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.close`)) + " ")]
                    ),
                  ]),
                  _vm.buttonVisible && !_vm.isPublished
                    ? _c("v-col", { attrs: { cols: "12", sm: "2" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-green btn-doc-upload-action",
                            class: _vm.documentsNotProcessed
                              ? "cursor-blocked"
                              : "",
                            attrs: { disabled: _vm.documentsNotProcessed },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.saveFile()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t(`common.save`)) + " ")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.buttonVisible &&
                  !_vm.isPublished &&
                  _vm.currentVersion !== "Master" &&
                  _vm.currentDocument.id
                    ? _c("v-col", { attrs: { cols: "12", sm: "2" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-green btn-doc-upload-action",
                            class:
                              _vm.documentsNotProcessed ||
                              _vm.documentsErrorProcessing
                                ? "cursor-blocked"
                                : _vm.hasUploadedFile
                                ? "btn-greyed cursor-blocked"
                                : "",
                            attrs: {
                              disabled:
                                _vm.documentsNotProcessed ||
                                _vm.documentsErrorProcessing ||
                                _vm.hasUploadedFile,
                              title: _vm.documentsNotProcessed
                                ? _vm.$t("documentManagement.please_wait")
                                : _vm.documentsErrorProcessing
                                ? _vm.$t("common.extractionError")
                                : "",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toggleModal.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t(`common.publish`)) + " ")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.buttonVisible
                    ? _c("v-col", { attrs: { cols: "12", sm: "3" } }, [
                        !_vm.isNewDocument
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-white btn-doc-upload-action",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.previewDocument.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `documentManagement.previewDocument`
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.currentDocument.type === "CID" && _vm.isMasterUpload
                    ? _c("v-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-green btn-doc-upload-action",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.saveFile(true)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t(`common.upload`)) + " ")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.buttonVisible
                    ? _c("v-col", { attrs: { cols: "12", sm: "3" } }, [
                        !_vm.isNewDocument
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-white btn-doc-upload-action",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.downloadDocument.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `documentManagement.downloadOriginalDocument`
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c("v-col", { attrs: { cols: "12", sm: "3" } }, [
                    !_vm.isNewDocument
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-white btn-doc-upload-action",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openCopyToNewVersionModal.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(`documentManagement.copyToNewVersion`)
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm.isPublished
                    ? _c("v-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-red btn-doc-upload-action",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toggleUnpublishModal.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(`documentManagement.unpublishAction`)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.canDeleteWholeYear
                    ? _c("v-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-red btn-doc-upload-action",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toggleDeleteWholeYearModal.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    `documentManagement.deleteWholeYearButton`,
                                    {
                                      year: _vm.currentDocument.year,
                                      organization:
                                        _vm.currentDocument.organizationName,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Modal", {
        attrs: {
          "is-modal-open": _vm.isModalOpen,
          "close-dialog": _vm.closeDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-heading mb-10" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`documentManagement.publishConfirmation`)) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "modal-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t(`documentManagement.publishProceed`)) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-3" }),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-cancel",
                        on: { click: _vm.confirmPublish },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.yes`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-confirm",
                        on: { click: _vm.closeDialog },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.no`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Modal", {
        attrs: {
          "is-modal-open": _vm.isMissingFilesModalOpen,
          "close-dialog": _vm.closeMissingFilesModal,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-heading mb-10" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(`documentManagement.missingFilesModalHeading`)
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "modal-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(`documentManagement.missingFilesModalText`)
                      ) +
                      " "
                  ),
                ]),
                _vm._l(_vm.missingFiles, function (missingFile) {
                  return _c(
                    "div",
                    { key: missingFile[0], staticClass: "row" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("SelectComponent", {
                            staticClass: "select-small mt-8",
                            attrs: {
                              value: _vm.missingFiles.get(missingFile[0]),
                              tabindex: 3,
                              options:
                                _vm.missingFilesOptions.get(missingFile[0]) ||
                                [],
                              name: missingFile[0],
                              "obj-key": "year",
                              "obj-val": "year",
                              "is-disabled":
                                _vm.missingFilesOptions.get(missingFile[0])
                                  ?.length == 0,
                              change: (val) => {
                                _vm.isMissingFilesModified =
                                  !_vm.isMissingFilesModified
                                _vm.missingFiles.set(missingFile[0], val)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-1" }),
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-cancel",
                        attrs: {
                          disabled: _vm.isCopyExistingFilesButtonDisabled,
                        },
                        on: { click: _vm.copyExistingFile },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(`documentManagement.copyExistingFiles`)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-confirm",
                        on: { click: _vm.confirmPublish },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t(`documentManagement.withoutFiles`)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-confirm",
                        on: { click: _vm.closeMissingFilesModal },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.cancel`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Modal", {
        attrs: {
          "is-modal-open": _vm.isUnpublishModalOpen,
          "close-dialog": _vm.closeUnpublishModal,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-heading mb-10" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(`documentManagement.unpublishConfirmation`)
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "modal-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `documentManagement.unpublishConfirmationDetails`
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-3" }),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-cancel",
                        on: { click: _vm.confirmUnpublish },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.yes`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-confirm",
                        on: { click: _vm.closeUnpublishModal },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.no`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Modal", {
        attrs: {
          "is-modal-open": _vm.isDeleteWholeYearModalOpen,
          "close-dialog": _vm.closeDeleteWholeYearModal,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-heading mb-10" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `documentManagement.deleteWholeYearConfirmation`,
                          {
                            year: _vm.currentDocument.year,
                            organization: _vm.currentDocument.organizationName,
                          }
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "modal-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          `documentManagement.deleteWholeYearConfirmationDetails`
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-3" }),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-cancel",
                        on: { click: _vm.confirmDeleteWholeYear },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.yes`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-confirm",
                        on: { click: _vm.closeDeleteWholeYearModal },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.no`)) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Modal", {
        attrs: {
          "is-modal-open": _vm.isPreviewModalOpen,
          "close-dialog": _vm.closePreviewDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-heading mb-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("documentManagement.select_preview")) + ":"
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _vm._l(_vm.filteredBooksOnly, function (book, index) {
                      return [
                        _vm.selectedBookFiles[index].status === "PROCESSED"
                          ? _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "col-12 mb-4 preview-file-container",
                                on: {
                                  click: function ($event) {
                                    return _vm.goToPreviewDocument(index)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(book.nameEn) + " ")]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Modal", {
        attrs: {
          "is-modal-open": _vm.isDownloadModalOpen,
          "close-dialog": _vm.closeDownloadDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-heading mb-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("documentManagement.select_download")) + ":"
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _vm._l(_vm.filteredBooksOnly, function (book, index) {
                      return [
                        _vm.selectedBookFiles[index].status === "PROCESSED"
                          ? _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "col-12 mb-4 preview-file-container",
                                on: {
                                  click: function ($event) {
                                    return _vm.goToDownloadDocument(index)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(book.nameEn) + " ")]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Modal", {
        attrs: {
          id: "copyToNewVersionModal",
          "is-modal-open": _vm.isCopyToNewVersionModalOpen,
          "close-dialog": _vm.closeCopyToNewVersionModal,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-heading mb-10" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(`documentManagement.copyToNewVersionHeading`)
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "modal-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(`documentManagement.copyToNewVersionText`)
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "row mt-10 centered" }, [
                  _c(
                    "div",
                    { staticClass: "col-8" },
                    [
                      _c("InputField", {
                        ref: "newVersionField",
                        attrs: {
                          name: "documentManagement.newVersionField",
                          value: _vm.newVersion,
                          "use-model": true,
                          error: _vm.newVersionError,
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.newVersion = $event
                          },
                          update: function ($event) {
                            return _vm.validateVersionNumber($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row centered" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-cancel",
                        attrs: {
                          disabled: !_vm.newVersion || !!_vm.newVersionError,
                        },
                        on: { click: _vm.confirmCopyToNewVersion },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(`documentManagement.copyToNewVersion`)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-button-confirm",
                        on: { click: _vm.closeCopyToNewVersionModal },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(`common.cancel`)) + " ")]
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }