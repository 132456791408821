import store from '../store';
import { axiosInstance } from '../utils/axios.js';
import { CHECK_SESSION_URL } from '../utils/api.endpoints';

export const isLoggedIn = async (toRoute) => {
  const sessionID = window.localStorage.getItem('sessionID');
  const isAnonymous = window.localStorage.getItem('anonymous') === 'true';
  return axiosInstance.get(CHECK_SESSION_URL).then((data) => {
    const hasError = Object.prototype.hasOwnProperty.call(data, 'error');
    if (!hasError) store.commit('auth/hydrateUser', data.user);

    // check if user has permissions to view the route
    const validRoles = toRoute.meta ? toRoute.meta.validRoles : null;
    if (validRoles && !validRoles.includes(data.user?.roles)) {
      return false;
    }

    if (hasError && !isAnonymous) window.localStorage.removeItem('sessionID');
    return !!sessionID && !hasError;
  });
};
